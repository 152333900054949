import React, { PureComponent } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSConvDemand extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.convdemand) {
      for (let i = 0; i < props.convdemand.length; i++) {
        const id = props.convdemand[i].id;
        for (let char in props.convdemand[i]) {
          if (char !== "id" && char !== "tech") {
            let dataKey = id + "_" + char;
            this.state[dataKey] = props.convdemand[i][char];
            dataKey += "_changed";
            this.state[dataKey] = false;
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this);
  }

  itemUpdate(event) {
    if (event.target.id) {
      let [type, id, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      const valKey = id + "_" + characteristic;
      const changedKey = valKey + "_changed";
      this.setState({ [valKey]: val, [changedKey]: true });
      let params = { type: type, id: id, vals: { [characteristic]: val } };
      this.props.fieldUpdate(params);
    }
  }

  render() {
    console.log("Rendering EDFSConvDemand");
    //console.log("convdemand", this.props.convdemand);
    //console.log("state", this.state);
    return (
      <MDBRow className="px-2">
        {this.props.convdemand.map((item) => (
          <MDBCol
            key={"convdemand_" + item.id + "_col"}
            id={"convdemand_" + item.id + "_col"}
            className="p-2"
            size="12"
            sm="6"
          >
            <MDBCard id={"convdemand_" + item.id + "_card"}>
              <MDBCardHeader id={"convdemand_" + item.id + "_header"}>
                {item.tech}
              </MDBCardHeader>
              <MDBCardBody
                id={"convdemand_" + item.id + "_body"}
                className="p-1"
              >
                <div className="form-group row px-2">
                  <label className="col-form-label col">
                    Demand <small className="text-muted">TWh</small>
                  </label>
                  <div className="col">
                    <input
                      id={"convdemand_" + item.id + "_elec"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_elec_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      value={this.state[item.id + "_elec"]}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    );
  }
}

export default EDFSConvDemand;
