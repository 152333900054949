import React, { Component } from "react";
import { MDBBtnGroup, MDBBtn, MDBRangeInput, MDBInput } from "mdbreact";
import moment from "moment";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { standardColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("standardColorSet", standardColorset);

class EDFSStorageElecCostChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: "manual",
      month: 0,
      week: 0,
      viewportRange: { viewportMinimum: null, viewportMaximum: null },
    };

    this.months = [
      { abbrev: "", range: { viewportMinimum: null, viewportMaximum: null } },
    ];

    this.weeks = [{ viewportMinimum: null, viewportMaximum: null }];

    if (typeof props.storageData.pumped[0] === "object") {
      const startDate = props.storageData.pumped[0].x;
      let startMoment = moment(startDate);
      let i = 1;
      for (let monthAbbrev of [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "month");
        this.months[i] = {
          abbrev: monthAbbrev,
          range: {
            viewportMinimum: startMoment.toDate(),
            viewportMaximum: endMoment.toDate(),
          },
        };
        startMoment = endMoment;
        i++;
      }

      startMoment = moment(startDate);
      for (let i = 1; i < 54; i++) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "week");
        this.weeks[i] = {
          viewportMinimum: startMoment.toDate(),
          viewportMaximum: endMoment.toDate(),
        };
        startMoment = endMoment;
      }
    }

    this.changeWeek = this.changeWeek.bind(this);
  }

  changeWeek(weekNo) {
    console.log(weekNo, this.weeks[weekNo]);
    this.setState({
      week: weekNo,
      viewportRange: this.weeks[weekNo],
    });
  }

  render() {
    console.log("Rendering EDFSStorageElecCostChart");
    //console.log("props", this.props);
    //const inflexibles = this.props.inflexiblesData || [];
    const names = {
      pumped: "Pumped Storage",
      batteries: "Batteries",
      compressedAir: "Compressed Air",
    };
    const dataFields = [];
    let minFlow = 0;
    let maxFlow = 0;
    if (
      this.props.storageData &&
      this.props.storageData.pumped &&
      this.props.storageData.pumped.length
    ) {
      for (let item in this.props.storageData) {
        dataFields.push({
          type: "line",
          name: names[item] + " flow (L)",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.storageData[item],
        });
        const orderedValues = this.props.storageData[item]
          .map(obj => obj.y)
          .sort((a, b) => a - b);
        //console.log(names[item] + " orderedValues", orderedValues);
        const topTwenty = orderedValues.slice(-20);
        //console.log(names[item] + " topTwenty", topTwenty);
        const bottomTwenty = orderedValues.slice(0, 20);
        //console.log(names[item] + " bottomTwenty", bottomTwenty);
        const topAvg = Math.ceil(topTwenty.reduce((a, b) => a + b) / 20);
        const bottomAvg = Math.floor(bottomTwenty.reduce((a, b) => a + b) / 20);
        const techMaxFlow = topTwenty[0] / topAvg > 2 ? topAvg : topTwenty[0];
        const techMinFlow =
          bottomAvg !== 0 &&
          Math.abs(bottomTwenty[19]) / Math.abs(bottomAvg) > 2
            ? bottomAvg
            : bottomTwenty[19];
        if (techMinFlow < minFlow) {
          minFlow = techMinFlow;
        }
        if (techMaxFlow > maxFlow) {
          maxFlow = techMaxFlow;
        }
      }

      let maxCost = 0;
      let minCost = 0;
      for (let item in this.props.storElecCostData) {
        dataFields.push({
          type: "line",
          name: names[item] + " elec. £ (R)",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          axisYType: "secondary",
          dataPoints: this.props.storElecCostData[item],
        });

        const orderedValues = this.props.storElecCostData[item]
          .map(obj => obj.y)
          .sort((a, b) => a - b);
        const topTwenty = orderedValues.slice(-20);
        const bottomTwenty = orderedValues.slice(0, 20);
        const topAvg = Math.ceil(topTwenty.reduce((a, b) => a + b) / 20);
        const bottomAvg = Math.floor(bottomTwenty.reduce((a, b) => a + b) / 20);
        const techMaxCost = topTwenty[0] / topAvg > 2 ? topAvg : topTwenty[0];
        const techMinCost =
          bottomAvg !== 0 &&
          Math.abs(bottomTwenty[19]) / Math.abs(bottomAvg) > 2
            ? bottomAvg
            : bottomTwenty[19];
        if (techMinCost < minCost) {
          minCost = techMinCost;
        }
        if (techMaxCost > maxCost) {
          maxCost = techMaxCost;
        }
      }

      //console.log("maxFlow", maxFlow);
      //console.log("minFlow", minFlow);
      //console.log("maxCost", maxCost);
      //console.log("minCost", minCost);
      let maxAxis1 = Math.max(maxFlow, Math.abs(minFlow));
      let factor = Math.pow(10, parseInt(maxAxis1).toString().length - 1);
      maxAxis1 = factor * Math.ceil(maxAxis1 / factor);
      const minAxis1 = -1 * maxAxis1;
      let maxAxis2 = Math.max(maxCost, Math.abs(minCost));
      factor = Math.pow(10, parseInt(maxAxis2).toString().length - 1);
      maxAxis2 = factor * Math.ceil(maxAxis2 / factor);
      const minAxis2 = -1 * maxAxis2;
      /*let factor = Math.pow(10, parseInt(maxFlow).toString().length - 2);
      let minAxis1 = factor * Math.floor(minFlow / factor);
      let maxAxis1 = -1 * minAxis1;
      if (maxAxis1 < maxFlow) {
        maxAxis1 = factor * Math.ceil(maxFlow / factor);
        minAxis1 = maxAxis1;
      }
      let intAxis1 = -1 * minAxis1;
      factor = Math.pow(10, parseInt(maxCost).toString().length - 2);
      let maxAxis2 = factor * Math.ceil(maxCost / factor);
      let intAxis2 = maxAxis2;
      let minAxis2 = -1 * intAxis2;
      if (maxAxis2 > maxAxis1) {
        if (maxAxis2 / maxAxis1 < 2) {
          minAxis1 = minAxis2;
          maxAxis1 = maxAxis2;
          intAxis1 = intAxis2;
        }
      } else {
        if (maxAxis1 / maxAxis2 < 2) {
          minAxis2 = minAxis1;
          maxAxis2 = maxAxis1;
          intAxis2 = intAxis1;
        }
      }*/

      //console.log("dataFields", dataFields);
      const options = {
        theme: "light2",
        colorSet: "standardColorSet",
        exportEnabled: true,
        zoomEnabled: true,
        rangeChanged: function(e) {
          const threshold = 300000000;
          const timediff =
            e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
          if (timediff > threshold || e.trigger === "reset") {
            this.options.axisX.valueFormatString = "D MMM";
          } else {
            this.options.axisX.valueFormatString = "D MMM HH:mm";
          }
          this.render();
        },
        zoomType: "xy",
        title: {
          text: "Storage flows and electricity cost/income",
          fontSize: 20,
          padding: {
            top: 1,
            bottom: 1,
            left: 30,
            right: 30,
          },
        },
        axisX: {
          ...this.state.viewportRange,
          valueFormatString: "D MMM",
          labelAngle: 45,
        },
        axisY: {
          includeZero: true,
          title: "MW",
          minimum: minAxis1,
          maximum: maxAxis1,
          //interval: intAxis1,
        },
        axisY2: {
          includeZero: true,
          title: "£",
          minimum: minAxis2,
          maximum: maxAxis2,
          //interval: intAxis2,
        },
        toolTip: {
          shared: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: function(e) {
            //console.log("legend click: " + e.dataPointIndex);
            //console.log(e);
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }

            e.chart.render();
          },
        },
        data: dataFields,
      };

      return (
        <div className="d-flex flex-column">
          <CanvasJSChart options={options} onRef={ref => (this.chart = ref)} />
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            Zoom:
            <MDBBtnGroup size="sm">
              <MDBBtn
                active={this.state.zoom === "manual"}
                onClick={() =>
                  this.setState({
                    zoom: "manual",
                    viewportRange: this.months[0].range,
                  })
                }
              >
                Manual
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "month"}
                onClick={() =>
                  this.setState({
                    zoom: "month",
                    viewportRange: this.months[this.state.month].range,
                  })
                }
              >
                Month
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "week"}
                onClick={() =>
                  this.setState({
                    zoom: "week",
                    viewportRange: this.weeks[this.state.week],
                  })
                }
              >
                Week
              </MDBBtn>
            </MDBBtnGroup>
          </div>
          {this.state.zoom === "month" && (
            <div className="d-flex flex-row justify-content-center align-items-center mt-2">
              <MDBBtnGroup size="sm">
                {this.months.map((object, index) => {
                  if (index > 0) {
                    return (
                      <MDBBtn
                        key={object.abbrev}
                        active={this.state.month === index}
                        className="px-2"
                        onClick={() =>
                          this.setState({
                            month: index,
                            viewportRange: this.months[index].range,
                          })
                        }
                      >
                        {object.abbrev}
                      </MDBBtn>
                    );
                  } else {
                    return "";
                  }
                })}
              </MDBBtnGroup>
            </div>
          )}
          {this.state.zoom === "week" && (
            <div className="d-flex flex-row align-items-center">
              <div className="justify-content-start mx-1">1</div>
              <div className="flex-fill mt-2">
                <MDBRangeInput
                  min={1}
                  max={53}
                  value={this.state.week}
                  getValue={this.changeWeek}
                />
              </div>
              <div className="justify-content-end mx-1">53</div>
              <div className="justify-content-end mx-1">
                <MDBInput
                  value={this.state.week}
                  size="sm"
                  min={1}
                  max={53}
                  type="number"
                  getValue={this.changeWeek}
                  outline
                />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return "Pending...";
    }
  }
}

export default EDFSStorageElecCostChart;
