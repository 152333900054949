import React from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput } from "mdbreact";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLegend,
  VictoryLabel,
  //VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryStack,
  VictoryArea,
  createContainer,
  VictoryBrushContainer,
} from "victory";
import EDScatterWithTrend from "./EDScatterWithTrend";
//import EDBMRLineChartContents from "./EDBMRLineChartContents";

class EDBMRGenUnitChart extends React.Component {
  constructor(props) {
    super(props);
    this.mainTitles = {
      hh: {
        1: "Half-hourly total",
        2: "Half-hourly average",
        3: "Half-hourly load factor",
      },
      day: {
        1: "Daily total",
        2: "Daily average",
        3: "Daily load factor",
      },
    };
    this.yTitles = {
      hh: {
        1: "MW",
        2: "MW",
        3: "%",
      },
      day: {
        1: "MWh",
        2: "MWh",
        3: "%",
      },
    };
    this.dimensions = {
      day: {
        chartheight: 240,
        chartwidth: 530,
        padbottom: 25,
        padleft: 84,
        padright: 160,
        padtop: 40,
        xaxiswidth: 500,
      },
      hh: {
        chartheight: 240,
        chartwidth: 530,
        padbottom: 25,
        padleft: 73,
        padright: 160,
        padtop: 40,
        xaxiswidth: 500,
      },
    };

    this.state = {
      expanded: -1,
      stacked: [],
      mainTitle: this.mainTitles[props.interval][props.dataType],
      yTitle1: this.yTitles[props.interval][props.dataType],
      yTitle2: this.yTitles[props.interval][props.dataType],
      dimensions: this.dimensions[props.interval],
      y2disabled: true,
      voronoiDisabled: false,
      linedata: [],
      fieldnames: {},
      maxmax: 1000000,
      ylower: 0,
      tickValues: [0.2, 0.4, 0.6, 0.8, 1],
      divisors: {},
      div2: 0,
      zoomDomain: { x: [] },
      defaultZoomDomain: { x: [] },
    };
    this.pad = this.pad.bind(this);
    this.calcChartDims = this.calcChartDims.bind(this);
    this.labelText = this.labelText.bind(this);
    this.expandCell = this.expandCell.bind(this);
    this.isStacked = this.isStacked.bind(this);
    this.toggleStacked = this.toggleStacked.bind(this);
    this.myDateFormat = this.myDateFormat.bind(this);
    this.setMainTitle = this.setMainTitle.bind(this);
    this.setyTitle1 = this.setyTitle1.bind(this);
    this.setyTitle2 = this.setyTitle2.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows) {
      const regex = /group/;
      const linedata = this.props.columns.filter(column => {
        return (
          column != null && column.field != null && regex.test(column.field)
        );
      });
      const fieldnames = linedata.reduce((map, obj) => {
        map[obj.field] = obj.label;
        return map;
      }, {});
      const chartDims = this.calcChartDims(linedata, this.state.stacked);
      chartDims.linedata = linedata;
      chartDims.fieldnames = fieldnames;
      if (this.props.rows && this.props.rows.length) {
        const firstdate = this.props.rows[0].d99datetime;
        const lastdate = this.props.rows[this.props.rows.length - 1]
          .d99datetime;
        chartDims.zoomDomain = { x: [new Date(firstdate), new Date(lastdate)] };
        chartDims.defaultZoomDomain = {
          x: [new Date(firstdate), new Date(lastdate)],
        };
        chartDims.voronoiDisabled = false;
      }
      this.setState(chartDims);
    }
    if (
      this.props.interval !== prevProps.interval ||
      this.props.dataType !== prevProps.dataType
    ) {
      console.log("update setting state...");
      this.setState({
        mainTitle: this.mainTitles[this.props.interval][this.props.dataType],
        yTitle1: this.yTitles[this.props.interval][this.props.dataType],
        yTitle2: this.yTitles[this.props.interval][this.props.dataType],
      });
    }
  }

  calcChartDims(linedata, stacked) {
    if (!stacked) {
      stacked = this.state.stacked;
    }

    const maxima = linedata.map(column =>
      Math.max(...this.props.rows.map(row => row[column.field]))
    );
    const minima = linedata.map(column =>
      Math.min(...this.props.rows.map(row => row[column.field]))
    );
    let ratios = maxima
      .map((val, index) => minima[index] / val)
      .filter(val => !isNaN(val));

    let minratio = Math.min(...ratios);
    let maxmax = Math.ceil(Math.max(...maxima));

    const combmaxima = { combo: 0 };
    const combminima = { combo: 0 };
    const notStacked = linedata
      .filter(column => !this.isStacked(column.field, stacked))
      .map(column => column.field);
    if (stacked.length > 1) {
      notStacked.forEach(function(fieldname) {
        combmaxima[fieldname] = 0;
        combminima[fieldname] = 0;
      });
      for (let i = 0; i < this.props.rows.length; i++) {
        const thisrow = this.props.rows[i];
        const stackedvals = stacked.map(fieldname => thisrow[fieldname]);
        const combmax = stackedvals
          .map(val => (val > 0 ? val : 0))
          .reduce((x, y) => x + y);
        if (combmax > combmaxima.combo) {
          combmaxima.combo = combmax;
        }
        const combmin = stackedvals
          .map(val => (val < 0 ? val : 0))
          .reduce((x, y) => x + y);
        if (combmin < combminima.combo) {
          combminima.combo = combmin;
        }

        notStacked.forEach(function(fieldname) {
          if (thisrow[fieldname] > combmaxima[fieldname]) {
            combmaxima[fieldname] = thisrow[fieldname];
          }
          if (thisrow[fieldname] < combminima[fieldname]) {
            combminima[fieldname] = thisrow[fieldname];
          }
        });
      }

      if (combmaxima.combo > maxmax) {
        maxmax = Math.ceil(combmaxima.combo);
      }
      minratio = 0;
      if (combmaxima.combo) {
        const combratio = combminima.combo / combmaxima.combo;
        minratio = combratio;
      }

      for (let i = 0; i < notStacked.length; i++) {
        const nsmax = combmaxima[notStacked[i]];
        const nsmin = combminima[notStacked[i]];
        if (nsmax > 0 && nsmin < 0) {
          const nsratio = nsmin / nsmax;
          if (nsratio < minratio) {
            minratio = nsratio;
          }
        }
      }
    }

    let ylower = 0;
    let multiplier = 5;
    let tickValues = [0.2, 0.4, 0.6, 0.8, 1];
    if (minratio < 0) {
      if (minratio >= -0.25) {
        ylower = -0.25;
        multiplier = 4;
        tickValues = [-0.25, 0, 0.25, 0.5, 0.75, 1];
      } else if (minratio >= -2 / 3) {
        ylower = -2 / 3;
        multiplier = 3;
        tickValues = [-2 / 3, -1 / 3, 0, 1 / 3, 2 / 3, 1];
      } else {
        ylower = -1;
        multiplier = 2.5;
        tickValues = [-1, -0.6, -0.2, 0, 0.2, 0.6, 1];
      }
    }

    const factor1 = multiplier * Math.pow(10, maxmax.toString().length - 2);
    maxmax = factor1 * Math.ceil(maxmax / factor1);
    const rhindex = [];
    const divisors = {};
    let div2 = 0;
    if (stacked.length > 1) {
      let stackright = false;
      if (combmaxima.combo / maxmax < 0.2) {
        stackright = true;
      }

      for (let i = 0; i < linedata.length; i++) {
        if (this.isStacked(linedata[i].field, stacked)) {
          if (stackright) {
            rhindex.push(i);
            if (combmaxima.combo > div2) {
              div2 = combmaxima.combo;
            }
            if (Math.abs(combminima.combo) > div2) {
              div2 = Math.abs(combminima.combo);
            }
          }
        } else {
          if (combmaxima[linedata[i].field] / maxmax < 0.2) {
            rhindex.push(i);
            if (combmaxima[linedata[i].field] > div2) {
              div2 = combmaxima[linedata[i].field];
            }
            if (Math.abs(combminima[linedata[i].field]) > div2) {
              div2 = Math.abs(combminima[linedata[i].field]);
            }
          }
        }
        divisors[linedata[i].field] = maxmax;
        linedata[i].label2 = "";
      }
    } else {
      for (let i = 0; i < maxima.length; i++) {
        if (
          maxima[i] / maxmax < 0.2 &&
          !this.isStacked(linedata[i].field, stacked)
        ) {
          rhindex.push(i);
          if (maxima[i] > div2) {
            div2 = maxima[i];
          }
          if (Math.abs(minima[i]) > div2) {
            div2 = Math.abs(minima[i]);
          }
        }
        divisors[linedata[i].field] = maxmax;
        linedata[i].label2 = "";
      }
    }

    let y2disabled = true;
    if (rhindex.length) {
      div2 = Math.ceil(div2);
      const factor2 = multiplier * Math.pow(10, div2.toString().length - 2);
      div2 = factor2 * Math.ceil(div2 / factor2);
      for (let i = 0; i < rhindex.length; i++) {
        divisors[linedata[rhindex[i]].field] = div2;
        linedata[rhindex[i]].label2 = " (RH)";
      }
      y2disabled = false;
    }
    return {
      maxmax: maxmax,
      ylower: ylower,
      tickValues: tickValues,
      divisors: divisors,
      div2: div2,
      y2disabled: y2disabled,
    };
  }

  pad(val) {
    return ("00" + val).slice(-2);
  }

  labelText(point, fieldnames) {
    const xdata = this.myDateFormat(new Date(point.d99datetime));
    const ydata = Object.keys(point)
      .filter(d => fieldnames[d])
      .map(d => {
        let decimalplaces = 4 - parseInt(point[d]).toString().length;
        decimalplaces = (decimalplaces + Math.abs(decimalplaces)) / 2;
        return (
          fieldnames[d] +
          ": " +
          (typeof point[d] === "number"
            ? decimalplaces
              ? point[d].toFixed(decimalplaces)
              : point[d].toLocaleString()
            : "")
        );
      })
      //.map(d => fieldnames[d] + ": " + Math.round(point[d], 0))
      //.map(d => fieldnames[d] + ": " + point[d].toPrecision(4))
      .join("\n");
    return xdata + "\n" + ydata;
  }

  expandCell(index) {
    if (this.state.expanded === index) {
      this.setState({ expanded: -1 });
    } else {
      this.setState({ expanded: index });
    }
  }

  setMainTitle = event => {
    this.setState({ mainTitle: event.target.value });
  };

  setyTitle1 = event => {
    this.setState({ yTitle1: event.target.value });
  };

  setyTitle2 = event => {
    this.setState({ yTitle2: event.target.value });
  };

  handleZoom(domain) {
    this.setState({ zoomDomain: domain, voronoiDisabled: true });
  }

  isStacked(fieldname, stacked) {
    if (!stacked) {
      stacked = this.state.stacked;
    }
    if (stacked.length) {
      for (let i = 0; i < stacked.length; i++) {
        if (stacked[i] === fieldname) {
          return true;
        }
      }
    }
    return false;
  }

  toggleStacked(fieldname) {
    const stacked = JSON.parse(JSON.stringify(this.state.stacked));
    let selected = false;
    if (stacked.length) {
      for (let i = 0; i < stacked.length; i++) {
        if (stacked[i] === fieldname) {
          selected = stacked.splice(i, 1)[0];
          break;
        }
      }
    }
    if (!selected) {
      stacked.push(fieldname);
    }
    const chartDims = this.calcChartDims(this.state.linedata, stacked);
    chartDims.stacked = stacked;
    this.setState(chartDims);
  }

  myDateFormat(dateobj) {
    if (this.props.interval === "day") {
      return dateobj.toDateString();
    } else {
      return (
        dateobj.toDateString() +
        " " +
        this.pad(dateobj.getHours()) +
        ":" +
        this.pad(dateobj.getMinutes())
      );
    }
  }

  render() {
    if (this.props.rows) {
      console.log("rendering chart...");
      const {
        linedata,
        fieldnames,
        maxmax,
        ylower,
        tickValues,
        divisors,
        div2,
        mainTitle,
        yTitle1,
        yTitle2,
      } = this.state;

      if (this.props.chartType === "scatter") {
        let pairs = [];
        let pairslength = 0;
        let breakpoints = [];
        const columncount = linedata.length;
        for (let i = 0; i < columncount - 1; i++) {
          for (let j = 1 + i; j < columncount; j++) {
            pairs.push([linedata[i].field, linedata[j].field]);
          }
        }

        pairslength = pairs.length;
        let colsizes = { sm: 6, lg: 4, xl: 3 };
        if (pairslength < 2) {
          colsizes.sm = 12;
          colsizes.lg = 12;
          colsizes.xl = 12;
        } else if (pairslength < 4) {
          colsizes.xl = 4;
        }

        for (let i = 0; i < pairslength; i++) {
          if (i === this.state.expanded) {
            breakpoints.push({
              sm: 12,
              lg: 12,
              xl: 12,
              className: "order-first",
              icon: "compress-arrows-alt",
            });
          } else {
            breakpoints.push({
              sm: colsizes.sm,
              lg: colsizes.lg,
              xl: colsizes.xl,
              className: "",
              icon: "expand-arrows-alt",
            });
          }
        }

        return (
          <MDBRow>
            {pairs.map((pair, index) => (
              <MDBCol
                key={"scatter" + this.pad(index)}
                sm={breakpoints[index].sm}
                lg={breakpoints[index].lg}
                xl={breakpoints[index].xl}
                className={"border " + breakpoints[index].className}
              >
                <EDScatterWithTrend
                  rows={this.props.rows}
                  x={pair[0]}
                  y={pair[1]}
                  xtitle={fieldnames[pair[0]]}
                  ytitle={fieldnames[pair[1]]}
                  from={this.props.from}
                  to={this.props.to}
                  dataType={this.props.dataType}
                  interval={this.props.interval}
                />
                {pairslength > 1 && (
                  <div
                    className="p-0 m-0 position-absolute"
                    style={{ bottom: "2px", right: "5px", cursor: "pointer" }}
                    onClick={() => {
                      this.expandCell(index);
                    }}
                  >
                    <MDBIcon icon={breakpoints[index].icon} />
                  </div>
                )}
              </MDBCol>
            ))}
          </MDBRow>
        );
      } else {
        const noStacked = linedata.filter(column => {
          return !this.isStacked(column.field);
        });
        const yesStacked = linedata.filter(column => {
          return this.isStacked(column.field);
        });
        const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");
        return (
          <>
            <VictoryChart
              scale={{ x: "time", y: "linear" }}
              height={this.dimensions[this.props.interval].chartheight}
              width={this.dimensions[this.props.interval].chartwidth}
              domain={{ y: [ylower, 1] }}
              padding={{
                bottom: this.dimensions[this.props.interval].padbottom,
                left: this.dimensions[this.props.interval].padleft,
                right: this.dimensions[this.props.interval].padright,
                top: this.dimensions[this.props.interval].padtop,
              }}
              containerComponent={
                <VictoryZoomVoronoiContainer
                  labels={point => this.labelText(point, fieldnames)}
                  labelComponent={<VictoryTooltip style={{ fontSize: 7 }} />}
                  voronoiPadding={50}
                  zoomDimension="x"
                  zoomDomain={this.state.zoomDomain}
                  onZoomDomainChange={this.handleZoom.bind(this)}
                  allowZoom={false}
                  disable={this.state.voronoiDisabled}
                />
              }
            >
              {yesStacked && yesStacked.length > 0 && (
                <VictoryStack
                  scale={{ x: "time", y: "linear" }}
                  height={this.dimensions[this.props.interval].chartheight}
                  width={this.dimensions[this.props.interval].chartwidth}
                  padding={{
                    bottom: this.dimensions[this.props.interval].padbottom,
                    left: this.dimensions[this.props.interval].padleft,
                    right: this.dimensions[this.props.interval].padright,
                    top: this.dimensions[this.props.interval].padtop,
                  }}
                >
                  {yesStacked.map(column => (
                    <VictoryArea
                      key={column.field}
                      data={this.props.rows}
                      x={d => new Date(d.d99datetime)}
                      y={row => row[column.field] / divisors[column.field]}
                      //y={column.field}
                      interpolation="stepAfter"
                      style={{
                        data: { fill: column.color },
                      }}
                    />
                  ))}
                </VictoryStack>
              )}
              {noStacked.map(column => (
                <VictoryLine
                  key={column.field}
                  data={this.props.rows}
                  x={d => new Date(d.d99datetime)}
                  y={row => row[column.field] / divisors[column.field]}
                  interpolation="stepAfter"
                  style={{
                    data: { stroke: column.color, strokeWidth: 1 },
                  }}
                />
              ))}

              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 10, padding: 5 },
                  ticks: { stroke: "gray", size: 5 },
                }}
                width={this.dimensions[this.props.interval].xaxiswidth}
                fixLabelOverlap={true}
                orientation="bottom"
                offsetY={this.dimensions[this.props.interval].padbottom}
              />

              <VictoryAxis
                dependentAxis
                tickValues={tickValues}
                tickFormat={t => (t * maxmax).toLocaleString()}
                label={yTitle1}
                style={{
                  tickLabels: { fontSize: 10, padding: 5 },
                  ticks: { stroke: "gray", size: 5 },
                  grid: { stroke: t => (t === 0 ? "black" : "transparent") },
                  axisLabel: { fontSize: 11, padding: 50 },
                }}
              />

              {div2 !== 0 && (
                <VictoryAxis
                  dependentAxis
                  orientation="right"
                  offsetX={160}
                  tickValues={tickValues}
                  tickFormat={t => (t * div2).toLocaleString()}
                  label={yTitle2}
                  style={{
                    tickLabels: {
                      fontSize: 10,
                      padding: 5,
                      textAnchor: "start",
                    },
                    ticks: {
                      stroke: "gray",
                      size: 5,
                    },
                    axisLabel: { fontSize: 11, padding: 38 },
                  }}
                />
              )}

              <VictoryLegend
                orientation="vertical"
                style={{
                  labels: { fontSize: 9 },
                  border: { stroke: "black" },
                }}
                data={linedata.map(column => ({
                  name: column.label + column.label2,
                  symbol: { type: "minus", fill: column.color },
                }))}
                y={40}
                x={420}
                rowGutter={{ top: 0, bottom: 0 }}
                padding={{ top: 1, bottom: 1 }}
                borderPadding={{ top: 8, bottom: 2, left: 8, right: 2 }}
                symbolSpacer={5}
              />

              <VictoryLabel
                //text={titles[this.props.dataType]}
                text={mainTitle}
                x={230}
                y={18}
                textAnchor="middle"
                style={{ fontSize: 18 }}
              />
            </VictoryChart>

            <VictoryChart
              scale={{ x: "time", y: "linear" }}
              height={50}
              width={this.dimensions[this.props.interval].chartwidth}
              domain={{ y: [ylower, 1] }}
              padding={{
                bottom: 25,
                left: this.dimensions[this.props.interval].padleft,
                right: this.dimensions[this.props.interval].padright,
                top: 2,
              }}
              containerComponent={
                <VictoryBrushContainer
                  brushDimension="x"
                  brushDomain={this.state.zoomDomain}
                  defaultBrushArea="none"
                  onBrushDomainChange={this.handleZoom.bind(this)}
                  onBrushCleared={() =>
                    this.setState({
                      voronoiDisabled: false,
                      zoomDomain: this.state.defaultZoomDomain,
                    })
                  }
                />
              }
            >
              {yesStacked && yesStacked.length > 0 && (
                <VictoryStack
                  scale={{ x: "time", y: "linear" }}
                  height={this.dimensions[this.props.interval].chartheight}
                  width={this.dimensions[this.props.interval].chartwidth}
                  padding={{
                    bottom: this.dimensions[this.props.interval].padbottom,
                    left: this.dimensions[this.props.interval].padleft,
                    right: this.dimensions[this.props.interval].padright,
                    top: this.dimensions[this.props.interval].padtop,
                  }}
                >
                  {yesStacked.map(column => (
                    <VictoryArea
                      key={column.field}
                      data={this.props.rows}
                      x={d => new Date(d.d99datetime)}
                      y={row => row[column.field] / divisors[column.field]}
                      //y={column.field}
                      interpolation="stepAfter"
                      style={{
                        data: { fill: column.color },
                      }}
                    />
                  ))}
                </VictoryStack>
              )}
              {noStacked.map(column => (
                <VictoryLine
                  key={column.field}
                  data={this.props.rows}
                  x={d => new Date(d.d99datetime)}
                  y={row => row[column.field] / divisors[column.field]}
                  interpolation="stepAfter"
                  style={{
                    data: { stroke: column.color, strokeWidth: 1 },
                  }}
                />
              ))}
              <VictoryAxis
                style={{
                  tickLabels: { fontSize: 8, padding: 3 },
                  ticks: { stroke: "gray", size: 3 },
                }}
                width={this.dimensions[this.props.interval].xaxiswidth}
                fixLabelOverlap={true}
                orientation="bottom"
                //offsetY={10}
              />

              <VictoryAxis
                dependentAxis
                tickValues={[0]}
                //tickFormat={t => (t * maxmax).toLocaleString()}
                style={{
                  tickLabels: { fontSize: 0, padding: 5 },
                  ticks: { stroke: "gray", size: 5 },
                  //  grid: { stroke: t => (t === 0 ? "black" : "transparent") },
                }}
              />
            </VictoryChart>

            <MDBRow>
              <MDBCol>
                <span>Stack:&nbsp;</span>
                <div
                  className="btn-group btn-group-toggle"
                  data-toggle="buttons"
                >
                  {Object.keys(fieldnames).map(fieldname => {
                    return (
                      <label
                        key={fieldname}
                        className={
                          "btn btn-default btn-sm form-check-label px-2 px-md-3 px-lg-4" +
                          (this.isStacked(fieldname) ? " active" : "")
                        }
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          autoComplete="off"
                          onChange={() => {
                            this.toggleStacked(fieldname);
                          }}
                          checked={this.isStacked(fieldname) ? true : false}
                          data-toggle="button"
                        />
                        {fieldnames[fieldname]}
                      </label>
                    );
                  })}
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  outline
                  label="Main title"
                  value={this.state.mainTitle}
                  onChange={this.setMainTitle}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  outline
                  label="Y title 1"
                  value={this.state.yTitle1}
                  onChange={this.setyTitle1}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  outline
                  label="Y title 2"
                  value={this.state.yTitle2}
                  onChange={this.setyTitle2}
                  disabled={this.state.y2disabled}
                />
              </MDBCol>
            </MDBRow>
          </>
        );
      }
    } else {
      return "Waiting for data";
    }
  }
}

export default EDBMRGenUnitChart;
