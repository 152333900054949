import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSInvestmentChart extends Component {
  render() {
    console.log("Rendering EDFSInvestmentChart");
    //console.log("constremissions", this.props.constremissions);
    const capexData = [];
    const writedownData = [];
    const carbonData = [];
    let capexTotal = 0;
    let writedownTotal = 0;
    let carbonTotal = 0;
    const fontFamily =
      "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";
    let x = 1;
    if (this.props.generation) {
      for (let tech of this.props.generation) {
        const initCapacity = this.props.init.generation[x - 1].current.capacity;
        let capex = tech.new.capacity * tech.new.capitalCost;
        let writedown = 0;
        if (tech.current.capacity > initCapacity) {
          capex +=
            (tech.current.capacity - initCapacity) * tech.current.capitalCost;
        } else {
          writedown =
            (initCapacity - tech.current.capacity) * tech.current.capitalCost;
          writedownTotal += writedown;
        }
        capexTotal += capex;
        const techCC =
          (this.props.constremissions[tech.id] * this.props.carbonCost) / 1000;
        //console.log(tech.id, techCC);
        carbonTotal += techCC;
        capexData.push({
          x: x,
          y: Math.round(capex / 1000) / 1000,
          label: tech.tech,
        });
        writedownData.push({
          x: x,
          y: Math.round(writedown / 1000) / 1000,
          label: tech.tech,
        });
        carbonData.push({
          x: x,
          y: Math.round(techCC / 1000) / 1000,
          label: tech.tech,
        });
        x++;
      }
    }

    if (this.props.transmission) {
      const initCapacity = this.props.init.transmission.elec.current.capacity;
      let capex =
        this.props.transmission.elec.new.capacity *
        this.props.transmission.elec.new.capitalCost;
      let writedown = 0;
      if (this.props.transmission.elec.current.capacity > initCapacity) {
        capex +=
          (this.props.transmission.elec.current.capacity - initCapacity) *
          this.props.transmission.elec.current.capitalCost;
      } else {
        writedown =
          (initCapacity - this.props.transmission.elec.current.capacity) *
          this.props.transmission.elec.current.capitalCost;
        writedownTotal += writedown;
      }
      capexTotal += capex;
      capexData.push({
        x: x,
        y: Math.round(capex / 1000) / 1000,
        label: "Network",
      });
      writedownData.push({
        x: x,
        y: Math.round(writedown / 1000) / 1000,
        label: "Network",
      });
      x++;
    }

    if (this.props.storage) {
      for (let i = 0; i < this.props.storage.length; i++) {
        const tech = this.props.storage[i];
        const initCapacity = this.props.init.storage[i].current.capacity;
        const initPower = this.props.init.storage[i].current.power;
        let capex =
          tech.new.capacity * tech.new.capitalCostkWh +
          tech.new.power * tech.new.capitalCostkW;
        let writedown = 0;
        if (tech.current.capacity > initCapacity) {
          capex +=
            (tech.current.capacity - initCapacity) *
            tech.current.capitalCostkWh;
        } else {
          writedown +=
            (initCapacity - tech.current.capacity) *
            tech.current.capitalCostkWh;
        }
        if (tech.current.power > initPower) {
          capex +=
            (tech.current.power - initPower) * tech.current.capitalCostkW;
        } else {
          writedown +=
            (initPower - tech.current.power) * tech.current.capitalCostkW;
        }
        writedownTotal += writedown;
        capexTotal += capex;
        const techCC =
          (this.props.constremissions[tech.id] * this.props.carbonCost) / 1000;
        carbonTotal += techCC;
        capexData.push({
          x: x,
          y: Math.round(capex / 1000) / 1000,
          label: tech.tech,
        });
        writedownData.push({
          x: x,
          y: Math.round(writedown / 1000) / 1000,
          label: tech.tech,
        });
        carbonData.push({
          x: x,
          y: Math.round(techCC / 1000) / 1000,
          label: tech.tech,
        });
        x++;
      }
    }

    capexTotal = (capexTotal / 1000000).toFixed();
    writedownTotal = (writedownTotal / 1000000).toFixed();
    carbonTotal = (carbonTotal / 1000000).toFixed();
    let title = `Capital (£${capexTotal}bn)`;
    if (writedownTotal > 0) {
      title += `, Writedown (£${writedownTotal}bn)`;
    }
    if (carbonTotal > 0) {
      title += ` & Carbon (£${carbonTotal}bn)`;
    }
    title += " Costs";
    //console.log("options", options);
    if (capexData.length) {
      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: title,
          fontSize: 24,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          labelAngle: 45,
          interval: 1,
        },
        axisY: {
          title: "billion £",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "0.0",
        },
      };

      let data = [];
      if (writedownTotal > 0 || carbonTotal > 0) {
        options.legend = {
          fontFamily: fontFamily,
        };
        //for (let i = 0; i < this.props.generation.length; i++) {
        data.push({
          legendText: "Capex",
          showInLegend: "true",
          type: "stackedColumn",
          yValueFormatString: "##0.00",
          toolTipContent: "{label} capex: {y}",
          dataPoints: capexData,
        });
        if (writedownTotal > 0) {
          data.push({
            legendText: "Writedowns",
            showInLegend: "true",
            type: "stackedColumn",
            toolTipContent: "{label} writedown: {y}",
            yValueFormatString: "##0.00",
            dataPoints: writedownData,
          });
        }
        if (carbonTotal > 0) {
          data.push({
            legendText: "Carbon",
            showInLegend: "true",
            type: "stackedColumn",
            toolTipContent: "{label} carbon: {y}",
            yValueFormatString: "##0.00",
            dataPoints: carbonData,
          });
        }
        //}
      } else {
        data.push({
          type: "column",
          //legendText: tech.tech,
          //showInLegend: "true",
          yValueFormatString: "##0.00",
          //toolTipContent: tech.tech + ": {y}",
          dataPoints: capexData,
        });
      }

      options.data = data;

      return <CanvasJSChart options={options} />;
    } else {
      return "Loading...";
    }
  }
}

export default EDFSInvestmentChart;
