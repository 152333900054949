import React from "react";
import { MDBCloseIcon, MDBIcon } from "mdbreact";
import { Draggable } from "react-beautiful-dnd";

class EDProjectItem extends React.Component {
  render = () => {
    return (
      <Draggable
        key={this.props.id}
        draggableId={this.props.id}
        index={this.props.index}
      >
        {(provided, snapshot) => {
          const classes = snapshot.isDragging ? "list-group-item-primary" : "";
          return (
            <div
              className={"p-2 list-group-item " + classes}
              ref={provided.innerRef}
              {...provided.draggableProps}
              //style={getItemStyle(
              //    snapshot.isDragging,
              //    provided.draggableProps.style
              //)}
            >
              {this.props.section === "selectgroups" && (
                <MDBCloseIcon
                  onClick={() => {
                    //console.log("groupIndex", this.props.parentIndex);
                    //console.log("itemIndex", this.props.index);
                    this.props.deleteItem(
                      this.props.index,
                      this.props.parentIndex
                    );
                  }}
                />
              )}
              <span
                onClick={() => {
                  this.props.clickMove(
                    this.props.index,
                    this.props.parentIndex,
                    this.props.section
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <MDBIcon className="mr-1" icon="reply" />
              </span>
              <span {...provided.dragHandleProps}>
                {this.props.code}{" "}
                <small className="text-muted">{this.props.name}</small>
              </span>
            </div>
          );
        }}
      </Draggable>
    );
  };
}

export default EDProjectItem;
