import React, { PureComponent } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSStorage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.storage) {
      for (let i = 0; i < props.storage.length; i++) {
        const id = props.storage[i].id;
        for (let cond in props.storage[i]) {
          if (cond !== "id" && cond !== "tech") {
            if (typeof props.storage[i][cond] === "object") {
              for (let char in props.storage[i][cond]) {
                let dataKey = id + "_" + cond + "_" + char;
                this.state[dataKey] = props.storage[i][cond][char];
                dataKey += "_changed";
                this.state[dataKey] = false;
              }
            } else {
              let dataKey = id + "_" + cond;
              this.state[dataKey] = props.storage[i][cond];
              dataKey += "_changed";
              this.state[dataKey] = false;
            }
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this);
  }

  itemUpdate(event) {
    if (event.target.id) {
      let [type, id, condition, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      let params = {};
      if (characteristic) {
        const valKey = id + "_" + condition + "_" + characteristic;
        const changedKey = valKey + "_changed";
        this.setState({ [valKey]: val, [changedKey]: true });
        params = {
          type: type,
          id: id,
          nested: { [condition]: { [characteristic]: val } },
        };
      } else {
        const valKey = id + "_" + condition;
        const changedKey = valKey + "_changed";
        this.setState({ [valKey]: val, [changedKey]: true });
        params = { type: type, id: id, vals: { [condition]: val } };
      }
      this.props.fieldUpdate(params);
    }
  }

  render() {
    console.log("Rendering EDFSStorage");
    //console.log("storage", this.props.storage);
    //console.log("state", this.state);
    return (
      <MDBRow className="px-2">
        {this.props.storage.map((item, index) => (
          <MDBCol
            key={"storage_" + item.id + "_col"}
            id={"storage_" + item.id + "_col"}
            className="p-2"
            size="12"
            md="6"
            xl="4"
          >
            <MDBCard id={"storage_" + item.id + "_card"}>
              <MDBCardHeader id={"storage_" + item.id + "_header"}>
                {item.tech}
              </MDBCardHeader>
              <MDBCardBody id={"storage_" + item.id + "_body"} className="p-1">
                <table
                  id={"storage_" + item.id + "_table"}
                  className="table table-sm table-borderless"
                >
                  <colgroup>
                    <col width="44%" />
                    <col width="28%" />
                    <col width="28%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="p-1">&nbsp;</th>
                      <th className="p-1">Current</th>
                      <th className="p-1">New</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-1">
                        Capacity <small className="text-muted">MWh</small>
                      </td>
                      <td className="p-1">
                        <input
                          id={"storage_" + item.id + "_current_capacity"}
                          type="number"
                          max={this.props.initStorage[index].current.capacity}
                          min={0}
                          className={
                            "form-control form-control-sm" +
                            (this.state[item.id + "_current_capacity_changed"]
                              ? " border-danger"
                              : "")
                          }
                          aria-label={item.tech + " current capacity"}
                          value={item.current.capacity}
                          onChange={this.itemUpdate}
                        />
                      </td>
                      <td className="p-1">
                        <input
                          id={"storage_" + item.id + "_new_capacity"}
                          type="number"
                          min={0}
                          className={
                            "form-control form-control-sm" +
                            (this.state[item.id + "_new_capacity_changed"]
                              ? " border-danger"
                              : "")
                          }
                          aria-label={item.tech + " new capacity"}
                          value={item.new.capacity}
                          onChange={this.itemUpdate}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        Power <small className="text-muted">MW</small>
                      </td>
                      <td className="p-1">
                        <input
                          id={"storage_" + item.id + "_current_power"}
                          type="number"
                          max={item.current.initpower}
                          min={0}
                          className={
                            "form-control form-control-sm" +
                            (this.state[item.id + "_current_power_changed"]
                              ? " border-danger"
                              : "")
                          }
                          aria-label={item.tech + " current power"}
                          value={item.current.power}
                          onChange={this.itemUpdate}
                        />
                      </td>
                      <td className="p-1">
                        <input
                          id={"storage_" + item.id + "_new_power"}
                          type="number"
                          min={0}
                          className={
                            "form-control form-control-sm" +
                            (this.state[item.id + "_new_power_changed"]
                              ? " border-danger"
                              : "")
                          }
                          aria-label={item.tech + " new power"}
                          value={item.new.power}
                          onChange={this.itemUpdate}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1">
                        Round-trip efficiency{" "}
                        <small className="text-muted">%</small>
                      </td>
                      <td className="p-1">
                        <input
                          id={"storage_" + item.id + "_current_efficiency"}
                          type="number"
                          className="form-control form-control-sm"
                          aria-label={item.tech + " current efficiency"}
                          value={item.current.efficiency}
                          disabled
                        />
                      </td>
                      <td className="p-1">
                        <input
                          id={"storage_" + item.id + "_new_efficiency"}
                          type="number"
                          max={100}
                          min={1}
                          className={
                            "form-control form-control-sm" +
                            (this.state[item.id + "_new_efficiency_changed"]
                              ? " border-danger"
                              : "")
                          }
                          aria-label={item.tech + " new efficiency"}
                          value={item.new.efficiency}
                          onChange={this.itemUpdate}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    );
  }
}

export default EDFSStorage;
