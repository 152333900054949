import React, { Component } from "react";
import { MDBBtnGroup, MDBBtn, MDBRangeInput, MDBInput } from "mdbreact";
import moment from "moment";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSMeritOrderStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: "manual",
      month: 0,
      week: 0,
      viewportRange: { viewportMinimum: null, viewportMaximum: null },
    };

    this.months = [
      { abbrev: "", range: { viewportMinimum: null, viewportMaximum: null } },
    ];

    this.weeks = [{ viewportMinimum: null, viewportMaximum: null }];

    if (typeof props.wholesaleElecDemandData[0] === "object") {
      const startDate = props.wholesaleElecDemandData[0].x;
      let startMoment = moment(startDate);
      let i = 1;
      for (let monthAbbrev of [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "month");
        this.months[i] = {
          abbrev: monthAbbrev,
          range: {
            viewportMinimum: startMoment.toDate(),
            viewportMaximum: endMoment.toDate(),
          },
        };
        startMoment = endMoment;
        i++;
      }

      startMoment = moment(startDate);
      for (let i = 1; i < 54; i++) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "week");
        this.weeks[i] = {
          viewportMinimum: startMoment.toDate(),
          viewportMaximum: endMoment.toDate(),
        };
        startMoment = endMoment;
      }
    }

    this.changeWeek = this.changeWeek.bind(this);
  }

  changeWeek(weekNo) {
    this.setState({
      week: weekNo,
      viewportRange: this.weeks[weekNo],
    });
  }

  render() {
    console.log("Rendering EDFSMeritOrderStack");
    //console.log("generation", this.props.generation);
    /*if (this.props.flexiblesData && this.props.flexiblesData.biomass) {
      let imbalance = [];
      const arLength = this.props.flexiblesData.biomass.length;
      const flexKeys = Object.keys(this.props.flexiblesData);
      const flexLength = flexKeys.length;
      const inflexLength = this.props.inflexiblesData.length;
      for (let i = 0; i < arLength; i++) {
        let balance =
          this.props.wholesaleElecDemandData[i].y -
          this.props.storageData[i].y -
          this.props.interconnectData[i].y;
        let period = {
          wholesaleElecDemand: this.props.wholesaleElecDemandData[i],
          storage: this.props.storageData[i],
          interconnect: this.props.interconnectData[i],
        };
        for (let j = 0; j < flexLength; j++) {
          balance -= this.props.flexiblesData[flexKeys[j]][i].y;
          period[flexKeys[j]] = this.props.flexiblesData[flexKeys[j]][i];
        }
        for (let j = 0; j < inflexLength; j++) {
          balance -= this.props.inflexiblesData[j].data[i].y;
          period[this.props.inflexiblesData[j].id] = this.props.inflexiblesData[
            j
          ].data[i];
        }
        if (Math.abs(balance) > 100) {
          period["balance"] = balance;
          imbalance.push(period);
        }
      }
      console.log("imbalance", imbalance);
    }*/
    //console.log("props", this.props);
    const inflexibles = this.props.inflexiblesData || [];
    const dataFields = inflexibles.map((item) => {
      return {
        type: "stackedArea",
        name: item.name,
        showInLegend: true,
        xValueFormatString: "D MMM HH:mm",
        yValueFormatString: "#,##0",
        dataPoints: item.data,
      };
    });
    const flexibles = this.props.flexiblesData || {};
    const flexKeys = Object.keys(flexibles);
    for (let i = 0; i < flexKeys.length; i++) {
      const name = flexKeys[i].charAt(0).toUpperCase() + flexKeys[i].slice(1);
      dataFields.push({
        type: "stackedArea",
        name: name,
        showInLegend: true,
        xValueFormatString: "D MMM HH:mm",
        yValueFormatString: "#,##0",
        dataPoints: flexibles[flexKeys[i]],
      });
    }
    dataFields.push({
      type: "stackedArea",
      name: "Storage",
      showInLegend: true,
      xValueFormatString: "D MMM HH:mm",
      yValueFormatString: "#,##0",
      dataPoints: this.props.storageData,
    });
    dataFields.push({
      type: "stackedArea",
      name: "Interconnectors",
      showInLegend: true,
      xValueFormatString: "D MMM HH:mm",
      yValueFormatString: "#,##0",
      dataPoints: this.props.interconnectData,
    });
    dataFields.push({
      type: "line",
      name: "Gross demand",
      showInLegend: true,
      xValueFormatString: "D MMM HH:mm",
      yValueFormatString: "#,##0",
      dataPoints: this.props.wholesaleElecDemandData,
    });
    //console.log("dataFields", dataFields);
    const options = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      zoomEnabled: true,
      rangeChanged: function (e) {
        //var colorSet = this._selectedColorSet;
        //console.log(colorSet);
        const threshold = 300000000;
        const timediff =
          e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
        if (timediff > threshold || e.trigger === "reset") {
          this.options.axisX.valueFormatString = "D MMM";
        } else {
          this.options.axisX.valueFormatString = "D MMM HH:mm";
        }
        this.render();
      },
      zoomType: "x",
      title: {
        text: "Electricity supply by energy source",
        fontSize: 20,
        padding: {
          top: 1,
          bottom: 1,
          left: 30,
          right: 30,
        },
      },
      axisX: {
        ...this.state.viewportRange,
        valueFormatString: "D MMM",
        labelAngle: 45,
      },
      axisY: {
        includeZero: true,
        title: "MW",
        minimum: 0,
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: function (e) {
          //console.log("legend click: " + e.dataPointIndex);
          //console.log(e);
          if (
            typeof e.dataSeries.visible === "undefined" ||
            e.dataSeries.visible
          ) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }

          e.chart.render();
        },
      },
      data: dataFields,
    };

    let barData = [];
    for (let i = 0; i < this.props.storage.length; i++) {
      const yBase = this.props.init.storage[i]
        ? 1 *
          (
            (this.props.init.storage[i].dischargekWh -
              this.props.init.storage[i].chargekWh) /
            1000000
          ).toFixed(1)
        : 0;
      const yProp = this.props.storage[i].dischargekWh
        ? 1 *
          (
            (this.props.storage[i].dischargekWh -
              this.props.storage[i].chargekWh) /
            1000000
          ).toFixed(1)
        : 0;
      barData.push({
        type: "stackedBar",
        legendText: this.props.storage[i].tech,
        showInLegend: true,
        dataPoints: [
          {
            x: 1,
            y: yBase,
            label: "Base",
            name: this.props.storage[i].tech,
            toolTipContent: "{name}: {y}",
          },
          {
            x: 2,
            y: yProp,
            label: "Proposed",
            name: this.props.storage[i].tech,
            toolTipContent: "{name}: {y}",
          },
        ],
      });
    }
    for (let i = 0; i < this.props.interconnection.length; i++) {
      const yBase = this.props.init.interconnection[i]
        ? 1 *
          (
            (this.props.init.interconnection[i].importMWh -
              this.props.init.interconnection[i].exportMWh) /
            1000000
          ).toFixed(1)
        : 0;
      const yProp = this.props.interconnection[i]
        ? 1 *
          (
            (this.props.interconnection[i].importMWh -
              this.props.interconnection[i].exportMWh) /
            1000000
          ).toFixed(1)
        : 0;
      const legend = "IC " + this.props.interconnection[i].tech;
      barData.push({
        type: "stackedBar",
        legendText: legend,
        showInLegend: true,
        dataPoints: [
          {
            x: 1,
            y: yBase,
            label: "Base",
            name: legend,
            toolTipContent: "{name}: {y}",
          },
          {
            x: 2,
            y: yProp,
            label: "Proposed",
            name: legend,
            toolTipContent: "{name}: {y}",
          },
        ],
      });
    }
    for (let i = 0; i < this.props.generation.length; i++) {
      const yBase = this.props.init.generation[i]
        ? 1 * (this.props.init.generation[i].MWhpa / 1000000).toFixed(1)
        : 0;
      const yProp = this.props.generation[i].MWhpa
        ? 1 * (this.props.generation[i].MWhpa / 1000000).toFixed(1)
        : 0;
      barData.push({
        type: "stackedBar",
        legendText: this.props.generation[i].tech,
        showInLegend: true,
        dataPoints: [
          {
            x: 1,
            y: yBase,
            label: "Base",
            name: this.props.generation[i].tech,
            toolTipContent: "{name}: {y}",
          },
          {
            x: 2,
            y: yProp,
            label: "Proposed",
            name: this.props.generation[i].tech,
            toolTipContent: "{name}: {y}",
          },
        ],
      });
    }
    barData[barData.length - 1].indexLabel = "#total";
    barData[barData.length - 1].indexLabelPlacement = "outside";

    const barOptions = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      title: {
        text: "Annual supply (TWh)",
        fontSize: 20,
        padding: {
          top: 1,
          bottom: 1,
          left: 30,
          right: 30,
        },
      },
      data: barData,
    };
    console.log("barData", barData);

    return (
      <>
        <div>
          <CanvasJSChart options={barOptions} />
        </div>
        <hr />
        <div className="d-flex flex-column">
          <CanvasJSChart
            options={options}
            onRef={(ref) => (this.chart = ref)}
          />
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            Zoom:
            <MDBBtnGroup size="sm">
              <MDBBtn
                active={this.state.zoom === "manual"}
                onClick={() =>
                  this.setState({
                    zoom: "manual",
                    viewportRange: this.months[0].range,
                  })
                }
              >
                Manual
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "month"}
                onClick={() =>
                  this.setState({
                    zoom: "month",
                    viewportRange: this.months[this.state.month].range,
                  })
                }
              >
                Month
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "week"}
                onClick={() =>
                  this.setState({
                    zoom: "week",
                    viewportRange: this.weeks[this.state.week],
                  })
                }
              >
                Week
              </MDBBtn>
            </MDBBtnGroup>
          </div>
          {this.state.zoom === "month" && (
            <div className="d-flex flex-row justify-content-center align-items-center mt-2">
              <MDBBtnGroup size="sm">
                {this.months.map((object, index) => {
                  if (index > 0) {
                    return (
                      <MDBBtn
                        key={object.abbrev}
                        active={this.state.month === index}
                        className="px-2"
                        onClick={() =>
                          this.setState({
                            month: index,
                            viewportRange: this.months[index].range,
                          })
                        }
                      >
                        {object.abbrev}
                      </MDBBtn>
                    );
                  } else {
                    return "";
                  }
                })}
              </MDBBtnGroup>
            </div>
          )}
          {this.state.zoom === "week" && (
            <div className="d-flex flex-row align-items-center">
              <div className="justify-content-start mx-1">1</div>
              <div className="flex-fill mt-2">
                <MDBRangeInput
                  min={1}
                  max={53}
                  value={this.state.week}
                  getValue={this.changeWeek}
                />
              </div>
              <div className="justify-content-end mx-1">53</div>
              <div className="justify-content-end mx-1">
                <MDBInput
                  value={this.state.week}
                  size="sm"
                  min={1}
                  max={53}
                  type="number"
                  getValue={this.changeWeek}
                  outline
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default EDFSMeritOrderStack;
