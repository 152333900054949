import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSCarbonElectricity extends Component {
  render() {
    console.log("Rendering EDFSCarbonElectricity");
    if (this.props.propData && this.props.baseData && this.props.generation) {
      const fontFamily =
        "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";
      const data = [];
      let elecTotal = 0;
      let initElecTotal = 0;
      for (let tech of this.props.generation) {
        if (
          this.props.propData.fuelemissions[tech.id] ||
          this.props.baseData.fuelemissions[tech.id]
        ) {
          const elecVal =
            Math.round(this.props.propData.fuelemissions[tech.id] || 0) /
            1000000;
          const initElecVal =
            Math.round(this.props.baseData.fuelemissions[tech.id] || 0) /
            1000000;
          data.push({
            type: "stackedColumn",
            legendText: tech.tech,
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: tech.tech + ": {y}",
            dataPoints: [
              {
                label: "Base",
                y: initElecVal,
              },
              {
                label: "Proposed",
                y: elecVal,
              },
            ],
          });
          elecTotal += elecVal;
          initElecTotal += initElecVal;
        }
      }
      if (data.length) {
        data[data.length - 1].indexLabel = "#total";
        data[data.length - 1].indexLabelPlacement = "outside";
      }

      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Fuel-related generation emissions",
          fontSize: 20,
          fontFamily: fontFamily,
          //fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "M tCO2e p.a.",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString:
            Math.max(elecTotal, initElecTotal) >= 2000000 ? "#,##0" : "0.0",
        },
        data: data,
      };
      //console.log("options", options);

      return <CanvasJSChart options={options} />;
    } else {
      return "Loading...";
    }
  }
}

export default EDFSCarbonElectricity;
