import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
//import { ReactComponent as Logo } from "./assets/logo.svg";
import { BrowserRouter as Router } from "react-router-dom";
import C4CSLogo from "./assets/C4CS_logo_128x64.png";
import Routes from "./Routes";

class App extends Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = collapseID => () => {
    window.scrollTo(0, 0);
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };

  render() {
    /*const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    ); */

    const { collapseID } = this.state;

    return (
      <Router>
        <div className="flyout">
          <MDBNavbar light color="white" expand="md">
            <MDBNavbarBrand to="/home">
              <a href="https://www.c4cs.org.uk">
                <img src={C4CSLogo} alt="C4CS Logo" />
              </a>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.toggleCollapse("mainNavbarCollapse")}
              right
            />
            <MDBCollapse id="mainNavbarCollapse" isOpen={collapseID} navbar>
              <MDBNavbarNav right className="text-right">
                <MDBNavItem>
                  <a
                    href="https://www.c4cs.org.uk"
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    C4CS Home
                  </a>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    exact
                    to="/"
                    onClick={this.closeCollapse("mainNavbarCollapse")}
                  >
                    ED Home
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem></MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <span className="mr-2">Energy Data</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu right className="text-right">
                      <MDBNavLink
                        className="py-0"
                        to="/edfutscen"
                        onClick={this.closeCollapse("mainNavbarCollapse")}
                      >
                        <MDBDropdownItem>Future Scenarios</MDBDropdownItem>
                      </MDBNavLink>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem header>BMR By Site</MDBDropdownItem>
                      <MDBNavLink
                        className="py-0"
                        to="/bmrhhsite"
                        onClick={this.closeCollapse("mainNavbarCollapse")}
                      >
                        <MDBDropdownItem>Half-Hourly</MDBDropdownItem>
                      </MDBNavLink>
                      <MDBNavLink
                        className="py-0"
                        to="/bmrdaysite"
                        onClick={this.closeCollapse("mainNavbarCollapse")}
                      >
                        <MDBDropdownItem>Daily</MDBDropdownItem>
                      </MDBNavLink>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem header>BMR By Type</MDBDropdownItem>
                      <MDBNavLink
                        className="py-0"
                        to="/bmrhhtype"
                        onClick={this.closeCollapse("mainNavbarCollapse")}
                      >
                        <MDBDropdownItem>Half-Hourly</MDBDropdownItem>
                      </MDBNavLink>
                      <MDBNavLink
                        className="py-0"
                        to="/bmrdaytype"
                        onClick={this.closeCollapse("mainNavbarCollapse")}
                      >
                        <MDBDropdownItem>Daily</MDBDropdownItem>
                      </MDBNavLink>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  {
                    //<MDBNavLink to="/bugreport" target="mantisbt">
                  }
                  <a
                    href="https://www.c4cs.org.uk/mantisbt/bug_report_page.php"
                    title="Report a bug or suggest a feature"
                    target="mantisbt"
                    className="nav-link"
                  >
                    Bug/Suggestion
                  </a>
                  {
                    //</MDBNavLink>
                  }
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
          {
            //collapseID && overlay
          }
          <main>
            <Routes />
          </main>
          <MDBFooter color="green darken-3">
            <p className="footer-copyright mb-0 py-3 text-center">
              &copy; {new Date().getFullYear()} Copyright:{" "}
              <a href="https://www.summerleaze.com"> summerleaze.com </a>
            </p>
          </MDBFooter>
        </div>
      </Router>
    );
  }
}

export default App;
