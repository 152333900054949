import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSCarbonInfrastructure extends Component {
  render() {
    console.log("Rendering EDFSCarbonInfrastructure");
    if (
      this.props.aggData &&
      this.props.aggData.elec &&
      this.props.initAggData &&
      this.props.generation
    ) {
      const fontFamily =
        "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";

      const names = {};
      for (let item of this.props.generation) {
        if (
          this.props.aggData.elec.constremissions[item.id] ||
          this.props.initAggData.elec.constremissions[item.id]
        ) {
          names[item.id] = item.tech;
        }
      }
      for (let item of this.props.storage) {
        if (
          this.props.aggData.elec.constremissions[item.id] ||
          this.props.initAggData.elec.constremissions[item.id]
        ) {
          names[item.id] = item.tech;
        }
      }
      const techs = Object.keys(this.props.aggData.elec.constremissions);
      const data = [];
      let constrTotal = 0;
      let initConstrTotal = 0;
      for (let tech of techs) {
        if (names[tech]) {
          const constrVal =
            Math.round(this.props.initAggData.elec.constremissions[tech]) /
            1000000;
          const initConstrVal =
            Math.round(this.props.aggData.elec.constremissions[tech]) / 1000000;
          data.push({
            type: "stackedColumn",
            legendText: names[tech],
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: names[tech] + ": {y}",
            dataPoints: [
              {
                label: "Base",
                y: constrVal,
              },
              {
                label: "Proposed",
                y: initConstrVal,
              },
            ],
          });
          constrTotal += constrVal;
          initConstrTotal += initConstrVal;
        }
      }
      let ictotal = 0;
      let iictotal = 0;
      for (let item of this.props.interconnection) {
        ictotal += this.props.aggData.elec.constremissions[item.id];
        iictotal += this.props.initAggData.elec.constremissions[item.id];
      }
      if (ictotal > 0 || iictotal > 0) {
        const icVal = Math.round(ictotal) / 1000000;
        const iicVal = Math.round(iictotal) / 1000000;
        data.push({
          type: "stackedColumn",
          legendText: "Interconnectors",
          showInLegend: "true",
          yValueFormatString: "0.000",
          toolTipContent: "Interconnectors: {y}",
          dataPoints: [
            {
              label: "Base",
              y: iicVal,
            },
            {
              label: "Proposed",
              y: icVal,
            },
          ],
        });
        constrTotal += icVal;
        initConstrTotal += iicVal;
      }
      if (this.props.networkReinforcement > 0) {
        const netReinf = this.props.networkReinforcement / 1000000;
        data.push({
          type: "stackedColumn",
          legendText: "Network reinforcement",
          showInLegend: "true",
          yValueFormatString: "0.000",
          toolTipContent: "Network reinforcement: {y}",
          dataPoints: [
            {
              label: "Base",
              y: 0,
            },
            {
              label: "Proposed",
              y: netReinf,
            },
          ],
        });
        constrTotal += netReinf;
      }
      if (data.length) {
        data[data.length - 1].indexLabel = "#total";
        data[data.length - 1].indexLabelPlacement = "outside";
      }

      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Electricity infrastructure-related emissions",
          fontSize: 20,
          fontFamily: fontFamily,
          //fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "M tCO2e",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString:
            Math.max(constrTotal, initConstrTotal) >= 2000000 ? "#,##0" : "0.0",
        },
        data: data,
      };
      //console.log("options", options);

      if (data.length > 0) {
        return <CanvasJSChart options={options} />;
      } else {
        return (
          <p className="border">
            No development currently planned under this scenario
          </p>
        );
      }
    } else {
      return "Loading...";
    }
  }
}

export default EDFSCarbonInfrastructure;
