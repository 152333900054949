import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSUtilisation extends Component {
  render() {
    console.log("Rendering EDFSUtilisation");
    //this.props.generation.current.initCapacity = this.props.initGeneration.current.capacity;
    //console.log("props", this.props.generation);
    let dataPoints1 = [];
    let dataPoints2 = [];
    let i = 0;
    let x = 1;
    for (let item of this.props.generation) {
      const combCapacity = item.current.capacity + item.new.capacity;
      if (combCapacity > 0) {
        const loadFactor =
          Math.round(
            (100 *
              (item.current.loadFactor * item.current.capacity +
                item.new.loadFactor * item.new.capacity)) /
              combCapacity
          ) / 100;
        dataPoints1.push({
          x: x,
          y: loadFactor,
          label: item.tech,
        });
        dataPoints2.push({
          x: x,
          y: this.props.initGeneration[i].current.loadFactor,
          label: item.tech,
        });
        x++;
      }
      i++;
    }

    const options = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      title: {
        text: "Capacity Factors",
      },
      axisX: {
        labelAngle: 45,
      },
      axisY: {
        includeZero: true,
        title: "%",
        valueFormatString: "#,##0",
      },
      toolTip: {
        shared: true,
      },
      data: [
        {
          type: "column",
          name: "Base",
          showInLegend: true,
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints2,
        },
        {
          type: "column",
          name: "Proposed",
          showInLegend: true,
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints1,
        },
      ],
    };

    return (
      <>
        <div>
          <CanvasJSChart options={options} />
        </div>
      </>
    );
  }
}

export default EDFSUtilisation;
