import React, { Component } from "react";
import { MDBBtnGroup, MDBBtn, MDBRangeInput, MDBInput } from "mdbreact";
import moment from "moment";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { standardColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("standardColorSet", standardColorset);

class EDFSITSDOChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: "manual",
      month: 0,
      week: 0,
      viewportRange: { viewportMinimum: null, viewportMaximum: null },
    };

    this.months = [
      { abbrev: "", range: { viewportMinimum: null, viewportMaximum: null } },
    ];

    this.weeks = [{ viewportMinimum: null, viewportMaximum: null }];

    if (typeof props.wholesaleElecDemandData[0] === "object") {
      const startDate = props.wholesaleElecDemandData[0].x;
      let startMoment = moment(startDate);
      let i = 1;
      for (let monthAbbrev of [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "month");
        this.months[i] = {
          abbrev: monthAbbrev,
          range: {
            viewportMinimum: startMoment.toDate(),
            viewportMaximum: endMoment.toDate(),
          },
        };
        startMoment = endMoment;
        i++;
      }

      startMoment = moment(startDate);
      for (let i = 1; i < 54; i++) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "week");
        this.weeks[i] = {
          viewportMinimum: startMoment.toDate(),
          viewportMaximum: endMoment.toDate(),
        };
        startMoment = endMoment;
      }
    }

    this.changeWeek = this.changeWeek.bind(this);
  }

  changeWeek(weekNo) {
    this.setState({
      week: weekNo,
      viewportRange: this.weeks[weekNo],
    });
  }

  render() {
    console.log("Rendering EDFSITSDOChart");
    //console.log("datapoints", this.props.wholesaleElecDemandData.length);
    const options = {
      theme: "light2",
      colorSet: "standardColorSet",
      exportEnabled: true,
      zoomEnabled: true,
      rangeChanged: function (e) {
        const threshold = 300000000;
        const timediff =
          e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
        if (timediff > threshold || e.trigger === "reset") {
          this.options.axisX.valueFormatString = "D MMM";
        } else {
          this.options.axisX.valueFormatString = "D MMM HH:mm";
        }
        this.render();
      },
      zoomType: "x",
      title: {
        text: "Wholesale electricity demand",
        fontSize: 20,
        padding: {
          top: 1,
          bottom: 1,
          left: 30,
          right: 30,
        },
      },
      axisX: {
        ...this.state.viewportRange,
        valueFormatString: "D MMM",
        labelAngle: 45,
      },
      axisY: {
        includeZero: true,
        title: "MW",
      },
      data: [
        {
          type: "line",
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.wholesaleElecDemandData,
        },
      ],
    };

    return (
      <div className="d-flex flex-column">
        <CanvasJSChart options={options} />
        <div className="d-flex flex-row justify-content-center align-items-center mt-3">
          Zoom:
          <MDBBtnGroup size="sm">
            <MDBBtn
              active={this.state.zoom === "manual"}
              onClick={() =>
                this.setState({
                  zoom: "manual",
                  viewportRange: this.months[0].range,
                })
              }
            >
              Manual
            </MDBBtn>
            <MDBBtn
              active={this.state.zoom === "month"}
              onClick={() =>
                this.setState({
                  zoom: "month",
                  viewportRange: this.months[this.state.month].range,
                })
              }
            >
              Month
            </MDBBtn>
            <MDBBtn
              active={this.state.zoom === "week"}
              onClick={() =>
                this.setState({
                  zoom: "week",
                  viewportRange: this.weeks[this.state.week],
                })
              }
            >
              Week
            </MDBBtn>
          </MDBBtnGroup>
        </div>
        {this.state.zoom === "month" && (
          <div className="d-flex flex-row justify-content-center align-items-center mt-2">
            <MDBBtnGroup size="sm">
              {this.months.map((object, index) => {
                if (index > 0) {
                  return (
                    <MDBBtn
                      key={object.abbrev}
                      active={this.state.month === index}
                      className="px-2"
                      onClick={() =>
                        this.setState({
                          month: index,
                          viewportRange: this.months[index].range,
                        })
                      }
                    >
                      {object.abbrev}
                    </MDBBtn>
                  );
                } else {
                  return "";
                }
              })}
            </MDBBtnGroup>
          </div>
        )}
        {this.state.zoom === "week" && (
          <div className="d-flex flex-row align-items-center">
            <div className="justify-content-start mx-1">1</div>
            <div className="flex-fill mt-2">
              <MDBRangeInput
                min={1}
                max={53}
                value={this.state.week}
                getValue={this.changeWeek}
              />
            </div>
            <div className="justify-content-end mx-1">53</div>
            <div className="justify-content-end mx-1">
              <MDBInput
                value={this.state.week}
                size="sm"
                min={1}
                max={53}
                type="number"
                getValue={this.changeWeek}
                outline
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EDFSITSDOChart;
