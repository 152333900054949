import React, { PureComponent } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSCostInputs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wacc: props.wacc,
      wacc_changed: false,
      carbonCost: props.carbonCost,
      carbonCost_changed: false,
    };
    if (props.generation) {
      for (let i = 0; i < props.generation.length; i++) {
        const id = props.generation[i].id;
        for (let cond in props.generation[i]) {
          if (cond !== "id" && cond !== "tech") {
            if (typeof props.generation[i][cond] === "object") {
              for (let char in props.generation[i][cond]) {
                let dataKey = id + "_" + cond + "_" + char;
                this.state[dataKey] = props.generation[i][cond][char];
                dataKey += "_changed";
                this.state[dataKey] = false;
              }
            } else {
              let dataKey = id + "_" + cond;
              this.state[dataKey] = props.generation[i][cond];
              dataKey += "_changed";
              this.state[dataKey] = false;
            }
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this);
    this.item2Update = this.item2Update.bind(this);
  }

  itemUpdate(event) {
    if (event.target.id) {
      let [type, id, condition, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      let params = {};
      if (characteristic) {
        const valKey = id + "_" + condition + "_" + characteristic;
        const changedKey = valKey + "_changed";
        this.setState({ [valKey]: val, [changedKey]: true });
        params = {
          type: type,
          id: id,
          nested: { [condition]: { [characteristic]: val } },
        };
      } else {
        const valKey = id + "_" + condition;
        const changedKey = valKey + "_changed";
        this.setState({ [valKey]: val, [changedKey]: true });
        params = { type: type, id: id, vals: { [condition]: val } };
      }
      this.props.fieldUpdate(params);
    }
  }

  item2Update(event) {
    if (event.target.id) {
      const valKey = event.target.id;
      const value = +event.target.value;
      const changedKey = valKey + "_changed";
      this.setState({ [valKey]: value, [changedKey]: true });
      this.props.soFieldUpdate(valKey, value);
    }
  }

  render() {
    console.log("Rendering EDFSCostInputs");
    //console.log("generation", this.props.generation);
    //console.log("state", this.state);
    return (
      <>
        <MDBRow className="px-2">
          <MDBCol
            key={"cost_general_col"}
            id={"cost_general_col"}
            className="p-2"
            size="12"
          >
            <MDBCard id="cost_general_card">
              <MDBCardHeader id="cost_general_header">General</MDBCardHeader>
              <MDBCardBody id={"cost_general_body"} className="p-1">
                <div className="form-group row px-2">
                  <label className="col-form-label col-2">
                    <span
                      title="Weighted Average Cost of Capital"
                      data-toggle="tooltip"
                      style={{
                        borderBottom: "1px dashed gray",
                        cursor: "pointer",
                      }}
                    >
                      WACC{" "}
                    </span>
                    <small>%</small>
                  </label>
                  <div className="col-3 col-xl-2">
                    <input
                      id={"wacc"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state.wacc_changed ? " border-danger" : "")
                      }
                      min={0}
                      value={this.state.wacc}
                      onChange={this.item2Update}
                    />
                  </div>
                  <label className="col-form-label offset-md-1 offset-xl-3 col-4 col-md-3">
                    Carbon Cost <small>&pound;/tCO2e</small>
                  </label>
                  <div className="col-3 col-xl-2">
                    <input
                      id={"carbonCost"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state.carbonCost_changed ? " border-danger" : "")
                      }
                      min={0}
                      value={this.state.carbonCost}
                      onChange={this.item2Update}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <h4 className="mt-4">Generation</h4>
        <MDBRow className="px-2">
          {this.props.generation.map(item => (
            <MDBCol
              key={"generation_" + item.id + "_col"}
              id={"generation_" + item.id + "_col"}
              className="p-2"
              size="12"
              md="6"
              lg="4"
              xl="3"
            >
              <MDBCard id={"generation_" + item.id + "_card"}>
                <MDBCardHeader id={"generation_" + item.id + "_header"}>
                  {item.tech}
                </MDBCardHeader>
                <MDBCardBody
                  id={"generation_" + item.id + "_body"}
                  className="p-1"
                >
                  <table
                    id={"generation_" + item.id + "_table"}
                    className="table table-sm table-borderless"
                  >
                    <thead>
                      <tr>
                        <th className="p-1">&nbsp;</th>
                        <th className="p-1">Current</th>
                        <th className="p-1">New</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-1">
                          Capital{" "}
                          <small className="text-muted">&pound;/kW</small>
                        </td>
                        <td className="p-1">
                          <input
                            id={
                              "generation_" + item.id + "_current_capitalCost"
                            }
                            type="number"
                            className={
                              "form-control form-control-sm" +
                              (this.state[
                                item.id + "_current_capitalCost_changed"
                              ]
                                ? " border-danger"
                                : "")
                            }
                            aria-label={item.tech + " current capital cost"}
                            value={item.current.capitalCost}
                            onChange={this.itemUpdate}
                          />
                        </td>
                        <td className="p-1">
                          <input
                            id={"generation_" + item.id + "_new_capitalCost"}
                            type="number"
                            className={
                              "form-control form-control-sm" +
                              (this.state[item.id + "_new_capitalCost_changed"]
                                ? " border-danger"
                                : "")
                            }
                            aria-label={item.tech + " new capital cost"}
                            value={item.new.capitalCost}
                            onChange={this.itemUpdate}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1">
                          Fixed{" "}
                          <small className="text-muted">&pound;/kW p.a.</small>
                        </td>
                        <td className="p-1">
                          <input
                            id={"generation_" + item.id + "_current_fixedCost"}
                            type="number"
                            className={
                              "form-control form-control-sm" +
                              (this.state[
                                item.id + "_current_fixedCost_changed"
                              ]
                                ? " border-danger"
                                : "")
                            }
                            aria-label={item.tech + " current fixed cost"}
                            value={item.current.fixedCost}
                            onChange={this.itemUpdate}
                          />
                        </td>
                        <td className="p-1">
                          <input
                            id={"generation_" + item.id + "_new_fixedCost"}
                            type="number"
                            className={
                              "form-control form-control-sm" +
                              (this.state[item.id + "_new_fixedCost_changed"]
                                ? " border-danger"
                                : "")
                            }
                            aria-label={item.tech + " new fixed cost"}
                            value={item.new.fixedCost}
                            onChange={this.itemUpdate}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1">
                          Variable{" "}
                          <small className="text-muted">&pound;/MWh</small>
                        </td>
                        <td className="p-1">
                          <input
                            id={
                              "generation_" + item.id + "_current_variableCost"
                            }
                            type="number"
                            className={
                              "form-control form-control-sm" +
                              (this.state[
                                item.id + "_current_variableCost_changed"
                              ]
                                ? " border-danger"
                                : "")
                            }
                            aria-label={item.tech + " current variable cost"}
                            value={item.current.variableCost}
                            onChange={this.itemUpdate}
                          />
                        </td>
                        <td className="p-1">
                          <input
                            id={"generation_" + item.id + "_new_variableCost"}
                            type="number"
                            className={
                              "form-control form-control-sm" +
                              (this.state[item.id + "_new_variableCost_changed"]
                                ? " border-danger"
                                : "")
                            }
                            aria-label={item.tech + " new variable cost"}
                            value={item.new.variableCost}
                            onChange={this.itemUpdate}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
        </MDBRow>
      </>
    );
  }
}

export default EDFSCostInputs;
