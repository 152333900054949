import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSCarbonTotal extends Component {
  render() {
    console.log("Rendering EDFSCarbonTotal");
    if (
      this.props.aggData &&
      this.props.aggData.elec &&
      this.props.initAggData &&
      this.props.generation
    ) {
      const fontFamily =
        "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";

      let elecTotal = 0;
      let initElecTotal = 0;
      for (let tech of this.props.generation) {
        if (this.props.aggData.elec.fuelemissions[tech.id]) {
          elecTotal +=
            Math.round(this.props.aggData.elec.fuelemissions[tech.id]) /
            1000000;
          initElecTotal +=
            Math.round(this.props.initAggData.elec.fuelemissions[tech.id]) /
            1000000;
        }
      }

      const uses = Object.keys(this.props.aggData.heat);
      let heatTotal = 0;
      let initHeatTotal = 0;
      for (let use of uses) {
        heatTotal += this.props.aggData.heat[use];
        initHeatTotal += this.props.initAggData.heat[use];
      }

      let transportTotal = 0;
      let initTransportTotal = 0;
      for (let mode in this.props.aggData.transport) {
        transportTotal += this.props.aggData.transport[mode];
        initTransportTotal += this.props.initAggData.transport[mode];
      }

      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Carbon emissions by energy-use",
          fontSize: 20,
          fontFamily: fontFamily,
          //fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "M tCO2e p.a.",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
        },
        data: [
          {
            type: "stackedColumn",
            legendText: "Electricity",
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: "Electricity: {y}",
            dataPoints: [
              {
                label: "Base",
                y: initElecTotal,
              },
              {
                label: "Proposed",
                y: elecTotal,
              },
            ],
          },
          {
            type: "stackedColumn",
            legendText: "Non-electric heat",
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: "Non-electric heat: {y}",
            dataPoints: [
              {
                label: "Base",
                y: initHeatTotal,
              },
              {
                label: "Proposed",
                y: heatTotal,
              },
            ],
          },
          {
            type: "stackedColumn",
            legendText: "Non-electric transport",
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: "Non-electric transport: {y}",
            dataPoints: [
              {
                label: "Base",
                y: initTransportTotal,
              },
              {
                label: "Proposed",
                y: transportTotal,
              },
            ],
          },
        ],
      };
      options.data[options.data.length - 1].indexLabel = "#total";
      options.data[options.data.length - 1].indexLabelPlacement = "outside";

      return <CanvasJSChart options={options} />;
    } else {
      return "Loading...";
    }
  }
}

export default EDFSCarbonTotal;
