import React, { Component } from "react";
import { MDBBtnGroup, MDBBtn, MDBRangeInput, MDBInput } from "mdbreact";
import moment from "moment";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSDemandStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: "manual",
      month: 0,
      week: 0,
      viewportRange: { viewportMinimum: null, viewportMaximum: null },
    };

    this.months = [
      { abbrev: "", range: { viewportMinimum: null, viewportMaximum: null } },
    ];

    this.weeks = [{ viewportMinimum: null, viewportMaximum: null }];

    if (typeof props.lightingData[0] === "object") {
      const startDate = props.lightingData[0].x;
      let startMoment = moment(startDate);
      let i = 1;
      for (let monthAbbrev of [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "month");
        this.months[i] = {
          abbrev: monthAbbrev,
          range: {
            viewportMinimum: startMoment.toDate(),
            viewportMaximum: endMoment.toDate(),
          },
        };
        startMoment = endMoment;
        i++;
      }

      startMoment = moment(startDate);
      for (let i = 1; i < 54; i++) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "week");
        this.weeks[i] = {
          viewportMinimum: startMoment.toDate(),
          viewportMaximum: endMoment.toDate(),
        };
        startMoment = endMoment;
      }
    }

    this.techNames = {
      lighting: "Lighting/Equipment",
      aircon: "Air-Con",
      resistance: "Direct Heat",
      ashp: "ASHP",
      gshp: "GSHP",
      road: "Road",
      rail: "Rail",
    };

    /*this.electotals = {total: 0};
    for (let data of [
      "lighting",
      "aircon",
      "resistance",
      "ashp",
      "gshp",
      "road",
      "rail",
    ]) {
      let total = 0;
      let dataField = data + "Data";
      for (let item in this.props[dataField]) {
        total += this.props[dataField][item].y;
      }
      this.electotals[data] = total;
      this.electotals["total"] += total;
      //console.log(data, total);
    }*/

    this.changeWeek = this.changeWeek.bind(this);
  }

  changeWeek(weekNo) {
    this.setState({
      week: weekNo,
      viewportRange: this.weeks[weekNo],
    });
  }

  render() {
    console.log("Rendering EDFSDemandStack");
    //console.log("init", this.props.init);

    let baseAnnualTWh = 0;
    let baseAnnualRadius = 95;
    const baseAnnualDataPoints = [];
    /*for (let item of this.props.init.convdemand) {
      if (item.demand !== 0) {
        baseAnnualDataPoints.push({ y: item.demand, label: item.tech });
        baseAnnualTWh += item.demand;
      }
    }
    for (let item of this.props.init.heatsupply) {
      if (item.elec !== 0) {
        baseAnnualDataPoints.push({ y: item.elec, label: item.tech });
        baseAnnualTWh += item.elec;
      }
    }
    for (let item of this.props.init.trnsptdemand) {
      if (item.elec !== 0) {
        baseAnnualDataPoints.push({ y: item.elec, label: item.tech });
        baseAnnualTWh += item.elec;
      }
    }*/
    let propAnnualTWh = 0;
    let propAnnualRadius = 95;
    const propAnnualDataPoints = [];
    /*for (let item of this.props.convdemand) {
      if (item.demand !== 0) {
        propAnnualDataPoints.push({ y: item.demand, label: item.tech });
        propAnnualTWh += item.demand;
      }
    }
    for (let item of this.props.heatsupply) {
      if (item.elec !== 0) {
        propAnnualDataPoints.push({ y: item.elec, label: item.tech });
        propAnnualTWh += item.elec;
      }
    }
    for (let item of this.props.trnsptdemand) {
      if (item.elec !== 0) {
        propAnnualDataPoints.push({ y: item.elec, label: item.tech });
        propAnnualTWh += item.elec;
      }
    }*/

    for (let tech in this.techNames) {
      console.log(tech, this.props.totals.elec[tech]);
      if (this.props.init.totals && this.props.init.totals.elec[tech]) {
        baseAnnualDataPoints.push({
          y: (this.props.init.totals.elec[tech] / 1000000).toFixed(1),
          label: this.techNames[tech],
        });
        baseAnnualTWh += this.props.init.totals.elec[tech];
      }
      if (this.props.totals.elec[tech]) {
        propAnnualDataPoints.push({
          y: (this.props.totals.elec[tech] / 1000000).toFixed(1),
          label: this.techNames[tech],
        });
        propAnnualTWh += this.props.totals.elec[tech];
      }
    }

    if (propAnnualTWh > baseAnnualTWh) {
      baseAnnualRadius *= (baseAnnualTWh / propAnnualTWh) ** 0.5;
    } else {
      propAnnualRadius *= (propAnnualTWh / baseAnnualTWh) ** 0.5;
    }
    const pARadius = `${propAnnualRadius}%`;
    const bARadius = `${baseAnnualRadius}%`;

    baseAnnualTWh = (baseAnnualTWh / 1000000).toFixed(1);
    propAnnualTWh = (propAnnualTWh / 1000000).toFixed(1);

    const baseAnnualOptions = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      animationEnabled: true,
      title: {
        text: `Base Electricity Demand (${baseAnnualTWh} TWh p.a.)`,
        fontSize: 20,
        padding: {
          top: 1,
          bottom: 1,
          left: 30,
          right: 30,
        },
      },
      data: [
        {
          type: "pie",
          radius: bARadius,
          //startAngle: 75,
          //toolTipContent: "<b>{label}</b>: {y}%",
          showInLegend: "true",
          legendText: "{label}",
          indexLabelFontSize: 16,
          indexLabel: "{y}",
          dataPoints: baseAnnualDataPoints,
        },
      ],
    };

    const propAnnualOptions = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      animationEnabled: true,
      title: {
        text: `Proposed Electricity Demand (${propAnnualTWh} TWh p.a.)`,
        fontSize: 20,
        padding: {
          top: 1,
          bottom: 1,
          left: 30,
          right: 30,
        },
      },
      data: [
        {
          type: "pie",
          radius: pARadius,
          //startAngle: 75,
          //toolTipContent: "<b>{label}</b>: {y}%",
          showInLegend: "true",
          legendText: "{label}",
          indexLabelFontSize: 16,
          //indexLabel: "{label} - {y}%",
          indexLabel: "{y}",
          dataPoints: propAnnualDataPoints,
        },
      ],
    };

    const options = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      zoomEnabled: true,
      rangeChanged: function (e) {
        const threshold = 300000000;
        const timediff =
          e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
        if (timediff > threshold || e.trigger === "reset") {
          this.options.axisX.valueFormatString = "D MMM";
        } else {
          this.options.axisX.valueFormatString = "D MMM HH:mm";
        }
        this.render();
      },
      zoomType: "x",
      title: {
        text: "Hourly Electricity Demand",
        fontSize: 20,
        padding: {
          top: 1,
          bottom: 1,
          left: 30,
          right: 30,
        },
      },
      axisX: {
        ...this.state.viewportRange,
        valueFormatString: "D MMM",
        labelAngle: 45,
      },
      axisY: {
        includeZero: true,
        title: "MW",
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: function (e) {
          //console.log("legend click: " + e.dataPointIndex);
          //console.log(e);
          if (
            typeof e.dataSeries.visible === "undefined" ||
            e.dataSeries.visible
          ) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }

          e.chart.render();
        },
      },
      data: [
        {
          type: "stackedArea",
          name: "Lighting/Equipment",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.lightingData,
        },
        {
          type: "stackedArea",
          name: "Air-Con",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.airconData,
        },
        {
          type: "stackedArea",
          name: "Direct Heat",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.resistanceData,
        },
        {
          type: "stackedArea",
          name: "ASHPs",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.ashpData,
        },
        {
          type: "stackedArea",
          name: "GSHPs",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.gshpData,
        },
        {
          type: "stackedArea",
          name: "Road",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.roadData,
        },
        {
          type: "stackedArea",
          name: "Rail",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.railData,
        },
      ],
    };

    return (
      <>
        <div className="row mb-4">
          <div className="col-12 col-sm-6">
            <CanvasJSChart options={baseAnnualOptions} />
          </div>
          <div className="col-12 col-sm-6">
            <CanvasJSChart options={propAnnualOptions} />
          </div>
        </div>
        <div className="d-flex flex-column">
          <CanvasJSChart
            options={options}
            onRef={(ref) => (this.chart = ref)}
          />
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            Zoom:
            <MDBBtnGroup size="sm">
              <MDBBtn
                active={this.state.zoom === "manual"}
                onClick={() =>
                  this.setState({
                    zoom: "manual",
                    viewportRange: this.months[0].range,
                  })
                }
              >
                Manual
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "month"}
                onClick={() =>
                  this.setState({
                    zoom: "month",
                    viewportRange: this.months[this.state.month].range,
                  })
                }
              >
                Month
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "week"}
                onClick={() =>
                  this.setState({
                    zoom: "week",
                    viewportRange: this.weeks[this.state.week],
                  })
                }
              >
                Week
              </MDBBtn>
            </MDBBtnGroup>
          </div>
          {this.state.zoom === "month" && (
            <div className="d-flex flex-row justify-content-center align-items-center mt-2">
              <MDBBtnGroup size="sm">
                {this.months.map((object, index) => {
                  if (index > 0) {
                    return (
                      <MDBBtn
                        key={object.abbrev}
                        active={this.state.month === index}
                        className="px-2"
                        onClick={() =>
                          this.setState({
                            month: index,
                            viewportRange: this.months[index].range,
                          })
                        }
                      >
                        {object.abbrev}
                      </MDBBtn>
                    );
                  } else {
                    return "";
                  }
                })}
              </MDBBtnGroup>
            </div>
          )}
          {this.state.zoom === "week" && (
            <div className="d-flex flex-row align-items-center">
              <div className="justify-content-start mx-1">1</div>
              <div className="flex-fill mt-2">
                <MDBRangeInput
                  min={1}
                  max={53}
                  value={this.state.week}
                  getValue={this.changeWeek}
                />
              </div>
              <div className="justify-content-end mx-1">53</div>
              <div className="justify-content-end mx-1">
                <MDBInput
                  value={this.state.week}
                  size="sm"
                  min={1}
                  max={53}
                  type="number"
                  getValue={
                    this.changeWeek
                    /*value =>
                    console.log("value",value)
                    this.setState({
                      week: value,
                      viewportRange: this.weeks[value],
                    })*/
                  }
                  outline
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default EDFSDemandStack;
