import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { standardColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("standardColorSet", standardColorset);

class EDFSUtilisationMarginal extends Component {
  componentDidMount() {
    //console.log("colorset", this.chart._selectedColorSet);
    this.colorset = this.chart._selectedColorSet;
  }

  /*colorset = [
    "#6D78AD",
    "#51CDA0",
    "#DF7970",
    "#4C9CA0",
    "#AE7D99",
    "#C9D45C",
    "#5592AD",
    "#DF874D",
    "#52BCA8",
    "#8E7AA3",
    "#E3CB64",
    "#C77B85",
    "#C39762",
    "#8DD17E",
    "#B57952",
    "#FCC26C",
  ];*/

  render() {
    console.log("Rendering EDFSUtilisationMarginal");
    //console.log("props", this.props.generation);
    let dataFields = [];
    let j = 0;
    for (let tech of this.props.generation) {
      if (
        this.props.marginalGenTechData[tech.id] ||
        this.props.initMarginalGenTechData[tech.id]
      ) {
        if (this.props.marginalGenTechData[tech.id]) {
          let dataPoints = [];
          for (
            let i = 0;
            i < this.props.marginalGenTechData[tech.id].length;
            i++
          ) {
            if (this.props.marginalGenTechData[tech.id][i]) {
              const lf =
                Math.round(
                  (this.props.marginalGenTechData[tech.id][i] * 1000) /
                    (this.props.marginalCapData[tech.id][i] * 8760)
                ) / 10;
              let init = "";
              let initlf = "";
              if (
                typeof this.props.initMarginalGenTechData[tech.id][i] !==
                  "undefined" &&
                typeof this.props.initMarginalCapData[tech.id][i] !==
                  "undefined"
              ) {
                init = this.props.initMarginalGenTechData[tech.id][
                  i
                ].toLocaleString();
                initlf =
                  Math.round(
                    (this.props.initMarginalGenTechData[tech.id][i] * 1000) /
                      (this.props.initMarginalCapData[tech.id][i] * 8760)
                  ) / 10;
              }
              dataPoints.push({
                x: i + 1,
                y: this.props.marginalGenTechData[tech.id][i],
                lf: lf,
                init: init,
                initlf: initlf,
              });
            }
          }
          dataFields.push({
            type: "line",
            name: tech.tech,
            color: standardColorset[j],
            showInLegend: true,
            toolTipContent:
              "<span style='\"'color: " +
              standardColorset[j] +
              ";'\"'>{name}:</span> {y} ({lf}%) &nbsp;<small>[{init} ({initlf}%)]</small>",
            xValueFormatString: "Tranche #,##0",
            yValueFormatString: "#,##0",
            dataPoints: dataPoints,
          });
        }
        if (this.props.initMarginalGenTechData[tech.id]) {
          let dataPoints = [];
          for (
            let i = 0;
            i < this.props.initMarginalGenTechData[tech.id].length;
            i++
          ) {
            if (this.props.initMarginalGenTechData[tech.id][i]) {
              /*const lf =
                Math.round(
                  (this.props.initMarginalGenTechData[tech.id][i] * 1000) /
                    (this.props.initMarginalCapData[tech.id][i] * 8760)
                ) / 10; */
              dataPoints.push({
                x: i + 1,
                y: this.props.initMarginalGenTechData[tech.id][i],
                //lf: lf,

                //  "<span style='\"'color: " +
                //  standardColorset[j] +
                //  ";'\"'>{label} (Base):</span> {y} ({lf}%)",
              });
            }
          }
          dataFields.push({
            type: "line",
            lineDashType: "dot",
            name: tech.tech + " (Base)",
            label: tech.tech,
            color: standardColorset[j],
            toolTipContent: null,
            xValueFormatString: "Tranche #,##0",
            yValueFormatString: "#,##0",
            dataPoints: dataPoints,
          });
        }
        j++;
      }
    }
    //console.log("dataFields", dataFields);

    const options = {
      theme: "light2",
      colorSet: "standardColorSet",
      exportEnabled: true,
      zoomEnabled: true,
      zoomType: "x",
      title: {
        text: "Marginal generation utilisation",
      },
      axisX: {
        valueFormatString: "#,##0",
        title: "Tranche of 1,000 MW capacity",
      },
      axisY: {
        includeZero: true,
        title: "Utilisation (MWh p.a.)",
      },
      toolTip: {
        shared: true,
      },
      data: dataFields,
    };

    return (
      <>
        <div>
          <CanvasJSChart options={options} onRef={ref => (this.chart = ref)} />
        </div>
      </>
    );
  }
}

export default EDFSUtilisationMarginal;
