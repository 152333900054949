import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSCarbonHeat extends Component {
  render() {
    console.log("Rendering EDFSCarbonHeat");
    if (this.props.propData && this.props.baseData) {
      const fontFamily =
        "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";

      //const fuels = Object.keys(this.props.propData);
      /* const fuelNames = {
		  gas: "Gas",
		  oil: "Oil",
		  coal: "Coal",
		  biomass: "Solid biomass",
		  biogas: "Biogas",
		  bioliquids: "Bioliquids",
      }; */
      const data = [];
      let heatTotal = 0;
      let initHeatTotal = 0;
      for (let techObj of this.props.heatsupply
        .map((obj) => {
          return obj;
        })
        .reverse()) {
        if (typeof techObj.fuel !== "undefined" && techObj.fuel > 0) {
          data.push({
            type: "stackedColumn",
            legendText: techObj.tech,
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: techObj.tech + ": {y}",
            dataPoints: [
              {
                label: "Base",
                y: this.props.baseData[techObj.id],
              },
              {
                label: "Proposed",
                y: this.props.propData[techObj.id],
              },
            ],
          });
          heatTotal += this.props.propData[techObj.id];
          initHeatTotal += this.props.baseData[techObj.id];
        }
      }
      /* for (let fuel of fuels) {
        if (this.props.heatsupply[fuel]) {
          data.push({
            type: "stackedColumn",
            legendText: this.props.heatsupply[fuel],
            showInLegend: "true",
            yValueFormatString: "0.000",
            toolTipContent: this.props.heatsupply[fuel] + ": {y}",
            dataPoints: [
              {
                label: "Base",
                y: this.props.baseData[fuel],
              },
              {
                label: "Proposed",
                y: this.props.propData[fuel],
              },
            ],
          });
          heatTotal += this.props.propData[fuel];
          initHeatTotal += this.props.baseData[fuel];
        }
      } */
      if (data.length) {
        data[data.length - 1].indexLabel = "#total";
        data[data.length - 1].indexLabelPlacement = "outside";
      }
      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Non-electric heat production",
          fontSize: 20,
          fontFamily: fontFamily,
          //fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "M tCO2e p.a.",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString:
            Math.max(heatTotal, initHeatTotal) >= 2000000 ? "#,##0" : "0.0",
        },
        data: data,
      };

      return <CanvasJSChart options={options} />;
    } else {
      return "Loading...";
    }
  }
}

export default EDFSCarbonHeat;
