import React, { Component } from "react";
import EDBMRPage from "../components/EDBMRPage";

class BMRDayTypePage extends Component {
  dataTypeInfoText() {
    return (
      <>
        <p>
          Sum is the total output of the technologies in a group each day. There
          are a few gaps in the data, and you will not be able to tell if the
          sum dips because of reduced output or missing data.
        </p>
        <p>
          Average addresses this by only taking the average of available data
          for each group on each day, but is more useful for the trend than for
          the absolute figures because the average output is of limited interest
          unless the technologies are similar.
        </p>
      </>
    );
  }

  topText() {
    return (
      <>
        <h2 className="h2-responsive mb-4">
          <strong>BMR Daily Flows by Type</strong>
        </h2>
        <p>
          Daily figures for flows on the transmission network aggregated by type
          of technology and flow.
        </p>
        <p>
          This page allows you to visualise the daily flows on the grid. The
          flows are expressed in MWh (energy) over the day, not average MW
          (power).
        </p>
      </>
    );
  }

  render() {
    return (
      <EDBMRPage
        itemURL="https://ed.c4cs.org.uk:5002/BMRTypes"
        dataURL="https://ed.c4cs.org.uk:5002/BMRDayByType"
        topText={this.topText()}
        datatypeInfoText={this.dataTypeInfoText()}
        initDays={365}
        initMonths={12}
        maxDays={3000}
        itemsColHeading="Categories"
        datatypes={[{ id: 1, name: "Sum" }, { id: 2, name: "Average" }]}
        dataTableClassName="bmrhhtypedt"
        interval="day"
      />
    );
  }
}

export default BMRDayTypePage;
