import React, { PureComponent } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSInterconnection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.interconnection) {
      for (let i = 0; i < props.interconnection.length; i++) {
        const id = props.interconnection[i].id;
        for (let char in props.interconnection[i]) {
          if (char !== "id" && char !== "tech") {
            let dataKey = id + "_" + char;
            this.state[dataKey] = props.interconnection[i][char];
            dataKey += "_changed";
            this.state[dataKey] = false;
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this);
  }

  itemUpdate(event) {
    if (event.target.id) {
      let [type, id, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      const valKey = id + "_" + characteristic;
      const changedKey = valKey + "_changed";
      this.setState({ [valKey]: val, [changedKey]: true });
      let params = { type: type, id: id, vals: { [characteristic]: val } };
      this.props.fieldUpdate(params);
    }
  }

  render() {
    console.log("Rendering EDFSInterconnection");
    //console.log("interconnection", this.props.interconnection);
    //console.log("state", this.state);
    return (
      <div
        className="d-flex flex-wrap flex-xl-nowrap px-0"
        style={{ margin: "-6px" }}
      >
        {this.props.interconnection.map(item => (
          <div
            key={"interconnection_" + item.id + "_col"}
            id={"interconnection_" + item.id + "_col"}
            className="flex-fill p-2"
          >
            <MDBCard
              id={"interconnection_" + item.id + "_card"}
              style={{ minWidth: "178px" }}
            >
              <MDBCardHeader id={"interconnection_" + item.id + "_header"}>
                {item.tech}
              </MDBCardHeader>
              <MDBCardBody
                id={"interconnection_" + item.id + "_body"}
                className="p-1"
              >
                <div className="form-group row px-2">
                  <label className="col-form-label col">
                    Capacity <small className="text-muted">MW</small>
                  </label>
                  <div className="col">
                    <input
                      id={"interconnection_" + item.id + "_capacity"}
                      type="number"
                      min={0}
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_capacity_changed"]
                          ? " border-danger"
                          : "")
                      }
                      aria-label={item.tech + " capacity"}
                      value={item.capacity}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        ))}
      </div>
    );
  }
}

export default EDFSInterconnection;
