import React, { PureComponent } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSHeatSupply extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.heatsupply) {
      for (let i = 0; i < props.heatsupply.length; i++) {
        const id = props.heatsupply[i].id;
        for (let char in props.heatsupply[i]) {
          if (char !== "id" && char !== "tech") {
            let dataKey = id + "_" + char;
            this.state[dataKey] = props.heatsupply[i][char];
            dataKey += "_changed";
            this.state[dataKey] = false;
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this);
  }

  itemUpdate(event) {
    if (event.target.id) {
      let [type, id, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      const valKey = id + "_" + characteristic;
      const changedKey = valKey + "_changed";
      let update_states = { [valKey]: val, [changedKey]: true };
      let params = {
        type: type,
        id: id,
        vals: { [characteristic]: val },
      };
      const heatKey = id + "_heat";
      const efficKey = id + "_efficiency";
      const fuelItem = this.props.getItem(this.props.heatsupply, id);
      let fuelBase = "elec";
      if ("fuel" in fuelItem) {
        fuelBase = "fuel";
      }
      const elecKey = id + "_" + fuelBase;
      if (characteristic === "heatshare") {
        //const heatval = Math.round((val * this.props.buildingheat) / 10) / 10;
        const heatval =
          Math.round((val * this.props.heatdemand.heat) / 10) / 10;
        update_states[heatKey] = heatval;
        params.vals.heat = heatval;
        params.vals.capacity = Math.ceil(
          (1000000 * heatval) / (87.6 * fuelItem.loadFactor)
        );
        update_states[elecKey] =
          Math.round((1000 * heatval) / this.state[efficKey]) / 10;
      } else if (characteristic === "efficiency") {
        update_states[elecKey] =
          Math.round((1000 * this.state[heatKey]) / val) / 10;
      }
      params.vals[fuelBase] = update_states[elecKey];
      // console.log("heat tech params", params);
      this.setState(update_states);
      this.props.fieldUpdate(params);
    }
  }

  render() {
    //console.log("Rendering EDFSHeatSupply");
    //console.log("heatsupply", this.props.heatsupply);
    //console.log("state", this.state);
    return (
      <>
        {/*<p className="lead">
          Total annual heat demand is {this.props.totalheat} TWh, of which
          building heat (inc. hot water but excluding cooking) is{" "}
          {this.props.buildingheat} TWh. Other electric heat (e.g. for
          industrial processes) is included in{" "}
          <em>{this.props.convdemandTech}</em> above.
          </p>*/}
        <p className="lead mb-2">
          Total annual heat demand is {this.props.heatdemand.heat.toFixed(1)}{" "}
          TWh, of which:
        </p>
        <MDBRow>
          <MDBCol size="4" sm="3" md="2">
            By sector:
          </MDBCol>
          <MDBCol size="8" sm="9" md="10">
            <div className="d-flex flex-column flex-md-row">
              <div className="flex-fill">
                Domestic: {this.props.heatdemand.homes.toFixed(1)}
              </div>
              <div className="flex-fill">
                Services: {this.props.heatdemand.services.toFixed(1)}
              </div>
              <div className="flex-fill">
                Industry: {this.props.heatdemand.industry.toFixed(1)}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
          <MDBCol size="4" sm="3" md="2">
            By use:
          </MDBCol>
          <MDBCol size="8" sm="9" md="10">
            <div className="d-flex flex-column flex-xl-row">
              <div className="d-flex flex-column flex-md-row flex-fill">
                <div className="flex-fill">
                  Buildings: {this.props.heatdemand.buildingHeat.toFixed(1)}
                </div>
                <div className="flex-fill">
                  Process (high temp):{" "}
                  {this.props.heatdemand.processHigh.toFixed(1)}
                </div>
                <div className="flex-fill">
                  Process (low temp):{" "}
                  {this.props.heatdemand.processLow.toFixed(1)}
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row flex-fill">
                <div className="flex-fill">
                  Drying/separation: {this.props.heatdemand.drying.toFixed(1)}
                </div>
                <div className="flex-fill">
                  Other: {this.props.heatdemand.other.toFixed(1)}
                </div>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="px-2">
          {this.props.heatsupply.map((item) => (
            <MDBCol
              key={"heatsupply_" + item.id + "_col"}
              id={"heatsupply_" + item.id + "_col"}
              className="p-2"
              size="12"
              md="6"
              lg="4"
            >
              <MDBCard id={"heatsupply_" + item.id + "_card"}>
                <MDBCardHeader id={"heatsupply_" + item.id + "_header"}>
                  {item.tech}
                </MDBCardHeader>
                <MDBCardBody
                  id={"heatsupply_" + item.id + "_body"}
                  className="p-1"
                >
                  <div className="form-group row px-2 mb-2">
                    <label className="col-form-label col-form-label-sm col-8">
                      Share of total heat{" "}
                      <small className="text-muted">%</small>
                    </label>
                    <div className="col-4">
                      <input
                        id={"heatsupply_" + item.id + "_heatshare"}
                        type="number"
                        className={
                          "form-control form-control-sm" +
                          (this.state[item.id + "_heatshare_changed"]
                            ? " border-danger"
                            : "")
                        }
                        max={
                          100 -
                          this.props.heatsupply
                            .map((item) => item.heatshare)
                            .reduce((x, y) => x + y) +
                          item.heatshare
                        }
                        min={0}
                        value={item.heatshare}
                        onChange={this.itemUpdate}
                      />
                    </div>
                  </div>
                  <div className="form-group row px-2 mb-2">
                    <label className="col-form-label col-form-label-sm col-8">
                      Heat demand <small className="text-muted">TWh</small>
                    </label>
                    <div className="col-4">
                      <input
                        id={"heatsupply_" + item.id + "_heat"}
                        type="number"
                        className="form-control form-control-sm"
                        value={item.heat}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row px-2 mb-2">
                    <label className="col-form-label col-form-label-sm col-8">
                      {item.id.match(/CHP$/) && "Heat "}Efficiency{" "}
                      <small className="text-muted">
                        %{item.id.match(/shp$/) && " (SPF x 100)"}
                      </small>
                    </label>
                    <div className="col-4">
                      <input
                        id={"heatsupply_" + item.id + "_efficiency"}
                        type="number"
                        className={
                          "form-control form-control-sm" +
                          (this.state[item.id + "_efficiency_changed"]
                            ? " border-danger"
                            : "")
                        }
                        min={1}
                        value={item.efficiency}
                        onChange={this.itemUpdate}
                      />
                    </div>
                  </div>
                  {!(typeof item.elec === "undefined") && (
                    <div className="form-group row px-2 mb-2">
                      <label className="col-form-label col-form-label-sm col-8">
                        Est. electricity demand{" "}
                        <small className="text-muted">TWh</small>
                      </label>
                      <div className="col-4">
                        <input
                          id={"heatsupply_" + item.id + "_elec"}
                          type="number"
                          className="form-control form-control-sm"
                          min={0}
                          value={item.elec}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {!(typeof item.fuel === "undefined") && (
                    <div className="form-group row px-2 mb-2">
                      <label className="col-form-label col-form-label-sm col-8">
                        Fuel consumption{" "}
                        <small className="text-muted">TWh</small>
                      </label>
                      <div className="col-4">
                        <input
                          id={"heatsupply_" + item.id + "_fuel"}
                          type="number"
                          className="form-control form-control-sm"
                          min={0}
                          value={item.fuel}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
        </MDBRow>
      </>
    );
  }
}

export default EDFSHeatSupply;
