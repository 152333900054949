import React, { Component } from "react";
import {
  MDBDataTable,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBBtnGroup,
  MDBCollapse,
  MDBIcon,
  MDBPopover,
  MDBPopoverBody,
  MDBEdgeHeader,
  MDBFreeBird,
} from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from "file-saver";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import mdbcolors from "./mdbcolors.json";
import EDProjGroupCard from "./EDProjGroupCard";
import EDBMRGenUnitChart from "./EDBMRGenUnitChart";
import EDBMRGenUnitInstructions from "./EDBMRGenUnitInstructions";

//import { resolveProjectReferencePath } from "typescript";
//import { labeledStatement } from "@babel/types";

class EDBMRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartType: "line",
      data: { datatype: 1 },
      dataType: 1,
      days: this.props.initDays, //this.defaultDays,
      displayChart: true,
      displayTable: false,
      dlCollapseOpen: true,
      dlCollapseCaret: "caret-up",
      downloadsDisabled: true,
      nextGroupId: 5,
      plCollapseOpen: true,
      plCollapseCaret: "caret-up",
      scatterDisabled: true,
      selectedgroup: 1,
      selectgroups: [
        {
          id: 1,
          name: "Group 1",
          items: [],
          color: "#3f51b5",
        },
        {
          id: 2,
          name: "Group 2",
          items: [],
          color: "#009688",
        },
        {
          id: 3,
          name: "Group 3",
          items: [],
          color: "#ffeb3b",
        },
        {
          id: 4,
          name: "Group 4",
          items: [],
          color: "#795548",
        },
      ],
      startDate: this.firstDate, //this.firstDateOfLastMonth,
      techListLoaded: false,
      techgroups: [],
      updateButtonDisabled: true,
    };

    this.addGroup = this.addGroup.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.clickMove = this.clickMove.bind(this);
    this.deleteSelectedGroup = this.deleteSelectedGroup.bind(this);
    this.deleteSelectedItem = this.deleteSelectedItem.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.handleDaysChange = this.handleDaysChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.moveGroup = this.moveGroup.bind(this);
    this.resetData = this.resetData.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.setChartType = this.setChartType.bind(this);
    this.setGroupColor = this.setGroupColor.bind(this);
    this.setDataType = this.setDataType.bind(this);
    this.toggledlCollapse = this.toggledlCollapse.bind(this);
    this.toggleplCollapse = this.toggleplCollapse.bind(this);
    this.updateButtonStatus = this.updateButtonStatus.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateGroupName = this.updateGroupName.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  firstMoment = moment().subtract(this.props.initDays, "days");
  firstDate = this.firstMoment.toDate();

  //firstMomentOfThisMonth = moment().startOf("month");
  //firstMomentOfLastMonth = this.firstMomentOfThisMonth.subtract(
  //  this.props.initMonths,
  //  "months"
  //);
  //firstDateOfLastMonth = this.firstMomentOfLastMonth.toDate();
  //defaultDays = moment().diff(this.firstMomentOfLastMonth, "days");

  componentDidMount() {
    fetch(this.props.itemURL)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            techListLoaded: true,
            techgroups: result,
          });
        },
        error => {
          this.setState({
            techListLoaded: true,
            error,
          });
          console.log(error);
        }
      );
    window.scrollTo(0, 0);
  }

  addGroup() {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const groupId = this.state.nextGroupId;
    const colorIndex = (groupId * 4) % 19;
    const groupColor = mdbcolors.palette[colorIndex][2];
    const groupName = "Group " + groupId;
    selectGroups.push({
      id: groupId,
      name: groupName,
      color: groupColor,
      items: [],
    });
    this.setState({
      selectgroups: selectGroups,
      nextGroupId: 1 + groupId,
    });
    //console.log("selectgroups", selectGroups);
  }

  changeDate(step) {
    const startMoment = moment(this.state.startDate);
    switch (step) {
      case -2:
        startMoment.subtract(this.state.days, "days");
        break;
      case -1:
        startMoment.subtract(1, "days");
        break;
      case 1:
        startMoment.add(1, "days");
        break;
      case 2:
        startMoment.add(this.state.days, "days");
        break;
      default:
        startMoment.add(0, "days");
    }
    const startDate = startMoment.toDate();
    this.setState({ startDate: startDate });
  }

  clickMove(index, parentIndex, section) {
    //console.log(index, parentIndex, section);
    if (this.state.selectedgroup) {
      const sourcesection = JSON.parse(JSON.stringify(this.state[section]));
      let destsection = sourcesection;
      if (section === "techgroups") {
        destsection = JSON.parse(JSON.stringify(this.state.selectgroups));
      }
      const moveproject = sourcesection[parentIndex].items.splice(index, 1)[0];

      let destIndex = null;
      for (let i = 0; i < destsection.length; i++) {
        if (destsection[i].id === this.state.selectedgroup) {
          destIndex = i;
          break;
        }
      }
      if (destIndex == null) {
        alert(
          "No current group is selected (did you delete the last selected group?)"
        );
      } else {
        destsection[destIndex].items.push(moveproject);
        const update_states = {
          selectgroups: destsection,
          updateButtonDisabled: false,
        };
        if (section === "techgroups") {
          update_states["techgroups"] = sourcesection;
        }
        this.setState(update_states);
      }
    }
  }

  deleteSelectedItem(itemIndex, groupIndex) {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const deleteGroup = selectGroups[groupIndex];
    const deleteItem = deleteGroup.items.splice(itemIndex, 1)[0];
    const home_id = deleteItem.home_id;

    selectGroups[groupIndex] = deleteGroup;

    const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
    const techcount = techGroups.length;
    for (let i = 0; i < techcount; i++) {
      if (techGroups[i].id === home_id) {
        techGroups[i].items.push(deleteItem);
        break;
      }
    }
    const update_states = {
      selectgroups: selectGroups,
      techgroups: techGroups,
    };
    const arStatus = this.updateButtonStatus(selectGroups);
    if (arStatus[0]) {
      update_states["updateButtonDisabled"] = arStatus[1];
    }
    //update_states["techgroups"] = techGroups;
    this.setState(update_states);
  }

  deleteSelectedGroup(groupIndex) {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const deleteGroup = selectGroups.splice(groupIndex, 1)[0];
    const delitems = deleteGroup.items;
    const projectcount = delitems.length;
    const update_states = { selectgroups: selectGroups };
    if (projectcount > 0) {
      const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
      const techcount = techGroups.length;
      const techGroupIds = {};
      for (let i = 0; i < techcount; i++) {
        techGroupIds[techGroups[i].id] = i;
      }
      for (let i = 0; i < projectcount; i++) {
        const home_id = delitems[i].home_id;
        techGroups[techGroupIds[home_id]].items.push(delitems[i]);
      }
      update_states["techgroups"] = techGroups;
    }
    const arStatus = this.updateButtonStatus(selectGroups);
    if (arStatus[0]) {
      update_states["updateButtonDisabled"] = arStatus[1];
    }
    this.setState(update_states);
  }

  downloadData(format) {
    const { startDate, days, selectgroups, dataType } = this.state;
    fetch(this.props.dataURL, {
      method: "POST",
      body: JSON.stringify({
        startDate: startDate,
        days: days,
        groups: selectgroups,
        datastyle: dataType,
        format: format,
      }),
    }).then(function(response) {
      // console.log(response.headers.get("Content-Type"));
      const contdisp = response.headers.get("content-disposition");
      // console.log(contdisp);
      const filematch = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
      const filename = contdisp.match(filematch)[2];

      response.blob().then(file => {
        // Download the file
        saveAs(file, filename);
      });
    });
  }

  handleDaysChange(event) {
    this.setState({ days: event.target.value });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }

  moveGroup(index) {
    if (this.state.selectedgroup) {
      const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
      const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
      let destIndex = null;
      for (let i = 0; i < selectGroups.length; i++) {
        if (selectGroups[i].id === this.state.selectedgroup) {
          destIndex = i;
          break;
        }
      }

      if (destIndex == null) {
        alert(
          "No current group is selected (did you delete the last selected group?)"
        );
      } else {
        techGroups[index].items.forEach(project =>
          selectGroups[destIndex].items.push(project)
        );
        techGroups[index].items = [];
        this.setState({
          selectgroups: selectGroups,
          techgroups: techGroups,
          updateButtonDisabled: false,
        });
      }
    }
  }

  resetData() {
    /*const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
    selectGroups.forEach(function(group) {
      group.items.forEach(function(item) {
        const home_id = item.home_id;
        for (let i = 0; i < techGroups.length; i++) {
          if (techGroups[i].id === home_id) {
            techGroups[i].push(item);
            break;
          }
        }
      });
      group.items = [];
    });
    this.setState({
      selectgroups: 
    });*/
    window.location.reload();
  }

  selectGroup(groupId) {
    this.setState({ selectedgroup: groupId });
    //console.log("selectedgroup:", groupId);
  }

  setChartType = chartType => () => {
    //this.setState({ chartLoading: true });
    if (
      this.state.selectgroups.filter(
        group => group.items && group.items.length > 0
      ).length > 1
    ) {
      this.setState({ chartType: chartType, scatterDisabled: false });
    } else {
      this.setState({ chartType: "line", scatterDisabled: true });
    }
    //this.setState({ chartLoading: false });
  };

  setDataType = dataType => () => {
    this.setState({ dataType: dataType });
  };

  setGroupColor(groupIndex, color) {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    selectGroups[groupIndex].color = color;
    this.setState({ selectgroups: selectGroups });
  }

  toggledlCollapse(event) {
    const newState = !this.state.dlCollapseOpen;
    const newCaret = newState ? "caret-up" : "caret-down";
    this.setState({
      dlCollapseOpen: newState,
      dlCollapseCaret: newCaret,
    });
  }

  toggleplCollapse(event) {
    const newState = !this.state.plCollapseOpen;
    const newCaret = newState ? "caret-up" : "caret-down";
    this.setState({
      plCollapseOpen: newState,
      plCollapseCaret: newCaret,
    });
  }

  // Pass in this.state.selectgroups, or its copy in destsection in onDragEnd
  // return array of two Booleans: (1) has changed, (2) disabled (true/fals)
  updateButtonStatus(destsection) {
    //console.log("items: ", destsection.length);
    const { updateButtonDisabled } = this.state;
    let activeGroups = 0;

    for (let i = 0; i < destsection.length; i++) {
      if (destsection[i].items.length > 0) {
        activeGroups++;
      }
    }
    //console.log("updateButtonStatus: ", activeGroups);
    let newStatus = activeGroups > 0 ? false : true;
    let changedStatus = newStatus === updateButtonDisabled ? false : true;
    return [changedStatus, newStatus];
  }

  updateData() {
    const { startDate, days, selectgroups, dataType } = this.state;
    //this.setState({ chartLoading: true });
    if (
      selectgroups.filter(group => group.items && group.items.length > 0)
        .length < 2
    ) {
      this.setState({ scatterDisabled: true, chartType: "line" });
    } else {
      this.setState({ scatterDisabled: false });
    }

    //console.log("state: ", JSON.stringify(selectgroups));
    fetch(this.props.dataURL, {
      method: "POST",
      body: JSON.stringify({
        startDate: startDate,
        days: days,
        groups: selectgroups,
        datastyle: dataType,
      }),
      headers: {
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(
        result => {
          let empty = true;
          if (result.rows && result.rows.length) {
            empty = false;
          }
          //const dataType = result.dataType;

          this.setState({
            data: result,
            downloadsDisabled: empty,
          });

          //this.setState({ chartLoading: false });
        },
        error => {
          /* this.setState({
            error,
          }); */
          this.setState({ downloadsDisabled: true });
          console.log(error);
        }
      );
  }

  updateGroupName(e, index) {
    const destsection = JSON.parse(JSON.stringify(this.state.selectgroups));
    const newval = e.target.value;
    const oldval = destsection[index].name;
    if (newval !== oldval) {
      destsection[index].name = newval;
      this.setState({ selectgroups: destsection });
    }
  }

  onDragEnd = result => {
    const { destination, source } = result; // draggableId

    if (!destination) {
      return;
    }
    //console.log("destination:", destination);
    //console.log("source:", source);
    //console.log("draggableId:", draggableId);
    let destgroup_section;
    let destgroup_id;
    let destsection;
    let destgroup_index;
    let sourcegroup_section;
    let sourcegroup_id;
    let sourcesection;
    let sourcegroup_index;
    //let project_id;
    const idre = /^(\w+)-(\d+)/;

    //const projmatch = idre.exec(draggableId);
    //if (!projmatch) {
    //project_id = parseInt(projmatch[2]);
    //} else {
    //  return;
    //}

    const destmatch = idre.exec(destination.droppableId);
    if (destmatch) {
      destgroup_section = destmatch[1];
      destgroup_id = parseInt(destmatch[2]);
      destsection = JSON.parse(JSON.stringify(this.state[destgroup_section]));
      for (let i = 0; i < destsection.length; i++) {
        if (destsection[i].id === destgroup_id) {
          //console.log("typeof", typeof destsection[i].id);
          destgroup_index = i;
          break;
        }
      }
    } else {
      return;
    }

    const update_states = {};

    if (destination.droppableId === source.droppableId) {
      if (destination.index === source.index) {
        return;
      } else {
        destsection[destgroup_index].items.splice(
          destination.index,
          0,
          destsection[destgroup_index].items.splice(source.index, 1)[0]
        );
        update_states[destgroup_section] = destsection;
      }
    } else {
      const sourcematch = idre.exec(source.droppableId);
      if (sourcematch) {
        sourcegroup_section = sourcematch[1];
        sourcegroup_id = parseInt(sourcematch[2]);
        if (destgroup_section === sourcegroup_section) {
          sourcesection = destsection;
        } else {
          sourcesection = JSON.parse(
            JSON.stringify(this.state[sourcegroup_section])
          );
        }
        for (let i = 0; i < sourcesection.length; i++) {
          if (sourcesection[i].id === sourcegroup_id) {
            sourcegroup_index = i;
            break;
          }
        }
      } else {
        return;
      }

      const moveproject = sourcesection[sourcegroup_index].items.splice(
        source.index,
        1
      )[0];
      //console.log("moveproject: ", JSON.stringify(moveproject));
      const home_id = moveproject.home_id;
      if (
        destgroup_section === "techgroups" &&
        destsection[destgroup_index].id !== home_id
      ) {
        return;
      } else {
        destsection[destgroup_index].items.splice(
          destination.index,
          0,
          moveproject
        );
        update_states[destgroup_section] = destsection;
        if (destgroup_section !== sourcegroup_section) {
          update_states[sourcegroup_section] = sourcesection;
          const selsection =
            destgroup_section === "techgroups" ? sourcesection : destsection;
          const arStatus = this.updateButtonStatus(selsection);
          if (arStatus[0]) {
            update_states["updateButtonDisabled"] = arStatus[1];
          }
        }
      }
    }

    if (Object.keys(update_states).length) {
      //console.log(update_states);
      this.setState(update_states);
    } else {
      return;
    }
  };

  /* console.log("sourcegroup_section: ", sourcegroup_section);
    console.log("sourcegroup_id: ", sourcegroup_id);
    console.log("sourcegroup_index: ", sourcegroup_index);
    console.log("source.index: ", source.index);
    console.log("destgroup_section: ", destgroup_section);
    console.log("destgroup_id: ", destgroup_id);
    console.log("destgroup_index: ", destgroup_index);
    console.log("destination.index: ", destination.index);
    console.log("destsection: ", JSON.stringify(destsection)); */

  render() {
    const {
      techListLoaded,
      techgroups,
      selectgroups,
      selectedgroup,
      chartType,
      dataType,
      dlCollapseCaret,
      dlCollapseOpen,
      startDate,
      days,
      updateButtonDisabled,
      data,
    } = this.state;

    //console.log("columns1:", data.columns);
    return (
      <>
        <MDBEdgeHeader color="green darken-3" />
        <MDBFreeBird>
          <MDBRow>
            <MDBCol
              md="10"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <MDBCardBody>
                {this.props.topText}
                <MDBRow>
                  <EDBMRGenUnitInstructions />
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
        <MDBContainer fluid className="mt-3">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <MDBRow className="py-3">
              <MDBCol lg="9" md="8" size="6">
                <MDBCard className="mb-3">
                  <MDBCardBody className="p-2">
                    <MDBCardTitle className="mb-4">
                      Select&nbsp;
                      <span
                        className="mt-0"
                        onClick={this.toggledlCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBIcon icon={dlCollapseCaret} />
                      </span>
                    </MDBCardTitle>
                    <MDBCollapse id="dlCollapse" isOpen={dlCollapseOpen}>
                      <MDBRow>
                        <MDBCol lg="4" md="6" size="12" className="pb-3">
                          <DatePicker
                            placeholderText="Start date"
                            id="startDate"
                            className="form-control"
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="bottom-start"
                            onChange={this.handleStartDateChange}
                            popperModifiers={{
                              flip: {
                                enabled: false,
                              },
                            }}
                          />
                          <span>&nbsp;for</span>
                        </MDBCol>
                        <MDBCol lg="4" md="6" size="12" className="pb-3">
                          <div className="input-group daysinput">
                            <input
                              className="form-control"
                              id="days"
                              name="days"
                              value={days}
                              onChange={this.handleDaysChange}
                              type="number"
                              min="1"
                              max={this.props.maxDays}
                              size="4"
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                id="basic-addon"
                              >
                                days
                              </span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol lg="4" md="6" size="12" className="pb-3">
                          <MDBBtnGroup className="mb-2 mr-md-2 ml-md-0 w-100">
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(-2);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="fast-backward" />
                            </MDBBtn>
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(-1);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="step-backward" />
                            </MDBBtn>
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(1);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="step-forward" />
                            </MDBBtn>
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(2);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="fast-forward" />
                            </MDBBtn>
                          </MDBBtnGroup>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="maxvh70 pt-2 border-top">
                        {selectgroups.map((selectgroup, index) => (
                          <MDBCol
                            key={"groupcol" + index}
                            lg="4"
                            md="6"
                            size="12"
                          >
                            <EDProjGroupCard
                              key={"selectgroups-" + selectgroup.id}
                              id={selectgroup.id}
			      selectedgroup={selectedgroup}
                              index={index}
                              name={selectgroup.name}
                              items={selectgroup.items}
                              color={selectgroup.color}
                              droppableId={"selectgroups-" + selectgroup.id}
                              section="selectgroups"
                              clickMove={this.clickMove}
                              moveGroup={this.moveGroup}
                              deleteGroup={this.deleteSelectedGroup}
                              deleteItem={this.deleteSelectedItem}
                              selectGroup={this.selectGroup}
                              setColor={this.setGroupColor}
                              updateName={this.updateGroupName}
                              mdbcolors={mdbcolors}
                              //updateDisabled={this.updateButtonDisabled}
                            />
                          </MDBCol>
                        ))}
                        <MDBCol lg="4" md="6" size="12">
                          <MDBCard className="mb-3">
                            <MDBCardBody className="minh110 mx-auto">
                              <span
                                onClick={() => {
                                  this.addGroup();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <MDBIcon icon="plus" size="4x" />
                              </span>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="border-top pt-2">
                        <MDBCol>
                          <span className="h6 mr-1">Type of data</span>
                          <MDBPopover popover clickable placement="right">
                            <MDBBtn
                              size="sm"
                              className="px-2 py-1 mr-4"
                              color="dark"
                              style={{ borderRadius: "15px" }}
                            >
                              <MDBIcon icon="info" className="m-1" />
                            </MDBBtn>
                            <div>
                              <MDBPopoverBody>
                                {this.props.datatypeInfoText}
                              </MDBPopoverBody>
                            </div>
                          </MDBPopover>
                          <div
                            className="btn-group btn-group-toggle"
                            data-toggle="buttons"
                          >
                            {this.props.datatypes.map(dt => {
                              return (
                                <label
                                  key={dt.id}
                                  className={
                                    "btn btn-default btn-sm px-2 px-md-3 px-lg-4 px-xl-5 " +
                                    (dataType === dt.id ? "active" : "")
                                  }
                                >
                                  <input
                                    type="radio"
                                    id={"datatype-" + dt.id}
                                    name="datatype"
                                    autoComplete="off"
                                    onChange={this.setDataType(dt.id)}
                                    checked={dataType === dt.id ? true : false}
                                  />
                                  {dt.name}
                                </label>
                              );
                            })}
                          </div>

                          <MDBBtn
                            className="float-right px-2 px-md-3 px-lg-4 px-xl-5"
                            size="sm"
                            color="default"
                            disabled={updateButtonDisabled}
                            onClick={() => {
                              this.updateData();
                            }}
                          >
                            Update
                          </MDBBtn>
                          <MDBBtn
                            className="float-right px-2 px-md-3 px-lg-4 px-xl-5"
                            size="sm"
                            color="warning"
                            onClick={() => {
                              this.resetData();
                            }}
                          >
                            Reset
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCollapse>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="3" md="4" size="6">
                <MDBCard className="mb-3">
                  <MDBCardBody className="p-2">
                    <MDBCardTitle className="mb-4">
                      {this.props.itemsColHeading}&nbsp;
                      <span
                        className="mt-0"
                        //size="sm"
                        //color="light"
                        //outline
                        onClick={this.toggleplCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBIcon icon={this.state.plCollapseCaret} />
                      </span>
                    </MDBCardTitle>
                    <MDBCollapse
                      id="plCollapse"
                      isOpen={this.state.plCollapseOpen}
                    >
                      <div className="maxvh85 p-1">
                        {!techListLoaded && <div>Loading...</div>}
                        {techgroups.map((techgroup, index) => (
                          <EDProjGroupCard
                            key={"techgroups" + techgroup.id}
                            id={techgroup.id}
                            index={index}
                            name={techgroup.name}
                            items={techgroup.items}
                            droppableId={"techgroups-" + techgroup.id}
                            section="techgroups"
                            clickMove={this.clickMove}
                            moveGroup={this.moveGroup}
                            deleteGroup={this.deleteSelectedGroup}
                            deleteItem={this.deleteSelectedItem}
                            selectGroup={this.selectGroup}
                            setColor={this.setGroupColor}
                            updateName={this.updateGroupName}
                            mdbcolors={mdbcolors}
                            //updateDisabled={this.updateButtonDisabled}
                          />
                        ))}
                      </div>
                    </MDBCollapse>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol size="12">
                <MDBCard className="mb-3">
                  <MDBCardTitle>
                    <div className="d-flex px-3">
                      <div className="h5 pt-2">Chart</div>
                      <button
                        type="button"
                        data-toggle="button"
                        className={
                          "btn btn-default btn-sm ml-auto" +
                          (this.state.displayChart ? " active" : "")
                        }
                        aria-pressed={this.state.displayChart}
                        onClick={() => {
                          this.setState({
                            displayChart: !this.state.displayChart,
                          });
                        }}
                      >
                        {this.state.displayChart ? "Hide" : "Show"}
                      </button>
                    </div>
                  </MDBCardTitle>
                  {this.state.displayChart && (
                    <MDBCardBody>
                      <EDBMRGenUnitChart
                        rows={data.rows}
                        columns={data.columns}
                        from={data.from}
                        to={data.to}
                        chartType={chartType}
                        dataType={data.datatype}
                        interval={this.props.interval}
                        //chartLoading={chartLoading}
                      />
                    </MDBCardBody>
                  )}
                  {this.state.displayChart && (
                    <MDBCardFooter>
                      <div
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                      >
                        <label
                          className={
                            "btn btn-default btn-sm " +
                            (chartType === "line" ? "active" : "")
                          }
                        >
                          <input
                            type="radio"
                            id="line"
                            name="charttype"
                            onChange={this.setChartType("line")}
                            checked={chartType === "line" ? true : false}
                            autoComplete="off"
                          />
                          Line
                        </label>
                        <label
                          className={
                            "btn btn-default btn-sm" +
                            (chartType === "scatter" ? " active" : "") +
                            (this.state.scatterDisabled ? " disabled" : "")
                          }
                        >
                          <input
                            type="radio"
                            id="scatter"
                            name="charttype"
                            onChange={this.setChartType("scatter")}
                            checked={chartType === "scatter" ? true : false}
                            autoComplete="off"
                            disabled={this.state.scatterDisabled}
                          />
                          Scatter
                        </label>
                      </div>
                    </MDBCardFooter>
                  )}
                </MDBCard>
              </MDBCol>
              <MDBCol className="w-100" />
              <MDBCol size="12">
                <MDBCard className="mb-3">
                  <MDBCardTitle>
                    <div className="d-flex px-3">
                      <div className="h5 pt-2">DataTable</div>

                      <button
                        type="button"
                        data-toggle="button"
                        className={
                          "btn btn-default btn-sm ml-auto" +
                          (this.state.displayTable ? " active" : "")
                        }
                        aria-pressed={this.state.displayTable}
                        onClick={() => {
                          this.setState({
                            displayTable: !this.state.displayTable,
                          });
                        }}
                      >
                        {this.state.displayTable ? "Hide" : "Show"}
                      </button>
                    </div>
                  </MDBCardTitle>
                  {this.state.displayTable && (
                    <MDBCardBody>
                      <MDBDataTable
                        striped
                        bordered
                        hover
                        small
                        autoWidth
                        scrollX
                        sortable={false}
                        entriesOptions={[10, 20, 50]}
                        entries={10}
                        pagesAmount={6}
                        data={data}
                        className={this.props.dataTableClassName}
                      />
                    </MDBCardBody>
                  )}
                  <MDBCardFooter>
                    <span>Download: </span>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Download"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          this.downloadData("xlsx");
                        }}
                        className="btn btn-sm btn-default"
                        disabled={this.state.downloadsDisabled}
                      >
                        XLSX
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          this.downloadData("csv");
                        }}
                        className="btn btn-sm btn-default"
                        disabled={this.state.downloadsDisabled}
                      >
                        CSV
                      </button>
                    </div>
                  </MDBCardFooter>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </DragDropContext>
        </MDBContainer>
      </>
    );
  }
}

export default EDBMRPage;
