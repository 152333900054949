import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { MDBRow, MDBCol } from "mdbreact";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSTotalCost extends Component {
  render() {
    console.log("Rendering EDFSGenTotalCost");
    // console.log("init", this.props.init);
    //console.log("storage", this.props.storage);
    const fontFamily =
      "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";
    if (this.props.generation) {
      const data1 = [];
      const data2 = [];
      const data3 = [];
      let totalPropOutput = 0;
      let totalBaseOutput = 0;
      let propCost = 0;
      let baseCost = 0;
      //let x = 1;
      for (let i = 0; i < this.props.generation.length; i++) {
        const dataPoints1 = [];
        const dataPoints2 = [];
        const dataPoints3 = [];
        const item = this.props.generation[i];
        const combCapacity = item.current.capacity + item.new.capacity || 0;
        const initCapacity =
          this.props.init.generation[i].current.capacity +
          this.props.init.generation[i].new.capacity;
        if (combCapacity + initCapacity > 0) {
          let currentOutput =
            item.current.capacity * item.current.loadFactor * 87.6 || 0;
          let newOutput = item.new.capacity * item.new.loadFactor * 87.6 || 0;
          totalPropOutput += currentOutput + newOutput;

          let currentVariableCost =
            item.current.variableCost * currentOutput || 0;
          let currentCapitalCost =
            10 *
              this.props.wacc *
              item.current.capacity *
              item.current.capitalCost || 0;
          let currentFixedCost =
            1000 * item.current.fixedCost * item.current.capacity || 0;
          let currentCost =
            currentVariableCost + currentCapitalCost + currentFixedCost;

          let newVariableCost = item.new.variableCost * newOutput || 0;
          let newCapitalCost =
            10 * this.props.wacc * item.new.capacity * item.new.capitalCost ||
            0;
          let newFixedCost = 1000 * item.new.fixedCost * item.new.capacity || 0;
          let newCost = newVariableCost + newCapitalCost + newFixedCost;
          let cost = Math.round((currentCost + newCost) / 10000) / 100;
          propCost += cost;
          let retailCost =
            (currentCost + newCost) / this.props.totalRetailElecDemand || 0;
          let wholesaleCost =
            (currentCost + newCost) / this.props.totalWholesaleElecDemand || 0;

          //console.log(item.id + " prop", cost);
          dataPoints1.unshift({
            y: cost,
            label: "Proposed",
          });

          dataPoints2.unshift({
            y: retailCost,
            label: "Proposed",
          });

          dataPoints3.unshift({
            y: wholesaleCost,
            label: "Proposed",
          });

          const init = this.props.init.generation[i];
          currentOutput =
            init.current.capacity * init.current.loadFactor * 87.6 || 0;
          newOutput = init.new.capacity * init.new.loadFactor * 87.6 || 0;
          totalBaseOutput += currentOutput + newOutput;
          //output = currentOutput + newOutput;

          currentVariableCost = init.current.variableCost * currentOutput || 0;
          currentCapitalCost =
            10 *
              this.props.init.wacc *
              init.current.capacity *
              init.current.capitalCost || 0;
          currentFixedCost =
            1000 * init.current.fixedCost * init.current.capacity || 0;
          currentCost =
            currentVariableCost + currentCapitalCost + currentFixedCost;

          newVariableCost = init.new.variableCost * newOutput || 0;
          newCapitalCost =
            10 *
              this.props.init.wacc *
              init.new.capacity *
              init.new.capitalCost || 0;
          newFixedCost = 1000 * init.new.fixedCost * init.new.capacity || 0;
          newCost = newVariableCost + newCapitalCost + newFixedCost;
          cost = Math.round((currentCost + newCost) / 10000) / 100;
          baseCost += cost;
          retailCost =
            (currentCost + newCost) / this.props.init.totalRetailElecDemand ||
            0;
          wholesaleCost =
            (currentCost + newCost) /
              this.props.init.totalWholesaleElecDemand || 0;

          // console.log(item.id + " base", cost);
          dataPoints1.unshift({
            y: cost,
            label: "Base",
          });

          dataPoints2.unshift({
            y: retailCost,
            label: "Base",
          });

          dataPoints3.unshift({
            y: wholesaleCost,
            label: "Base",
          });

          data1.push({
            type: "stackedColumn",
            legendText: item.tech,
            showInLegend: "true",
            yValueFormatString: "#,##0",
            toolTipContent: item.tech + ": {y}",
            dataPoints: dataPoints1,
          });

          data2.push({
            type: "stackedColumn",
            legendText: item.tech,
            showInLegend: "true",
            yValueFormatString: "#,##0.00",
            toolTipContent: item.tech + ": {y}",
            dataPoints: dataPoints2,
          });

          data3.push({
            type: "stackedColumn",
            legendText: item.tech,
            showInLegend: "true",
            yValueFormatString: "#,##0.00",
            toolTipContent: item.tech + ": {y}",
            dataPoints: dataPoints3,
          });
        }
      }
      if (data1.length) {
        let retailCost =
          (1000000 * baseCost) / this.props.init.totalRetailElecDemand || 0;
        let wholesaleCost =
          (1000000 * baseCost) / this.props.init.totalWholesaleElecDemand || 0;
        let pretailCost =
          (1000000 * propCost) / this.props.totalRetailElecDemand || 0;
        let pwholesaleCost =
          (1000000 * propCost) / this.props.totalWholesaleElecDemand || 0;
        data1[data1.length - 1].dataPoints[0].indexLabel =
          "Gen: " +
          Math.round(
            baseCost
          ).toLocaleString(); /* +
          " (W: " +
          wholesaleCost.toFixed(2) +
          ", R: " +
          retailCost.toFixed(2) +
          ")";*/
        data1[data1.length - 1].dataPoints[1].indexLabel =
          "Gen: " + Math.round(propCost).toLocaleString();
        /*+ " (W: " +
          pwholesaleCost.toFixed(2) +
          ", R: " +
          pretailCost.toFixed(2) +
          ")";*/
        data1[data1.length - 1].indexLabelPlacement = "auto";
        data1[data1.length - 1].indexLabelFontSize = 12;

        data2[data2.length - 1].dataPoints[0].indexLabel =
          "Gen: " + retailCost.toFixed(2);
        data2[data2.length - 1].dataPoints[1].indexLabel =
          "Gen: " + pretailCost.toFixed(2);
        data2[data2.length - 1].indexLabelPlacement = "auto";
        data2[data2.length - 1].indexLabelFontSize = 12;

        data3[data3.length - 1].dataPoints[0].indexLabel =
          "Gen: " + wholesaleCost.toFixed(2);
        data3[data3.length - 1].dataPoints[1].indexLabel =
          "Gen: " + pwholesaleCost.toFixed(2);
        data3[data3.length - 1].indexLabelPlacement = "auto";
        data3[data3.length - 1].indexLabelFontSize = 12;
      }

      if (this.props.transmission) {
        const propTrans = this.props.transmission.elec || 0;
        const baseTrans = this.props.init.transmission.elec || 0;
        const propCapacity =
          propTrans.current.capacity + propTrans.new.capacity || 0;
        const baseCapacity =
          baseTrans.current.capacity + baseTrans.new.capacity || 0;
        const propNewOutputShare =
          (totalPropOutput * propTrans.new.capacity) / propCapacity || 0;
        const propCurOutputShare =
          (totalPropOutput * propTrans.current.capacity) / propCapacity || 0;
        const baseNewOutputShare =
          (totalBaseOutput * baseTrans.new.capacity) / baseCapacity || 0;
        const baseCurOutputShare =
          (totalBaseOutput * baseTrans.current.capacity) / baseCapacity || 0;
        const newTransPropCost =
          1000 *
            propTrans.new.capacity *
            (propTrans.new.fixedCost +
              (this.props.wacc * propTrans.new.capitalCost) / 100) +
            propTrans.new.variableCost * propNewOutputShare || 0;
        const newTransBaseCost =
          1000 *
            baseTrans.new.capacity *
            (baseTrans.new.fixedCost +
              (this.props.init.wacc * baseTrans.new.capitalCost) / 100) +
            baseTrans.new.variableCost * baseNewOutputShare || 0;
        const curTransPropCost =
          1000 *
            propTrans.current.capacity *
            (propTrans.current.fixedCost +
              (this.props.wacc * propTrans.current.capitalCost) / 100) +
            propTrans.current.variableCost * propCurOutputShare || 0;
        const curTransBaseCost =
          1000 *
            baseTrans.current.capacity *
            (baseTrans.current.fixedCost +
              (this.props.init.wacc * baseTrans.current.capitalCost) / 100) +
            baseTrans.current.variableCost * baseCurOutputShare || 0;
        let cost =
          Math.round((curTransBaseCost + newTransBaseCost) / 10000) / 100;
        baseCost += cost;
        let retailCost =
          (curTransBaseCost + newTransBaseCost) /
          this.props.init.totalRetailElecDemand;
        let wholesaleCost =
          (curTransBaseCost + newTransBaseCost) /
          this.props.init.totalWholesaleElecDemand;

        let pcost =
          Math.round((curTransPropCost + newTransPropCost) / 10000) / 100;
        propCost += pcost;
        let pretailCost =
          (curTransPropCost + newTransPropCost) /
          this.props.totalRetailElecDemand;
        let pwholesaleCost =
          (curTransPropCost + newTransPropCost) /
          this.props.totalWholesaleElecDemand;

        // console.log("transmission base", cost);
        data1.push({
          type: "stackedColumn",
          legendText: "Network",
          showInLegend: "true",
          yValueFormatString: "#,##0",
          toolTipContent: "Network: {y}",
          dataPoints: [
            {
              y: cost,
              label: "Base",
            },
            {
              y: pcost,
              label: "Proposed",
            },
          ],
        });

        data2.push({
          type: "stackedColumn",
          legendText: "Network",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Network: {y}",
          dataPoints: [
            {
              y: retailCost,
              label: "Base",
            },
            {
              y: pretailCost,
              label: "Proposed",
            },
          ],
        });

        data3.push({
          type: "stackedColumn",
          legendText: "Network",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Network: {y}",
          dataPoints: [
            {
              y: wholesaleCost,
              label: "Base",
            },
            {
              y: pwholesaleCost,
              label: "Proposed",
            },
          ],
        });
      }

      if (this.props.storage) {
        for (let i = 0; i < this.props.storage.length; i++) {
          let dataPoints1 = [];
          let dataPoints2 = [];
          let dataPoints3 = [];
          const item = this.props.storage[i];
          let combCapacity = item.current.capacity + item.new.capacity || 0;
          const initCapacity =
            this.props.init.storage[i].current.capacity +
            this.props.init.storage[i].new.capacity;
          let combFlow = item.dischargekWh + item.chargekWh || 0;
          if (combCapacity + initCapacity > 0) {
            let avgVariableCost =
              (item.current.variableCost * item.current.capacity +
                item.new.variableCost * item.new.capacity) /
                combCapacity || 0;
            let variableCost = avgVariableCost * combFlow;

            let currentCapitalCost =
              10 *
                this.props.wacc *
                (item.current.capacity * item.current.capitalCostkWh +
                  item.current.power * item.current.capitalCostkW) || 0;
            let currentFixedCost =
              1000 * item.current.fixedCost * item.current.capacity || 0;
            let currentCost = currentCapitalCost + currentFixedCost;
            //console.log(item.tech + " currentCost", currentCost);

            let newCapitalCost =
              10 *
                this.props.wacc *
                (item.new.capacity * item.new.capitalCostkWh +
                  item.new.power * item.new.capitalCostkW) || 0;
            let newFixedCost =
              1000 * item.new.fixedCost * item.new.capacity || 0;
            let newCost = newCapitalCost + newFixedCost;
            let cost =
              Math.round(
                (currentCost + newCost + variableCost + item.elecCost) / 10000
              ) / 100;
            propCost += cost;
            let retailCost =
              (currentCost + newCost + variableCost + item.elecCost) /
                this.props.totalRetailElecDemand || 0;
            let wholesaleCost =
              (currentCost + newCost + variableCost + item.elecCost) /
                this.props.totalWholesaleElecDemand || 0;

            dataPoints1.unshift({
              y: cost,
              label: "Proposed",
            });

            dataPoints2.unshift({
              y: retailCost,
              label: "Proposed",
            });

            dataPoints3.unshift({
              y: wholesaleCost,
              label: "Proposed",
            });

            const init = this.props.init.storage[i];
            combCapacity = init.current.capacity + init.new.capacity || 0;
            combFlow = init.dischargekWh + init.chargekWh || 0;
            avgVariableCost =
              (init.current.variableCost * init.current.capacity +
                init.new.variableCost * init.new.capacity) /
                combCapacity || 0;
            variableCost = avgVariableCost * combFlow;

            currentCapitalCost =
              10 *
                this.props.init.wacc *
                (init.current.capacity * init.current.capitalCostkWh +
                  init.current.power * init.current.capitalCostkW) || 0;
            currentFixedCost =
              1000 * init.current.fixedCost * init.current.capacity || 0;
            currentCost = currentCapitalCost + currentFixedCost;

            newCapitalCost =
              10 *
                this.props.init.wacc *
                (init.new.capacity * init.new.capitalCostkWh +
                  init.new.power * init.new.capitalCostkW) || 0;
            newFixedCost = 1000 * init.new.fixedCost * init.new.capacity || 0;
            newCost = newCapitalCost + newFixedCost;
            cost =
              Math.round(
                (currentCost + newCost + variableCost + init.elecCost) / 10000
              ) / 100;
            baseCost += cost;
            retailCost =
              (currentCost + newCost + variableCost + init.elecCost) /
                this.props.init.totalRetailElecDemand || 0;
            wholesaleCost =
              (currentCost + newCost + variableCost + init.elecCost) /
                this.props.init.totalWholesaleElecDemand || 0;

            // console.log(item.id + " base", cost);
            dataPoints1.unshift({
              y: cost,
              label: "Base",
            });

            dataPoints2.unshift({
              y: retailCost,
              label: "Base",
            });

            dataPoints3.unshift({
              y: wholesaleCost,
              label: "Base",
            });

            data1.push({
              type: "stackedColumn",
              legendText: item.tech,
              showInLegend: "true",
              yValueFormatString: "#,##0",
              toolTipContent: item.tech + ": {y}",
              dataPoints: dataPoints1,
            });

            data2.push({
              type: "stackedColumn",
              legendText: item.tech,
              showInLegend: "true",
              yValueFormatString: "#,##0.00",
              toolTipContent: item.tech + ": {y}",
              dataPoints: dataPoints2,
            });

            data3.push({
              type: "stackedColumn",
              legendText: item.tech,
              showInLegend: "true",
              yValueFormatString: "#,##0.00",
              toolTipContent: item.tech + ": {y}",
              dataPoints: dataPoints3,
            });
          }
        }
      }

      if (this.props.interconnection) {
        let dataPoints1 = [
          { y: 0, label: "Base" },
          { y: 0, label: "Proposed" },
        ];
        let dataPoints2 = [
          { y: 0, label: "Base" },
          { y: 0, label: "Proposed" },
        ];
        let dataPoints3 = [
          { y: 0, label: "Base" },
          { y: 0, label: "Proposed" },
        ];
        for (let i = 0; i < this.props.interconnection.length; i++) {
          const icPropCost = this.props.interconnection[i].elecCost || 0;
          const icBaseCost = this.props.init.interconnection[i].elecCost || 0;
          dataPoints1[0].y += icBaseCost;
          dataPoints1[1].y += icPropCost;
        }
        const icPropRetailPrice =
          Math.round(
            (100 * dataPoints1[1].y) / this.props.totalRetailElecDemand
          ) / 100 || 0;
        const icBaseRetailPrice =
          Math.round(
            (100 * dataPoints1[0].y) / this.props.init.totalRetailElecDemand
          ) / 100 || 0;
        dataPoints2[0].y = icBaseRetailPrice;
        dataPoints2[1].y = icPropRetailPrice;
        const icPropWholesalePrice =
          dataPoints1[1].y / this.props.totalWholesaleElecDemand || 0;
        const icBaseWholesalePrice =
          dataPoints1[0].y / this.props.init.totalWholesaleElecDemand || 0;
        dataPoints3[0].y = icBaseWholesalePrice;
        dataPoints3[1].y = icPropWholesalePrice;
        dataPoints1[0].y = Math.round(dataPoints1[0].y / 10000) / 100;
        dataPoints1[1].y = Math.round(dataPoints1[1].y / 10000) / 100;
        baseCost += dataPoints1[0].y;
        propCost += dataPoints1[1].y;

        data1.push({
          type: "stackedColumn",
          legendText: "Interconnectors",
          showInLegend: "true",
          yValueFormatString: "#,##0",
          toolTipContent: "Interconnectors: {y}",
          dataPoints: dataPoints1,
        });

        data2.push({
          type: "stackedColumn",
          legendText: "Interconnectors",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Interconnectors: {y}",
          dataPoints: dataPoints2,
        });

        data3.push({
          type: "stackedColumn",
          legendText: "Interconnectors",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Interconnectors: {y}",
          dataPoints: dataPoints3,
        });
      }

      if (this.props.supply) {
        const propSupply = this.props.supply.elec;
        const baseSupply = this.props.init.supply.elec;
        const pSupplyCost =
          (propSupply.capitalCost * this.props.wacc) / 100 +
            propSupply.fixedCost +
            (propSupply.variableCost * this.props.totalRetailElecDemand) /
              1000000 || 0;
        const bSupplyCost =
          (baseSupply.capitalCost * this.props.init.wacc) / 100 +
            baseSupply.fixedCost +
            (baseSupply.variableCost * this.props.init.totalRetailElecDemand) /
              1000000 || 0;
        const pRetailCost =
          (1000000 * pSupplyCost) / this.props.totalRetailElecDemand || 0;
        const bRetailCost =
          (1000000 * bSupplyCost) / this.props.init.totalRetailElecDemand || 0;
        const pWholesaleCost =
          (1000000 * pSupplyCost) / this.props.totalWholesaleElecDemand || 0;
        const bWholesaleCost =
          (1000000 * bSupplyCost) / this.props.init.totalWholesaleElecDemand ||
          0;
        baseCost += bSupplyCost;
        propCost += pSupplyCost;
        //console.log("pSupplyCost", pSupplyCost);

        //console.log("supply base", bSupplyCost);
        data1.push({
          type: "stackedColumn",
          legendText: "Supply",
          showInLegend: "true",
          yValueFormatString: "#,##0",
          toolTipContent: "Supply: {y}",
          dataPoints: [
            {
              y: bSupplyCost,
              label: "Base",
            },
            {
              y: pSupplyCost,
              label: "Proposed",
            },
          ],
        });

        data2.push({
          type: "stackedColumn",
          legendText: "Supply",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Supply: {y}",
          dataPoints: [
            {
              y: bRetailCost,
              label: "Base",
            },
            {
              y: pRetailCost,
              label: "Proposed",
            },
          ],
        });

        data3.push({
          type: "stackedColumn",
          legendText: "Supply",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Supply: {y}",
          dataPoints: [
            {
              y: bWholesaleCost,
              label: "Base",
            },
            {
              y: pWholesaleCost,
              label: "Proposed",
            },
          ],
        });
      }

      if (
        // (typeof this.props.demandShedCost !== "undefined" &&
        this.props.demandShedCost > 0 ||
        // (typeof this.props.init.demandShedCost !== "defined" &&
        this.props.init.demandShedCost > 0
      ) {
        const propDemandShedCost = this.props.demandShedCost / 1000000;
        const baseDemandShedCost = this.props.init.demandShedCost / 1000000;
        const propDemandShedRetailCost =
          this.props.demandShedCost / this.props.totalRetailElecDemand || 0;
        const baseDemandShedRetailCost =
          this.props.init.demandShedCost /
            this.props.init.totalRetailElecDemand || 0;
        const propDemandShedWholesaleCost =
          this.props.demandShedCost / this.props.totalWholesaleElecDemand || 0;
        const baseDemandShedWholesaleCost =
          this.props.init.demandShedCost /
            this.props.init.totalWholesaleElecDemand || 0;
        baseCost += baseDemandShedCost;
        propCost += propDemandShedCost;

        data1.push({
          type: "stackedColumn",
          legendText: "Demand Shedding",
          showInLegend: "true",
          yValueFormatString: "#,##0",
          toolTipContent: "Demand Shedding: {y}",
          dataPoints: [
            {
              y: baseDemandShedCost,
              label: "Base",
            },
            {
              y: propDemandShedCost,
              label: "Proposed",
            },
          ],
        });

        data2.push({
          type: "stackedColumn",
          legendText: "Demand Shedding",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Demand Shedding: {y}",
          dataPoints: [
            {
              y: baseDemandShedRetailCost,
              label: "Base",
            },
            {
              y: propDemandShedRetailCost,
              label: "Proposed",
            },
          ],
        });

        data3.push({
          type: "stackedColumn",
          legendText: "Demand Shedding",
          showInLegend: "true",
          yValueFormatString: "#,##0.00",
          toolTipContent: "Demand Shedding: {y}",
          dataPoints: [
            {
              y: baseDemandShedWholesaleCost,
              label: "Base",
            },
            {
              y: propDemandShedWholesaleCost,
              label: "Proposed",
            },
          ],
        });
      } /* else {
        console.log("demandShedCost: ", this.props.demandShedCost);
      } */

      let baseRetailCost = 0;
      let propRetailCost = 0;
      let baseWholesaleCost = 0;
      let propWholesaleCost = 0;
      if (data1.length) {
        baseRetailCost =
          (1000000 * baseCost) / this.props.init.totalRetailElecDemand || 0;
        propRetailCost =
          (1000000 * propCost) / this.props.totalRetailElecDemand || 0;
        baseWholesaleCost =
          (1000000 * baseCost) / this.props.init.totalWholesaleElecDemand || 0;
        propWholesaleCost =
          (1000000 * propCost) / this.props.totalWholesaleElecDemand || 0;
        data1[data1.length - 1].dataPoints[0].indexLabel =
          "Sys: " +
          Math.round(
            baseCost
          ).toLocaleString(); /* +
          "(W: " +
          baseWholesaleCost.toFixed(2) +
          ", R: " +
          baseRetailCost.toFixed(2) +
          ")"; */
        data1[data1.length - 1].dataPoints[1].indexLabel =
          "Sys: " +
          Math.round(
            propCost
          ).toLocaleString(); /* +
          " (W: " +
          propWholesaleCost.toFixed(2) +
          ", R: " +
          propRetailCost.toFixed(2) +
          ")"; */
        data1[data1.length - 1].indexLabelPlacement = "auto";
        data1[data1.length - 1].indexLabelFontSize = 12;

        data2[data2.length - 1].dataPoints[0].indexLabel =
          "Sys: " + baseRetailCost.toFixed(2);
        data2[data2.length - 1].dataPoints[1].indexLabel =
          "Sys: " + propRetailCost.toFixed(2);
        data2[data2.length - 1].indexLabelPlacement = "auto";
        data2[data2.length - 1].indexLabelFontSize = 12;

        data3[data3.length - 1].dataPoints[0].indexLabel =
          "Sys: " + baseWholesaleCost.toFixed(2);
        data3[data3.length - 1].dataPoints[1].indexLabel =
          "Sys: " + propWholesaleCost.toFixed(2);
        data3[data3.length - 1].indexLabelPlacement = "auto";
        data3[data3.length - 1].indexLabelFontSize = 12;
      }
      //const baseSupplyCost = baseCost / this.props.init.totalRetailElecDemand;
      //console.log("data1", data1);
      const elecTotals = { Base: baseCost, Proposed: propCost };

      // Carbon cost
      let propCarbon = 0;
      for (const fuelType in this.props.aggData.elec.fuelemissions) {
        propCarbon += this.props.aggData.elec.fuelemissions[fuelType];
      }
      let baseCarbon = 0;
      for (const fuelType in this.props.initAggData.elec.fuelemissions) {
        baseCarbon += this.props.initAggData.elec.fuelemissions[fuelType];
      }
      let propCarbonCost = propCarbon * this.props.carbonCost;
      let baseCarbonCost = baseCarbon * this.props.init.carbonCost;
      const propRetailCC = propCarbonCost / this.props.totalRetailElecDemand;
      const baseRetailCC =
        baseCarbonCost / this.props.init.totalRetailElecDemand;
      const propWholesaleCC =
        propCarbonCost / this.props.totalWholesaleElecDemand;
      const baseWholesaleCC =
        baseCarbonCost / this.props.init.totalWholesaleElecDemand;
      propCarbonCost /= 1000000;
      baseCarbonCost /= 1000000;
      let carbonTotals = { Base: baseCarbonCost, Proposed: propCarbonCost };
      baseCost += baseCarbonCost;
      propCost += propCarbonCost;
      baseRetailCost += baseRetailCC;
      propRetailCost += propRetailCC;
      baseWholesaleCost += baseWholesaleCC;
      propWholesaleCost += propWholesaleCC;
      const annualMaximum =
        Math.ceil((1.08 * Math.max(baseCost, propCost)) / 10000) * 10000;
      const retailMaximum =
        Math.ceil((1.1 * Math.max(baseRetailCost, propRetailCost)) / 10) * 10;
      const wholesaleMaximum =
        Math.ceil((1.1 * Math.max(baseWholesaleCost, propWholesaleCost)) / 10) *
        10;
      data1.push({
        type: "stackedColumn",
        legendText: "Carbon",
        showInLegend: "true",
        yValueFormatString: "#,##0",
        toolTipContent: "Carbon: {y}",
        dataPoints: [
          {
            y: baseCarbonCost,
            label: "Base",
          },
          {
            y: propCarbonCost,
            label: "Proposed",
          },
        ],
      });

      data2.push({
        type: "stackedColumn",
        legendText: "Carbon",
        showInLegend: "true",
        yValueFormatString: "#,##0.00",
        toolTipContent: "Carbon: {y}",
        dataPoints: [
          {
            y: baseRetailCC,
            label: "Base",
          },
          {
            y: propRetailCC,
            label: "Proposed",
          },
        ],
      });

      data3.push({
        type: "stackedColumn",
        legendText: "Carbon",
        showInLegend: "true",
        yValueFormatString: "#,##0.00",
        toolTipContent: "Carbon: {y}",
        dataPoints: [
          {
            y: baseWholesaleCC,
            label: "Base",
          },
          {
            y: propWholesaleCC,
            label: "Proposed",
          },
        ],
      });

      const options1 = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Electricity system annual cost",
          fontSize: 20,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "million £",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
          maximum: annualMaximum,
        },
        /*axisY2: [
          {
            title: "£/MWh generated",
          },
          {
            title: "£/MWh supplied",
          },
        ],*/
        data: data1,
      };
      options1.data[options1.data.length - 1].indexLabel = "#total";
      options1.data[options1.data.length - 1].indexLabelPlacement = "outside";

      const options2 = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Electricity system cost /MWh (supplied)",
          fontSize: 20,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "£/MWh",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
          maximum: retailMaximum,
        },
        data: data2,
      };
      options2.data[options2.data.length - 1].indexLabel = "#total";
      options2.data[options2.data.length - 1].indexLabelPlacement = "outside";

      const options3 = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Electricity system cost /MWh (generated)",
          fontSize: 20,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "£/MWh",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
          maximum: wholesaleMaximum,
        },
        data: data3,
      };
      options3.data[options3.data.length - 1].indexLabel = "#total";
      options3.data[options3.data.length - 1].indexLabelPlacement = "outside";

      const data4 = [];
      let heatTotals = { Base: 0, Proposed: 0, elecBase: 0, elecProposed: 0 };
      for (let i = 0; i < this.props.heatsupply.length; i++) {
        //console.log(this.props.heatsupply[i].id, this.props.heatsupply[i]);
        let dataPoints4 = [];
        let label = "Base";
        for (let techObj of [
          this.props.init.heatsupply[i],
          this.props.heatsupply[i],
        ]) {
          const variableCost = techObj.variableCost * techObj.heat;
          const fixedCost = (techObj.fixedCost * techObj.capacity) / 1000;
          const capitalCost =
            (techObj.capitalCost * techObj.capacity * this.props.wacc) / 100000;
          const fuelCost = techObj.fuelCost * techObj.fuel || 0;
          /* if (typeof techObj.fuel === "undefined") {
            fuelCost =
              (label === "Base" ? baseRetailCost : propRetailCost) *
              techObj.elec;
            heatTotals[label] -= fuelCost;
          } */
          const elecCost = techObj.elecCost / 1000000 || 0;
          const totalAnnualCost = Math.round(
            variableCost + fuelCost + elecCost + fixedCost + capitalCost
          );
          heatTotals[label] += totalAnnualCost;
          heatTotals["elec" + label] += elecCost;
          dataPoints4.push({ y: totalAnnualCost, label: label });
          label = "Proposed";
        }
        data4.push({
          type: "stackedColumn",
          legendText: this.props.heatsupply[i].tech,
          showInLegend: "true",
          yValueFormatString: "#,##0",
          toolTipContent: this.props.heatsupply[i].tech + ": {y}",
          dataPoints: dataPoints4,
        });
      }
      data4[data4.length - 1].dataPoints[0].indexLabel =
        "Sys: " + Math.round(heatTotals.Base).toLocaleString();
      data4[data4.length - 1].dataPoints[1].indexLabel =
        "Sys: " + Math.round(heatTotals.Proposed).toLocaleString();
      data4[data4.length - 1].indexLabelPlacement = "auto";
      data4[data4.length - 1].indexLabelFontSize = 12;
      let propHeatCarbon = 0;
      for (const fuelType in this.props.aggData.heat) {
        propHeatCarbon += this.props.aggData.heat[fuelType];
      }
      let baseHeatCarbon = 0;
      for (const fuelType in this.props.initAggData.heat) {
        baseHeatCarbon += this.props.initAggData.heat[fuelType];
      }
      let propHeatCarbonCost = propHeatCarbon * this.props.carbonCost;
      let baseHeatCarbonCost = baseHeatCarbon * this.props.init.carbonCost;
      carbonTotals.Base += baseHeatCarbonCost;
      carbonTotals.Proposed += propHeatCarbonCost;
      const heatMaximum =
        Math.ceil(
          (1.08 *
            Math.max(
              heatTotals.Base + baseHeatCarbonCost,
              heatTotals.Proposed + propHeatCarbonCost
            )) /
            10000
        ) * 10000;
      heatTotals.Base -= heatTotals.elecBase;
      heatTotals.Proposed -= heatTotals.elecProposed;
      data4.push({
        type: "stackedColumn",
        legendText: "Carbon",
        showInLegend: "true",
        yValueFormatString: "#,##0",
        toolTipContent: "Carbon: {y}",
        dataPoints: [
          {
            y: baseHeatCarbonCost,
            label: "Base",
          },
          {
            y: propHeatCarbonCost,
            label: "Proposed",
          },
        ],
      });
      // console.log("data4", data4);
      const options4 = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Annual cost of heat",
          fontSize: 20,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "million £",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
          maximum: heatMaximum,
        },
        data: data4,
      };
      options4.data[options4.data.length - 1].indexLabel = "#total";
      options4.data[options4.data.length - 1].indexLabelPlacement = "outside";

      const data5 = [];
      let trnsptTotals = { Base: 0, Proposed: 0, elecBase: 0, elecProposed: 0 };
      for (let i = 0; i < this.props.trnsptdemand.length; i++) {
        let dataPoints5 = [];
        let label = "Base";
        for (let techObj of [
          this.props.init.trnsptdemand[i],
          this.props.trnsptdemand[i],
        ]) {
          const fuel = techObj.total - techObj.elec;
          const fuelWork = (fuel * techObj.fuelefficiency) / 100;
          const elecWork = (techObj.elec * techObj.elecefficiency) / 100;
          const work = fuelWork + elecWork;
          const fuelSpend = fuel * techObj.fuelCost;
          const elecSpend = techObj.elecCost / 1000000 || 0;
          // techObj.elec * (label === "Base" ? baseRetailCost : propRetailCost);
          let capitalCost =
            (techObj.networkCapitalCost * this.props.wacc) / 100;
          const networkMaintCost = techObj.networkMaintCost * work;
          let operatorCost = 0;
          if (techObj.operatorCost) {
            operatorCost = techObj.operatorCost * work;
          } else {
            const iceVehiclesM = fuelWork / techObj.avgAnnualOutput || 0;
            const elecVehiclesM = elecWork / techObj.avgAnnualOutput || 0;
            const iceCapitalM = iceVehiclesM * techObj.iceVehicleCapitalCost;
            const elecCapitalM = elecVehiclesM * techObj.elecVehicleCapitalCost;
            if (typeof iceCapitalM !== "undefined") {
              capitalCost += (iceCapitalM * this.props.wacc) / 100;
            }
            if (typeof elecCapitalM !== "undefined") {
              capitalCost += (elecCapitalM * this.props.wacc) / 100;
            }
            operatorCost =
              (iceVehiclesM + elecVehiclesM) * (techObj.vehicleMaintCost || 0);
          }
          const totalAnnualCost = Math.round(
            networkMaintCost +
              fuelSpend +
              elecSpend +
              operatorCost +
              capitalCost
          );
          console.log(techObj.id + " " + label, totalAnnualCost);
          trnsptTotals[label] += totalAnnualCost;
          trnsptTotals["elec" + label] += elecSpend;
          dataPoints5.push({ y: totalAnnualCost, label: label });
          label = "Proposed";
        }
        data5.push({
          type: "stackedColumn",
          legendText: this.props.trnsptdemand[i].tech,
          showInLegend: "true",
          yValueFormatString: "#,##0",
          toolTipContent: this.props.trnsptdemand[i].tech + ": {y}",
          dataPoints: dataPoints5,
        });
      }
      data5[data5.length - 1].dataPoints[0].indexLabel =
        "Sys: " + Math.round(trnsptTotals.Base).toLocaleString();
      data5[data5.length - 1].dataPoints[1].indexLabel =
        "Sys: " + Math.round(trnsptTotals.Proposed).toLocaleString();
      data5[data5.length - 1].indexLabelPlacement = "auto";
      data5[data5.length - 1].indexLabelFontSize = 12;
      let propTrnsptCarbon = 0;
      for (const fuelType in this.props.aggData.transport) {
        propTrnsptCarbon += this.props.aggData.transport[fuelType];
      }
      let baseTrnsptCarbon = 0;
      for (const fuelType in this.props.initAggData.transport) {
        baseTrnsptCarbon += this.props.initAggData.transport[fuelType];
      }
      let propTrnsptCarbonCost = propTrnsptCarbon * this.props.carbonCost;
      let baseTrnsptCarbonCost = baseTrnsptCarbon * this.props.init.carbonCost;
      carbonTotals.Base += baseTrnsptCarbonCost;
      carbonTotals.Proposed += propTrnsptCarbonCost;
      const trnsptMaximum =
        Math.ceil(
          (1.08 *
            Math.max(
              trnsptTotals.Base + baseTrnsptCarbonCost,
              trnsptTotals.Proposed + propTrnsptCarbonCost
            )) /
            10000
        ) * 10000;
      trnsptTotals.Base -= trnsptTotals.elecBase;
      trnsptTotals.Proposed -= trnsptTotals.elecProposed;
      data5.push({
        type: "stackedColumn",
        legendText: "Carbon",
        showInLegend: "true",
        yValueFormatString: "#,##0",
        toolTipContent: "Carbon: {y}",
        dataPoints: [
          {
            y: baseTrnsptCarbonCost,
            label: "Base",
          },
          {
            y: propTrnsptCarbonCost,
            label: "Proposed",
          },
        ],
      });
      // console.log("data5", data5);
      const options5 = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Annual cost of transport",
          fontSize: 20,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "million £",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
          maximum: trnsptMaximum,
        },
        data: data5,
      };
      options5.data[options5.data.length - 1].indexLabel = "#total";
      options5.data[options5.data.length - 1].indexLabelPlacement = "outside";

      const combinedMaximum =
        Math.ceil(
          (1.08 *
            Math.max(
              trnsptTotals.Base +
                heatTotals.Base +
                elecTotals.Base +
                carbonTotals.Base,
              trnsptTotals.Proposed +
                heatTotals.Proposed +
                elecTotals.Proposed +
                carbonTotals.Proposed
            )) /
            10000
        ) * 10000;
      const options6 = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Annual cost of energy",
          fontSize: 20,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "million £",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString: "#,##0",
          maximum: combinedMaximum,
        },
        data: [
          {
            type: "stackedColumn",
            legendText: "Transport*",
            showInLegend: "true",
            yValueFormatString: "#,##0",
            toolTipContent: "Transport: {y}",
            dataPoints: [
              {
                y: trnsptTotals.Base,
                label: "Base",
              },
              {
                y: trnsptTotals.Proposed,
                label: "Proposed",
              },
            ],
          },
          {
            type: "stackedColumn",
            legendText: "Heat*",
            showInLegend: "true",
            yValueFormatString: "#,##0",
            toolTipContent: "Heat: {y}",
            dataPoints: [
              {
                y: heatTotals.Base,
                label: "Base",
              },
              {
                y: heatTotals.Proposed,
                label: "Proposed",
              },
            ],
          },
          {
            type: "stackedColumn",
            legendText: "Electricity",
            showInLegend: "true",
            yValueFormatString: "#,##0",
            toolTipContent: "Electricity: {y}",
            dataPoints: [
              {
                y: elecTotals.Base,
                label: "Base",
              },
              {
                y: elecTotals.Proposed,
                label: "Proposed",
              },
            ],
          },
          {
            type: "stackedColumn",
            legendText: "Carbon  (* = exc. electricity)",
            showInLegend: "true",
            yValueFormatString: "#,##0",
            toolTipContent: "Carbon: {y}",
            dataPoints: [
              {
                y: carbonTotals.Base,
                label: "Base",
              },
              {
                y: carbonTotals.Proposed,
                label: "Proposed",
              },
            ],
          },
        ],
      };
      options6.data[options6.data.length - 1].indexLabel = "#total";
      options6.data[options6.data.length - 1].indexLabelPlacement = "outside";
      options6.data[options6.data.length - 2].dataPoints[0].indexLabel =
        "Sys: " +
        Math.round(
          elecTotals.Base + heatTotals.Base + trnsptTotals.Base
        ).toLocaleString();
      options6.data[options6.data.length - 2].dataPoints[1].indexLabel =
        "Sys: " +
        Math.round(
          elecTotals.Proposed + heatTotals.Proposed + trnsptTotals.Proposed
        ).toLocaleString();
      options6.data[options6.data.length - 2].indexLabelPlacement = "auto";
      options6.data[options6.data.length - 2].indexLabelFontSize = 12;

      return (
        <>
          <MDBRow>
            <MDBCol size="12">
              X
              <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                <CanvasJSChart options={options6} />
              </div>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="12">
              Y
              <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                <CanvasJSChart options={options5} />
              </div>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="12">
              A
              <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                <CanvasJSChart options={options4} />
              </div>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="12">
              B
              <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                <CanvasJSChart options={options1} />
              </div>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="12">
              C
              <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                <CanvasJSChart options={options2} />
              </div>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="12">
              D
              <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                <CanvasJSChart options={options3} />
              </div>
            </MDBCol>
          </MDBRow>
        </>
      );
    } else {
      return "Pending...";
    }
  }
}

export default EDFSTotalCost;
