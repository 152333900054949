import React from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  // MDBModalFooter,
} from "mdbreact";

class EDBMRGenUnitInstructions extends React.Component {
  state = {
    showInstructions: false,
  };

  toggle = () => {
    this.setState({
      showInstructions: !this.state.showInstructions,
    });
  };

  render() {
    return (
      <MDBContainer>
        <MDBBtn onClick={this.toggle} className="mx-auto">
          Instructions
        </MDBBtn>
        <MDBModal
          isOpen={this.state.showInstructions}
          size="lg"
          toggle={this.toggle}
        >
          <MDBModalHeader toggle={this.toggle}>
            How to use this page
          </MDBModalHeader>
          <MDBModalBody>
            <h4 className="h4-responsive">Selecting/Unselecting</h4>
            <p>
              The available generating units are listed in the right-hand column
              ("Projects"), grouped by technology. They are (broadly) the
              (mostly larger) generating units connected to the HV transmission
              network. The list is much longer than the screen height. The
              column is slightly shorter than the screen height, and you can
              scroll the list up and down within it.
            </p>
            <p>
              You can select units for visualisation by dragging them into one
              of the groups in the left-hand box ("Select"). If you drag
              multiple units into a Group, their combined outputs will be
              displayed.
            </p>
            <p>
              You can also select units for visualisation by selecting the Group
              you want to put the unit in with the radio button in the top-left
              corner of the Group, and then clicking/tapping the curved arrow
              for that unit.
            </p>
            <p>
              You can likewise select all the (unselected) projects of a
              technology by selecting the Group you want to put them in, and
              clicking/tapping the curved arrow next to the technology heading.
            </p>
            <p>
              You can unselect units either (a) by dragging them back to their
              technology in the right-hand column (if you try to drag them to
              the wrong technology, the unselection will fail), or (b) by
              clicking/tapping the X at the right side of the unit's item in the
              Group.
            </p>
            <p>
              You can move units from one group to another either (a) by
              dragging them, or (b) by selecting the Group you want to move them
              to, and then clicking/tapping the curved arrow for that unit.
            </p>
            <h4 className="h4-responsive">Managing Groups</h4>
            <p>
              By default, 4 Groups are provided. If you want more, you can click
              the big plus sign after the Groups. If you create enough Groups
              that they stack longer than the height of the screen, the "Select"
              box will remain slightly shorter than the height of the screen and
              you will be able to scroll the Groups wihin it. The internal
              vertical scrolling of the "Projects" and "Select" boxes is
              designed to make it as simple as possible to drag any project to
              any Group.
            </p>
            <p>
              If you want to reduce the number of Groups, you can click the X in
              the top-right corner of the Group. Any Groups without units will
              be ignored.
            </p>
            <p>
              The Groups are automatically assigned default colours from a
              palette. If you want to change the colours, click on the coloured
              button with a paint-brush icon, next to the Group name. This will
              open up a Colour Swatch from which you can selected a different
              colour. The Swatch does not automatically close when you select a
              colour. Click/tap anywhere outside the swatch to close it. The
              colour is used for that Group's line in the line drawing.
            </p>
            <p>
              You can edit each Group's title simply by clicking/tapping on it,
              and editing it. This "inline editing" facility seems to respond
              slightly slowly, so you may need to allow a second for the title
              to respond to your typing. The Group titles are used in the
              legends of the line charts, and in the axis titles of the scatter
              charts.
            </p>
            <h4 className="h4-responsive">Time period</h4>
            <p>
              By default, data will be selected from the first day of the month
              before the current month (in the current year for half-hourly data
              and the previous year for daily data), until the most recent date
              for which data are available (typically lagged around 6 days). You
              can change the start of the period by clicking/tapping on the date
              at the top-left of the Select box and selecting a new start date
              with the Datepicker that pops up. You can change the length of the
              period by editing the number of days in the top-central field in
              the Select box.
            </p>
            <p>
              To limit the impact on the server's memory of a very large
              dataset, the number of days is limited to 366 days for half-hourly
              data, and to 3,000 days for daily data. The server could probably
              cope comfortably with more than 3,000 days of daily data, but the
              data does not go back any further. For half-hourly data, there is
              a trade-off between the number of Groups and the period. If you
              are visualising a lot of Groups, it will be best to limit yourself
              to around 100 days. If you are visualising only a few Groups, you
              can get away with longer periods, but there may be a bit of a
              delay.
            </p>
            <p>
              You can step backwards and forwards through time with the
              navigation buttons in the top-right of the Select box. The inner
              (single) left and right arrows step backward or forward by 1 day.
              The outer (double) left and right arrows step forward by the
              number of days selected in the days field.
            </p>
            <h4 className="h4-responsive">Visualising the selected data</h4>
            <p>
              To visualise the data for the selected units and period, click the
              Update button at the bottom right of the Select box. Every time
              you change something (e.g. date or selected units), you will need
              to click the Update button to update the visualisations for the
              new selections. The Update button is disabled unless there is at
              least one unit in at least one Group.
            </p>
            <p>
              In the bottom row of the Select box, you can click/tap Sum,
              Average or Load Factor to choose how the data for each Group
              should be aggregated. The "i" button next to them explains more.
            </p>
            <p>
              When you click the Update button, the two boxes below are updated
              with the selected data.
            </p>
            <p>
              You can shrink/expand the Select and Project buttons, to make it
              easier to focus on the visualisations, by clicking the carets next
              to "Select" and "Projects".
            </p>
            <h4 className="h4-responsive">Charts</h4>
            <p>
              The first box charts the data. By default, it shows a time series
              line chart with a line for each Group. You can view the data for
              each period by hovering over the chart at the relevant point
              horizontally for the period you are interested in.
            </p>
            <p>
              You can switch between Line and Scatter chart visualisations of
              the data by clicking/tapping the relevant button at the bottom of
              the box. Scatter is disabled unless you have selected at least two
              Groups, because at least two datasets are required for a Scatter
              chart. You can switch between these chart types simply by
              clicking/tapping the button, without having to Update the data
              again, as they are alternative visualisations of the same data.
            </p>
            <p>
              As scatter charts are confusing with more than two datasets, a
              scatter chart is created for each pair of Groups that can be
              created from the number of Groups selected (e.g. 1 for two Groups,
              3 for three Groups, 6 for four Groups, 10 for five Groups, etc.)
              Avoid using the Scatter visualisation if you have selected a large
              number of Groups, as a large number of Scatter charts will need to
              be created, and (depending on the power of your system) it may
              take a long time to process, or even freeze/crash your system (as
              most of the processing to generate the charts is done on your
              system).
            </p>
            <p>
              Where there are multiple scatter charts, they are shown by default
              as large thumbprints, to facilitate a comparison between the
              charts. To view one of the charts at the full width of your
              screen, click the expand icon at the bottom right of each chart.
              To shrink it back to a thumbnail, click the contract icon at the
              bottom right of the expanded chart.
            </p>
            <p>
              At the bottom left of each Scatter chart is some basic statistical
              information.
            </p>
            <ul>
              <li>
                R<sup>2</sup> is the coefficient of determination - a measure of
                the degree to which the variance of the dependent variable
                (Y-axis) is determined by the independent variable (X-axis), on
                the assumption (in this case) of a linear relationship. It
                roughly indicates the degree of correlation between the data
                points and the best-fit linear regression for the two datasets
                (shown on the chart).
              </li>
              <li>
                N is the number of data points charted. If there is some missing
                data, it will be in the form X/Y where X is the number of data
                points charted and Y is the number that should have been
                available.
              </li>
              <li>
                The final line at bottom-left is the formula for the best-fit
                linear regression for the two datasets (the line on the Scatter
                chart).
              </li>
            </ul>
            <p>
              The charts are in SVG format. Most browsers do not (out-the-box)
              support saving SVG images on a page by right-clicking on them, as
              you can do for many other types of embedded image. However, most
              browsers have extensions/plugins that you can install to allow you
              to grab SVGs from a page.
            </p>
            <h4 className="h4-responsive">Datatable</h4>
            <p>
              The last box contains a datatable for the selected data. You can
              navigate through the pages of data with the buttons at the bottom
              right of this box. You can filter the data with the Search field
              at the top-right, although this is of limited use in this context
              as the table is largely numeric. You can change the number of
              entries per page with the dropdown list at the top-left of this
              box.
            </p>
            <p>
              If you want to download the data, you can use the XLSX (Excel) or
              CSV buttons to download the data in those formats. The XLSX
              contains a second worksheet for a line chart of the data, as well
              as the data itself on the first worksheet.
            </p>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default EDBMRGenUnitInstructions;
