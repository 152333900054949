import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { standardColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("standardColorSet", standardColorset);

class EDFSGenCostMarginal extends Component {
  componentDidMount() {
    //console.log("colorset", this.chart._selectedColorSet);
    console.log("this.chart", this.chart);
    this.colorset = this.chart._selectedColorSet;
  }

  /*colorset = [
    "#6D78AD",
    "#51CDA0",
    "#DF7970",
    "#4C9CA0",
    "#AE7D99",
    "#C9D45C",
    "#5592AD",
    "#DF874D",
    "#52BCA8",
    "#8E7AA3",
    "#E3CB64",
    "#C77B85",
    "#C39762",
    "#8DD17E",
    "#B57952",
    "#FCC26C",
  ];*/

  render() {
    console.log("Rendering EDFSGenCostMarginal");
    //console.log("props", this.props.generation);

    const options = {
      theme: "light2",
      colorSet: "standardColorSet",
      exportEnabled: true,
      zoomEnabled: true,
      zoomType: "x",
      title: {
        text: "Marginal generation cost",
      },
      axisX: {
        valueFormatString: "#,##0",
        title: "Tranche of 1,000 MW capacity",
      },
      axisY: {
        includeZero: true,
        title: "Average £/MWh",
      },
      axisY2: {
        includeZero: true,
        title: "Average £/MWh",
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: function(e) {
          //console.log("legend click: " + e.dataPointIndex);
          console.log(e);
          if (
            typeof e.dataSeries.visible === "undefined" ||
            e.dataSeries.visible
          ) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }

          e.chart.render();
        },
      },
    };

    let dataFields = [];
    //let k = 0;
    let j = 0;
    for (let tech of this.props.generation) {
      if (
        (this.props.marginalGenTechData[tech.id] &&
          this.props.marginalCapData[tech.id]) ||
        (this.props.initMarginalGenTechData[tech.id] &&
          this.props.initMarginalCapData[tech.id])
      ) {
        const fixedCurrentCostPerMWpa =
          10 * this.props.wacc * tech.current.capitalCost +
          1000 * tech.current.fixedCost;
        const fixedNewCostPerMWpa =
          10 * this.props.wacc * tech.new.capitalCost +
          1000 * tech.new.fixedCost;
        let dataPoints = [];
        let maxVal = 0;
        //console.log("tech fixed current", fixedCurrentCostPerMWpa);
        //console.log("tech fixed new", fixedNewCostPerMWpa);
        if (
          this.props.marginalGenTechData[tech.id] &&
          this.props.marginalCapData[tech.id]
        ) {
          const newCurThreshold = Math.round(tech.new.capacity / 1000);
          for (
            let i = 0;
            i < this.props.marginalGenTechData[tech.id].length;
            i++
          ) {
            if (this.props.marginalGenTechData[tech.id][i]) {
              let price = 0;
              if (i < newCurThreshold) {
                // NEW
                const fixedPrice =
                  Math.round(
                    (100 *
                      fixedNewCostPerMWpa *
                      this.props.marginalCapData[tech.id][i]) /
                      this.props.marginalGenTechData[tech.id][i]
                  ) / 100;
                price = fixedPrice + tech.new.variableCost;
              } else {
                // CURRENT
                const fixedPrice =
                  Math.round(
                    (100 *
                      fixedCurrentCostPerMWpa *
                      this.props.marginalCapData[tech.id][i]) /
                      this.props.marginalGenTechData[tech.id][i]
                  ) / 100;
                price = fixedPrice + tech.current.variableCost;
              }
              if (price > maxVal) {
                maxVal = price;
              }
              dataPoints.push({
                x: i + 1,
                y: price,
                init: "",
              });
            }
          }
        }
        dataFields.push({
          type: "line",
          name: tech.tech,
          color: standardColorset[j],
          showInLegend: true,
          toolTipContent:
            "<span style='\"'color: " +
            standardColorset[j] +
            ";'\"'>{name}:</span> {y} &nbsp;<small>[{init}]</small>",
          xValueFormatString: "Tranche #,##0",
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints,
        });
        if (
          this.props.initMarginalGenTechData[tech.id] &&
          this.props.initMarginalCapData[tech.id]
        ) {
          const newCurThreshold = 0; // Math.round(tech.new.initcapacity / 1000);
          dataPoints = [];
          for (
            let i = 0;
            i < this.props.initMarginalGenTechData[tech.id].length;
            i++
          ) {
            if (this.props.initMarginalGenTechData[tech.id][i]) {
              let price = 0;
              if (i < newCurThreshold) {
                // NEW
                const fixedPrice =
                  Math.round(
                    (100 *
                      fixedNewCostPerMWpa *
                      this.props.initMarginalCapData[tech.id][i]) /
                      this.props.initMarginalGenTechData[tech.id][i]
                  ) / 100;
                price = fixedPrice + tech.new.variableCost;
              } else {
                // CURRENT
                const fixedPrice =
                  Math.round(
                    (100 *
                      fixedCurrentCostPerMWpa *
                      this.props.initMarginalCapData[tech.id][i]) /
                      this.props.initMarginalGenTechData[tech.id][i]
                  ) / 100;
                price = fixedPrice + tech.current.variableCost;
              }
              if (price > maxVal) {
                maxVal = price;
              }
              const baseData = dataFields[dataFields.length - 1].dataPoints;
              if (typeof baseData[i] === "undefined") {
                baseData[i] = {
                  x: i + 1,
                  y: "",
                  init: price.toLocaleString(),
                };
              } else {
                baseData[i].init = price.toLocaleString();
              }
              dataPoints.push({
                x: i + 1,
                y: price,
              });
            }
          }

          dataFields.push({
            type: "line",
            lineDashType: "dot",
            name: tech.tech + " (Base)",
            //label: tech.tech,
            toolTipContent: null,
            color: standardColorset[j],
            xValueFormatString: "Tranche #,##0",
            yValueFormatString: "#,##0.00",
            dataPoints: dataPoints,
          });
          if (maxVal > 250) {
            dataFields[dataFields.length - 1].axisYType = "secondary";
            dataFields[dataFields.length - 2].axisYType = "secondary";
            dataFields[dataFields.length - 2].name += " (R)";
          }
        } else if (maxVal > 250) {
          dataFields[dataFields.length - 1].axisYType = "secondary";
          dataFields[dataFields.length - 1].name += " (R)";
        }
        if (maxVal > 10000) {
          options.axisY2.maximum = 10000;
        }
        j++;
      }
      //k++;
    }
    //console.log("dataFields", dataFields);

    options.data = dataFields;

    return (
      <>
        <div>
          <CanvasJSChart options={options} onRef={ref => (this.chart = ref)} />
        </div>
      </>
    );
  }
}

export default EDFSGenCostMarginal;
