import React, { PureComponent } from "react";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

class EDFSDownload extends PureComponent {
  /*constructor(props) {
    super(props);
    
  }*/

  downloadData() {
    if (
      this.props.wholesaleElecDemandData &&
      this.props.wholesaleElecDemandData.length
    ) {
      this.props.setLoading(true);
      const arLength = this.props.wholesaleElecDemandData.length;

      const workbook = new Excel.Workbook();
      const sheet1 = workbook.addWorksheet("Assumptions");
      const sheet2 = workbook.addWorksheet("Heat_Use");
      const sheet3 = workbook.addWorksheet("El_Demand");
      const sheet4 = workbook.addWorksheet("El_Supply");
      const sheet5 = workbook.addWorksheet("El_Balance");
      const sheet6 = workbook.addWorksheet("El_Cost");
      const sheet7 = workbook.addWorksheet("El_Storage");
      //console.log("heatsupply", this.props.heatsupply);

      sheet3.columns = [
        {
          header: "Time",
          key: "time",
          width: 13,
          style: { numFmt: "d/m/yy hh:mm" },
        },
        {
          header: "Light/Equip",
          key: "lighting",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Air-Con",
          key: "aircon",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Resist. Heat",
          key: "resistance",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        { header: "ASHP", key: "ashp", width: 9, style: { numFmt: "#,##0" } },
        { header: "GSHP", key: "gshp", width: 9, style: { numFmt: "#,##0" } },
        { header: "Road", key: "road", width: 9, style: { numFmt: "#,##0" } },
        { header: "Rail", key: "rail", width: 9, style: { numFmt: "#,##0" } },
      ];

      sheet4.columns = [
        {
          header: "Time",
          key: "time",
          width: 13,
          style: { numFmt: "d/m/yy hh:mm" },
        },
        { header: "Solar", key: "solar", width: 9, style: { numFmt: "#,##0" } },
        {
          header: "Biogas",
          key: "biogas",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Nuclear",
          key: "nuclear",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Onshore wind",
          key: "onshorewind",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Offshore wind",
          key: "offshorewind",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Biomass",
          key: "biomass",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        { header: "Hydro", key: "hydro", width: 9, style: { numFmt: "#,##0" } },
        { header: "Gas", key: "gas", width: 9, style: { numFmt: "#,##0" } },
        { header: "Coal", key: "coal", width: 9, style: { numFmt: "#,##0" } },
        { header: "Oil", key: "oil", width: 9, style: { numFmt: "#,##0" } },
      ];

      sheet5.columns = [
        {
          header: "Time",
          key: "time",
          width: 13,
          style: { numFmt: "d/m/yy hh:mm" },
        },
        {
          header: "Retail Elec",
          key: "retailElecDemand",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Losses",
          key: "losses",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Wholesale Elec",
          key: "wholesaleElecDemand",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Inflexibles",
          key: "inflexibles",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Demand net of inflex.",
          key: "inflexImbalance",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Flexibles",
          key: "Flexibles",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Pumped Storage",
          key: "pumped",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Batteries",
          key: "batteries",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Storage Total",
          key: "stortotal",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "PS Balance",
          key: "pumpedbalance",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Batteries Balance",
          key: "batteriesbalance",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "French IC",
          key: "intfr",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Irish IC",
          key: "intew",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Dutch IC",
          key: "intned",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "N.Irish IC",
          key: "intirl",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Belgian IC",
          key: "intnem",
          width: 9,
          style: { numFmt: "#,##0" },
        },
        {
          header: "Interconnectors",
          key: "ictotal",
          width: 9,
          style: { numFmt: "#,##0" },
        },
      ];

      sheet6.columns = [
        {
          header: "Time",
          key: "time",
          width: 13,
          style: { numFmt: "d/m/yy hh:mm" },
        },
        {
          header: "Avg. Cost",
          key: "y",
          width: 9,
          style: { numFmt: "0.00" },
        },
        {
          header: "Marginal Operating Cost",
          key: "marginalOp",
          width: 9,
          style: { numFmt: "0.00" },
        },
        {
          header: "Marginal Net Cost",
          key: "marginalNet",
          width: 9,
          style: { numFmt: "0.00" },
        },
        {
          header: "Market condition",
          key: "condition",
          width: 9,
          style: { numFmt: "0.00" },
        },
      ];

      let sheet2columns = this.props.heatsupply.map((obj) => ({
        header: obj.tech,
        key: obj.id,
        width: 9,
        style: { numFmt: "#,##0" },
      }));
      sheet2columns.unshift({
        header: "Time",
        key: "time",
        width: 13,
        style: { numFmt: "d/m/yy hh:mm" },
      });
      sheet2.columns = sheet2columns;

      let sheet7columns = this.props.storage.map((obj) => ({
        header: obj.tech,
        key: obj.id,
        width: 9,
        style: { numFmt: "#,##0" },
      })); // Flows
      sheet7columns.push(
        ...this.props.storage.map((obj) => ({
          header: obj.tech,
          key: obj.id,
          width: 9,
          style: { numFmt: "#,##0" },
        }))
      ); // Balance
      sheet7columns.push(
        ...this.props.storage.map((obj) => ({
          header: obj.tech,
          key: obj.id,
          width: 9,
          style: { numFmt: "#,##0" },
        }))
      ); // Cost
      // Add column for total, but where?
      sheet7columns.unshift({
        header: "Time",
        key: "time",
        width: 13,
        style: { numFmt: "d/m/yy hh:mm" },
      });
      sheet7.columns = sheet7columns;
      let s7GroupRow = [""];
      let groupcols = this.props.storage.map(() => "");
      groupcols[0] = "Flows (MW)";
      s7GroupRow.push(...groupcols);
      groupcols[0] = "Stored (MWh)";
      s7GroupRow.push(...groupcols);
      groupcols[0] = "Cost";
      s7GroupRow.push(...groupcols);
      sheet7.addRow(s7GroupRow);

      for (let i = 0; i < arLength; i++) {
        const daysSince1900 =
          moment(this.props.wholesaleElecDemandData[i].x).diff(
            moment([1899, 11, 30, 0, 0, 0, 0])
          ) / 86400000;
        const lighting = this.props.lightingData[i].y || 0;
        const aircon = this.props.airconData[i].y || 0;
        const resistance = this.props.resistanceData[i].y || 0;
        const ashp = this.props.ashpData[i].y || 0;
        const gshp = this.props.gshpData[i].y || 0;
        const road = this.props.roadData[i].y || 0;
        const rail = this.props.railData[i].y || 0;
        sheet3
          .addRow([
            daysSince1900,
            lighting,
            aircon,
            resistance,
            ashp,
            gshp,
            road,
            rail,
          ])
          .commit();

        const solar = this.props.solarData[i].y || 0;
        const biogas = this.props.biogasData[i].y || 0;
        const nuclear = this.props.nuclearData[i].y || 0;
        const onshorewind = this.props.onshorewindData[i].y || 0;
        const offshorewind = this.props.offshorewindData[i].y || 0;
        const biomass = this.props.flexiblesData.generation.biomass[i].y || 0;
        const hydro = this.props.flexiblesData.generation.hydro[i].y || 0;
        const gas = this.props.flexiblesData.generation.gas[i].y || 0;
        const coal = this.props.flexiblesData.generation.coal[i].y || 0;
        const oil = this.props.flexiblesData.generation.oil[i].y || 0;
        sheet4
          .addRow([
            daysSince1900,
            solar,
            biogas,
            nuclear,
            onshorewind,
            offshorewind,
            biomass,
            hydro,
            gas,
            coal,
            oil,
          ])
          .commit();

        const retailElecDemand =
          lighting + aircon + resistance + ashp + gshp + road + rail;
        const wholesaleElecDemand =
          this.props.wholesaleElecDemandData[i].y || 0;
        const losses = wholesaleElecDemand - retailElecDemand;
        const inflexibles = this.props.inflexiblesData[i].y || 0;
        //const inflexibles = solar + biogas + nuclear + onshorewind + offshorewind;
        const inflexImbalance = this.props.inflexImbalanceData[i].y || 0;
        const flexibles = this.props.flexiblesData.gentotal[i].y || 0;
        const pumped = this.props.flexiblesData.storage.pumped[i].y || 0;
        const batteries = this.props.flexiblesData.storage.batteries[i].y || 0;
        const stortotal = this.props.flexiblesData.stortotal[i].y || 0;
        const pumpedbalance =
          this.props.flexiblesData.storbalance.pumped[i].y || 0;
        const batteriesbalance =
          this.props.flexiblesData.storbalance.batteries[i].y || 0;
        const intfr = this.props.flexiblesData.interconnection.intfr[i].y || 0;
        const intew = this.props.flexiblesData.interconnection.intew[i].y || 0;
        const intned =
          this.props.flexiblesData.interconnection.intned[i].y || 0;
        const intirl =
          this.props.flexiblesData.interconnection.intirl[i].y || 0;
        const intnem =
          this.props.flexiblesData.interconnection.intnem[i].y || 0;
        const ictotal = this.props.flexiblesData.ictotal[i].y || 0;
        sheet5
          .addRow([
            daysSince1900,
            retailElecDemand,
            losses,
            wholesaleElecDemand,
            inflexibles,
            inflexImbalance,
            flexibles,
            pumped,
            batteries,
            stortotal,
            pumpedbalance,
            batteriesbalance,
            intfr,
            intew,
            intned,
            intirl,
            intnem,
            ictotal,
          ])
          .commit();

        let sheet2vals = this.props.heatsupply.map((obj) => {
          return this.props.heatUseData[obj.id][i].y || 0;
        });
        sheet2vals.unshift(daysSince1900);
        sheet2.addRow(sheet2vals).commit();

        sheet6
          .addRow([
            daysSince1900,
            this.props.costData[i].y,
            this.props.costData[i].marginalOp,
            this.props.costData[i].marginalNet,
            this.props.costData[i].condition,
          ])
          .commit();

        let sheet7Row = [daysSince1900];
        sheet7Row.push(
          ...this.props.storage.map((obj) => {
            return this.props.storageData[obj.id][i].y;
          })
        );
        sheet7Row.push(
          ...this.props.storage.map((obj) => {
            return this.props.storBalanceData[obj.id][i].y;
          })
        );
        sheet7Row.push(
          ...this.props.storage.map((obj) => {
            return this.props.storElecCostData[obj.id][i].y;
          })
        );
        sheet7.addRow(sheet7Row).commit();
      }

      for (let i = 0; i < 3; i++) {
        const s7start = 2 + this.props.storage.length * i;
        const s7end = s7start + this.props.storage.length - 1;
        sheet7.mergeCells(2, s7start, 2, s7end);
      }

      sheet2.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
      sheet3.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
      //sheet3.commit();
      sheet4.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
      //sheet4.commit();
      sheet5.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
      //sheet5.commit();
      sheet6.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
      sheet7.views = [{ state: "frozen", xSplit: 1, ySplit: 2 }];

      let rowno = 1;
      sheet1.addRow(["Base Year:", this.props.year]).commit(); // 1
      rowno++;
      sheet1.addRow(["Weather:", this.props.weather]).commit(); // 2
      rowno++;

      sheet1.addRow([]).commit(); // 3
      rowno++;
      sheet1.addRow(["Demand"]); // 4
      let row = sheet1.getRow(rowno);
      row.font = { size: 16, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow(["Electricity"]); // 5
      row = sheet1.getRow(rowno);
      row.font = { size: 14, bold: true, italic: true };
      row.commit();
      rowno++;
      for (let item of this.props.convdemand) {
        sheet1.addRow([item.tech, item.elec, "TWh"]).commit(); // 6-7
        rowno++;
      }

      sheet1.addRow([]).commit(); // 8
      rowno++;
      sheet1.addRow(["Heat"]); // 9
      row = sheet1.getRow(rowno);
      row.font = { size: 14, bold: true, italic: true };
      row.commit();
      rowno++;
      sheet1.addRow(["Existing Homes"]); // 10
      row = sheet1.getRow(rowno);
      row.font = { size: 12, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow([
        "",
        "Insulated",
        "Not Insulated (Easy)",
        "Not Insulated (Hard)",
        "Total",
      ]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 11
      rowno++;
      let heatDemandPart = this.props.heatdemand.homes.existing.cavityWall;
      sheet1
        .addRow([
          "Cavity wall insulation",
          heatDemandPart.insulated,
          heatDemandPart.notInsulatedEasy,
          heatDemandPart.notInsulatedHard,
          heatDemandPart.total,
          "million",
        ])
        .commit(); // 12
      rowno++;
      sheet1.addRow([
        "",
        "Insulated",
        "Uninsulated",
        "Total",
        "",
        "Unknown wall insulation",
      ]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      sheet1.mergeCells(rowno, 6, rowno, 8);
      row.commit(); // 13
      rowno++;
      heatDemandPart = this.props.heatdemand.homes.existing.solidWall;
      sheet1
        .addRow([
          "Solid wall insulation",
          heatDemandPart.insulated,
          heatDemandPart.notInsulated,
          heatDemandPart.total,
          "million",
          "",
          this.props.heatdemand.homes.existing.unknownWall,
          "million",
        ])
        .commit(); // 14
      rowno++;
      sheet1.addRow([
        "",
        "Insulated > 125mm",
        "Insulated <125mm (Easy)",
        "Insulated <125mm (Hard)",
        "Not Insulated",
        "Total",
      ]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 15
      rowno++;
      heatDemandPart = this.props.heatdemand.homes.existing.loft;
      sheet1
        .addRow([
          "Loft insulation",
          heatDemandPart.insulatedPlus,
          heatDemandPart.insulatedMinusEasy,
          heatDemandPart.insulatedMinusHard,
          heatDemandPart.notInsulated,
          heatDemandPart.total,
          "million",
        ])
        .commit(); // 16
      rowno++;
      sheet1.addRow(["", "Full", "Partial", "None"]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 17
      rowno++;
      heatDemandPart = this.props.heatdemand.homes.existing.doubleGlazing;
      sheet1
        .addRow([
          "Double Glazing",
          heatDemandPart.fullPC,
          heatDemandPart.partialPC,
          heatDemandPart.nonePC,
          "%",
        ])
        .commit(); // 18
      rowno++;
      sheet1.addRow(["", "Space heating", "Hot water", "Cooking", "Total"]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 19
      rowno++;
      heatDemandPart = this.props.heatdemand.homes.existing.totals;
      sheet1
        .addRow([
          "Total energy for heat",
          heatDemandPart.spaceHeating,
          heatDemandPart.hotWater,
          heatDemandPart.cooking,
          heatDemandPart.heat,
          "TWh",
        ])
        .commit(); // 20
      rowno++;
      sheet1.addRow(["New Homes"]); // 21
      row = sheet1.getRow(rowno);
      row.font = { size: 12, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow([
        "",
        "mn Built",
        "FEE Level",
        "Size (m2)",
        "Space Heating",
        "Hot Water",
        "Cooking",
        "Total (TWh)",
      ]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 22
      rowno++;
      heatDemandPart = this.props.heatdemand.homes.new.houses;
      sheet1
        .addRow([
          "Houses",
          heatDemandPart.mnBuilt,
          heatDemandPart.feeLevel,
          heatDemandPart.size,
          heatDemandPart.spaceHeating,
          heatDemandPart.hotWater,
          heatDemandPart.cooking,
          heatDemandPart.heat,
        ])
        .commit(); // 23
      rowno++;
      heatDemandPart = this.props.heatdemand.homes.new.flats;
      sheet1
        .addRow([
          "Flats",
          heatDemandPart.mnBuilt,
          heatDemandPart.feeLevel,
          heatDemandPart.size,
          heatDemandPart.spaceHeating,
          heatDemandPart.hotWater,
          heatDemandPart.cooking,
          heatDemandPart.heat,
        ])
        .commit(); // 24
      rowno++;
      sheet1.addRow(["Non-domestic"]); // 25
      row = sheet1.getRow(rowno);
      row.font = { size: 12, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow(["", "Space heating", "Hot water", "Cooking"]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 26
      rowno++;
      heatDemandPart = this.props.heatdemand.nondom.services;
      sheet1
        .addRow([
          "Services",
          heatDemandPart.spaceHeating,
          heatDemandPart.hotWater,
          heatDemandPart.cooking,
          "TWh",
        ])
        .commit(); // 27
      rowno++;
      sheet1.addRow([
        "",
        "Space heating",
        "Process (high temp)",
        "Process (low temp)",
        "Drying/separation",
        "Other",
      ]);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "right",
        wrapText: true,
      };
      row.commit(); // 28
      rowno++;
      heatDemandPart = this.props.heatdemand.nondom.industry;
      sheet1
        .addRow([
          "Industry",
          heatDemandPart.spaceHeating,
          heatDemandPart.processHigh,
          heatDemandPart.processLow,
          heatDemandPart.drying,
          heatDemandPart.other,
          "TWh",
        ])
        .commit(); // 29
      rowno++;
      sheet1
        .addRow([
          "Total UK heat",
          this.props.totalheat,
          "TWh",
          "Building heat",
          this.props.buildingheat,
          "TWh",
          "Space heating",
          this.props.spaceheat,
          "TWh",
        ])
        .commit(); // 30
      rowno++;
      sheet1.addRow([]).commit(); // 31
      rowno++;
      sheet1.addRow([
        "Boiler type",
        "% of building heat",
        "TWh of heat",
        "Efficiency (%)",
        "TWh of electricity /fuel",
      ]); // 32
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      row.commit();
      rowno++;
      for (let item of this.props.heatsupply) {
        sheet1.addRow([
          item.tech,
          item.heatshare,
          item.heat,
          item.efficiency,
          item.elec || item.fuel,
        ]); // 33-15
        row = sheet1.getRow(rowno);
        row.numFmt = "#,##0.0";
        row.commit();
        rowno++;
      }

      sheet1.addRow([]).commit(); // 36
      rowno++;
      sheet1.addRow(["Transport"]); // 37
      row = sheet1.getRow(rowno);
      row.font = { size: 14, bold: true, italic: true };
      row.commit();
      rowno++;
      sheet1.addRow([
        "Mode",
        "TWh of energy",
        "TWh of electricity",
        "Fuel efficiency (%)",
        "Electric efficiency (%)",
      ]); // 38
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      row.commit();
      rowno++;
      for (let item of this.props.trnsptdemand) {
        sheet1.addRow([
          item.tech,
          item.total,
          item.elec,
          item.fuelefficiency,
          item.elecefficiency,
        ]); // 39-42
        row = sheet1.getRow(rowno);
        row.numFmt = "#,##0.0";
        row.commit();
        rowno++;
      }

      sheet1.addRow([]).commit(); // 43
      rowno++;
      sheet1.addRow(["Transmission/Distribution"]); // 44
      row = sheet1.getRow(rowno);
      row.font = { size: 16, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow(["Losses:", this.props.distlosses, "%"]).commit(); // 45
      rowno++;

      sheet1.addRow([]).commit(); // 46
      rowno++;
      sheet1.addRow(["Generation"]); // 47
      row = sheet1.getRow(rowno);
      row.font = { size: 16, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow(["Technology", "Current", "", "", "New", "", ""]); // 48
      sheet1.mergeCells(rowno, 2, rowno, 4);
      sheet1.mergeCells(rowno, 5, rowno, 7);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      row.commit();
      rowno++;
      sheet1.addRow([
        "",
        "Capacity (MW)",
        "Availability factor (%)",
        "Capacity factor (%)",
        "Capacity (MW)",
        "Availability factor (%)",
        "Capacity factor (%)",
      ]); // 49
      sheet1.mergeCells(rowno - 1, 1, rowno, 1);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      row.commit();
      rowno++;
      for (let item of this.props.generation) {
        sheet1.addRow([
          item.tech,
          item.current.capacity,
          item.current.availFactor,
          item.current.loadFactor,
          item.new.capacity,
          item.new.availFactor,
          item.new.loadFactor,
        ]); //50-59
        row = sheet1.getRow(rowno);
        row.numFmt = "#,##0.0";
        row.commit();
        rowno++;
      }

      sheet1.addRow([]).commit(); //60
      rowno++;
      sheet1.addRow(["Storage"]); //61
      row = sheet1.getRow(rowno);
      row.font = { size: 16, bold: true, underline: true };
      row.commit();
      rowno++;
      sheet1.addRow(["Technology", "Current", "", "", "New", "", ""]); //62
      sheet1.mergeCells(rowno, 2, rowno, 4);
      sheet1.mergeCells(rowno, 5, rowno, 7);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      row.commit();
      rowno++;
      sheet1.addRow([
        "",
        "Capacity (MWh)",
        "Power (MW)",
        "Round-trip efficiency (%)",
        "Capacity (MWh)",
        "Power (MW)",
        "Round-trip efficiency (%)",
      ]); //63
      sheet1.mergeCells(rowno - 1, 1, rowno, 1);
      row = sheet1.getRow(rowno);
      row.font = { italic: true };
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      row.commit();
      rowno++;
      for (let item of this.props.storage) {
        sheet1.addRow([
          item.tech,
          item.current.capacity,
          item.current.power,
          item.current.efficiency,
          item.new.capacity,
          item.new.power,
          item.new.efficiency,
        ]); //64-65
        row = sheet1.getRow(rowno);
        row.numFmt = "#,##0.0";
        row.commit();
        rowno++;
      }

      sheet1.addRow([]).commit(); // 66
      rowno++;
      sheet1.addRow(["Interconnection"]); // 67
      row = sheet1.getRow(rowno);
      row.font = { size: 16, bold: true, underline: true };
      row.commit();
      rowno++;
      for (let item of this.props.interconnection) {
        sheet1.addRow([item.tech, item.capacity, "MW"]).commit(); // 68-72
        rowno++;
      }

      const firstcol = sheet1.getColumn(1);
      firstcol.width = 21;
      for (let i = 2; i < 8; i++) {
        const col = sheet1.getColumn(i);
        col.width = 11;
      }

      const filename = this.props.filename + ".xlsx";
      //console.log("filename", filename);
      workbook.xlsx
        .writeBuffer()
        .then((buf) => {
          var blob = new Blob([buf], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, filename);
          this.props.setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          this.props.setLoading(false);
        });
    }
  }

  render() {
    console.log("Rendering EDFSDownload");
    //console.log("flexiblesData", this.props.flexiblesData);
    //console.log("aircon", this.props.airconData);
    return (
      <button
        className="btn btn-secondary btn-block mt-3 mb-1 d-print-none"
        onClick={() => {
          this.downloadData();
        }}
      >
        Download Data
      </button>
    );
  }
}

export default EDFSDownload;
