import React, { Component } from "react";
import EDBMRPage from "../components/EDBMRPage";
/*import {
  MDBDataTable,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBBtnGroup,
  MDBCollapse,
  MDBIcon,
  MDBPopover,
  MDBPopoverBody,
  MDBEdgeHeader,
  MDBFreeBird,
} from "mdbreact";
import DatePicker from "react-datepicker";
import { saveAs } from "file-saver";
import EDProjGroupCard from "../components/EDProjGroupCard";
import EDBMRGenUnitChart from "../components/EDBMRGenUnitChart";
import EDBMRGenUnitInstructions from "../components/EDBMRGenUnitInstructions";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import mdbcolors from "../components/mdbcolors.json";
import "react-datepicker/dist/react-datepicker.css";
//import { resolveProjectReferencePath } from "typescript";
//import { labeledStatement } from "@babel/types";

const firstMomentOfThisMonth = moment().startOf("month");
const firstMomentOfLastMonth = firstMomentOfThisMonth.subtract(12, "months");
const firstDateOfLastMonth = firstMomentOfLastMonth.toDate();
const defaultDays = moment().diff(firstMomentOfLastMonth, "days");
*/
class BMRDayGenUnitPage extends Component {
  dataTypeInfoText() {
    return (
      <>
        <p>
          Sum is the total output of the projects in a group in each half-hour.
          There are a few gaps in the data, and you will not be able to tell if
          the sum dips because of reduced output or missing data.
        </p>
        <p>
          Average addresses this by only taking the average of available data
          for each group for each half-hour, but is more useful for the trend
          than for the absolute figures because the average output is of limited
          interest unless the projects are similar.
        </p>
        <p>
          Load Factor (%) divides the total output from available data for the
          projects in a group in each half-hour by the nominal capacity of those
          projects in that half-hour. This provides the most comparable view,
          normalising for project size and data availability, but disguises the
          absolute values.
        </p>
      </>
    );
  }

  topText() {
    return (
      <>
        <h2 className="h2-responsive mb-4">
          <strong>BMR Daily Output by Site</strong>
        </h2>
        <p>
          Daily output from generating units included in Elexon's Balancing
          Mechanism Report B1610 (aggregated from the half-hourly data).
        </p>
        <p>
          This page allows you to visualise the daily output from groups of
          generating units. The output is expressed in MWh (energy) not MW
          (power). If you divided the daily MWh by 24, you would get the average
          MW for each day produced by the members of the group.
        </p>
      </>
    );
  }

  render() {
    return (
      <EDBMRPage
        itemURL="https://ed.c4cs.org.uk:5002/BMRGenUnitsByPS"
        dataURL="https://ed.c4cs.org.uk:5002/BMRDayGenUnitOutput"
        topText={this.topText()}
        datatypeInfoText={this.dataTypeInfoText()}
        initDays={365}
        initMonths={12}
        maxDays={3000}
        itemsColHeading="Projects"
        datatypes={[
          { id: 1, name: "Sum" },
          { id: 2, name: "Average" },
          { id: 3, name: "Load Factor" },
        ]}
        dataTableClassName="bmrhhgenunitdt"
        interval="day"
      />
    );
  }

  /*  constructor(props) {
    super(props);
    this.state = {
      chartType: "line",
      dataType: 1,
      scatterDisabled: true,
      downloadsDisabled: true,
      updateButtonDisabled: true,
      //chartLoading: true,
      dlCollapseOpen: true,
      dlCollapseCaret: "caret-up",
      plCollapseOpen: true,
      plCollapseCaret: "caret-up",
      startDate: firstDateOfLastMonth,
      days: defaultDays,
      projListLoaded: false,
      techgroups: [],
      nextGroupId: 5,
      selectedgroup: 1,
      selectgroups: [
        {
          id: 1,
          name: "Group 1",
          items: [],
          color: "#3f51b5",
        },
        {
          id: 2,
          name: "Group 2",
          items: [],
          color: "#009688",
        },
        {
          id: 3,
          name: "Group 3",
          items: [],
          color: "#ffeb3b",
        },
        {
          id: 4,
          name: "Group 4",
          items: [],
          color: "#795548",
        },
      ],
      data: {},
    };

    this.addGroup = this.addGroup.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.clickMove = this.clickMove.bind(this);
    this.deleteSelectedGroup = this.deleteSelectedGroup.bind(this);
    this.deleteSelectedItem = this.deleteSelectedItem.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.handleDaysChange = this.handleDaysChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.moveGroup = this.moveGroup.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.setGroupColor = this.setGroupColor.bind(this);
    this.toggledlCollapse = this.toggledlCollapse.bind(this);
    this.toggleplCollapse = this.toggleplCollapse.bind(this);
    this.updateButtonStatus = this.updateButtonStatus.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateGroupName = this.updateGroupName.bind(this);
  }

  componentDidMount() {
    fetch("https://ed.c4cs.org.uk:5002/BMRHHGenUnitsByPS")
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            projListLoaded: true,
            techgroups: result,
          });
          //console.log(this.state.techgroups);
        },
        error => {
          this.setState({
            projListLoaded: true,
            error,
          });
          console.log(error);
        }
      );
  }

  addGroup() {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const groupId = this.state.nextGroupId;
    const colorIndex = (groupId * 4) % 19;
    const groupColor = mdbcolors.palette[colorIndex][2];
    const groupName = "Group " + groupId;
    selectGroups.push({
      id: groupId,
      name: groupName,
      color: groupColor,
      items: [],
    });
    this.setState({
      selectgroups: selectGroups,
      nextGroupId: 1 + groupId,
    });
    //console.log("selectgroups", selectGroups);
  }

  changeDate(step) {
    const startMoment = moment(this.state.startDate);
    switch (step) {
      case -2:
        startMoment.subtract(this.state.days, "days");
        break;
      case -1:
        startMoment.subtract(1, "days");
        break;
      case 1:
        startMoment.add(1, "days");
        break;
      case 2:
        startMoment.add(this.state.days, "days");
        break;
      default:
        startMoment.add(0, "days");
    }
    const startDate = startMoment.toDate();
    this.setState({ startDate: startDate });
  }

  clickMove(index, parentIndex, section) {
    //console.log(index, parentIndex, section);
    if (this.state.selectedgroup) {
      const sourcesection = JSON.parse(JSON.stringify(this.state[section]));
      let destsection = sourcesection;
      if (section === "techgroups") {
        destsection = JSON.parse(JSON.stringify(this.state.selectgroups));
      }
      const moveitem = sourcesection[parentIndex].items.splice(index, 1)[0];

      let destIndex = null;
      for (let i = 0; i < destsection.length; i++) {
        if (destsection[i].id === this.state.selectedgroup) {
          destIndex = i;
          break;
        }
      }
      if (destIndex == null) {
        alert(
          "No current group is selected (did you delete the last selected group?)"
        );
      } else {
        destsection[destIndex].items.push(moveitem);
        const update_states = {
          selectgroups: destsection,
          updateButtonDisabled: false,
        };
        if (section === "techgroups") {
          update_states["techgroups"] = sourcesection;
        }
        this.setState(update_states);
      }
    }
  }

  deleteSelectedItem(itemIndex, groupIndex) {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const deleteGroup = selectGroups[groupIndex];
    const deleteItem = deleteGroup.items.splice(itemIndex, 1)[0];
    const home_id = deleteItem.home_id;

    selectGroups[groupIndex] = deleteGroup;

    const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
    const techcount = techGroups.length;
    for (let i = 0; i < techcount; i++) {
      if (techGroups[i].id === home_id) {
        techGroups[i].items.push(deleteItem);
        break;
      }
    }
    const update_states = {
      selectgroups: selectGroups,
      techgroups: techGroups,
    };
    const arStatus = this.updateButtonStatus(selectGroups);
    if (arStatus[0]) {
      update_states["updateButtonDisabled"] = arStatus[1];
    }
    //update_states["techgroups"] = techGroups;
    this.setState(update_states);
  }

  deleteSelectedGroup(groupIndex) {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    const deleteGroup = selectGroups.splice(groupIndex, 1)[0];
    const delitems = deleteGroup.items;
    const itemcount = delitems.length;
    const update_states = { selectgroups: selectGroups };
    if (itemcount > 0) {
      const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
      const techcount = techGroups.length;
      const techGroupIds = {};
      for (let i = 0; i < techcount; i++) {
        techGroupIds[techGroups[i].id] = i;
      }
      for (let i = 0; i < itemcount; i++) {
        const home_id = delitems[i].home_id;
        techGroups[techGroupIds[home_id]].items.push(delitems[i]);
      }
      update_states["techgroups"] = techGroups;
    }
    const arStatus = this.updateButtonStatus(selectGroups);
    if (arStatus[0]) {
      update_states["updateButtonDisabled"] = arStatus[1];
    }
    this.setState(update_states);
  }

  downloadData(format) {
    const { startDate, days, selectgroups, dataType } = this.state;
    fetch(
      "https://ed.c4cs.org.uk:5002/BMRDayGenUnitOutput",
      {
        method: "POST",
        body: JSON.stringify({
          startDate: startDate,
          days: days,
          groups: selectgroups,
          datastyle: dataType,
          format: format,
        }),
      }
    ).then(function(response) {
      // console.log(response.headers.get("Content-Type"));
      const contdisp = response.headers.get("content-disposition");
      // console.log(contdisp);
      const filematch = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;
      const filename = contdisp.match(filematch)[2];

      response.blob().then(file => {
        // Download the file
        saveAs(file, filename);
      });
    });
  }

  handleDaysChange(event) {
    this.setState({ days: event.target.value });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }

  moveGroup(index) {
    if (this.state.selectedgroup) {
      const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
      const techGroups = JSON.parse(JSON.stringify(this.state.techgroups));
      let destIndex = null;
      for (let i = 0; i < selectGroups.length; i++) {
        if (selectGroups[i].id === this.state.selectedgroup) {
          destIndex = i;
          break;
        }
      }

      if (destIndex == null) {
        alert(
          "No current group is selected (did you delete the last selected group?)"
        );
      } else {
        techGroups[index].items.forEach(item =>
          selectGroups[destIndex].items.push(item)
        );
        techGroups[index].items = [];
        this.setState({
          selectgroups: selectGroups,
          techgroups: techGroups,
          updateButtonDisabled: false,
        });
      }
    }
  }

  selectGroup(groupId) {
    this.setState({ selectedgroup: groupId });
    //console.log("selectedgroup:", groupId);
  }

  setChartType = chartType => () => {
    //this.setState({ chartLoading: true });
    if (
      this.state.selectgroups.filter(
        group => group.items && group.items.length > 0
      ).length > 1
    ) {
      this.setState({ chartType: chartType, scatterDisabled: false });
    } else {
      this.setState({ chartType: "line", scatterDisabled: true });
    }
    //this.setState({ chartLoading: false });
  };

  setDataType = dataType => () => {
    this.setState({ dataType: dataType });
  };

  setGroupColor(groupIndex, color) {
    const selectGroups = JSON.parse(JSON.stringify(this.state.selectgroups));
    selectGroups[groupIndex].color = color;
    this.setState({ selectgroups: selectGroups });
  }

  toggledlCollapse(event) {
    const newState = !this.state.dlCollapseOpen;
    const newCaret = newState ? "caret-up" : "caret-down";
    this.setState({
      dlCollapseOpen: newState,
      dlCollapseCaret: newCaret,
    });
  }

  toggleplCollapse(event) {
    const newState = !this.state.plCollapseOpen;
    const newCaret = newState ? "caret-up" : "caret-down";
    this.setState({
      plCollapseOpen: newState,
      plCollapseCaret: newCaret,
    });
  }

  // Pass in this.state.selectgroups, or its copy in destsection in onDragEnd
  // return array of two Booleans: (1) has changed, (2) disabled (true/fals)
  updateButtonStatus(destsection) {
    //console.log("items: ", destsection.length);
    const { updateButtonDisabled } = this.state;
    let activeGroups = 0;

    for (let i = 0; i < destsection.length; i++) {
      if (destsection[i].items.length > 0) {
        activeGroups++;
      }
    }
    //console.log("updateButtonStatus: ", activeGroups);
    let newStatus = activeGroups > 0 ? false : true;
    let changedStatus = newStatus === updateButtonDisabled ? false : true;
    return [changedStatus, newStatus];
  }

  updateData() {
    const { startDate, days, selectgroups, dataType } = this.state;
    //this.setState({ chartLoading: true });
    if (
      selectgroups.filter(group => group.items && group.items.length > 0)
        .length < 2
    ) {
      this.setState({ scatterDisabled: true, chartType: "line" });
    } else {
      this.setState({ scatterDisabled: false });
    }

    //console.log("state: ", JSON.stringify(selectgroups));
    fetch(
      "https://ed.c4cs.org.uk:5002/BMRDayGenUnitOutput",
      {
        method: "POST",
        body: JSON.stringify({
          startDate: startDate,
          days: days,
          groups: selectgroups,
          datastyle: dataType,
        }),
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then(res => res.json())
      .then(
        result => {
          let empty = true;
          if (result.rows && result.rows.length) {
            empty = false;
          }
          this.setState({
            data: result,
            downloadsDisabled: empty,
          });
        },
        error => {
          // this.setState({
          // error,
          // });
          this.setState({ downloadsDisabled: true });
          console.log(error);
        }
      );
  }

  updateGroupName(e, index) {
    const destsection = JSON.parse(JSON.stringify(this.state.selectgroups));
    const newval = e.target.value;
    const oldval = destsection[index].name;
    if (newval !== oldval) {
      destsection[index].name = newval;
      this.setState({ selectgroups: destsection });
    }
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    let destgroup_section;
    let destgroup_id;
    let destsection;
    let destgroup_index;
    let sourcegroup_section;
    let sourcegroup_id;
    let sourcesection;
    let sourcegroup_index;
    //let project_id;
    const idre = /^(\w+)-(\d+)/;

    const projmatch = idre.exec(draggableId);
    if (!projmatch) {
      //project_id = parseInt(projmatch[2]);
      //} else {
      return;
    }

    const destmatch = idre.exec(destination.droppableId);
    if (destmatch) {
      destgroup_section = destmatch[1];
      destgroup_id = parseInt(destmatch[2]);
      destsection = JSON.parse(JSON.stringify(this.state[destgroup_section]));
      for (let i = 0; i < destsection.length; i++) {
        if (destsection[i].id === destgroup_id) {
          //console.log("typeof", typeof destsection[i].id);
          destgroup_index = i;
          break;
        }
      }
    } else {
      return;
    }

    const update_states = {};

    if (destination.droppableId === source.droppableId) {
      if (destination.index === source.index) {
        return;
      } else {
        destsection[destgroup_index].items.splice(
          destination.index,
          0,
          destsection[destgroup_index].items.splice(source.index, 1)[0]
        );
        update_states[destgroup_section] = destsection;
      }
    } else {
      const sourcematch = idre.exec(source.droppableId);
      if (sourcematch) {
        sourcegroup_section = sourcematch[1];
        sourcegroup_id = parseInt(sourcematch[2]);
        if (destgroup_section === sourcegroup_section) {
          sourcesection = destsection;
        } else {
          sourcesection = JSON.parse(
            JSON.stringify(this.state[sourcegroup_section])
          );
        }
        for (let i = 0; i < sourcesection.length; i++) {
          if (sourcesection[i].id === sourcegroup_id) {
            sourcegroup_index = i;
            break;
          }
        }
      } else {
        return;
      }

      const moveitem = sourcesection[sourcegroup_index].items.splice(
        source.index,
        1
      )[0];
      //console.log("moveitem: ", JSON.stringify(moveitem));
      const home_id = moveitem.home_id;
      if (
        destgroup_section === "techgroups" &&
        destsection[destgroup_index].id !== home_id
      ) {
        return;
      } else {
        destsection[destgroup_index].items.splice(
          destination.index,
          0,
          moveitem
        );
        update_states[destgroup_section] = destsection;
        if (destgroup_section !== sourcegroup_section) {
          update_states[sourcegroup_section] = sourcesection;
          const selsection =
            destgroup_section === "techgroups" ? sourcesection : destsection;
          const arStatus = this.updateButtonStatus(selsection);
          if (arStatus[0]) {
            update_states["updateButtonDisabled"] = arStatus[1];
          }
        }
      }
    }

    if (Object.keys(update_states).length) {
      //console.log(update_states);
      this.setState(update_states);
    } else {
      return;
    }
  };

  //  console.log("sourcegroup_section: ", sourcegroup_section);
  //  console.log("sourcegroup_id: ", sourcegroup_id);
  //  console.log("sourcegroup_index: ", sourcegroup_index);
  //  console.log("source.index: ", source.index);
  //  console.log("destgroup_section: ", destgroup_section);
  //  console.log("destgroup_id: ", destgroup_id);
  //  console.log("destgroup_index: ", destgroup_index);
  //  console.log("destination.index: ", destination.index);
  //  console.log("destsection: ", JSON.stringify(destsection));

  render() {
    const {
      projListLoaded,
      techgroups,
      selectgroups,
      chartType,
      dataType,
      //chartLoading,
      //startDate,
      data,
    } = this.state;

    //console.log("columns1:", data.columns);
    return (
      <>
        <MDBEdgeHeader color="green darken-3" />
        <MDBFreeBird>
          <MDBRow>
            <MDBCol
              md="10"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <MDBCardBody>
                <h2 className="h2-responsive mb-4">
                  <strong>BMR Daily Output by Site</strong>
                </h2>
                <p>
                  Daily output from generating units included in Elexon's
                  Balancing Mechanism Report B1610 (aggregated from the
                  half-hourly data).
                </p>
                <p>
                  This page allows you to visualise the daily output from groups
                  of generating units. The output is expressed in MWh (energy)
                  not MW (power). If you divided the daily MWh by 24, you would
                  get the average MW for each day produced by the members of the
                  group.
                </p>
                <MDBRow>
                  <EDBMRGenUnitInstructions />
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
        <MDBContainer fluid className="mt-3">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <MDBRow className="py-3">
              <MDBCol lg="9" md="8" size="6">
                <MDBCard className="mb-3">
                  <MDBCardBody className="p-2">
                    <MDBCardTitle className="mb-4">
                      Select&nbsp;
                      <span
                        className="mt-0"
                        //size="sm"
                        //color="light"
                        //outline
                        onClick={this.toggledlCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBIcon icon={this.state.dlCollapseCaret} />
                      </span>
                    </MDBCardTitle>
                    <MDBCollapse
                      id="dlCollapse"
                      isOpen={this.state.dlCollapseOpen}
                    >
                      <MDBRow>
                        <MDBCol lg="4" md="6" size="12" className="pb-3">
                          <DatePicker
                            placeholderText="Start date"
                            id="startDate"
                            className="form-control"
                            selected={this.state.startDate}
                            dateFormat="dd/MM/yyyy"
                            popperPlacement="bottom-start"
                            onChange={this.handleStartDateChange}
                            popperModifiers={{
                              flip: {
                                enabled: false,
                              },
                            }}
                          />
                          <span>&nbsp;for</span>
                        </MDBCol>
                        <MDBCol lg="4" md="6" size="12" className="pb-3">
                          <div className="input-group daysinput">
                            <input
                              className="form-control"
                              id="days"
                              name="days"
                              value={this.state.days}
                              onChange={this.handleDaysChange}
                              type="number"
                              min="1"
                              max="3000"
                              size="4"
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                id="basic-addon"
                              >
                                days
                              </span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol lg="4" md="6" size="12" className="pb-3">
                          <MDBBtnGroup className="mb-2 mr-md-2 ml-md-0 w-100">
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(-2);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="fast-backward" />
                            </MDBBtn>
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(-1);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="step-backward" />
                            </MDBBtn>
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(1);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="step-forward" />
                            </MDBBtn>
                            <MDBBtn
                              //size="sm"
                              onClick={() => {
                                this.changeDate(2);
                              }}
                              className="p-1 p-sm-2 px-2 px-sm-3 w-25"
                            >
                              <MDBIcon icon="fast-forward" />
                            </MDBBtn>
                          </MDBBtnGroup>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="maxvh70 pt-2 border-top">
                        {selectgroups.map((selectgroup, index) => (
                          <MDBCol
                            key={"groupcol" + index}
                            lg="4"
                            md="6"
                            size="12"
                          >
                            <EDProjGroupCard
                              key={"selectgroups-" + selectgroup.id}
                              id={selectgroup.id}
                              index={index}
                              name={selectgroup.name}
                              items={selectgroup.items}
                              color={selectgroup.color}
                              droppableId={"selectgroups-" + selectgroup.id}
                              section="selectgroups"
                              clickMove={this.clickMove}
                              moveGroup={this.moveGroup}
                              deleteGroup={this.deleteSelectedGroup}
                              deleteItem={this.deleteSelectedItem}
                              selectGroup={this.selectGroup}
                              setColor={this.setGroupColor}
                              updateName={this.updateGroupName}
                              mdbcolors={mdbcolors}
                              //updateDisabled={this.updateButtonDisabled}
                            />
                          </MDBCol>
                        ))}
                        <MDBCol lg="4" md="6" size="12">
                          <MDBCard className="mb-3">
                            <MDBCardBody className="minh110 mx-auto">
                              <span
                                onClick={() => {
                                  this.addGroup();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <MDBIcon icon="plus" size="4x" />
                              </span>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="border-top pt-2">
                        <MDBCol>
                          <span className="h6 mr-1">Type of data</span>
                          <MDBPopover popover clickable placement="right">
                            <MDBBtn
                              size="sm"
                              className="px-2 py-1 mr-4"
                              color="dark"
                              style={{ borderRadius: "15px" }}
                            >
                              <MDBIcon icon="info" className="m-1" />
                            </MDBBtn>
                            <div>
                              <MDBPopoverBody>
                                <p>
                                  Sum is the total output of the projects in a
                                  group in each half-hour. There are a few gaps
                                  in the data, and you will not be able to tell
                                  if the sum dips because of reduced output or
                                  missing data.
                                </p>
                                <p>
                                  Average addresses this by only taking the
                                  average of available data for each group for
                                  each half-hour, but is more useful for the
                                  trend than for the absolute figures because
                                  the average output is of limited interest
                                  unless the projects are similar.
                                </p>
                                <p>
                                  Load Factor (%) divides the total output from
                                  available data for the projects in a group in
                                  each half-hour by the nominal capacity of
                                  those projects in that half-hour. This
                                  provides the most comparable view, normalising
                                  for project size and data availability, but
                                  disguises the absolute values.
                                </p>
                              </MDBPopoverBody>
                            </div>
                          </MDBPopover>
                          <div
                            className="btn-group btn-group-toggle"
                            data-toggle="buttons"
                          >
                            <label
                              className={
                                "btn btn-default btn-sm px-2 px-md-3 px-lg-4 px-xl-5 " +
                                (dataType === 1 ? "active" : "")
                              }
                            >
                              <input
                                type="radio"
                                id="datatype-1"
                                name="datatype"
                                autoComplete="off"
                                onChange={this.setDataType(1)}
                                checked={dataType === 1 ? true : false}
                              />
                              Sum
                            </label>
                            <label
                              className={
                                "btn btn-default btn-sm px-2 px-md-3 px-lg-4 px-xl-5 " +
                                (dataType === 2 ? "active" : "")
                              }
                            >
                              <input
                                type="radio"
                                id="datatype-2"
                                name="datatype"
                                autoComplete="off"
                                onChange={this.setDataType(2)}
                                checked={dataType === 2 ? true : false}
                              />
                              Average
                            </label>
                            <label
                              className={
                                "btn btn-default btn-sm px-2 px-md-3 px-lg-4 px-xl-5 " +
                                (dataType === 3 ? "active" : "")
                              }
                            >
                              <input
                                type="radio"
                                id="datatype-3"
                                name="datatype"
                                autoComplete="off"
                                onChange={this.setDataType(3)}
                                checked={dataType === 3 ? true : false}
                              />
                              Load Factor
                            </label>
                          </div>

                          <MDBBtn
                            className="float-right px-2 px-md-3 px-lg-4 px-xl-5"
                            size="sm"
                            disabled={this.state.updateButtonDisabled}
                            onClick={() => {
                              this.updateData();
                            }}
                          >
                            Update
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCollapse>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="3" md="4" size="6">
                <MDBCard className="mb-3">
                  <MDBCardBody className="p-2">
                    <MDBCardTitle className="mb-4">
                      Projects&nbsp;
                      <span
                        className="mt-0"
                        //size="sm"
                        //color="light"
                        //outline
                        onClick={this.toggleplCollapse}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBIcon icon={this.state.plCollapseCaret} />
                      </span>
                    </MDBCardTitle>
                    <MDBCollapse
                      id="plCollapse"
                      isOpen={this.state.plCollapseOpen}
                    >
                      <div className="maxvh85 p-1">
                        {!projListLoaded && <div>Loading...</div>}
                        {techgroups.map((techgroup, index) => (
                          <EDProjGroupCard
                            key={"techgroups" + techgroup.id}
                            id={techgroup.id}
                            index={index}
                            name={techgroup.name}
                            items={techgroup.items}
                            droppableId={"techgroups-" + techgroup.id}
                            section="techgroups"
                            clickMove={this.clickMove}
                            moveGroup={this.moveGroup}
                            deleteGroup={this.deleteSelectedGroup}
                            deleteItem={this.deleteSelectedItem}
                            selectGroup={this.selectGroup}
                            setColor={this.setGroupColor}
                            updateName={this.updateGroupName}
                            mdbcolors={mdbcolors}
                            //updateDisabled={this.updateButtonDisabled}
                          />
                        ))}
                      </div>
                    </MDBCollapse>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol size="12">
                <MDBCard className="mb-3">
                  <MDBCardBody>
                    <EDBMRGenUnitChart
                      rows={data.rows}
                      columns={data.columns}
                      from={data.from}
                      to={data.to}
                      chartType={chartType}
                      dataType={data.datatype}
                      interval="day"
                      //chartLoading={chartLoading}
                    />
                  </MDBCardBody>
                  <MDBCardFooter>
                    <div
                      className="btn-group btn-group-toggle"
                      data-toggle="buttons"
                    >
                      <label
                        className={
                          "btn btn-default btn-sm " +
                          (this.state.chartType === "line" ? "active" : "")
                        }
                      >
                        <input
                          type="radio"
                          id="line"
                          name="charttype"
                          onChange={this.setChartType("line")}
                          checked={
                            this.state.chartType === "line" ? true : false
                          }
                          autoComplete="off"
                        />
                        Line
                      </label>
                      <label
                        className={
                          "btn btn-default btn-sm" +
                          (this.state.chartType === "scatter"
                            ? " active"
                            : "") +
                          (this.state.scatterDisabled ? " disabled" : "")
                        }
                      >
                        <input
                          type="radio"
                          id="scatter"
                          name="charttype"
                          onChange={this.setChartType("scatter")}
                          checked={
                            this.state.chartType === "scatter" ? true : false
                          }
                          autoComplete="off"
                          disabled={this.state.scatterDisabled}
                        />
                        Scatter
                      </label>
                    </div>
                  </MDBCardFooter>
                </MDBCard>
              </MDBCol>
              <MDBCol className="w-100" />
              <MDBCol size="12">
                <MDBCard className="mb-3">
                  <MDBCardBody>
                    <MDBDataTable
                      striped
                      bordered
                      hover
                      small
                      autoWidth
                      scrollX
                      sortable={false}
                      entriesOptions={[10, 20, 50]}
                      entries={10}
                      pagesAmount={6}
                      data={data}
                      className="bmrhhgenunitdt"
                    />
                  </MDBCardBody>
                  <MDBCardFooter>
                    <span>Download: </span>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Download"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          this.downloadData("xlsx");
                        }}
                        className="btn btn-sm btn-default"
                        disabled={this.state.downloadsDisabled}
                      >
                        XLSX
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          this.downloadData("csv");
                        }}
                        className="btn btn-sm btn-default"
                        disabled={this.state.downloadsDisabled}
                      >
                        CSV
                      </button>
                    </div>
                  </MDBCardFooter>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </DragDropContext>
        </MDBContainer>
      </>
    );
  } */
}

export default BMRDayGenUnitPage;
