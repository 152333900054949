import React from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  //MDBIcon,
  MDBNavLink,
} from "mdbreact";
import "./FrontPage.css";
//import MDBLogo from "../assets/mdb-react-small.png";
//import SLZLogo from "../assets/slz_icon_44x44.png";

class FrontPage extends React.Component {
  render() {
    return (
      <div>
        <MDBEdgeHeader color="green darken-3" />
        <MDBFreeBird>
          <MDBRow>
            <MDBCol
              md="10"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <MDBCardBody>
                <h2 className="h2-responsive mb-4">
                  <strong>Energy Data Portal</strong>
                </h2>
                <p>Interface to publicly-accessible energy data</p>
                <p className="pb-4">
                  This application provides tools to query and visualise energy
                  data beyond the facilities provided by the data-originators.
                </p>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto mt-4">
              <MDBRow id="categories">
                <MDBCol md="6" xl="4" className="p-5 mb-5">
                  <h4 className="font-weight-bold">Future Energy Scenarios</h4>
                  <p className="grey-text">
                    Play with the effect of changing the use of various
                    technologies and patterns of demand on the overall energy
                    system, based on historic supply, demand and weather
                    patterns.
                  </p>
                  <MDBNavLink
                    tag="button"
                    className="btn btn-sm indigo darken-3 text-white"
                    to="/edfutscen"
                  >
                    Go to Page
                  </MDBNavLink>
                </MDBCol>

                <MDBCol md="6" xl="4" className="p-5 mb-5">
                  <h4 className="font-weight-bold">BMR, daily, by site</h4>
                  <p className="grey-text">
                    Data from Elexon Balancing Mechanism Report B1610, output
                    per generating unit, aggregated by day
                  </p>

                  <MDBNavLink
                    tag="button"
                    className="btn btn-sm indigo darken-3 text-white"
                    to="/bmrdaysite"
                  >
                    Go to Page
                  </MDBNavLink>
                </MDBCol>
                <MDBCol md="6" xl="4" className="p-5 mb-5">
                  <h4 className="font-weight-bold">
                    BMR, half-hourly, by site
                  </h4>
                  <p className="grey-text">
                    Data from Elexon Balancing Mechanism Report B1610,
                    half-hourly output per generating unit
                  </p>
                  <MDBNavLink
                    tag="button"
                    className="btn btn-sm indigo darken-3 text-white"
                    to="/bmrhhsite"
                  >
                    Go to Page
                  </MDBNavLink>
                </MDBCol>

                <MDBCol md="6" xl="4" className="p-5 mb-5">
                  <h4 className="font-weight-bold">BMR, daily, by type</h4>
                  <p className="grey-text">
                    Data from various Elexon Balancing Mechanism Reports,
                    aggregating flows by technology/flow type and by day
                  </p>
                  <MDBNavLink
                    tag="button"
                    className="btn btn-sm indigo darken-3 text-white"
                    to="/bmrdaytype"
                  >
                    Go to Page
                  </MDBNavLink>
                </MDBCol>
                <MDBCol md="6" xl="4" className="p-5 mb-5">
                  <h4 className="font-weight-bold">
                    BMR, half-hourly, by type
                  </h4>
                  <p className="grey-text">
                    Data from various Elexon Balancing Mechanism Reports,
                    aggregating flows by technology/flow type
                  </p>
                  <MDBNavLink
                    tag="button"
                    className="btn btn-sm indigo darken-3 text-white"
                    to="/bmrhhtype"
                  >
                    Go to Page
                  </MDBNavLink>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default FrontPage;
