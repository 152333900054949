export const standardColorset = [
  "#43a047",
  "#1e88e5",
  "#673ab7",
  "#fb8c00",
  "#6d4c41",
  "#e53935",
  "#fdd835",
  "#00897b",
  "#c0ca33",
  "#00acc1",
  "#8e24aa",
  "#f4511e",
  "#757575",
  "#e91e66",
  "#ffb300",
  "#039be5",
  "#3949ab",
  "#7cb342",
  "#59698d",
];

export const darkColorset = [
  "#2e7d32",
  "#1565c0",
  "#512da8",
  "#ef6c00",
  "#4e342e",
  "#c62828",
  "#f9a825",
  "#00695c",
  "#9e9d24",
  "#00838f",
  "#6a1b9a",
  "#d84315",
  "#424242",
  "#ad1457",
  "#ff8f00",
  "#0277bd",
  "#283593",
  "#558b2f",
  "#3b465e",
];

export const lightColorset = [
  "#81c784",
  "#64b5f6",
  "#9575cd",
  "#ffb74d",
  "#a1887f",
  "#e57373",
  "#fff59d",
  "#4db6ac",
  "#dce775",
  "#4dd0e1",
  "#ba68c8",
  "#ff8a65",
  "#bdbdbd",
  "#f06292",
  "#ffd54f",
  "#81d4fa",
  "#7986cb",
  "#aed581",
  "#929fba",
];

export default {
  standardColorset,
};
