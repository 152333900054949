import React, { PureComponent } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSConvDemand extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      distlosses: this.props.distlosses,
      distlosses_changed: false,
    };
    this.itemUpdate = this.itemUpdate.bind(this);
  }

  itemUpdate(event) {
    if (typeof event.target.value !== "undefined") {
      const val = +event.target.value;
      this.setState({ distlosses: val, distlosses_changed: true });
      this.props.setDistLosses(val);
    }
  }

  render() {
    console.log("Rendering EDFSDistribution");
    //console.log("distribution", this.props.distribution);
    //console.log("state", this.state);
    return (
      <div className="row justify-content-center">
        <div className="col-auto">
          <MDBCard id="distribution_card" style={{ minWidth: "250px" }}>
            <MDBCardHeader id="distribution_header">System</MDBCardHeader>
            <MDBCardBody id="distribution_body" className="p-1">
              <div className="form-group row px-2">
                <label className="col-form-label col">
                  Losses <small className="text-muted">%</small>
                </label>
                <div className="col">
                  <input
                    type="number"
                    className={
                      "form-control form-control-sm" +
                      (this.state.distlosses_changed ? " border-danger" : "")
                    }
                    min={0}
                    max={99}
                    value={this.state.distlosses}
                    onChange={this.itemUpdate}
                  />
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    );
  }
}

export default EDFSConvDemand;
