import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCloseIcon,
  MDBPopover,
  MDBPopoverBody,
  //MDBPopoverHeader,
  //MDBBtn,
  MDBFormInline,  
  MDBInput,
  //MDBCardTitle,
  //MDBCardText,
  //MDBListGroup,
  //MDBListGroupItem,
} from "mdbreact";
import { Droppable } from "react-beautiful-dnd";
import ContentEditable from "react-contenteditable";
import { SwatchesPicker } from "react-color";
import EDProjectItem from "./EDProjectItem";
//import { threadId } from "worker_threads";

function getListStyle(isDraggingOver) {
  return {
    minHeight: 60,
    backgroundColor: isDraggingOver ? "paleturquoise" : "transparent",
  };
}

class EDProjGroupCard extends React.Component {
  constructor(props) {
    super(props);
    this.ContentEditable = React.createRef();
  }

  render = () => {
    return (
      <MDBCard key={this.props.id} className="mb-3 p-1">
        <MDBCardBody className="p-1">
          <div className="h5">
	    <MDBFormInline className="justify-content-between">
            {this.props.color && (
                <MDBInput
		  gap
                  className="mr-0"
                  //style={{ verticalAlign: "top" }}
                  name="selected"
                  type="radio"
                  id={"radio" + this.props.id}
                  label=" "
		  checked={this.props.selectedgroup === this.props.id ? true : false}
                  onClick={() => {
                    this.props.selectGroup(this.props.id);
                  }}
                />
            )}
            {!this.props.color && (
              <span
                onClick={() => {
                  this.props.moveGroup(this.props.index);
                }}
                style={{ cursor: "pointer" }}
              >
                <MDBIcon className="mr-1" icon="reply" />
              </span>
            )}
            <ContentEditable
              html={this.props.name}
              innerRef={this.ContentEditable}
              tagName="span"
              onChange={e => {
                this.props.updateName(e, this.props.index);
              }}
              onKeyDown={e => {
                //console.log("keyCode:", e.keyCode);
                if (
                  e.keyCode === 13 ||
                  (e.target.innerHTML.length > 15 &&
                    e.keyCode !== 46 &&
                    e.keyCode !== 8)
                ) {
                  //console.log("innerHTML", e.target.innerHTML);
                  e.preventDefault();
                }
              }}
            />
            {this.props.color && (
              <MDBPopover
                placement="bottom"
                popover
                clickable
                domElement
                id={"colorpo-" + this.props.id}
              >
                <span
                  className={
                    "py-0 px-1 mx-1 " +
                    this.props.mdbcolors.names[this.props.color]
                  }
                  style={{
                    cursor: "pointer",
                    border: "1px solid black",
                    borderRadius: "3px",
                  }}
                >
                  <MDBIcon icon="paint-brush" />
                </span>

                <MDBPopoverBody>
                  <SwatchesPicker
                    color={this.props.color}
                    colors={this.props.mdbcolors.palette}
                    width="270px"
                    height="200px"
                    onChangeComplete={color => {
                      this.props.setColor(this.props.index, color.hex);
                    }}
                  />
                </MDBPopoverBody>
              </MDBPopover>
            )}
	    {this.props.color && (
                <MDBCloseIcon
                  className="ml-1 mr-1"
                  onClick={() => {
                    this.props.deleteGroup(this.props.index);
                  }}
                />
	    )}
	    </MDBFormInline>
          </div>
          <Droppable droppableId={this.props.droppableId}>
            {(provided, snapshot) => (
              <div
                className="list-group"
                ref={provided.innerRef}
                /* style={
                {minHeight: 60},
                backgroundColor: snapshot.isDraggingOver
                ? "green"
                : "transparent",
                ...provided.draggableProps.style,
              } */
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.props.items.map((item, index) => (
                  <EDProjectItem
                    id={item.id}
                    key={item.id}
                    name={item.name}
                    code={item.code}
                    index={index}
                    parentIndex={this.props.index}
                    tech={this.props.id}
                    section={this.props.section}
                    deleteItem={this.props.deleteItem}
                    clickMove={this.props.clickMove}
                    //fdyupdateDisabled={this.props.updateDisabled}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </MDBCardBody>
      </MDBCard>
    );
  };
}

export default EDProjGroupCard;
