import React, { PureComponent } from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCloseIcon,
  MDBModal,
  MDBModalHeader,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBSideNavItem,
  MDBBtn,
  MDBIcon,
  MDBCollapse,
} from "mdbreact";
import moment from "moment";
import { saveAs } from "file-saver";
import C4CSLogo from "../assets/C4CS_logo_128x64.png";
import EDFSDemandStack from "../components/EDFSDemandStack";
import EDFSITSDOChart from "../components/EDFSITSDOChart";
import EDFSInflexibles from "../components/EDFSInflexibles";
import EDFSInflexImbalance from "../components/EDFSInflexImbalance";
import EDFSFlowTypeStack from "../components/EDFSFlowTypeStack";
import EDFSMeritOrderStack from "../components/EDFSMeritOrderStack";
import EDFSStorageChart from "../components/EDFSStorageChart";
import EDFSMarginChart from "../components/EDFSMarginChart";
import EDFSConvDemand from "../components/EDFSConvDemand";
import EDFSHeatDemand from "../components/EDFSHeatDemand";
import EDFSHeatSupply from "../components/EDFSHeatSupply";
import EDFSTrnsptDemand from "../components/EDFSTrnsptDemand";
import EDFSDistribution from "../components/EDFSDistribution";
import EDFSGeneration from "../components/EDFSGeneration";
import EDFSStorage from "../components/EDFSStorage";
import EDFSInterconnection from "../components/EDFSInterconnection";
import EDFSDownload from "../components/EDFSDownload";
import EDFSInstructions from "../components/EDFSInstructions";
import EDFSUtilisation from "../components/EDFSUtilisation";
import EDFSUtilisationMarginal from "../components/EDFSUtilisationMarginal";
import EDFSCostInputs from "../components/EDFSCostInputs";
import EDFSInvestmentChart from "../components/EDFSInvestmentChart";
import EDFSGenCostHourly from "../components/EDFSGenCostHourly";
import EDFSGenCostMarginal from "../components/EDFSGenCostMarginal";
import EDFSGenTechPrices from "../components/EDFSGenTechPrices";
import EDFSTotalCost from "../components/EDFSTotalCost";
import EDFSStorageElecCostChart from "../components/EDFSStorageElecCostChart";
import EDFSCarbonTotal from "../components/EDFSCarbonTotal";
import EDFSCarbonElectricity from "../components/EDFSCarbonElectricity";
import EDFSCarbonInfrastructure from "../components/EDFSCarbonInfrastructure";
import EDFSCarbonHeat from "../components/EDFSCarbonHeat";
import EDFSCarbonTransport from "../components/EDFSCarbonTransport";

class EDFutureScenarios extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showInstructions: false,
      showSideNav: false,
      scrollTo: "",
      infrSources: false,
      carbonNotIncluded: false,
      recalcDate: 0,
      inputDemandElec: true,
      inputDemandHeat: false,
      inputSupplyHeat: false,
      inputDemandTrans: false,
      inputTransDist: false,
      inputGeneration: false,
      inputStorage: false,
      inputInterconnectors: false,
      inputCost: false,
      energyDemandElec: true,
      energyITSDO: false,
      energyInflexibles: false,
      energyNetDemand: false,
      energySupplyByType: false,
      energySupplyBySource: false,
      energyStorage: false,
      energyAdequacy: false,
      energyUtilisation: false,
      carbonTotal: true,
      carbonElectricity: false,
      carbonInfrastructure: false,
      carbonHeat: false,
      carbonTransport: false,
      costMWh: true,
      costCombined: false,
      costInvestment: false,
      costHourly: false,
      costMarginalCost: false,
      costStorage: false,
      activeTab: "1",
      activeTab2: "a",
      activeTab3: "a",
      printLayout: false,
      year: 2017,
      weather: "normal",
      heatUseRev: 0,
      heatFuelRev: 0,
      lightingData: [],
      airconData: [],
      resistanceData: [],
      ashpData: [],
      gshpData: [],
      roadData: [],
      railData: [],
      wholesaleElecDemandData: [],
      onshorewindData: [],
      offshorewindData: [],
      nuclearData: [],
      solarData: [],
      //hydroData: [],
      biogasData: [],
      inflexiblesData: [],
      inflexImbalanceData: [],
      flexiblesData: {},
      costData: [],
      storElecCostData: {},
      icElecCostData: {},
      aggData: {},
      initAggData: {},
      //interconnectorData: {},
    };

    this.generation = [
      {
        id: "solar",
        tech: "Solar",
        //MWhpa: 0,
        current: {
          capacity: 13000,
          availFactor: 9,
          // loadFactor: 9,
          capitalCost: 1300, // 1500 for small, < 1000 for big, avg. 1300?
          fixedCost: 10, // Inspection/testing/service every 2 years, cleaning every year
          // https://www.spiritenergy.co.uk/solar-panel-service
          // Others suggest 1% of cap. cost p.a.
          variableCost: 0.05,
        },
        new: {
          capacity: 0,
          availFactor: 9,
          // loadFactor: 9,
          capitalCost: 1000, // Lower than current but new not depreciated value
          fixedCost: 10,
          variableCost: 0.05,
        },
      },
      {
        id: "biogas",
        tech: "Biogas",
        //MWhpa: 0,
        current: {
          capacity: 1000,
          availFactor: 70,
          // loadFactor: 70,
          capitalCost: 4000, // £14m/3.5MW = 4000
          fixedCost: 300, // Labour, maintenance, connection, rent/rates, overheads, etc.
          variableCost: -10, // Gate fee
        },
        new: {
          capacity: 0,
          availFactor: 70,
          // loadFactor: 70,
          capitalCost: 3500, // Assume some falling costs, but new not depreciated
          fixedCost: 300,
          variableCost: -10,
        },
      },
      {
        id: "nuclear",
        tech: "Nuclear",
        //MWhpa: 0,
        current: {
          capacity: 9400,
          availFactor: 93,
          // loadFactor: 93,
          capitalCost: 1300, // DA on 9.4 GW = 850m/yr @ 7% WACC = 12.14bn / 9.4 GW = 1291
          // See Consolidated Segmental Statements for EDF and Centrica
          fixedCost: 150,
          variableCost: 11,
          // CSS: Fuel = 8.5/MWh. Other variable costs (e.g. transport/waste) rounds up.
          // 150 (fixed) + 11 (var) works out to direct + indirect costs in CSS
        },
        new: {
          capacity: 0,
          availFactor: 95,
          // loadFactor: 95,
          capitalCost: 6000, // Rough (current) cost per MW for Hinckley C and Flamanville
          fixedCost: 140, // Assume will be marginally more cost-effective than current
          variableCost: 10, // Fuel costs similar, but other costs maybe slightly lower
        },
      },
      {
        id: "onshorewind",
        tech: "Onshore Wind",
        //MWhpa: 0,
        current: {
          capacity: 8000,
          availFactor: 28,
          // loadFactor: 26,
          capitalCost: 1500,
          fixedCost: 30,
          variableCost: 0.05,
        },
        new: {
          capacity: 0,
          availFactor: 35,
          // loadFactor: 33,
          capitalCost: 1250,
          fixedCost: 25,
          variableCost: 0.05,
        },
      },
      {
        id: "offshorewind",
        tech: "Offshore Wind",
        //MWhpa: 0,
        current: {
          capacity: 5000,
          availFactor: 39,
          // loadFactor: 39,
          capitalCost: 3600,
          // https://www.sciencedirect.com/science/article/pii/S030142151930206X
          fixedCost: 100,
          variableCost: 0.05,
        },
        new: {
          capacity: 0,
          availFactor: 48,
          // loadFactor: 45,
          capitalCost: 3200,
          fixedCost: 65,
          // https://analysis.newenergyupdate.com/wind-energy-update/offshore-wind-analytics-cut-opex-less-quarter-costs
          variableCost: 0.05,
        },
      },
      {
        id: "biomass",
        tech: "Biomass",
        //MWhpa: 0,
        current: {
          capacity: 4000,
          availFactor: 88,
          // loadFactor: 88,
          capitalCost: 200,
          // https://www.drax.com/wp-content/uploads/2017/12/Future-biomass-renewables-portfolio-Poyry-Drax.pdf
          fixedCost: 40, // Assuming fixed cost lower per MW for Drax than for new
          variableCost: 90, // get pellets in @ c.120/t = 25/MWh, convert @ 32% effic
          // = 78, rounded to 90 for other variable costs
        },
        new: {
          capacity: 0,
          availFactor: 90,
          // loadFactor: 90,
          capitalCost: 3500,
          // http://www.glennmont.com/wp-content/uploads/2016/07/20140225-Glennmont-Fund-II-Port-Clarence-ES-UK-Biomass-Non-Technical-Summary.pdf
          // https://www.wbdg.org/resources/biomass-electricity-generation
          fixedCost: 60, // Assuming fixed (ex. fuel) cost might be ->2% of capital cost
          variableCost: 110, // Assume fuel will have to be imported for significant
          // expansion of capacity, so at least as much as Drax cost, but
          // lower efficiency because of scale
        },
      },
      {
        id: "hydro",
        tech: "Hydro",
        //MWhpa: 0,
        current: {
          capacity: 1600,
          availFactor: 37,
          // loadFactor: 37,
          capitalCost: 100,
          fixedCost: 5,
          variableCost: 0.05,
        },
        new: {
          capacity: 0,
          availFactor: 45,
          // loadFactor: 45,
          capitalCost: 3200, // https://www.renewablesfirst.co.uk/hydropower/hydropower-learning-centre/how-much-do-hydropower-systems-cost-to-build/
          fixedCost: 5,
          variableCost: 0.05,
        },
      },
      /***
       *
       * https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/566567/BEIS_Electricity_Generation_Cost_Report.pdf
       *
       ***/
      // https://www.e-education.psu.edu/ebf483/node/583
      {
        id: "gas",
        tech: "Gas",
        //MWhpa: 0,
        current: {
          capacity: 34000,
          availFactor: 88,
          // loadFactor: 88,
          capitalCost: 500,
          fixedCost: 17,
          variableCost: 45,
        },
        new: {
          capacity: 0,
          availFactor: 90,
          // loadFactor: 90,
          capitalCost: 350,
          fixedCost: 10,
          variableCost: 65,
        },
      },
      {
        id: "coal",
        tech: "Coal",
        //MWhpa: 0,
        current: {
          capacity: 15000,
          availFactor: 80,
          // loadFactor: 10,
          capitalCost: 300,
          // Highly depreciated
          fixedCost: 10,
          variableCost: 25,
        },
        new: {
          capacity: 0,
          availFactor: 90,
          // loadFactor: 10,
          capitalCost: 1000,
          // https://assets.publishing.service.gov.uk/media/559fb55940f0b6156400003d/Appendix_4.2_Generation_return_on_capital_employed.pdf
          fixedCost: 10,
          variableCost: 25,
        },
      },
      {
        id: "oil",
        tech: "Oil",
        //MWhpa: 0,
        current: {
          capacity: 1000,
          availFactor: 80,
          // loadFactor: 10,
          capitalCost: 300,
          fixedCost: 10,
          variableCost: 135,
        },
        new: {
          capacity: 0,
          availFactor: 90,
          // loadFactor: 10,
          capitalCost: 300,
          fixedCost: 10,
          variableCost: 135,
        },
      },
    ];
    //this.init.generation = JSON.parse(JSON.stringify(this.generation));
    /*this.generation.forEach(item => {
      item.current.initCapacity = item.current.capacity;
    });*/

    this.transmission = {
      elec: {
        distlosses: 8,
        current: {
          capacity: 60000,
          capitalCost: 450, // £m/GW
          fixedCost: 100, // £m/GW/year
          variableCost: 5, // £/MWh
        },
        new: {
          capacity: 0,
          capitalCost: 1000,
          fixedCost: 150,
          variableCost: 6,
        },
      },
      gas: {
        current: {
          capacity: 0,
          capitalCost: 0,
          fixedCost: 0,
          variableCost: 0,
        },
        new: {
          capacity: 0,
          capitalCost: 0,
          fixedCost: 0,
          variableCost: 0,
        },
      },
    };

    //this.distlosses = 8;
    this.maxWholesaleElecDemand = 0;
    this.totalWholesaleElecDemand = 0;
    this.totalRetailElecDemand = 0;
    //this.initMaxWholesaleElecDemand = 0;

    this.supply = {
      elec: {
        capitalCost: 4000, // £m
        fixedCost: 1500, // £m p.a.
        variableCost: 10, // £/MWh
      },
    };

    this.storage = [
      {
        id: "pumped",
        tech: "Pumped Storage",
        current: {
          capacity: 15000,
          power: 2800,
          efficiency: 75,
          balance: 7500,
          capitalCostkW: 15,
          capitalCostkWh: 15,
          fixedCost: 10,
          variableCost: 0.1,
        },
        new: {
          capacity: 0,
          power: 0,
          efficiency: 77,
          balance: 0,
          capitalCostkW: 160, // 1000 if only measured /kW. Tend to be multiple kWh/kW
          capitalCostkWh: 160,
          // http://2050-calculator-tool-wiki.decc.gov.uk/cost_categories/60
          // https://bravenewclimate.com/2010/04/05/pumped-hydro-system-cost/
          fixedCost: 10, // /kW power p.a. (not /kWh capacity p.a.)
          variableCost: 0.05, // /MWh charged/discharged
        },
      },
      {
        id: "compressedAir",
        tech: "Compressed Air",
        current: {
          capacity: 0,
          power: 0,
          efficiency: 60,
          balance: 0,
          capitalCostkW: 1500, //30,
          capitalCostkWh: 40, //6,
          fixedCost: 2,
          variableCost: 0.1,
        },
        new: {
          capacity: 0,
          power: 0,
          efficiency: 65,
          balance: 0,
          capitalCostkW: 1000, // Guessing 1/3 reduction from current
          capitalCostkWh: 30, // Guessing 1/4 reduction from current
          fixedCost: 1, // /kW power p.a. (not /kWh capacity p.a.)
          variableCost: 0.05, // /MWh charged/discharged
        },
        // Project announcement: 250 MWh / 50 MW scheme costs £85m
        // https://www.highviewpower.com/wp-content/uploads/2019/11/ESA-storage-exchange-poster-2019-2.pdf gives $50/kWh as marginal cost
        // of storage. So £40/kWh. 250 MWh = £10,000,000
        // So generating/charging capacity costs £75m, i.e. £1,500/kW
      },
      {
        id: "batteries",
        tech: "Batteries",
        current: {
          capacity: 1300,
          power: 700,
          efficiency: 80,
          balance: 650,
          capitalCostkW: 300,
          capitalCostkWh: 300,
          fixedCost: 10,
          variableCost: 0.05,
        },
        new: {
          capacity: 0,
          power: 0,
          efficiency: 90,
          balance: 0,
          capitalCostkW: 240, // /kW power over lifetime @ WACC
          capitalCostkWh: 240, // /kWh capacity over lifetime @ WACC
          fixedCost: 5, // /kW power p.a.
          variableCost: 0.05, // /MWh charged/discharged
          // https://www.windpowermonthly.com/article/1523664/energy-storage-picks-pace-costs-fall "For peaker replacement, lithium ion battery-based storage costs are estimated at $268/MWh for 2018. For distribution they are estimated to be $261/MWh for 2018 (see charts, below). For peak replacement applications, capital cost of lithium ion battery-based storage costs estimated for 2018 are $291/kWh."
          // https://www.irena.org/-/media/Files/IRENA/Agency/Publication/2017/Oct/IRENA_Electricity_Storage_Costs_2017_Summary.pdf
          // https://www.nrel.gov/docs/fy19osti/71714.pdf
        },
      },
    ];
    /*this.storage.forEach(item => {
      item.current.initCapacity = item.current.capacity;
      item.current.initpower = item.current.power;
    });*/

    this.interconnection = [
      {
        id: "intfr",
        tech: "France",
        capacity: 2050,
      },
      {
        id: "intew",
        tech: "Ireland",
        capacity: 560,
      },
      {
        id: "intned",
        tech: "Netherlands",
        capacity: 1100,
      },
      {
        id: "intirl",
        tech: "Northern Ireland",
        capacity: 550,
      },
      {
        id: "intnem",
        tech: "Belgium",
        capacity: 1800,
      },
    ];
    /*this.interconnection.forEach(item => {
      item.initCapacity = item.capacity;
    });*/

    this.convdemand = [
      {
        id: "lighting",
        tech: "Lighting/Equipment",
        elec: 184,
      },
      {
        id: "aircon",
        tech: "Air conditioning",
        elec: 5,
        // Some reports that it's 30 TWh, but that gives improbable
        // hourly consumption during hotter periods, given that there are
        // probably only a few hundred heavy cooling hours a year in UK.
      },
    ];

    this.heatdemand = {
      homes: {
        existing: {
          cavityWall: {
            insulated: 13.8,
            notInsulatedEasy: 4.0,
            notInsulatedHard: 1.3,
            total: 19.1,
            saving: 0.075,
            easyCost: 500,
            hardCost: 2000,
          },
          solidWall: {
            insulated: 0.8,
            notInsulated: 7.7,
            total: 8.5,
            saving: 0.132,
            cost: 12000,
          },
          unknownWall: 0.8,
          loft: {
            insulatedPlus: 16.2,
            insulatedMinusEasy: 5.8,
            insulatedMinusHard: 2.3,
            notInsulated: 0.2,
            total: 24.5,
            saving: 0.039,
            easyCost: 250,
            hardCost: 500,
            fullCost: 400,
          },
          doubleGlazing: {
            fullPC: 85,
            partialPC: 7.5,
            nonePC: 7.5,
            fullSaving: 0.15,
            partialSaving: 0.075,
            fullCost: 5000,
            partialCost: 2500,
          },
          avgUninsulatedMWh: 12,
          flatsPC: 14,
          housesPC: 86,
          totals: {
            spaceHeating: 271.6,
            hotWater: 74.8,
            cooking: 12.0,
            heat: 358.4,
          },
        },
        new: {
          houses: {
            mnBuilt: 0.1,
            feeLevel: 3,
            size: 92,
            baseCostPerM2: 1600,
            spaceHeating: 0.552,
            hotWater: 0.29,
            cooking: 0.05,
            heat: 0.892,
          },
          flats: {
            mnBuilt: 0.1,
            feeLevel: 3,
            size: 57,
            baseCostPerM2: 2200,
            spaceHeating: 0.2736,
            hotWater: 0.2,
            cooking: 0.035,
            heat: 0.5086,
          },
        },
        houses: {
          hotWater: 2.7,
          cooking: 0.42,
        },
        flats: {
          hotWater: 1.84,
          cooking: 0.3,
        },
      },
      nondom: {
        services: {
          spaceHeating: 97.9,
          hotWater: 14.5,
          cooking: 23.0,
        },
        industry: {
          spaceHeating: 19.6,
          processHigh: 36.3,
          processLow: 58.4,
          drying: 18.6,
          other: 22.5,
        },
      },
    };

    this.feePoints = {
      houses: {
        3: 60,
        4: 55,
        5: 52,
        6: 49,
        7: 46,
        8: 42,
        9: 38,
      },
      flats: {
        3: 48,
        4: 45,
        5: 43,
        6: 41,
        7: 39,
        8: 35,
        9: 32,
      },
      eo_cc: {
        3: 0,
        4: 70,
        5: 140,
        6: 210,
        7: 280,
        8: 350,
        9: 420,
      },
    };

    this.heatsupply = [
      {
        id: "resistance",
        tech: "Direct Electric",
        heatshare: 12.03,
        heat: 78.4,
        spaceheat: 40,
        elec: 80.8,
        efficiency: 97,
        loadFactor: 25, // %. To convert MWh to MW. Industrial use higher than 25, domestic lower
        capacity: 35799, // MW
        capitalCost: 80, // £/kW inc. installation cost
        fixedCost: 10, // £/kW/year
        fuelCost: 100, // £/MWh[e]. A lot on off-peak tariffs. Some non-dom
        variableCost: 5, // £/MWh[th]
      },
      {
        id: "ashp",
        tech: "Air-Source Heat Pumps",
        heatshare: 2.46,
        heat: 16.0,
        elec: 6.0,
        efficiency: this.ashpBaseSPF,
        loadFactor: 18, // %.
        capacity: 10147, // MW
        capitalCost: 300, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 150, // £/MWh[e]. Not much off-peak or non-dom.
        variableCost: 5, // £/MWh[th].
      },
      {
        id: "gshp",
        tech: "Ground-Source Heat Pumps",
        heatshare: 0.18,
        heat: 1.2,
        elec: 0.4,
        efficiency: this.gshpBaseSPF,
        loadFactor: 20, // %.
        capacity: 685, // MW
        capitalCost: 600, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 130, // £/MWh[e]. Some off-peak. Some non-dom.
        variableCost: 5, // £/MWh[th].
      },
      {
        id: "solarthermal",
        tech: "Solar Thermal",
        heatshare: 0.09,
        heat: 0.6,
        elec: 0.03,
        efficiency: 2000, // the inverse of 5% as a percentage.
        // 5% is electricity consumption / heat supplied
        loadFactor: 10, // %.
        capacity: 685, // MW
        capitalCost: 1200, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 150, // £/MWh[e].
        variableCost: 5, // £/MWh[th].
      },
      {
        id: "biomassHeat",
        tech: "Biomass Boilers",
        heatshare: 2.73,
        heat: 17.8,
        fuel: 22.8,
        efficiency: 78,
        loadFactor: 20, // %.
        capacity: 10160, // MW
        capitalCost: 700, // £/kW
        fixedCost: 15, // £/kW/year
        fuelCost: 35, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
      {
        id: "woodfires",
        tech: "Wood fires/stoves",
        heatshare: 1.8,
        spaceheat: 100,
        heat: 11.7,
        fuel: 26.0,
        efficiency: 45,
        loadFactor: 9.5, // %.
        capacity: 14059, // MW
        capitalCost: 400, // £/kW
        fixedCost: 40, // £/kW/year
        fuelCost: 35, // £/MWh[fuel].
        variableCost: 5, // £/MWh[th].
      },
      {
        id: "biomassCHP",
        tech: "Biomass CHP",
        heatshare: 0.09,
        heat: 0.6,
        fuel: 1.0,
        efficiency: 60,
        loadFactor: 60, // %.
        capacity: 114, // MW[th]
        capitalCost: 1200, // £/kW
        fixedCost: 20, // £/kW/year
        fuelCost: 35, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
        elecExport: 0.2,
        elecEfficiency: 20,
      },
      {
        id: "biogasAD",
        tech: "Biogas (AD)",
        heatshare: 0.73,
        heat: 4.8,
        fuel: 8.0,
        efficiency: 60,
        loadFactor: 90, // %.
        capacity: 609, // MW[th]
        capitalCost: 1900, // £/kW
        fixedCost: 100, // £/kW/year
        fuelCost: -10, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
      {
        id: "biogasification",
        tech: "Biogas (Gasification)",
        heatshare: 0.01,
        heat: 0.07,
        fuel: 0.08,
        efficiency: 90,
        loadFactor: 80, // %.
        capacity: 10, // MW[th]
        capitalCost: 2000, // £/kW
        fixedCost: 100, // £/kW/year
        fuelCost: 20, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
      {
        id: "bioliquids",
        tech: "Bioliquids",
        heatshare: 0.01,
        heat: 0.07,
        fuel: 0.08,
        efficiency: 90,
        loadFactor: 20, // %.
        capacity: 40, // MW[th]
        capitalCost: 400, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 100, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
      {
        id: "coal",
        tech: "Coal",
        heatshare: 2.39,
        heat: 15.6,
        fuel: 19.5,
        efficiency: 80,
        loadFactor: 18, // %.
        capacity: 9893, // MW[th]
        capitalCost: 350, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 20, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
      {
        id: "oil",
        tech: "Oil",
        heatshare: 8.96,
        spaceheat: 66,
        heat: 58.4,
        fuel: 68.7,
        efficiency: 85,
        loadFactor: 18, // %.
        capacity: 37037, // MW[th]
        capitalCost: 400, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 40, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
      {
        id: "gas",
        tech: "Gas",
        heatshare: 68.52,
        spaceheat: 66,
        heat: 446.3,
        fuel: 485.3,
        efficiency: 92,
        loadFactor: 18, // %.
        capacity: 283042, // MW[th]
        capitalCost: 250, // £/kW
        fixedCost: 10, // £/kW/year
        fuelCost: 40, // £/MWh[fuel].
        variableCost: 10, // £/MWh[th].
      },
    ];

    this.totalheat = 649.2;
    this.buildingheat = 513.4;
    this.spaceheat = 389.1;

    this.trnsptdemand = [
      {
        id: "road",
        tech: "Road",
        total: 482,
        elec: 0.2,
        fuelefficiency: 20,
        elecefficiency: 68, // 90% on road, but 20-30% losses on recharging
        fuelCost: 40, // £/MWh = 40p/litre. V cheap because it deliberately doesn't include tax
        networkMaintCost: 40, // £m/TWh of work, not fuel, i.e. after efficiency calcs to get output energy
        networkCapitalCost: 128000, // £m, i.e. £9bn p.a. at 7% WACC. Road spend is £10bn, but
        // extra is being spent 2020-5, so annual cost prob c. £14bn now
        iceVehicleCapitalCost: 25000,
        elecVehicleCapitalCost: 35000,
        avgAnnualOutput: 2.65, // MWh of work p.a., i.e. after efficiency calcs to get output
        // 7,500 miles p.a. at 38 mpg at 20% efficiency = 1,800 kWh of work p.a. But
        // that implies 55m road vehicles. There are only 37.5m so pro-rata the value.
        // Discrepancy presumably accounted for by trucks and vans, higher usage and fuel consumption
        vehicleMaintCost: 360, // p.a./vehicle, excludes insurance, which would double it, and
        // minor costs like cleaning, parking, fines, etc. Also excludes tax.
        // https://www.thecarexpert.co.uk/average-car-costs-more-than-160-per-month-to-run/
      },
      {
        id: "rail",
        tech: "Rail",
        total: 12.4,
        elec: 4.6,
        fuelefficiency: 70,
        elecefficiency: 90,
        fuelCost: 40,
        // https://www.gov.uk/government/publications/cost-of-running-the-rail-network
        // https://www.transport-network.co.uk/Passengers-take-on-more-of-the-costs-of-running-rail/14754
        // https://www.businessinsider.com/network-rail-to-be-valued-at-100-billion-more-because-of-new-accounting-2016-4?r=US&IR=T
        networkMaintCost: 200, // £m/TWh of work, i.e. after efficiency calcs to get output
        networkCapitalCost: 60000, // £m, i.e. £4.2bn p.a. @ 7% WACC
        operatorCost: 550, // £m/TWh of work. Network operator costs (esc govt charges) + freight + HS
      },
      {
        id: "air",
        tech: "Air",
        total: 151,
        elec: 0,
        fuelefficiency: 60,
        elecefficiency: 90,
        fuelCost: 40,
        // https://www.building.co.uk/infrastructure-costs-airports/5038824.article
        // https://www.heathrow.com/company/about-heathrow/economic-regulation/capital-expenditure
        // Guessing Heathrow is around 25% of UK capital valuation, given LHR, LGW & STN
        // are said to be 60%. RAB of £13.5bn (falling) for LHR, suggests c. £50bn for all
        // https://publicapps.caa.co.uk/docs/33/1563c_H7_Opex_efficiency_report_by_CEPA.pdf
        networkMaintCost: 16, // £m/TWh of work. £4bn/yr, assuming LHR is c. 25%.
        networkCapitalCost: 50000, // £m
        operatorCost: 40, // £m/TWh work. EasyJet is c.£1bn/yr of fuel and £3.5bn of other costs. Assuming
        // large proportion is outside UK, but EasyJet is only minority of UK market,
        // maybe £10bn for all airline operating costs, exc. fuel & taxes.
        // https://corporate.easyjet.com/~/media/Files/E/Easyjet/pdf/investors/results-centre/2018/2018-annual-report-and-accounts.pdf
      },
      {
        id: "water",
        tech: "Water",
        total: 10.8,
        elec: 0,
        fuelefficiency: 40,
        elecefficiency: 90,
        fuelCost: 40,
        // https://en.wikipedia.org/wiki/British_Waterways
        // https://www.abports.co.uk/investor-relations/reports-results-and-presentations/
        // https://www.maritimeuk.org/value-2017/
        // ABP cost of sales = £131m. Maybe £500m for op costs for all ports & canals?
        networkMaintCost: 18.5, // £m/TWh of work
        networkCapitalCost: 8700, // £700m for canals. ABP is c.£3.8bn, so maybe £8bn for all ports?
        operatorCost: 370, // £m/TWh of work. c.£10bn of annual value?
      },
    ];

    this.totals = {
      elec: {},
      heat: {},
      transport: {},
    };

    /*const inputStatus = this.resetInputStatus();
    for (let type in inputStatus) {
      this.state[type] = inputStatus[type];
    }*/

    /* this.profiles = {
      elecdemand: [],
      heatsupply: { normal: [] },
      temperatures: { normal: [] },
      onshorewind: { current: [] },
      offshorewind: { current: [] },
      solar: { current: [] },
      nuclear: { current: [] },
      hydro: { current: [] },
      biogas: { current: [] },
    }; */

    this.demandprofiles = {
      2016: { normal: [] },
      2017: { normal: [] },
      2018: { normal: [] },
    };

    this.generationprofiles = {
      2016: { current: [] },
      2017: { current: [] },
      2018: { current: [] },
    };

    this.icprofiles = {
      2016: [],
      2017: [],
      2018: [],
    };

    this.elecConvEffic = {
      gas: 0.5, // Assume CCGT
      oil: 0.4,
      coal: 0.35,
      biomass: 0.3,
      biogas: 0.4,
    };

    this.CO2e = {
      // tonnes/MWh input BEIS/DEFRA GHG Conversion Factors
      fuel: {
        gas: 0.2,
        oil: 0.25,
        coal: 0.33,
        biomass: 0.015,
        biomassHeat: 0.015,
        biomassCHP: 0.015,
        woodfires: 0.015,
        biogas: 0.0002,
        biogasAD: 0.0002,
        biogasification: 0.0002,
        bioliquids: 0.0035,
        hydro: 0,
        onshorewind: 0,
        offshorewind: 0,
        solar: 0,
        solarthermal: 0,
        nuclear: 0,
      },
      // tonnes/MWh output inc. construction/decommissioning and indirect impacts
      // https://en.wikipedia.org/wiki/Life-cycle_greenhouse-gas_emissions_of_energy_sources
      lca: {
        gas: 0.47,
        oil: 0.78,
        coal: 0.9,
        biomass: 0.018, // IPCC 2014: 0.23, applying high CO2 factor for biomass combustion
        // https://radar.brookes.ac.uk/radar/file/cbbbce38-a0a5-4a95-ba2a-f472a186c693/1/fulltext.pdf also gives high LCA because imported biomass required and has high embodied carbon (manufacture and transport) compounded by low conversion efficiency.
        biogas: 0.011,
        hydro: 0.024,
        onshorewind: 0.012,
        offshorewind: 0.014,
        solar: 0.045,
        nuclear: 0.014,
        pumped: 0.001,
        // http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.468.9733&rep=rep1&type=pdf
        // https://www.eurekalert.org/pub_releases/2013-03/su-ssc030813.php
        batteries: 0.041,
        // https://www.bren.ucsb.edu/research/2018Group_Projects/documents/SolarStash_FinalGPReportredacted.pdf
        // https://link.springer.com/article/10.1007/s40095-017-0237-5 Missing actual figure?
        compressedAir: 0.0001,
      },
      // Years, for spreading CO2 costs of construction
      // Hydro and nuclear have long lives. Combustion techs may last longer but
      // 25 is conservative. Wind tends to be estimated on 20 years, whereas
      // solar industry uses 30, even though panels will degrade significantly
      lifespan: {
        gas: 25,
        oil: 25,
        coal: 25,
        biomass: 25,
        biogas: 25,
        hydro: 30,
        onshorewind: 20,
        offshorewind: 20,
        solar: 30,
        nuclear: 30,
        pumped: 30,
        batteries: 15,
        compressedAir: 30,
      },
      // tonnes/MWh, upstream (fuel-production/operation, etc)
      // exc. construction/decommissioning
      upstream: {
        gas: 0.0085, // https://festkoerper-kernphysik.de/Weissbach_EROI_preprint.pdf
        oil: 0.007, // Assumed similar to gas, as dominated by fuel extraction,
        // but slightly better because of simpler/cheaper processing/movement and lower losses
        coal: 0.025,
        // https://reader.elsevier.com/reader/sd/pii/S0196890414004415
        biomass: 0.025, // Assume similar to coal
        biogas: 0.0085, // Similar to gas, assuming embodied carbon in fuel is
        // encapsulated in fuel value above.
        hydro: 0.0019, // https://festkoerper-kernphysik.de/Weissbach_EROI_preprint.pdf
        onshorewind: 0, // negligible, https://festkoerper-kernphysik.de/Weissbach_EROI_preprint.pdf
        offshorewind: 0, // negligible
        solar: 0, // negligible
        nuclear: 0.0033,
        // https://www.world-nuclear.org/information-library/energy-and-the-environment/energy-return-on-investment.aspx.
        // See below for construction figure.
        // Upstream non-construction = 14.5 PJ input/GW capacity, i.e. 4 TWh
        // @ 0.25t/MWh = 1m tCO2. 300 TWh output over 40 years means 0.033 tCO2/MWh
        pumped: 0, // negligible?
        batteries: 0, // negligible?
        compressedAir: 0,
      },
      // tonnes/MW for generation, tonnes/MWh for storage
      // Construction & Decommissioning, excl. fuel-production & operation
      construction: {
        gas: 45,
        // https://festkoerper-kernphysik.de/Weissbach_EROI_preprint.pdf
        oil: 35, // assumed cheaper than CCGT because used in simple, lower-efficiency OCGT or reciprocating engines
        coal: 380,
        // V. Good: Table 1 @ https://reader.elsevier.com/reader/sd/pii/S0196890414004415
        // Assumed CO2 emissions per MWh of energy input at around 0.25t/MWh
        // Seems to be very high compared to gas and oil because of amount of concrete etc.
        biomass: 380, // Assume similar to coal, as uses similar technology
        biogas: 35, // Assumed cheaper than CCGT, because used in simple, lower-efficiency OCGT or reciprocating engines
        hydro: 500,
        // http://esu-services.ch/fileadmin/download/publicLCI/flury-2012-hydroelectric-power-generation.pdf
        // See pumped below. Barrier hydro marginally better than pumped.
        // But measured in MWh, not MW. 1 MW = 3000-3500 MWh/yr. 1MWh storage = 240 MWh/yr
        // Also: widely-agreed EROEI of over 50, @ 40yr lifespan & 3,500 MWh/MW/yr
        onshorewind: 730, // 14kgCO2e/kWh over 20yr lifetime @ 2,600MWh/MW/yr
        // https://www.nrel.gov/docs/fy12osti/52409-1.pdf / https://energy.utexas.edu/news/nuclear-and-wind-power-estimated-have-lowest-levelized-co2-emissions
        // 350: https://www.omicsonline.org/open-access/life-cycle-analysis-of-the-embodied-carbon-emissions-from-14-wind-turbines-with-rated-powers-between-50-kw-and-34-mw-2090-4541-1000211.php?aid=74577
        offshorewind: 840, // 12kgCO2e/kWh over 20yr lifetime @ 3,500MWh/MW/yr
        // https://www.climatexchange.org.uk/media/1461/main_report_-_life_cycle_costs_and_carbon_emissions_of_offshore_wind_power.pdf
        solar: 1700, // 60kgCO2e/kWh over 30yr life @ 950MWh/MW/yr
        // https://www.nrel.gov/docs/fy12osti/52409-1.pdf / https://energy.utexas.edu/news/nuclear-and-wind-power-estimated-have-lowest-levelized-co2-emissions
        // 7,500: https://www.nrel.gov/docs/fy13osti/56487.pdf
        nuclear: 1250, // tonnes/MW = 12kgCO2e/kWh over 30yr life @ 7,800MWh/MW/yr
        // = 2800, but includes large chunk for fuel-production etc, so round down to 2,250
        // https://www.nrel.gov/docs/fy12osti/52409-1.pdf / https://energy.utexas.edu/news/nuclear-and-wind-power-estimated-have-lowest-levelized-co2-emissions
        // https://www.world-nuclear.org/information-library/energy-and-the-environment/energy-return-on-investment.aspx and https://festkoerper-kernphysik.de/Weissbach_EROI_preprint.pdf say 3.9 PJ for constr/decom 1GW but footnotes indicate more like 18 PJ conservatively.
        // 3.9 makes nuclear less energy-intensive to construct than fossil-fuel generation!
        // 18 PJ = 5 TWh, i.e. 1 MW cap = 5 GWh energy input. 5 GWh @ 0.25 t/MWh = 1250tCO2e.
        pumped: 40,
        // http://esu-services.ch/fileadmin/download/publicLCI/flury-2012-hydroelectric-power-generation.pdf
        // Infrastructure = 1.1% of 4.22 MJ/kWh CED. = 0.01289 MWh in / MWh out.
        // 50yr lifespan, 300 cycles @ 80% efficiency = 12,000 MWh / MWh cap.
        // = 155 MWh CED for infrastructure. @0.25tCO2e/MWh = 39t/MWh cap.
        // ESOEI > 200. Almost daily cycles. >30yr lifespan. 70-80% conv. effic.
        // Also: https://www.eurekalert.org/pub_releases/2013-03/su-ssc030813.php
        batteries: 40, // tonne/MWh
        // https://pubs.acs.org/doi/suppl/10.1021/es903729a/suppl_file/es903729a_si_001.pdf
        // Table S21. 6kgCO2e/kg of Li-Ion battery. 1kg battery stores 0.15 kWh.
        compressedAir: 20,
        interconnection: 10, // Couldn't find data, but similar to transmission,
        // presumably less because undersea needs less steel and no distribution network
        transmission: 21, // Ofgem study said 675000 tCO2e for 32 GW network capacity
        // https://www.ofgem.gov.uk/sites/default/files/docs/2013/10/130517_-_capacity_to_customers_project_-_full_submission_vs_03_0.pdf
      },
    };

    this.techNames = {
      gas: "Gas",
      oil: "Oil",
      coal: "Coal",
      biomass: "Biomass (solid)",
      biogas: "Biogas",
      hydro: "Hydro",
      onshorewind: "Onshore wind",
      offshorewind: "Offshore wind",
      solar: "Solar",
      nuclear: "Nuclear",
      pumped: "Pumped storage",
      batteries: "Batteries",
      compressedAir: "Compressed air",
      interconnection: "Interconnection",
      transmission: "Transmission/ distribution",
    };

    this.wacc = 7;
    this.carbonCost = 50;
    this.demandShedCost = 0;

    this.heatUseHourly = {};
    this.heatFuelHourly = {};

    this.iidAverages = [];

    this.sections = [
      "inputDemandElec",
      "inputDemandHeat",
      "inputDemandTrans",
      "inputTransDist",
      "inputGeneration",
      "inputStorage",
      "inputInterconnectors",
      "inputCost",
      "inputSupplyHeat",
      "energyDemandElec",
      "energyITSDO",
      "energyInflexibles",
      "energyNetDemand",
      "energySupplyByType",
      "energySupplyBySource",
      "energyStorage",
      "energyAdequacy",
      "energyUtilisation",
      "carbonTotal",
      "carbonElectricity",
      "carbonInfrastructure",
      "carbonHeat",
      "carbonTransport",
      "costMWh",
      "costCombined",
      "costInvestment",
      "costHourly",
      "costMarginal",
      "costStorage",
    ];
    this.sectionRefs = {};
    for (let sectionName of this.sections) {
      this.sectionRefs[sectionName] = React.createRef();
      //console.log(sectionName,this.sectionRefs[sectionName]);
    }
    //console.log("t.refs",this.sectionRefs);
    // ["convdemand", "heatsupply", "trnsptdemand", "distribution", "generation", "storage", "interconnection", "cost", "demandStack", "itsdo", "inflexibles", "inflexImbalance", "flowTypeStack", "meritOrderStack", "storageChart", "marginChart", "utilisation", "utilMarginal", "carbonTotal", "carbonElectricity", "carbonInfrastructure", "carbonHeat", "carbonTransport", "genTechPrices", "totalCost", "investment", "genCostHourly", "genCostMarginal", "storageChart"]

    this.directVars = [
      "generation",
      "transmission",
      "supply",
      "storage",
      "interconnection",
      "convdemand",
      "heatdemand",
      "heatsupply",
      "trnsptdemand",
      "totalheat",
      "buildingheat",
      "wacc",
      "carbonCost",
      "heatUseHourly",
      "heatFuelHourly",
      "demandShedCost",
      //"totals"
    ];
    this.init = {};
    for (let key of this.directVars) {
      this.init[key] = this[key] ? JSON.parse(JSON.stringify(this[key])) : "";
    }

    this.calcAirCon = this.calcAirCon.bind(this);
    // this.calcASHP = this.calcASHP.bind(this);
    this.calcDemand = this.calcDemand.bind(this);
    this.calcGeneration = this.calcGeneration.bind(this);
    //this.calcInterconnectors = this.calcInterconnectors.bind(this);
    // this.calcGSHP = this.calcGSHP.bind(this);
    this.calcHeatDemand = this.calcHeatDemand.bind(this);
    this.calcHeatPeriods = this.calcHeatPeriods.bind(this);
    this.calcHourlyHeatFuel = this.calcHourlyHeatFuel.bind(this);
    this.calcInflexible = this.calcInflexible.bind(this);
    this.calcInflexibles = this.calcInflexibles.bind(this);
    this.calcWholesaleElecDemand = this.calcWholesaleElecDemand.bind(this);
    this.calcLighting = this.calcLighting.bind(this);
    //this.calcMidMeritProfile = this.calcMidMeritProfile.bind(this);
    //this.calcMidMeritData = this.calcMidMeritData.bind(this);
    this.calcFlexibles = this.calcFlexibles.bind(this);
    this.calcRoad = this.calcRoad.bind(this);
    this.calcRail = this.calcRail.bind(this);
    //this.calcResistance = this.calcResistance.bind(this);
    //this.calcWeather = this.calcWeather.bind(this);
    this.getProfiles = this.getProfiles.bind(this);
    this.getDemandProfiles = this.getDemandProfiles.bind(this);
    this.getGenerationProfiles = this.getGenerationProfiles.bind(this);
    //this.heatshareUpdate = this.heatshareUpdate.bind(this);
    //this.heatefficiencyUpdate = this.heatefficiencyUpdate.bind(this);
    this.fieldUpdate = this.fieldUpdate.bind(this);
    this.soFieldUpdate = this.soFieldUpdate.bind(this);
    this.heatHomesExistingUpdate = this.heatHomesExistingUpdate.bind(this);
    this.heatHomesNewUpdate = this.heatHomesNewUpdate.bind(this);
    this.heatNondomUpdate = this.heatNondomUpdate.bind(this);
    this.heatSupplyRecalc = this.heatSupplyRecalc.bind(this);
    this.heatSplit = this.heatSplit.bind(this);
    //this.itemUpdate = this.itemUpdate.bind(this);
    //this.item2Update = this.item2Update.bind(this);
    // this.setAirCon = this.setAirCon.bind(this);
    // this.setASHP = this.setASHP.bind(this);
    // this.setGSHP = this.setGSHP.bind(this);
    // this.setLighting = this.setLighting.bind(this);
    // this.setRoad = this.setRoad.bind(this);
    // this.setRail = this.setRail.bind(this);
    // this.setResistance = this.setResistance.bind(this);
    this.setDistLosses = this.setDistLosses.bind(this);
    // this.setWholesaleElecDemand = this.setWholesaleElecDemand.bind(this);
    // this.setInflexible = this.setInflexible.bind(this);
    // this.setInflexibles = this.setInflexibles.bind(this);
    // this.setInflexImbalance = this.setInflexImbalance.bind(this);
    // this.setFlexibles = this.setFlexibles.bind(this);
    this.setLoadFactors = this.setLoadFactors.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.randomGenProfile = this.randomGenProfile.bind(this);
    this.setNewGenProfile = this.setNewGenProfile.bind(this);
    this.getTechObj = this.getTechObj.bind(this);
    this.getItem = this.getItem.bind(this);
    this.readScenario = this.readScenario.bind(this);
    this.loadScenario = this.loadScenario.bind(this);
    this.saveScenario = this.saveScenario.bind(this);
    //this.buildFileSelector = this.buildFileSelector.bind(this);
    //this.getLinkObj = this.getLinkObj.bind(this);
  }

  avgTemp = 11.5;
  weatherAdj = 1.35;
  ashpBaseSPF = 267;
  gshpBaseSPF = 300;
  inflexibles = ["solar", "biogas", "nuclear", "onshorewind", "offshorewind"];

  /*resetInputStatus() {
    let changedStates = {};
    for (let type of [
      "convdemand",
      "heatsupply",
      "trnsptdemand",
      "generation",
      "storage",
      "interconnection",
    ]) {
      let group = {};
      for (let i = 0; i < this[type].length; i++) {
        const id = this[type][i].id;
        group[id] = {};
        const conditionKeys = Object.keys(this[type][i]);
        for (let j = 0; j < conditionKeys; j++) {
          if ((conditionKeys[j] !== "tech") & (conditionKeys[j] !== "id")) {
            if (typeof this[type][i][conditionKeys[j]] === "object") {
              group[id][conditionKeys[j]] = {};
              const charKeys = Object.keys(this[type][i][conditionKeys[j]]);
              for (let k = 0; k < charKeys.length; k++) {
                group[id][conditionKeys[j]][charKeys[k]] = false;
              }
            } else {
              group[id][conditionKeys[j]] = false;
            }
          }
        }
      }
      changedStates[type] = group;
    }
    return changedStates;
  }*/

  componentDidMount() {
    this.getProfiles();
    //this.fileSelector = this.buildFileSelector();
    //this.fileSelector.click();
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    if (this.state.scrollTo) {
      if (this.sectionRefs[this.state.scrollTo]) {
        //console.log("scrollTo", this.state.scrollTo);
        //console.log("thisRef", this.sectionRefs[this.state.scrollTo]);
        if (this.sectionRefs[this.state.scrollTo].current) {
          this.sectionRefs[this.state.scrollTo].current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      this.setState({ scrollTo: "" });
    }
  }

  getProfiles(update_states) {
    update_states = update_states || {};
    const now = new Date();
    update_states.recalcDate = now.getTime();
    const year = update_states.year || this.state.year || 2017;
    const weather = update_states.weather || this.state.weather || "normal";
    this.setState({ isLoading: true });
    update_states.isLoading = false;
    Promise.all([
      this.demandprofiles[year][weather] &&
      this.demandprofiles[year][weather].length
        ? this.calcDemand(update_states)
        : this.getDemandProfiles(update_states),
      this.generationprofiles[year] && this.generationprofiles[year].length
        ? this.calcGeneration(update_states)
        : this.getGenerationProfiles(update_states),
    ])
      .then((values) => {
        update_states = values[0];
        //console.log("update_states: ", Object.keys(update_states).join(", "));
        //console.log("update_states2: ", Object.keys(update_states2).join(", "));
        Object.assign(update_states, values[1]);

        //params.inflexiblesData = update_states.inflexiblesData;
        //params.wholesaleElecDemandData = update_states.wholesaleElecDemandData;
        update_states.inflexImbalanceData = this.calcInflexImbalance(
          update_states
        );
        //params.inflexImbalanceData = update_states.inflexImbalanceData;
        //console.log("this.icprofiles",this.icprofiles);
        return this.icprofiles &&
          this.icprofiles[year] &&
          this.icprofiles[year].length
          ? this.calcFlexibles(update_states)
          : this.getICProfiles(update_states);
      })
      .then((flexiblesData) => {
        update_states.flexiblesData = flexiblesData;
        if (typeof this.init.totals === "undefined") {
          this.init.totals = JSON.parse(JSON.stringify(this.totals));
        }
        //console.log("totals",this.totals);
        //console.log("initTotals",this.init.totals);
        //update_states.flexiblesData =
        //this.icprofiles[year] && this.icprofiles[year].length
        //  ? this.calcFlexibles(params)
        //  : this.getICProfiles(params);
        //update_states.midMeritData = this.calcMidMeritData({
        //  inflexImbalanceData: update_states.inflexImbalanceData,
        //});
        //console.log("this.generation",this.generation);
        //console.log("midMeritData",update_states.midMeritData);
        //console.log("Profile: flexGen",flexiblesData.generation);
        this.setLoadFactors(update_states);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  getDemandProfiles(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    const weather = params.weather || this.state.weather || "normal";
    console.log("start download demand");
    //if (this.state.activeTab === "1" || this.state.activeTab === "2") {
    //  this.setState({ isLoading: true });
    //}
    const dataURL =
      "https://ed.c4cs.org.uk:5002/demandprofiles?year=" +
      year +
      "&weather=" +
      weather;

    const update_states = fetch(dataURL)
      .then((res) => res.json())
      .then((result) => {
        this.demandprofiles[year][weather] = result;
        console.log("completed download demand, start processing");
        return this.calcDemand(params);
      })
      .catch((error) => {
        console.log(error);
        //if (this.state.activeTab === "1" || this.state.activeTab === "2") {
        //  this.setState({ isLoading: false });
        //}
        return {};
      });
    return update_states;
  }

  getGenerationProfiles(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    //const weather = params.weather || this.state.weather || "normal";

    console.log("start download generation");
    //if (
    //  this.state.activeTab === "3" ||
    //  this.state.activeTab === "4" ||
    //  this.state.activeTab === "5"
    //) {
    //  this.setState({ isLoading: true });
    //}
    const dataURL =
      "https://ed.c4cs.org.uk:5002/generationprofiles?year=" + year;
    const update_states = fetch(dataURL)
      .then((res) => res.json())
      .then((result) => {
        this.generationprofiles[year].current = result;
        console.log("completed download generation, start processing");
        return this.calcGeneration(params);
      })
      .catch((error) => {
        console.log(error);
        //if (
        //  this.state.activeTab === "3" ||
        //  this.state.activeTab === "4" ||
        //  this.state.activeTab === "5"
        //) {
        //  this.setState({ isLoading: false });
        //}
        return {};
      });
    return update_states;
  }

  calcDemand(update_states) {
    update_states = update_states || {};
    const year = update_states.year || this.state.year || 2017;
    const weather = update_states.weather || this.state.weather || "normal";
    console.log("calculating demand for ", year);
    const initHeatDemand = this.calcHeatDemand(this.init.heatdemand);
    const heatDemand = this.calcHeatDemand(this.heatdemand);
    //let update_states = { year: year, weather: weather };
    update_states.lightingData = this.calcLighting({
      weather: weather,
      year: year,
    });
    update_states.airconData = this.calcAirCon({
      weather: weather,
      year: year,
    });
    this.calcHeatPeriods({
      weather: weather,
      year: year,
      heatUseTotals: heatDemand,
    });
    update_states.resistanceData = this.heatFuelHourly.resistance;
    update_states.ashpData = this.heatFuelHourly.ashp;
    update_states.gshpData = this.heatFuelHourly.gshp;
    /*update_states.resistanceData = this.calcResistance({
      weather: weather,
      year: year,
      initHeatDemand: initHeatDemand,
      heatDemand: heatDemand,
    });
    update_states.ashpData = this.calcASHP({
      weather: weather,
      year: year,
      initHeatDemand: initHeatDemand,
      heatDemand: heatDemand,
    });
    update_states.gshpData = this.calcGSHP({
      weather: weather,
      year: year,
      initHeatDemand: initHeatDemand,
      heatDemand: heatDemand,
    }); */
    //console.log("gshpData", update_states.gshpData);
    update_states.roadData = this.calcRoad({ year: year });
    update_states.railData = this.calcRail({ year: year });
    update_states.wholesaleElecDemandData = this.calcWholesaleElecDemand(
      update_states
    );
    if (this.state.activeTab === "1" || this.state.activeTab === "2") {
      update_states.isLoading = false;
    }
    console.log("completed processing demand");
    return update_states;
  }

  calcGeneration(update_states) {
    update_states = update_states || {};
    const year = update_states.year || this.state.year || 2017;
    console.log("calculating generation for ", year);
    //const weather = update_states.weather || this.state.weather || "normal";
    this.generationprofiles[year].new = [];

    const arLength = this.generationprofiles[year].current.length;
    const biogasObj = this.getTechObj("biogas");
    //this.profiles.biogas = {current: []};
    //console.log("biogasProfile", this.profiles.biogas);
    const biogasProfile = this.randomGenProfile({
      availFactor: biogasObj.current.availFactor,
    });
    for (let i = 0; i < arLength; i++) {
      this.generationprofiles[year].current[i].biogas =
        typeof biogasProfile[i] !== "undefined" ? biogasProfile[i].y : 0;
      this.generationprofiles[year].new.push({
        x: this.generationprofiles[year].current[i].x,
      });
    }
    //let update_states = { year: year, weather: weather };
    // eslint-disable-next-line no-unused-vars
    for (let tech of this.inflexibles) {
      this.setNewGenProfile({ tech: tech, year: year });
      const inflexData = this.calcInflexible({ tech: tech, year: year });
      const dataKey = tech + "Data";
      update_states[dataKey] = inflexData;
      update_states[dataKey] = inflexData;
    }
    update_states.inflexiblesData = this.calcInflexibles(update_states);
    if (
      this.state.activeTab === "3" ||
      this.state.activeTab === "4" ||
      this.state.activeTab === "5"
    ) {
      update_states.isLoading = false;
    }
    console.log("completed processing generation");
    return update_states;
  }

  getICProfiles(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;

    console.log("start download interconnectors");
    if (this.state.activeTab === "5") {
      this.setState({ isLoading: true });
    }
    const dataURL = "https://ed.c4cs.org.uk:5002/icprofiles?year=" + year;
    const flexiblesData = fetch(dataURL)
      .then((res) => res.json())
      .then((result) => {
        //console.log("this.icprofiles",this.icprofiles);
        this.icprofiles[year] = result;
        console.log("completed download interconnectors, start processing");
        // Populate any interconnectors without data with zero values
        for (let i = 0; i < this.interconnection.length; i++) {
          const linkId = this.interconnection[i].id;
          if (typeof this.icprofiles[year][0][linkId] === "undefined") {
            for (let j = 0; j < this.icprofiles[year].length; j++) {
              this.icprofiles[year][j][linkId] = 0;
            }
          }
        }
        return this.calcFlexibles(params);
      })
      .catch((error) => {
        console.log(error);
        if (this.state.activeTab === "5") {
          this.setState({ isLoading: false });
        }
        return {};
      });
    return flexiblesData;
  }

  getTechObj(tech) {
    let techObj = {};
    for (let i = 0; i < this.generation.length; i++) {
      if (this.generation[i].id === tech) {
        techObj = this.generation[i];
        break;
      }
    }
    return techObj;
  }

  getItem(object, itemId) {
    let item = {};
    if (typeof object === "object") {
      if (Array.isArray(object)) {
        for (let i = 0; i < object.length; i++) {
          if (object[i].id === itemId) {
            item = object[i];
            break;
          }
        }
      } else {
        if (typeof object[itemId] !== "undefined") {
          item = object[itemId];
        }
      }
    }
    return item;
  }

  /*getLinkObj(link) {
    let linkObj = {};
    for (let i = 0; i < this.interconnection.length; i++) {
      if (this.interconnection[i].id === link) {
        linkObj = this.interconnection[i];
        break;
      }
    }
    return linkObj;
  }*/

  /*heatshareUpdate(event) {
    if (event.target.id) {
      let [type, id, characteristic] = event.target.id.split("_");
      for (let i = 0; i < this[type].length; i++) {
        if (this[type][i].id === id) {
          this[type][i][characteristic] = +event.target.value;
          this[type][i].heat = decRound(
            (event.target.value * this.state.buildingheat) / 100,
            1
          );
          this[type][i].elec = decRound(
            (100 * this[type][i].heat) / this[type][i].efficiency,
            1
          );
          break;
        }
      }
      if (id === "resistance") {
        this.setResistance();
      } else if (id === "ashp") {
        this.setASHP();
      } else if (id === "gshp") {
        this.setGSHP();
      }
    }
  }

  heatefficiencyUpdate(event) {
    if (event.target.id) {
      let [type, id, characteristic] = event.target.id.split("_");
      for (let i = 0; i < this[type].length; i++) {
        if (this[type][i].id === id) {
          this[type][i][characteristic] = +event.target.value;
          this[type][i].elec = decRound(
            (100 * this[type][i].heat) / event.target.value,
            1
          );
          break;
        }
      }
      if (id === "resistance") {
        this.setResistance();
      } else if (id === "ashp") {
        this.setASHP();
      } else if (id === "gshp") {
        this.setGSHP();
      }
    }
  }

  itemUpdate(event) {
    if (event.target.id) {
      let [type, id, condition, characteristic] = event.target.id.split("_");
      //console.log(event.target.id,event.target.value);
      
      console.log("type", type);
      console.log("id", id);
      console.log("condition", condition);
      console.log("characteristic", characteristic);
      console.log("value", event.target.value);
      
      for (let i = 0; i < this[type].length; i++) {
        if (this[type][i].id === id) {
          //console.log("matched", this[type][i]);
          this[type][i][condition][characteristic] = +event.target.value;
          break;
        }
      }
      let inputGroupStatus = JSON.parse(JSON.stringify(this.state[type]));
      inputGroupStatus[id][condition][characteristic] = true;
      this.setState({ [type]: inputGroupStatus });
      
      if (type === "generation") {
        if (this.inflexibles.includes(id)) {
          //console.log("setting inflexible ", id);
          this.setInflexible({ tech: id });
          //const inflexData = this.calcInflexible({ tech: id });
          //this.setState({ [id + "Data"]: inflexData });
        } else {
          //console.log("setting flexible ", id);
          this.setFlexibles();
          //const flexiblesData = this.calcFlexibles();
          //this.setState({ flexiblesData: flexiblesData });
        }
      } else if (type === "storage") {
        //console.log("setting storage ", id);
        this.setFlexibles();
        //const flexiblesData = this.calcFlexibles();
        //this.setState({ flexiblesData: flexiblesData });
      }
      
    }
  } */

  fieldUpdate(params) {
    //    console.log("running fieldUpdate");
    let index = 0;
    for (let i = 0; i < this[params.type].length; i++) {
      if (this[params.type][i].id === params.id) {
        index = i;
        break;
      }
    }
    if (params.vals) {
      for (let item in params.vals) {
        this[params.type][index][item] = params.vals[item];
      }
    }
    if (params.nested) {
      for (let item in params.nested) {
        for (let subitem in params.nested[item]) {
          this[params.type][index][item][subitem] =
            params.nested[item][subitem];
        }
      }
    }
  }

  soFieldUpdate(key, val) {
    this[key] = val;
  }

  /*field2Update(key, val) {
    console.log("running field2Update");
    console.log(key,val);
    if (key) {
      let [type, id, characteristic] = key.split("_");
      for (let i = 0; i < this[type].length; i++) {
        if (this[type][i].id === id) {
          this[type][i][characteristic] = val;
          break;
        }
      }
    }
  }

  item2Update(event) {
    console.log("running item2Update");
    if (event.target.id) {
      let [type, id, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      for (let i = 0; i < this[type].length; i++) {
        if (this[type][i].id === id) {
          this[type][i][characteristic] = val;
          break;
        }
      }
      console.log("updated value");
      let inputGroupStatus = JSON.parse(JSON.stringify(this.state[type]));
      inputGroupStatus[id][characteristic] = true;
      this.setState({ [type]: inputGroupStatus });
      console.log("state set");
      //let update_states = {};
      /*
      if (type === "convdemand") {
        if (id === "lighting") {
          this.setLighting();
          //update_states.lightingData = this.calcLighting();
          //update_states.lightingData = lightingData;
        } else if (id === "aircon") {
          this.setAirCon();
          //update_states.airconData = this.calcAirCon({});
          //update_states.airconData = airconData;
        }
        //this.setWholesaleElecDemand(update_states);
        //const wholesaleElecDemandData = this.calcWholesaleElecDemand(update_states);
        //update_states.wholesaleElecDemandData = wholesaleElecDemandData;
      } else if (type === "trnsptdemand" && characteristic === "elec") {
        if (id === "road") {
          this.setRoad();
          //update_states.roadData = this.calcRoad();
          //update_states.roadData = roadData;
        } else if (id === "rail") {
          this.setRail();
          //update_states.railData = this.calcRail();
          //update_states.railData = railData;
        } else if (id === "air") {
        } else if (id === "water") {
        }
        //this.setWholesaleElecDemand(update_states);
      } else if ((type = "interconnection")) {
        this.setFlexibles();
      }
      //this.setState(update_states);
      
    }
  }*/

  calcAirCon(params) {
    params = params || {};
    const weather = params.weather || this.state.weather || "normal";
    const year = params.year || this.state.year || 2017;

    let airconTotal = 0;
    let airconData = [];
    if (this.demandprofiles[year] && this.demandprofiles[year][weather]) {
      const arLength = this.demandprofiles[year][weather].length;
      if (arLength > 0) {
        for (let i = 0; i < arLength; i++) {
          let yval =
            this.demandprofiles[year][weather][i].coolIntensity *
            this.convdemand[1].elec;
          airconData.push({
            x: new Date(this.demandprofiles[year][weather][i].x),
            y: yval,
          });
          airconTotal += yval;
        }
      }
    }
    this.totals.elec.aircon = 1 * airconTotal.toFixed();
    console.log("calculated aircon data", this.totals.elec.aircon);
    return airconData;
  }

  /*setAirCon(update_states) {
    update_states = update_states || {};
    update_states.airconData = this.calcAirCon(update_states);
    this.setWholesaleElecDemand(update_states);
  }*/

  /* calcASHP(params) {
    params = params || {};
    const weather = params.weather || this.state.weather || "normal";
    const year = params.year || this.state.year || 2017;
    const heatingFuel = this.getItem(this.heatsupply, "ashp");
    const spf = params.spf || heatingFuel.efficiency || this.ashpBaseSPF;
    const initHeatDemand =
      params.initHeatDemand || this.calcHeatDemand(this.init.heatdemand);
    const heatDemand =
      params.heatDemand || this.calcHeatDemand(this.heatdemand);
    const nonSeasonalPart = this.heatSplit({
      heatDemand: heatDemand,
      initHeatDemand: initHeatDemand,
    });
    const seasonalPart = 1 - nonSeasonalPart;
    const hourPerYear = 1000000 / 8760;
    let ashpTotal = 0;
    let ashpData = [];
    const ashpcopa = (0.045 * spf) / this.ashpBaseSPF;
    const ashpcopb = (2.15 * spf) / this.ashpBaseSPF;
    if (this.demandprofiles[year] && this.demandprofiles[year][weather]) {
      const arLength = this.demandprofiles[year][weather].length;
      if (arLength > 0) {
        for (let i = 0; i < arLength; i++) {
          const cop =
            ashpcopb +
              this.demandprofiles[year][weather][i].temperatures * ashpcopa ||
            spf;
          const hourlyShare =
            this.demandprofiles[year][weather][i].heatdemand * seasonalPart +
            hourPerYear * nonSeasonalPart;
          //  / 2; // Why /2 ?
          // if (!i % 100) {
          //   console.log(
          //     "TS: " + this.demandprofiles[year][weather][i].x,
          //     nonSeasonalPart
          //   );
          // }
          let yval = (hourlyShare * heatingFuel.heat) / cop;
          ashpData.push({
            x: new Date(this.demandprofiles[year][weather][i].x),
            y: yval,
          });
          ashpTotal += yval;
        }
      }
    }

    this.totals.elec.ashp = 1 * ashpTotal.toFixed();
    console.log("calculated ASHP elec", this.totals.elec.ashp);
    return ashpData;
  } */

  /*setASHP(update_states) {
    update_states = update_states || {};
    update_states.ashpData = this.calcASHP(update_states);
    this.setWholesaleElecDemand(update_states);
  }*/

  /* calcGSHP(params) {
    params = params || {};
    const weather = params.weather || this.state.weather || "normal";
    const year = params.year || this.state.year || 2017;
    let heatingFuel = this.getItem(this.heatsupply, "gshp");
    const spf = params.spf || heatingFuel.efficiency || this.gshpBaseSPF;
    const initHeatDemand =
      params.initHeatDemand || this.calcHeatDemand(this.init.heatdemand);
    const heatDemand =
      params.heatDemand || this.calcHeatDemand(this.heatdemand);
    const nonSeasonalPart = this.heatSplit({
      heatDemand: heatDemand,
      initHeatDemand: initHeatDemand,
    });
    const seasonalPart = 1 - nonSeasonalPart;
    const hourPerYear = 1000000 / 8760;
    let gshpTotal = 0;
    let gshpData = [];
    const gshpcopa = (0.035 * spf) / this.gshpBaseSPF;
    const gshpcopb = (2.6 * spf) / this.gshpBaseSPF;
    if (this.demandprofiles[year] && this.demandprofiles[year][weather]) {
      const arLength = this.demandprofiles[year][weather].length;
      if (arLength > 0) {
        for (let i = 0; i < arLength; i++) {
          const cop =
            gshpcopb +
              this.demandprofiles[year][weather][i].temperatures * gshpcopa ||
            spf;
          const hourlyShare =
            this.demandprofiles[year][weather][i].heatdemand * seasonalPart +
            hourPerYear * nonSeasonalPart;
          //            / 2;
          let yval = (hourlyShare * heatingFuel.heat) / cop;
          gshpData.push({
            x: new Date(this.demandprofiles[year][weather][i].x),
            y: yval,
          });
          gshpTotal += yval;
        }
      }
    }

    this.totals.elec.gshp = 1 * gshpTotal.toFixed();
    console.log("calculated GSHP elec", this.totals.elec.gshp);
    return gshpData;
  } */

  calcHeatDemand(heatdemand = this.heatdemand) {
    //console.log("heatdemand", heatdemand);
    let totals = {};
    totals.spaceHeating =
      heatdemand.homes.existing.totals.spaceHeating +
      heatdemand.homes.new.houses.spaceHeating +
      heatdemand.homes.new.flats.spaceHeating +
      heatdemand.nondom.industry.spaceHeating +
      heatdemand.nondom.services.spaceHeating;
    totals.hotWater =
      heatdemand.homes.existing.totals.hotWater +
      heatdemand.homes.new.houses.hotWater +
      heatdemand.homes.new.flats.hotWater +
      heatdemand.nondom.services.hotWater;
    totals.cooking =
      heatdemand.homes.existing.totals.cooking +
      heatdemand.homes.new.houses.cooking +
      heatdemand.homes.new.flats.cooking +
      heatdemand.nondom.services.cooking;
    totals.processHigh = heatdemand.nondom.industry.processHigh;
    totals.processLow = heatdemand.nondom.industry.processLow;
    totals.drying = heatdemand.nondom.industry.drying;
    totals.other = heatdemand.nondom.industry.other;
    totals.buildingHeat =
      totals.spaceHeating + totals.hotWater + totals.cooking;
    totals.homes = 0;
    for (let a of ["spaceHeating", "hotWater", "cooking"]) {
      totals.homes +=
        heatdemand.homes.existing.totals[a] +
        heatdemand.homes.new.houses[a] +
        heatdemand.homes.new.flats[a];
    }
    totals.services = 0;
    for (let key in heatdemand.nondom.services) {
      totals.services += heatdemand.nondom.services[key];
    }
    totals.industry = 0;
    for (let key in heatdemand.nondom.industry) {
      totals.industry += heatdemand.nondom.industry[key];
    }
    totals.heat =
      totals.buildingHeat +
      totals.processHigh +
      totals.processLow +
      totals.drying +
      totals.other;
    this.totalheat = totals.heat;
    this.buildingheat = totals.buildingHeat;
    this.spaceheat = totals.spaceHeating;
    return totals;
  }

  /*
  *
  A. Calculate space and nonspace totals for each technology:
  1. Get residual totals after allocating woodfires (100/0)
  2. Get residual totals after allocating oil (60/40)
  3. Get residual totals after allocating gas (60/40)
  4. Split residual between other technologies pro rata to their shares of total heat
  (deduct 1-3's share of total heat from 100(%) to get denominator for next calcs)
  If tech share of space heating > total residual space heating,
  set tech space share to 100% of space residual, and set rest of tech to non-space
  B. Allocate each tech's space and nonspace totals to hourly periods according to 
  proportions in JSON file to calculate combined hourly heat from tech
  C. Convert each tech's hourly heat into hourly fuel by dividing by efficiency
  *
  */
  calcHeatPeriods(params = {}) {
    const weather = params.weather || this.state.weather || "normal";
    const year = params.year || this.state.year || 2017;
    if (this.demandprofiles[year] && this.demandprofiles[year][weather]) {
      const arLength = this.demandprofiles[year][weather].length;
      const heatUseTotals =
        params.heatUseTotals || this.calcHeatDemand(this.heatdemand);
      let spaceHeatTotal = heatUseTotals.spaceHeating;
      let otherHeatTotal = heatUseTotals.heat - spaceHeatTotal;

      const firstTechs = { woodfires: 1, oil: 2, gas: 3, resistance: 4 };
      const elecTechs = { resistance: 1, ashp: 1, gshp: 1 };
      for (let tech of Object.keys(firstTechs)) {
        let techObj = this.getItem(this.heatsupply, tech);
        const techSpaceHeatTotal = Math.min(
          spaceHeatTotal,
          (techObj.heat * techObj.spaceheat) / 100
        );
        const techOtherHeatTotal = techObj.heat - techSpaceHeatTotal;
        let techTotalUse = 0;
        let techTotalFuel = 0;
        let techHourlyUse = [];
        let techHourlyFuel = [];
        //let techMaxFuel = 0;
        for (let i = 0; i < arLength; i++) {
          const hourlyData = this.calcHourlyHeatFuel({
            spaceHeatTotal: techSpaceHeatTotal,
            otherHeatTotal: techOtherHeatTotal,
            techObj: techObj,
            hourlyShare: this.demandprofiles[year][weather][i],
          });
          techHourlyUse.push(hourlyData[0]);
          techHourlyFuel.push(hourlyData[1]);
          techTotalUse += hourlyData[0].y;
          techTotalFuel += hourlyData[1].y;
          //if (hourlyData[1] > techMaxFuel) {techMaxFuel = hourlyData[1]}
        }
        this.heatUseHourly[tech] = techHourlyUse;
        this.heatFuelHourly[tech] = techHourlyFuel;
        spaceHeatTotal -= techSpaceHeatTotal;
        otherHeatTotal -= techOtherHeatTotal;
        if (elecTechs[techObj.id]) {
          this.totals.elec[techObj.id] = 1 * techTotalFuel.toFixed();
        }
      }

      const residualHeatTotal = spaceHeatTotal + otherHeatTotal;
      // Sort to take techs with highest load factors first. See below.
      const sortedTechs = this.heatsupply
        .map((obj) => {
          return {
            id: obj.id,
            heat: obj.heat,
            efficiency: obj.efficiency,
            loadFactor: obj.loadFactor,
          };
        })
        .sort((a, b) => b.loadFactor - a.loadFactor);
      /* for (let techObj of this.heatsupply.sort(
        (a, b) => b.loadFactor - a.loadFactor
      )) { */
      for (let techObj of sortedTechs) {
        if (firstTechs[techObj.id]) {
          continue;
        }
        let techShare = techObj.heat / residualHeatTotal;
        let techSpaceHeatTotal = techShare * spaceHeatTotal;
        let techOtherHeatTotal = techShare * otherHeatTotal;
        // Techs with high load factors cannot be doing much seasonal heat,
        // so can't split spaceHeat equally with lower-load-factor techs.
        // The maths here isn't perfect (assigning switched value to totals
        // and then splitting between lower load-factor techs doesn't work out
        // to exactly 100%, but close enough in most circs)
        // Without this adjustment, the calculated capacities of high-LF techs are way out.
        if (techObj.loadFactor > 25) {
          const switchHeat = Math.floor(
            (techSpaceHeatTotal * techObj.loadFactor) / 100
          );
          techSpaceHeatTotal -= switchHeat;
          techOtherHeatTotal += switchHeat;
          spaceHeatTotal += switchHeat;
          otherHeatTotal -= switchHeat;
        }
        let techTotalUse = 0;
        let techTotalFuel = 0;
        let techHourlyUse = [];
        let techHourlyFuel = [];
        for (let i = 0; i < arLength; i++) {
          const hourlyData = this.calcHourlyHeatFuel({
            spaceHeatTotal: techSpaceHeatTotal,
            otherHeatTotal: techOtherHeatTotal,
            techObj: techObj,
            hourlyShare: this.demandprofiles[year][weather][i],
          });
          techHourlyUse.push(hourlyData[0]);
          techHourlyFuel.push(hourlyData[1]);
          techTotalUse += hourlyData[0].y;
          techTotalFuel += hourlyData[1].y;
        }
        this.heatUseHourly[techObj.id] = techHourlyUse;
        this.heatFuelHourly[techObj.id] = techHourlyFuel;
        if (elecTechs[techObj.id]) {
          this.totals.elec[techObj.id] = 1 * techTotalFuel.toFixed();
        }
        /* if (techObj.id === "resistance") {
          this.totals.elec.resistance = 1 * techTotalFuel.toFixed();
          console.log(
            "calculated resistance heat elec",
            this.totals.elec.resistance
          );
        } else if (techObj.id === "ashp") {
          this.totals.elec.ashp = 1 * techTotalFuel.toFixed();
          console.log("calculated ASHP elec", this.totals.elec.ashp);
        } else if (techObj.id === "gshp") {
          this.totals.elec.gshp = 1 * techTotalFuel.toFixed();
          console.log("calculated GSHP elec", this.totals.elec.gshp);
        } */
      }
      this.setState({
        heatUseRev: this.state.heatUseRev + 1,
        heatFuelRev: this.state.heatFuelRev + 1,
      });
    }
  }

  calcHourlyHeatFuel(params = {}) {
    const spaceHeatTotal = params.spaceHeatTotal;
    const otherHeatTotal = params.otherHeatTotal;
    const techObj = params.techObj;
    const hourlyShare = params.hourlyShare;
    const spaceShare = hourlyShare.spaceheat;
    const otherShare = hourlyShare.otherheat;
    const hourlySpaceUse = spaceShare * spaceHeatTotal;
    const hourlyOtherUse = otherShare * otherHeatTotal;
    const hourlyHeatUse = hourlySpaceUse + hourlyOtherUse;
    const hourlyUse = {
      x: new Date(hourlyShare.x),
      y: hourlyHeatUse,
    };
    //techHourlyUse.push();
    let efficiency = techObj.efficiency;
    if (techObj.id === "ashp") {
      const spf = techObj.efficiency || this.ahspBaseSPF;
      const ahspcopa = (4.5 * spf) / this.ahspBaseSPF;
      const ahspcopb = (215 * spf) / this.ahspBaseSPF;
      efficiency = ahspcopb + hourlyShare.temperatures * ahspcopa || spf;
    } else if (techObj.id === "gshp") {
      const spf = techObj.efficiency || this.gshpBaseSPF;
      const gshpcopa = (3.5 * spf) / this.gshpBaseSPF;
      const gshpcopb = (260 * spf) / this.gshpBaseSPF;
      efficiency = gshpcopb + hourlyShare.temperatures * gshpcopa || spf;
    }
    const hourlyHeatFuel = (hourlyHeatUse * 100) / efficiency;
    const hourlyFuel = {
      x: new Date(hourlyShare.x),
      y: hourlyHeatFuel,
    };
    //techHourlyFuel.push();
    return [hourlyUse, hourlyFuel];
  }

  /*setGSHP(update_states) {
    update_states = update_states || {};
    update_states.gshpData = this.calcGSHP(update_states);
    this.setWholesaleElecDemand(update_states);
  }*/

  /*
  setGeneration(update_states) {
    update_states.inflexImbalanceData = this.calcInflexImbalance({
      inflexiblesData: update_states.inflexiblesData,
    });
    this.setState(update_states);
  }
  */

  /*
  setDemand(update_states) {
    update_states.inflexImbalanceData = this.calcInflexImbalance({
      wholesaleElecDemandData: update_states.wholesaleElecDemandData,
    });
    this.setState(update_states);
  }
  */

  /*calcInterconnectors(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    console.log("calculating interconnection flows for ", year);
    const arLength = this.icprofiles[year].length;
    let update_states = { year: year };
  } */

  calcInflexible(params) {
    params = params || {};
    const tech = params.tech;
    let totalDemand = 0;
    let inflexData = [];
    if (tech) {
      const year = params.year || this.state.year || 2017;
      const techObj = this.getTechObj(tech);
      techObj.MWhpa = 0;
      //if (this.generationprofiles[tech]) {
      const arLength = this.generationprofiles[year].current.length;
      const currentDemand =
        techObj.current.capacity * 87.6 * techObj.current.availFactor;
      const newDemand = techObj.new.capacity * 87.6 * techObj.new.availFactor;
      if (arLength > 0) {
        for (let i = 0; i < arLength; i++) {
          let yval = Math.round(
            (this.generationprofiles[year].current[i][tech] * currentDemand +
              this.generationprofiles[year].new[i][tech] * newDemand) /
              1000000
          );
          if (isNaN(yval)) {
            yval = 0;
          }
          inflexData.push({
            x: new Date(this.generationprofiles[year].current[i].x),
            y: yval,
          });
          totalDemand += yval;
          //techObj.MWhpa += yval;
        }
        //console.log("calculated " + tech + " data", totalDemand);
      }
      techObj.MWhpa = 1 * totalDemand.toFixed();
    }
    //this.totals.elec.inflex = 1 * totalDemand.toFixed();

    return inflexData;
  }

  /* setInflexible(params) {
    params = params || {};
    if (params.tech) {
      const dataKey = params.tech + "Data";
      //console.log("setting data for inflexible ", params.tech);
      const inflexibleData = this.calcInflexible(params);
      this.setInflexibles({ [dataKey]: inflexibleData });
    }
  } */

  calcInflexibles(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    let inflexiblesData = this.generationprofiles[year].current.map((item) => {
      return { x: new Date(item.x), y: 0 };
    });
    const arLength = inflexiblesData.length;
    // eslint-disable-next-line no-unused-vars
    for (let tech of this.inflexibles) {
      const dataKey = tech + "Data";
      const inflexData = params[dataKey] || this.state[dataKey];
      if (inflexData) {
        for (let i = 0; i < arLength; i++) {
          inflexiblesData[i].y += decRound(inflexData[i].y, 2);
        }
      }
    }
    return inflexiblesData;
  }

  /* setInflexibles(update_states) {
    update_states = update_states || {};
    this.setState({ isLoading: true });
    console.log("setting inflexible generation data");
    update_states.inflexiblesData = this.calcInflexibles(update_states);
    this.setInflexImbalance(update_states);
  } */

  calcInflexImbalance(params) {
    params = params || {};
    const inflexiblesData =
      params.inflexiblesData && params.inflexiblesData.length
        ? params.inflexiblesData
        : this.state.inflexiblesData;
    const wholesaleElecDemandData =
      params.wholesaleElecDemandData && params.wholesaleElecDemandData.length
        ? params.wholesaleElecDemandData
        : this.state.wholesaleElecDemandData;
    //console.log("inflexiblesData", inflexiblesData);
    let imbalanceData = [];
    const arLength = inflexiblesData.length;
    for (let i = 0; i < arLength; i++) {
      imbalanceData.push({
        x: inflexiblesData[i].x,
        y: decRound(wholesaleElecDemandData[i].y - inflexiblesData[i].y, 2),
      });
    }
    return imbalanceData;
  }

  /* setInflexImbalance(update_states) {
    update_states = update_states || {};
    console.log("setting inflexible imbalance data");
    update_states.inflexImbalanceData = this.calcInflexImbalance(update_states);
    this.setFlexibles(update_states);
  } */

  /* setFlexibles(update_states) {
    console.log("setting flexible generation data");
    this.calcFlexibles(update_states)
      .then((flexiblesData) => {
        update_states = update_states || {};
        update_states.flexiblesData = flexiblesData;
        //console.log("flexible Gen", update_states.flexiblesData.generation);
        this.setLoadFactors(update_states);
      })
      .catch((error) => {
        console.log(error);
      });
  } */

  setLoadFactors(update_states = {}) {
    console.log("starting setLoadFactors");
    update_states.isLoading = false;
    const flexiblesData =
      update_states.flexiblesData || this.state.flexiblesData;
    /*if (flexiblesData.marginalGenTechData) {
      update_states.marginalGenTechData = flexiblesData.marginalGenTechData;
      if (!this.state.initMarginalGenTechData) {
        update_states.initMarginalGenTechData = flexiblesData.marginalGenTechData;
      }
      delete flexiblesData.marginalGenTechData;
    }
    if (flexiblesData.marginalGenHourlyData) {
      update_states.marginalGenHourlyData = flexiblesData.marginalGenHourlyData;
      if (!this.state.initmarginalGenHourlyData) {
        update_states.initmarginalGenHourlyData = flexiblesData.marginalGenHourlyData;
      }
      delete flexiblesData.marginalGenHourlyData;
    }*/
    let arLength = flexiblesData.inflexAdjustments.length;
    let adjustTotal = 0;
    //const flexGenKeys = Object.keys(flexiblesData.generation);
    //const flexGenLength = flexGenKeys.length;
    //let techTotals = {};
    //for (let i = 0; i < flexGenLength; i++) {
    //  techTotals[flexGenKeys[i]] = 0;
    //}
    for (let i = 0; i < arLength; i++) {
      adjustTotal += flexiblesData.inflexAdjustments[i].y;
      //  for (let tech in flexGenKeys) {
      //    techTotals[tech] += flexiblesData[tech][i].y;
      //  }
    }
    const inflexImbalanceData =
      update_states.inflexImbalanceData ||
      this.state.inflexImbalanceData.map((item) => {
        return { x: item.x, y: item.y };
      });
    if (adjustTotal) {
      const inflexiblesData =
        update_states.inflexiblesData ||
        this.state.inflexiblesData.map((item) => {
          return { x: item.x, y: item.y };
        });
      const inflexData = {};
      const inflexKeys = [];
      for (let i = this.inflexibles.length - 1; i >= 0; i--) {
        const dataKey = this.inflexibles[i] + "Data";
        inflexKeys.push(dataKey);
        //console.log("dataKey",dataKey);
        inflexData[dataKey] =
          update_states[dataKey] ||
          this.state[dataKey].map((item) => {
            return { x: item.x, y: item.y };
          });
        //JSON.parse(JSON.stringify(this.state[dataKey]));
      }
      const inLength = inflexKeys.length;
      /*const solarData = update_states.solarData || JSON.parse(JSON.stringify(this.state.solarData));
        const biogasData = update_states.biogasData || JSON.parse(JSON.stringify(this.state.biogasData));
        const nuclearData = update_states.nuclearData || JSON.parse(JSON.stringify(this.state.nuclearData));
        const onshorewindData = update_states.onshorewindData || JSON.parse(JSON.stringify(this.state.onshorewindData));
        const offshoreData = update_states.offshoreData || JSON.parse(JSON.stringify(this.state.offshoreData));
        const inflexKeys = this.inflexibles*/
      for (let i = 0; i < arLength; i++) {
        // Needed because JSON parse/stringify trick above turns date object into string
        //for (let j = 0; j < inLength; j++) {
        //  inflexData[inflexKeys[j]][i].x = flexiblesData.inflexAdjustments[i].x;
        //}
        let adjustment = flexiblesData.inflexAdjustments[i].y;
        if (adjustment < 0) {
          //let adjustObj = {x: flexiblesData.inflexAdjustments[i].x, adj: adjustment};
          for (let j = 0; j < inLength; j++) {
            const startingVal = inflexData[inflexKeys[j]][i].y;
            const balance = startingVal + adjustment;
            //adjustObj[inflexKeys[j]] = {start: startingVal, balance: balance};
            if (balance < 0) {
              adjustment = balance;
              inflexData[inflexKeys[j]][i].y = 0;
              inflexiblesData[i].y -= startingVal;
              inflexImbalanceData[i].y += startingVal;
            } else {
              inflexData[inflexKeys[j]][i].y = balance;
              inflexiblesData[i].y += adjustment; // adjustment is negative value
              inflexImbalanceData[i].y -= adjustment;
              break;
            }
            //adjustObj[inflexKeys[j]].adj = adjustment;
          }
          //console.log("adjustObj",adjustObj);
        }
      }
      for (let j = 0; j < inLength; j++) {
        update_states[inflexKeys[j]] = inflexData[inflexKeys[j]];
      }
      update_states.inflexiblesData = inflexiblesData;
      update_states.inflexImbalanceData = inflexImbalanceData;
    }

    console.log("Calculating gen data");
    /*console.log("inflexAdjustments",
      JSON.parse(JSON.stringify(flexiblesData.inflexAdjustments.filter(obj => {if (obj.y < 0) {return true} else {return false}}))));*/
    arLength = flexiblesData.gentotal.length;
    let techTotals = {};
    let techCosts = {};
    let marginalGenTechData = {};
    let marginalCapData = {};
    let marginalGenHourlyData = [];
    let flexCapacity = 0;
    for (let j = this.generation.length - 1; j >= 0; j--) {
      const tech = this.generation[j].id;
      const dataKey = tech + "Data";
      let dataSet = [];
      if (this.inflexibles.includes(tech)) {
        dataSet = update_states[dataKey] || this.state[dataKey];
      } else {
        dataSet = flexiblesData.generation[tech];
        flexCapacity +=
          this.generation[j].new.capacity + this.generation[j].current.capacity;
      }
      const stepSize = Math.round(
        (10 *
          (this.generation[j].current.availFactor *
            this.generation[j].current.capacity +
            this.generation[j].new.availFactor *
              this.generation[j].new.capacity)) /
          (this.generation[j].current.capacity +
            this.generation[j].new.capacity)
      ); // Multiply by 1000 for 1000MW steps, but divide by 100 for % => * 10
      //const stepSize = Math.round((100 * maxOutput) / mtLength) / 100;
      marginalGenTechData[tech] = [];
      marginalCapData[tech] = [];
      let reducingCap =
        this.generation[j].current.capacity + this.generation[j].new.capacity;
      //for (let i = 0; i <= mtLength; i++) {
      let i = 0;
      while (reducingCap > 0) {
        marginalCapData[tech][i] = Math.min(1000, reducingCap);
        if (this.inflexibles.includes(tech)) {
          marginalGenTechData[tech][i] = decRound(
            marginalCapData[tech][i] * stepSize * 8.76,
            2
          );
          // stepSize is hourly availability for 1000 MW, this requires annual (*8760)
          // but marginalCapData is also for 1000 MW, so have to divide by 1000
        } else {
          marginalGenTechData[tech][i] = 0;
        }
        reducingCap -= marginalCapData[tech][i];
        i++;
      }
      let techTotal = 0;
      for (let i = 0; i < arLength; i++) {
        let techActOutput = dataSet[i].y;
        techTotal += techActOutput;
        // Moved from calcFlexibles
        if (!marginalGenHourlyData[i]) {
          marginalGenHourlyData[i] = [];
        }
        let mtIndex = 0;
        let inflexCostArray = [];
        while (
          Math.floor(techActOutput) > 0 &&
          mtIndex < marginalCapData[tech].length
        ) {
          /*let thisStep = stepSize;
          if (stepSize > techActOutput) {
            thisStep = techActOutput;
          }*/
          const thisStep = Math.round(Math.min(stepSize, techActOutput));
          if (this.inflexibles.includes(tech)) {
            const inflexIndex = marginalCapData[tech].length - mtIndex - 1;
            const inflexAdjust = decRound(
              Math.min(
                Math.abs(flexiblesData.inflexAdjustments[i].y),
                thisStep,
                marginalGenTechData[tech][inflexIndex]
              ),
              2
            );
            /*if (flexiblesData.inflexAdjustments[i].y < 0) {
              console.log("inflexAdjust",{
                tech: tech,
                x: flexiblesData.inflexAdjustments[i].x,
                adjust: flexiblesData.inflexAdjustments[i].y,
                inflexIndex: inflexIndex,
                inflexAdjust: inflexAdjust,
                marginalGenTechData: marginalGenTechData[tech][inflexIndex],
              });
            } */
            marginalGenTechData[tech][inflexIndex] -= inflexAdjust;
            flexiblesData.inflexAdjustments[i].y += inflexAdjust;
            //const inflexStep = thisStep + flexiblesData.inflexAdjustments[j].y
            const inflexStep = decRound(thisStep - inflexAdjust, 2);
            techActOutput -= inflexStep;
            if (inflexStep < thisStep) {
              inflexCostArray.unshift({
                tech: tech,
                tranche: inflexIndex,
                output: inflexStep,
              });
            }
          } else {
            marginalGenTechData[tech][mtIndex] += thisStep;
            marginalGenHourlyData[i].push({
              tech: tech,
              tranche: mtIndex,
              output: thisStep,
            });
            techActOutput -= thisStep;
          }
          mtIndex++;
        }
        if (this.inflexibles.includes(tech)) {
          marginalGenHourlyData[i].push(...inflexCostArray);
        }
      }
      techTotals[tech] = techTotal;
      techCosts[tech] = {
        threshold: Math.ceil(this.generation[j].new.capacity / 1000),
        new: {
          variableCost: this.generation[j].new.variableCost,
          invariableCost: Math.round(
            1000 * this.generation[j].new.fixedCost +
              10 * this.wacc * this.generation[j].new.capitalCost
          ),
          // per MW, i.e. multiply both by 1,000 (prices in £/kW)
          // but divide wacc percentage by 100,
        },
        current: {
          variableCost: this.generation[j].current.variableCost,
          invariableCost: Math.round(
            1000 * this.generation[j].current.fixedCost +
              10 * this.wacc * this.generation[j].current.capitalCost
          ),
        },
      };
      const combCapacity =
        this.generation[j].current.capacity + this.generation[j].new.capacity;
      const loadFactor =
        combCapacity > 0
          ? decRound((100 * techTotal) / (combCapacity * arLength), 1)
          : 0;
      //console.log(tech,this.generation[j].current.capacity + " + " + this.generation[j].new.capacity);
      //console.log("techTotal",techTotal);
      this.generation[j].current.loadFactor = loadFactor;
      this.generation[j].new.loadFactor = loadFactor;
      if (typeof this.init.generation[j].current.loadFactor === "undefined") {
        this.init.generation[j].current.loadFactor = loadFactor;
        this.init.generation[j].new.loadFactor = loadFactor;
      }
    }

    console.log("Calculating cost data");
    //console.log("marginalGenTechData", marginalGenTechData);
    //console.log("marginalCapData", marginalCapData);
    //console.log("this.generation",this.generation);
    update_states.marginalGenTechData = marginalGenTechData;
    if (!this.state.initMarginalGenTechData) {
      update_states.initMarginalGenTechData = marginalGenTechData;
    }
    update_states.marginalCapData = marginalCapData;
    if (!this.state.initMarginalCapData) {
      update_states.initMarginalCapData = marginalCapData;
    }
    update_states.marginalGenHourlyData = marginalGenHourlyData;
    if (!this.state.initmarginalGenHourlyData) {
      update_states.initmarginalGenHourlyData = marginalGenHourlyData;
    }
    if (update_states.flexiblesData.inflexAdjustments) {
      delete update_states.flexiblesData.inflexAdjustments;
    }
    const wholesaleElecDemandData =
      update_states.wholesaleElecDemandData ||
      this.state.wholesaleElecDemandData;
    //console.log("wholesaleElecDemandData", wholesaleElecDemandData);

    //const halfFlexCapacity = flexCapacity / 2;
    // 6 steps either side of the average (/2 /6 = /12)
    const sigmoidStep = 1 + Math.floor(flexCapacity / 12);
    let demandShedTotal = 0;
    let demandShedTranches = []; // Array of objects per GW tranche
    // {count: X; price: Y; total: Z}
    const demandShedTrancheIncrement = 1.1;
    // For each GW tranche, the starting price for demand shedding is 10% more than previous tranche
    const demandShedRepeatIncrement = 1.01;
    // For each GW tranche, each time it is triggered, price increases by 1%
    const demandShedBasePrice = 200; // Starting price for demand shedding is £200/MWh

    const networkNewOutputShare =
      (this.totalRetailElecDemand * this.transmission.elec.new.capacity) /
      (this.transmission.elec.new.capacity +
        this.transmission.elec.current.capacity);
    const networkCurOutputShare =
      this.totalRetailElecDemand - networkNewOutputShare;
    const networkCurCost =
      1000 *
        this.transmission.elec.new.capacity *
        (this.transmission.elec.new.fixedCost +
          (this.wacc * this.transmission.elec.new.capitalCost) / 100) +
        this.transmission.elec.new.variableCost * networkCurOutputShare || 0;
    const networkNewCost =
      1000 *
        this.transmission.elec.current.capacity *
        (this.transmission.elec.current.fixedCost +
          (this.wacc * this.transmission.elec.current.capitalCost) / 100) +
        this.transmission.elec.current.variableCost * networkNewOutputShare ||
      0;
    const networkHourlyCost = decRound(
      (networkCurCost + networkNewCost) / arLength,
      2
    );
    const supplyHourlyCost =
      decRound(
        (10000 * this.supply.elec.capitalCost * this.wacc +
          1000000 * this.supply.elec.fixedCost +
          this.supply.elec.variableCost * this.totalRetailElecDemand) /
          arLength,
        2
      ) || 0;
    console.log("capex", 10000 * this.supply.elec.capitalCost * this.wacc);
    console.log("fixed", 1000000 * this.supply.elec.fixedCost);
    console.log(
      "variable",
      this.supply.elec.variableCost * this.totalRetailElecDemand
    );
    console.log("supplyHourlyCost", supplyHourlyCost);

    let costData = [];
    let storElecCostData = {};
    let icElecCostData = {};
    for (let tech of this.storage) {
      storElecCostData[tech.id] = [];
      tech.elecCost = 0;
    }
    for (let tech of this.interconnection) {
      icElecCostData[tech.id] = [];
      tech.elecCost = 0;
    }
    for (let category of ["convdemand", "heatsupply", "trnsptdemand"]) {
      for (let usage of this[category]) {
        if (typeof usage.elec !== "undefined") {
          usage.elecCost = 0;
        }
      }
    }
    for (let i = 0; i < arLength; i++) {
      let maxOpPrice = 0;
      let maxNetPrice = 0;
      let combCost = 0;
      let combOutput = 0;
      let inflexOutput = 0;
      //let mGHDcount = 0;
      for (let item of marginalGenHourlyData[i]) {
        const tech = item.tech;
        const tranche = item.tranche;
        if (marginalGenTechData[tech][tranche]) {
          const output = item.output;
          if (output > 0) {
            combOutput += output;
            if (this.inflexibles.includes(tech)) {
              inflexOutput += output;
            }
            const age =
              tranche >= techCosts[tech].threshold ? "current" : "new";
            const variableNet = decRound(
              techCosts[tech][age].variableCost * output,
              2
            );
            const invariableNet = decRound(
              (marginalCapData[tech][tranche] *
                techCosts[tech][age].invariableCost *
                output) /
                marginalGenTechData[tech][tranche],
              2
            );
            // Multiply by marginalCapData to get figure per 1000 MW for whole tranches,
            // or per X MW for last, partial tranche per technology. Important, otherwise
            // treating all as 1,000 MW means last tranche would be highly over-priced.
            const combinedNet = variableNet + invariableNet;
            combCost += combinedNet;
            const combinedPrice = decRound(combinedNet / output, 3);
            if (techCosts[tech][age].variableCost > maxOpPrice) {
              maxOpPrice = techCosts[tech][age].variableCost;
            }
            if (combinedPrice > maxNetPrice) {
              maxNetPrice = combinedPrice;
            }
          }
        }
        //mGHDcount++;
      }

      // Calculate demand shedding cost
      let dSPeriodCost = 0;
      let dSPeriodMWh = 0;
      if (flexiblesData.margin[i].y < 0) {
        for (let j = 0; j < flexiblesData.margin[i].y / -1000; j++) {
          if (typeof demandShedTranches[j] === "undefined") {
            demandShedTranches[j] = {
              count: 0,
              price: demandShedBasePrice * demandShedTrancheIncrement ** j,
              total: 0,
            };
          }
          const dSInvRemainder = flexiblesData.margin[i].y + j * 1000;
          const dSMWh = dSInvRemainder < -1000 ? 1000 : -1 * dSInvRemainder;
          const dSCost = dSMWh * demandShedTranches[j].price;
          demandShedTotal += dSCost;
          dSPeriodCost += dSCost;
          dSPeriodMWh += dSMWh;
          demandShedTranches[j].total += dSCost;
          demandShedTranches[j].count++;
          demandShedTranches[j].price *= demandShedRepeatIncrement;
        }
        //combCost += dSPeriodCost;
      }

      const genCost = combOutput ? decRound(combCost / combOutput, 2) : 0;
      costData.push({
        x: flexiblesData.gentotal[i].x,
        y: genCost,
        marginalOp: maxOpPrice,
        marginalNet: maxNetPrice,
        condition: combOutput > inflexOutput ? "Short" : "Long",
        dSPrice: 1 * (dSPeriodCost / dSPeriodMWh).toFixed(2),
        dSCost: dSPeriodCost,
      });

      const month = inflexImbalanceData[i].x.getMonth();
      //const hour = inflexImbalanceData[i].x.getHours();
      const dayOfWeek = inflexImbalanceData[i].x.getDay();
      const daytype =
        dayOfWeek === 0 || dayOfWeek === 6 ? "weekend" : "weekday";
      const sigmoidWeight =
        (inflexImbalanceData[i].y - this.iidAverages[month][daytype]) /
        sigmoidStep;
      const sigmoidExp = Math.exp(sigmoidWeight);
      const sigmoidScaler = sigmoidExp / (1 + sigmoidExp);
      const storPrice = maxOpPrice + (maxNetPrice - maxOpPrice) * sigmoidScaler;
      let storCost = 0;
      for (let tech of this.storage) {
        const storElecCost = decRound(
          storPrice * flexiblesData.storage[tech.id][i].y,
          2
        );
        storElecCostData[tech.id].push({
          x: flexiblesData.gentotal[i].x,
          y: storElecCost,
        });
        tech.elecCost -= storElecCost; // flow is negative when charging, but this
        // represents a cost to the storage owner. So negative value is positive
        // cost and vice versa. Subtracting storElecCost reverses sign appropriately.
        storCost += storElecCost; // Flows out are income to system.
        // Flows in to system are cost to system. Negative values = flows out.
        // storCost is used to add to system cost each hour, so should reduce cost
        // when flowing out (negative) and add to cost when flowing in (positive)
        // So keep sign. Opposite of tech.elecCost, which is the cost or value to storage operator
      }
      let icCost = 0;
      for (let tech of this.interconnection) {
        const icElecCost = decRound(
          storPrice * flexiblesData.interconnection[tech.id][i].y,
          2
        );
        //if (isNaN(icElecCost)) {
        //  console.log("IC " + flexiblesData.gentotal[i].x, icElecCost);
        //}
        icElecCostData[tech.id].push({
          x: flexiblesData.gentotal[i].x,
          y: icElecCost,
        });
        tech.elecCost += icElecCost; // flow is negative when exporting. The cost of
        // each interconnector to the GB system is export income minus import costs. So
        // imports (positive) should be costs and exports (negative) should be income.
        // But total costs are recorded as positive amounts, so don't reverse sign (unlike storage)
        icCost += icElecCost;
      }

      const estHourlyRetailDemand =
        (wholesaleElecDemandData[i].y *
          (100 - this.transmission.elec.distlosses)) /
        100;
      const nonGenHourlyPrice =
        (dSPeriodCost +
          supplyHourlyCost +
          networkHourlyCost +
          storCost +
          icCost) /
        estHourlyRetailDemand;
      //console.log("estHourlyRetailDemand", estHourlyRetailDemand);
      console.log("nonGenHourlyPrice", nonGenHourlyPrice);
      for (let category of ["convdemand", "heatsupply", "trnsptdemand"]) {
        for (let usage of this[category]) {
          if (typeof usage.elec !== "undefined" && usage.elec > 0) {
            const dataKey = usage.id + "Data";
            const usageData = update_states[dataKey] || this.state[dataKey];
            if (
              typeof usageData !== "undefined" &&
              typeof usageData[i] !== "undefined"
            ) {
              const useHourlyCost =
                usageData[i].y * (genCost + nonGenHourlyPrice);
              if (!useHourlyCost.isNaN) {
                usage.elecCost += useHourlyCost;
              }
            }
          }
        }
      }
    }
    for (let category of ["convdemand", "heatsupply", "trnsptdemand"]) {
      for (let i = 0; i < this[category].length; i++) {
        if (typeof this[category][i].elecCost !== "undefined") {
          this[category][i].elecCost = Math.round(this[category][i].elecCost);
          console.log(this[category][i].id, this[category][i].elecCost);
          if (typeof this.init[category][i].elecCost === "undefined") {
            this.init[category][i].elecCost = this[category][i].elecCost;
          }
        }
      }
    }
    for (let i = 0; i < this.storage.length; i++) {
      if (typeof this.init.storage[i].elecCost === "undefined") {
        this.init.storage[i].elecCost = this.storage[i].elecCost;
      }
    }
    for (let i = 0; i < this.interconnection.length; i++) {
      if (typeof this.init.interconnection[i].elecCost === "undefined") {
        this.init.interconnection[i].elecCost = this.interconnection[
          i
        ].elecCost;
      }
    }
    update_states.costData = costData;
    update_states.storElecCostData = storElecCostData;
    update_states.icElecCostData = icElecCostData;
    this.demandShedCost = decRound(demandShedTotal, 0);
    console.log("icElecCostData", this.interconnection);
    console.log("demandShedCost", this.demandShedCost);
    //console.log("demandShedTranches", demandShedTranches);

    // Calculate carbon etc
    console.log("Calculating carbon data");
    const aggData = {
      elec: {
        fuelconsumption: {},
        fuelemissions: {},
        constremissions: {},
      },
      heat: {
        gas: 0,
        oil: 0,
        coal: 0,
        biomass: 0,
        biogas: 0,
        bioliquids: 0,
      },
      transport: {},
    };
    for (let tech_id in this.elecConvEffic) {
      aggData.elec.fuelconsumption[tech_id] = decRound(
        techTotals[tech_id] / this.elecConvEffic[tech_id],
        2
      );
      aggData.elec.fuelemissions[tech_id] = decRound(
        aggData.elec.fuelconsumption[tech_id] * this.CO2e.fuel[tech_id],
        2
      );
    }
    for (let tech of this.generation) {
      aggData.elec.constremissions[tech.id] = decRound(
        this.CO2e.construction[tech.id] * tech.new.capacity,
        2
      );
    }
    for (let tech of this.storage) {
      aggData.elec.constremissions[tech.id] = decRound(
        this.CO2e.construction[tech.id] * tech.new.capacity,
        2
      );
    }
    for (let i = 0; i < this.interconnection.length; i++) {
      //let tech = this.interconnection[i];
      aggData.elec.constremissions[this.interconnection[i].id] = decRound(
        this.CO2e.construction.interconnection *
          (this.interconnection[i].capacity -
            this.init.interconnection[i].capacity),
        2
      );
      //console.log(tech.id, tech.capacity + " - " + tech.initCapacity + "; " + this.CO2e.construction.interconnection)
    }

    for (let techObj of this.heatsupply) {
      if (typeof techObj.fuel !== "undefined" && techObj.fuel > 0) {
        aggData.heat[techObj.id] = decRound(
          this.CO2e.fuel[techObj.id] * techObj.fuel,
          2
        );
      }
    }
    /* 
    let heatingFuel = this.getItem(this.heatsupply, "gas");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.gas = decRound(this.CO2e.fuel.gas * heatingFuel.fuel, 2);
    }
    heatingFuel = this.getItem(this.heatsupply, "oil");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.oil = decRound(this.CO2e.fuel.oil * heatingFuel.fuel, 2);
    }
    heatingFuel = this.getItem(this.heatsupply, "coal");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.coal = decRound(this.CO2e.fuel.coal * heatingFuel.fuel, 2);
    }
    heatingFuel = this.getItem(this.heatsupply, "bioliquids");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.bioliquids = decRound(
        this.CO2e.fuel.biodiesel * heatingFuel.fuel,
        2
      );
    }
    heatingFuel = this.getItem(this.heatsupply, "biomassHeat");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.biomass = decRound(
        this.CO2e.fuel.biomass * heatingFuel.fuel,
        2
      );
    }
    heatingFuel = this.getItem(this.heatsupply, "biomassCHP");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.biomass += decRound(
        this.CO2e.fuel.biomass * heatingFuel.fuel,
        2
      );
    }
    heatingFuel = this.getItem(this.heatsupply, "biogasAD");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.biogas = decRound(
        this.CO2e.fuel.biogas * heatingFuel.fuel,
        2
      );
    }
    heatingFuel = this.getItem(this.heatsupply, "biogasification");
    if (
      typeof heatingFuel === "object" &&
      typeof heatingFuel.fuel !== "undefined" &&
      heatingFuel.fuel > 0
    ) {
      aggData.heat.biogas += decRound(
        this.CO2e.fuel.biogas * heatingFuel.fuel,
        2
      );
    } 
    */
    //	 console.log("aggData.heat",aggData.heat);

    /*    let nonElecBuildHeat = this.buildingheat;
    for (let item of this.heatsupply) {
		console.log(nonElecBuildHeat,item.heat);
      nonElecBuildHeat -= item.heat;
    }
    aggData.heat.building = decRound(
      nonElecBuildHeat *
        (0.79 * this.CO2e.fuel.gas + // Gas (share from UKEC2018 * CO2e)
        0.09 * this.CO2e.fuel.oil + // Oil
        0.02 * this.CO2e.fuel.coal + // Coal-based fuels
          0.1 * this.CO2e.fuel.biomass), // Biomass/biogas
      2
    );
	 console.log("Building CO2:",aggData.heat.building);
    aggData.heat.cooking = decRound(
      19.6 *
        (0.73 * this.CO2e.fuel.gas + // Gas
          0.27 * this.CO2e.fuel.oil), // Oil
      2
    );
    aggData.heat.process = decRound(
      77.2 *
        (0.8 * this.CO2e.fuel.gas + // Gas
        0.065 * this.CO2e.fuel.oil + // Oil
          0.135 * this.CO2e.fuel.coal), // Coal
      2
    );
    aggData.heat.drying = decRound(
      13.8 *
        (0.83 * this.CO2e.fuel.gas + // Gas
        0.06 * this.CO2e.fuel.oil + // Oil
          0.11 * this.CO2e.fuel.coal), // Coal
      2
    ); */

    for (let item of this.trnsptdemand) {
      aggData.transport[item.id] = decRound(
        (item.total - item.elec) * this.CO2e.fuel.oil,
        2
      );
    }
    //console.log("aggData",aggData);
    update_states.aggData = aggData;
    if (!this.state.initAggData.elec) {
      update_states.initAggData = aggData;
    }

    //console.log("update_states", update_states);
    this.setState(update_states);
    console.log("Completed");
  }

  calcFlexibles(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    console.log("Starting calcFlexibles");
    let flexiblesData = {};
    const iIData = params.inflexImbalanceData || this.state.inflexImbalanceData;
    console.log("iIData", iIData);
    if (iIData) {
      const arLength = iIData.length;
      if (arLength) {
        /* const iIVals = iIData.map((obj) => {
          return obj.y;
        });
        const midway = Math.floor(arLength / 2);
        const iIMedian = iIVals[midway];
        const upperMid = Math.floor(iIMedian * 1.15);
        const lowerMid = Math.floor(iIMedian * 0.85);
        console.log("midway", midway);
        console.log("iIMedian", iIMedian);
        console.log("upperMid", upperMid);
        console.log("lowerMid", lowerMid); */
        /*
         * Restored section to calculate averages per month for weekdays and weekends
         * Was used to make storage decisions switch around the central value for the day
         * That makes storage charge/discharge artificially frequently.
         * In reality, the value of discharging / cost of charging will be determined
         * by the marginal price of electricity, which depends on the capacity margin
         * regardless of the season/day-of-the-week. Simplifying to choose whether to charge/discharge
         * based on whether the demand for flexibles is more than 15% above or below the average (35% gap).
         * This assumes (as elsewhere in model) a sigmoid curve in marginal pricing, where
         * high % means marginal price approaches marginal net cost (i.e. recovering fixed costs too)
         * and low % means marginal price approaches marginal op cost.
         * Gap between 60% and 40% allows for price-gap to
         */
        // Get average imbalance values per month, weekend/weekday, and
        // hour to get typical range (eliminates seasonality and outliers)
        let iidPeriods = [];
        let iidDateParts = [];
        for (let j = 0; j < arLength; j++) {
          const month = iIData[j].x.getMonth();
          const hour = iIData[j].x.getHours();
          const dayOfWeek = iIData[j].x.getDay();
          const daytype =
            dayOfWeek === 0 || dayOfWeek === 6 ? "weekend" : "weekday";
          if (!iidPeriods[month]) {
            iidPeriods[month] = { weekday: [], weekend: [] };
          }
          if (!iidPeriods[month][daytype][hour]) {
            iidPeriods[month][daytype][hour] = {
              hour: hour,
              total: 0,
              count: 0,
            };
          }
          iidPeriods[month][daytype][hour].total += iIData[j].y;
          iidPeriods[month][daytype][hour].count++;
          iidDateParts.push({ month: month, daytype: daytype, hour: hour });
        }

        const iidAverages = [];
        for (let i = 0; i < 12; i++) {
          iidAverages[i] = { weekend: 0, weekday: 0 };

          const wkendAverages = iidPeriods[i].weekend.map((item) =>
            Math.round(item.total / item.count)
          );
          //iidAverages[i].weekend.low = wkendAverages[0];
          //iidAverages[i].weekend.high = wkendAverages[wkendAverages.length - 1];
          iidAverages[i].weekend = Math.round(
            wkendAverages.reduce((x, y) => x + y) / wkendAverages.length
          );

          const wkdayAverages = iidPeriods[i].weekday.map((item) =>
            Math.round(item.total / item.count)
          );
          //iidAverages[i].weekday.low = wkdayAverages[0];
          //iidAverages[i].weekday.high = wkdayAverages[wkdayAverages.length - 1];
          iidAverages[i].weekday = Math.round(
            wkdayAverages.reduce((x, y) => x + y) / wkdayAverages.length
          );
        }
        this.iidAverages = iidAverages;
        console.log("iidAverages", iidAverages);
        /*
         * End of commented-out section
         */

        flexiblesData = {
          generation: {},
          gentotal: [],
          storage: {},
          storbalance: {},
          stortotal: [],
          interconnection: {},
          ictotal: [],
          inflexAdjustments: [],
          margin: [],
        };
        let genTechIds = [];
        let flexGenActCapacity = 0;
        for (let i = 0; i < this.generation.length; i++) {
          if (!this.inflexibles.includes(this.generation[i].id)) {
            //console.log("matched");
            const maxCurrentOutput =
              Math.round(
                this.generation[i].current.capacity *
                  this.generation[i].current.availFactor
              ) / 100;
            const maxNewOutput =
              Math.round(
                this.generation[i].new.capacity *
                  this.generation[i].new.availFactor
              ) / 100;
            const maxOutput = maxCurrentOutput + maxNewOutput;
            genTechIds.push({
              index: i,
              id: this.generation[i].id,
              maxOutput: {
                current: maxCurrentOutput,
                new: maxNewOutput,
              },
            });
            flexGenActCapacity += maxOutput;
            this.generation[i].MWhpa = 0;
            flexiblesData.generation[this.generation[i].id] = [];
          }
        }
        //console.log("genTechIds",genTechIds);
        //console.log("icprofiles",this.icprofiles);
        //console.log("flexGenActCapacity",flexGenActCapacity);

        //const dischargeDenominator = flexGenActCapacity - upperMid;

        for (let i = 0; i < this.storage.length; i++) {
          flexiblesData.storage[this.storage[i].id] = [];
          flexiblesData.storbalance[this.storage[i].id] = [];
          this.storage[i].chargekWh = 0;
          this.storage[i].dischargekWh = 0;
          this.storage[i].elecCost = 0;
          // Set starting condition of storage arbitrarily at half-full
          // Starting at empty or full creates unwanted artefacts, where
          // storage cannot charge or discharge even if conditions indicate
          for (let age of ["new", "current"]) {
            this.storage[i][age].balance = Math.round(
              this.storage[i][age].capacity / 2
            );
          }
        }

        for (let i = 0; i < this.interconnection.length; i++) {
          flexiblesData.interconnection[this.interconnection[i].id] = [];
          this.interconnection[i].exportMWh = 0;
          this.interconnection[i].importMWh = 0;
        }

        for (let j = 0; j < arLength; j++) {
          flexiblesData.stortotal[j] = { x: iIData[j].x, y: 0 };
          flexiblesData.ictotal[j] = { x: iIData[j].x, y: 0 };
          flexiblesData.gentotal[j] = { x: iIData[j].x, y: 0 };
          flexiblesData.inflexAdjustments[j] = { x: iIData[j].x, y: 0 };

          const month = iidDateParts[j].month;
          const daytype = iidDateParts[j].daytype;
          const iidAverage = iidAverages[month][daytype];

          // eslint-disable-next-line no-unused-vars
          for (
            let techIndex = this.storage.length - 1;
            techIndex >= 0;
            techIndex--
          ) {
            flexiblesData.storage[this.storage[techIndex].id][j] = {
              x: iIData[j].x,
              y: 0,
            };
          }

          // If the inflexible imbalance is more than the average
          // for month and daytype, discharge storage
          let storageFlow = 0;
          if (iIData[j].y > iidAverage) {
            //if (iIData[j].y > upperMid) {
            let declBalance = iIData[j].y - iidAverage;
            //let declBalance = iIData[j].y - upperMid;
            for (
              let techIndex = this.storage.length - 1;
              techIndex >= 0;
              techIndex--
            ) {
              // eslint-disable-next-line no-unused-vars
              for (let age of ["new", "current"]) {
                const neutralScale =
                  (100 - this.storage[techIndex][age].efficiency) / 200;
                const hurdleRate = Math.floor(iidAverage * (1 + neutralScale));
                if (iIData[j].y > hurdleRate) {
                  const dischargeDenominator = flexGenActCapacity - hurdleRate;
                  const maxFlow =
                    iIData[j].y - hurdleRate > dischargeDenominator
                      ? this.storage[techIndex][age].power
                      : ((iIData[j].y - hurdleRate) *
                          this.storage[techIndex][age].power) /
                        dischargeDenominator;
                  const actDischarge = decRound(
                    Math.min(
                      this.storage[techIndex][age].balance,
                      maxFlow,
                      //this.storage[techIndex][age].power,
                      declBalance
                    ),
                    2
                  );
                  this.storage[techIndex][age].balance -= actDischarge;
                  flexiblesData.storage[this.storage[techIndex].id][
                    j
                  ].y += actDischarge;
                  flexiblesData.stortotal[j].y += actDischarge;
                  storageFlow += actDischarge;
                  this.storage[techIndex].dischargekWh += actDischarge;
                  declBalance -= actDischarge;
                }
              }
            }
          }

          // If the inflexible imbalance is less than the average,
          // charge storage
          else {
            // if (iIData[j].y < lowerMid) {
            // else if (iIData[j].y * 1.1 < central) {
            // let declBalance = central - iIData[j].y;
            let declBalance = iidAverage - iIData[j].y;
            // eslint-disable-next-line no-unused-vars
            for (
              let techIndex = this.storage.length - 1;
              techIndex >= 0;
              techIndex--
            ) {
              /* flexiblesData.storage[this.storage[techIndex].id][j] = {
                x: iIData[j].x,
                y: 0,
              }; */
              // eslint-disable-next-line no-unused-vars
              for (let age of ["new", "current"]) {
                const neutralScale =
                  (100 - this.storage[techIndex][age].efficiency) / 200;
                const hurdleRate = Math.floor(iidAverage * (1 - neutralScale));
                if (iIData[j].y < hurdleRate) {
                  const maxFlow =
                    iIData[j].y < 0
                      ? this.storage[techIndex][age].power
                      : ((hurdleRate - iIData[j].y) *
                          this.storage[techIndex][age].power) /
                        hurdleRate;
                  const space =
                    this.storage[techIndex][age].capacity -
                    this.storage[techIndex][age].balance;
                  const maxCharge =
                    (100 * space) / this.storage[techIndex][age].efficiency <
                    maxFlow
                      ? //this.storage[techIndex][age].power
                        space
                      : //  : this.storage[techIndex][age].power;
                        maxFlow;
                  const actCharge = decRound(
                    maxCharge > declBalance ? declBalance : maxCharge,
                    2
                  );
                  this.storage[techIndex][age].balance +=
                    (actCharge * this.storage[techIndex][age].efficiency) / 100;
                  flexiblesData.storage[this.storage[techIndex].id][
                    j
                  ].y -= actCharge;
                  flexiblesData.stortotal[j].y -= actCharge;
                  storageFlow -= actCharge;
                  this.storage[techIndex].chargekWh += actCharge;
                  declBalance -= actCharge;
                }
              }
            }
          }
          /*
          // Else if flexibles output is within 10% of the central value for month and daytype,
          // Neither charge nor discharge
          else {
            for (
              let techIndex = this.storage.length - 1;
              techIndex >= 0;
              techIndex--
            ) {
              flexiblesData.storage[this.storage[techIndex].id][j] = {
                x: iIData[j].x,
                y: 0,
              };
            }
          }
          */

          for (
            let techIndex = this.storage.length - 1;
            techIndex >= 0;
            techIndex--
          ) {
            flexiblesData.storbalance[this.storage[techIndex].id][j] = {
              x: iIData[j].x,
              y:
                this.storage[techIndex].current.balance +
                this.storage[techIndex].new.balance,
            };
          }

          // Now calculate interconnector flows based on historic
          // profile and current demand.
          // If flow strongly positive (into UK), assume exporter
          // needed to push upto the historic amount (or max capacity
          // if connector capacity reduced) and accommodate if possible,
          // plus more pro rata if capacity has been increased and UK
          // balance is tight.
          // If flow strongly negative (out of UK), assume importer
          // needed supply to balance network and accommodate upto the
          // historic amount (or max capacity if reduced) if possible,
          // plus more pro rata if capacity has been increased and UK
          // has plenty of slack.
          // If flow is weekly positive or negative or zero, assume overseas
          // partner has some slack to supply or take as suits the UK, pro
          // rata to need, but at modest levels.
          // Strong = >70% of capacity
          // Need to know max flexible generating output to compare with
          // inflexible imbalance and storage flows to judge if UK can
          // meet external demand or needs to import
          // Need to work out what order to calculate ICs for each period,
          // because some may be importing and some exporting, e.g. UK as
          // transit if France has excess and Ireland has insufficient.
          // Also need to know if inflexible imbalance is negative, as UK
          // will look to push everything it can to rebalance to zero or
          // positive.
          let netDemand = decRound(iIData[j].y - storageFlow, 2);
          let marketTightness = (100 * netDemand) / flexGenActCapacity;
          //if (!(j % 1000)) {
          //  console.log("netDemand",netDemand);
          //}
          const icprofileperiod = this.icprofiles[year][j];
          // icOrder holds the indexes of the items in genTechIds,
          // sorted from lowest negative (outflow) to highest
          // positive (inflow), for this period
          let icOrder = genTechIds
            .sort(function (a, b) {
              return icprofileperiod[a.id] - icprofileperiod[b.id];
            })
            .map((item, index) => index);
          // If market is tight, work through ICs from largest historic
          // positive (inflow) to largest negative (outflow)
          if (marketTightness > 50) {
            icOrder.reverse();
          }
          for (let ic = 0; ic < icOrder.length; ic++) {
            const icID = this.interconnection[icOrder[ic]].id;
            //console.log("icprofile",icprofileperiod);
            const histLF = icprofileperiod[icID];
            let icFlow = 0;
            // If market is tight (<10% margin), take as much from
            // interconnectors as possible
            if (marketTightness > 90) {
              const icLF = (histLF + 100) / 2;
              icFlow =
                (icLF * this.interconnection[icOrder[ic]].capacity) / 100;
            }
            // If market is so oversupplied that less than 10% of flexible
            // generating capacity is required (including where infleximbalance
            // is negative), dump as much as possible through interconnectors.
            else if (marketTightness < 10) {
              const icLF = (histLF - 100) / 2;
              icFlow =
                (icLF * this.interconnection[icOrder[ic]].capacity) / 100;
            }
            // If interconnector was at >70% export in this period, assume
            // overseas partner needs it and calculate how much we can export.
            // Or if interconnector was at >70% import, assume overseas
            // partner needed to dump it and calculate how much we can absorb.
            // Otherwise, assume interconnector will flow based on
            // average of current need and historic flow (as percentages).
            else {
              if (histLF > 70) {
                icFlow =
                  //(histLF * this.interconnection[icOrder[ic]].initCapacity) /
                  (histLF * this.init.interconnection[icOrder[ic]].capacity) /
                    100 +
                  (marketTightness *
                    (this.interconnection[icOrder[ic]].capacity -
                      //this.interconnection[icOrder[ic]].initCapacity)) /
                      this.init.interconnection[icOrder[ic]].capacity)) /
                    100;
              } else if (histLF < -70) {
                icFlow =
                  //(histLF * this.interconnection[icOrder[ic]].initCapacity) /
                  (histLF * this.init.interconnection[icOrder[ic]].capacity) /
                    100 -
                  ((90 - marketTightness) *
                    (this.interconnection[icOrder[ic]].capacity -
                      //this.interconnection[icOrder[ic]].initCapacity)) /
                      this.init.interconnection[icOrder[ic]].capacity)) /
                    100;
              } else {
                const icLF = (histLF + marketTightness - 30) / 2;
                //console.log("this.interconnection", this.interconnection[icOrder[ic]]);
                icFlow =
                  (icLF * this.interconnection[icOrder[ic]].capacity) / 100;
              }
            }
            icFlow = decRound(icFlow, 2);
            /*if (!(j % 2000)) {
              console.log({
                date: iIData[j].x,
                tech: icID, 
                marketTightness: marketTightness,
                netDemand: netDemand,
                histLF: histLF,
                icFlow: icFlow,
              });
            }*/
            flexiblesData.interconnection[icID][j] = {
              x: iIData[j].x,
              y: icFlow,
            };
            flexiblesData.ictotal[j].y = decRound(
              flexiblesData.ictotal[j].y + icFlow,
              2
            );
            netDemand = decRound(netDemand - icFlow, 2);
            marketTightness = (100 * netDemand) / flexGenActCapacity;
            if (icFlow > 0) {
              this.interconnection[icOrder[ic]].importMWh += icFlow;
            } else {
              this.interconnection[icOrder[ic]].exportMWh -= icFlow;
            }
          }

          const needed = netDemand;
          let potential = 0;
          //marginalGenHourlyData[j] = [];
          for (let i = 0; i < genTechIds.length; i++) {
            const tech = genTechIds[i].id;
            flexiblesData.generation[tech][j] = { x: iIData[j].x, y: 0 };
            //let techActOutput = 0;
            // eslint-disable-next-line no-unused-vars
            for (let age of ["new", "current"]) {
              if (netDemand > 0) {
                const maxOutput = genTechIds[i].maxOutput[age];
                //  (this.generation[genTechIds[i].index][age].capacity *
                //    this.generation[genTechIds[i].index][age].availFactor) /
                //  100;
                potential += maxOutput;
                const actOutput = decRound(
                  maxOutput > netDemand ? netDemand : maxOutput,
                  2
                );
                //techActOutput += actOutput;
                netDemand = decRound(netDemand - actOutput, 2);
                flexiblesData.generation[tech][j].y = decRound(
                  flexiblesData.generation[tech][j].y + actOutput,
                  2
                );
                flexiblesData.gentotal[j].y = decRound(
                  flexiblesData.gentotal[j].y + actOutput,
                  2
                );
                this.generation[genTechIds[i].index].MWhpa += actOutput;
              }
            }
            /*const stepSize = genTechIds[i].stepSize;
            let mtIndex = 0;
            while (techActOutput > 0) {
              let thisStep = stepSize;
              if (stepSize > techActOutput) {
                thisStep = techActOutput;
              } 
              marginalGenTechData[tech][mtIndex] += Math.round(thisStep);
              marginalGenHourlyData[j].push({
                tech: tech,
                tranche: mtIndex,
                output: thisStep,
              });
              techActOutput -= thisStep;
              mtIndex++;
            } */
          }

          const margin = potential - needed;
          flexiblesData.margin[j] = { x: iIData[j].x, y: margin };

          if (netDemand < 0) {
            flexiblesData.inflexAdjustments[j].y = netDemand;
          }
        }

        //for (let techIndex of [1, 0]) {
        for (
          let techIndex = this.storage.length - 1;
          techIndex >= 0;
          techIndex--
        ) {
          if (typeof this.init.storage[techIndex].chargekWh === "undefined") {
            this.init.storage[techIndex].chargekWh = this.storage[
              techIndex
            ].chargekWh;
          }
          if (
            typeof this.init.storage[techIndex].dischargekWh === "undefined"
          ) {
            this.init.storage[techIndex].dischargekWh = this.storage[
              techIndex
            ].dischargekWh;
          }
        }

        for (
          let techIndex = 0;
          techIndex < this.generation.length;
          techIndex++
        ) {
          if (typeof this.init.generation[techIndex].MWhpa === "undefined") {
            this.init.generation[techIndex].MWhpa = this.generation[
              techIndex
            ].MWhpa;
          }
        }

        for (
          let techIndex = 0;
          techIndex < this.interconnection.length;
          techIndex++
        ) {
          if (
            typeof this.init.interconnection[techIndex].importMWh ===
            "undefined"
          ) {
            this.init.interconnection[
              techIndex
            ].importMWh = this.interconnection[techIndex].importMWh;
          }
          if (
            typeof this.init.interconnection[techIndex].exportMWh ===
            "undefined"
          ) {
            this.init.interconnection[
              techIndex
            ].exportMWh = this.interconnection[techIndex].exportMWh;
          }
        }

        /*flexiblesData.marginalGenTechData = marginalGenTechData;
        flexiblesData.marginalGenHourlyData = marginalGenHourlyData;
        console.log("marginalGenTechData", marginalGenTechData);
        console.log("marginalGenHourlyData", marginalGenHourlyData);*/
      }
    }
    /*const topkeys = Object.keys(flexiblesData);
    //console.log("topkeys", topkeys);
    const subkeys = {};
    for (let i = 0; i < topkeys.length; i++) {
      if (
        typeof flexiblesData[topkeys[i]] === "object" &&
        !Array.isArray(flexiblesData[topkeys[i]])
      ) {
        subkeys[topkeys[i]] = Object.keys(flexiblesData[topkeys[i]]);
      }
    }
    //console.log("subkeys", subkeys);
    for (let i = 0; i < 8750; i += 2000) {
      let dataPoint = { x: flexiblesData.gentotal[i].x, iid: iIData[i].y };
      for (let topkey of topkeys) {
        if (subkeys[topkey]) {
          dataPoint[topkey] = {};
          for (let subkey of subkeys[topkey]) {
            dataPoint[topkey][subkey] = flexiblesData[topkey][subkey][i].y;
          }
        } else {
          dataPoint[topkey] = flexiblesData[topkey][i].y;
        }
      }
      console.log("dataPoint", dataPoint);
    }*/

    //console.log("flexiblesData.storage", flexiblesData.storage);
    console.log("Finishing calcFlexibles");
    return new Promise(function (resolve, reject) {
      if (
        flexiblesData.generation &&
        flexiblesData.generation.gas &&
        flexiblesData.generation.gas.length
      ) {
        resolve(flexiblesData);
      } else {
        reject(Error("The flexiblesData object was undefined or empty."));
      }
    });
  }

  calcWholesaleElecDemand(params) {
    params = params || {};
    params.distlosses =
      params.distlosses || this.transmission.elec.distlosses || 5;
    params.lightingData = params.lightingData || this.state.lightingData;
    params.airconData = params.airconData || this.state.airconData;
    params.resistanceData = params.resistanceData || this.state.resistanceData;
    params.ashpData = params.ashpData || this.state.ashpData;
    params.gshpData = params.gshpData || this.state.gshpData;
    params.roadData = params.roadData || this.state.roadData;
    params.railData = params.railData || this.state.railData;

    //const lossFactor = 100 / (100 - distlosses);
    const arLength = params.lightingData.length;
    const wholesaleElecDemandData = [];
    let retailElecDemandTotal = 0;
    let wholesaleElecDemandTotal = 0;
    for (let i = 0; i < arLength; i++) {
      let lightingDatum = 0;
      let airconDatum = 0;
      let resistanceDatum = 0;
      let ashpDatum = 0;
      let gshpDatum = 0;
      let roadDatum = 0;
      let railDatum = 0;
      if (params.lightingData[i]) {
        lightingDatum = params.lightingData[i].y;
      }
      if (params.airconData[i]) {
        airconDatum = params.airconData[i].y;
      }
      if (params.resistanceData[i]) {
        resistanceDatum = params.resistanceData[i].y;
      }
      if (params.ashpData[i]) {
        ashpDatum = params.ashpData[i].y;
      }
      if (params.gshpData[i]) {
        gshpDatum = params.gshpData[i].y;
      }
      if (params.roadData[i]) {
        roadDatum = params.roadData[i].y;
      }
      if (params.railData[i]) {
        railDatum = params.railData[i].y;
      }
      const retailElecDemand =
        lightingDatum +
        airconDatum +
        resistanceDatum +
        ashpDatum +
        gshpDatum +
        roadDatum +
        railDatum;
      /*const lossFactor = 100.5 + (distlosses * 325/Math.pow((retailElecDemand/1000)-4,2));
      if (lossFactor > 125 + distlosses) {
        lossFactor = 125 + distlosses;
      }
      const wholesaleElecDemand = decRound(lossFactor * retailElecDemand / 100,2);*/
      wholesaleElecDemandData.push({
        x: params.lightingData[i].x,
        y: retailElecDemand,
      });
      retailElecDemandTotal += retailElecDemand;
      //wholesaleElecDemandTotal += wholesaleElecDemand;
    }
    this.totalRetailElecDemand = retailElecDemandTotal;
    const avgRetailElecDemand = retailElecDemandTotal / arLength;
    const avgLossIncrement =
      Math.round(params.distlosses * avgRetailElecDemand) / 100;
    let wholesaleElecDemandMax = 0;
    for (let i = 0; i < arLength; i++) {
      const lossFactor = Math.round(
        (avgLossIncrement * avgRetailElecDemand ** 0.5) /
          wholesaleElecDemandData[i].y ** 0.5
      );
      wholesaleElecDemandData[i].y += lossFactor;
      if (wholesaleElecDemandData[i].y > wholesaleElecDemandMax) {
        wholesaleElecDemandMax = wholesaleElecDemandData[i].y;
      }
      wholesaleElecDemandTotal += wholesaleElecDemandData[i].y;
    }
    this.maxWholesaleElecDemand = wholesaleElecDemandMax;
    this.totalWholesaleElecDemand = wholesaleElecDemandTotal;
    if (
      wholesaleElecDemandMax >
      this.transmission.elec.current.capacity +
        this.transmission.elec.new.capacity
    ) {
      this.transmission.elec.new.capacity =
        Math.ceil(
          (wholesaleElecDemandMax - this.transmission.elec.current.capacity) /
            10
        ) * 10;
    }
    if (!this.init.maxWholesaleElecDemand) {
      this.init.maxWholesaleElecDemand = wholesaleElecDemandMax;
    }
    if (!this.init.totalWholesaleElecDemand) {
      this.init.totalWholesaleElecDemand = wholesaleElecDemandTotal;
    }
    if (!this.init.totalRetailElecDemand) {
      this.init.totalRetailElecDemand = retailElecDemandTotal;
    }

    //console.log("Total retailElecDemand", retailElecDemandTotal);
    //console.log("Total wholesaleElecDemand", wholesaleElecDemandTotal);
    return wholesaleElecDemandData;
  }

  /* setWholesaleElecDemand(update_states) {
    //params = params || {};
    update_states = update_states || {};
    this.setState({ isLoading: true });
    update_states.wholesaleElecDemandData = this.calcWholesaleElecDemand(
      update_states
    );
    this.setInflexImbalance(update_states);
    //params.wholesaleElecDemandData = update_states.wholesaleElecDemandData;
    //update_states.inflexImbalanceData = this.calcInflexImbalance(update_states);
    //params.inflexImbalanceData = update_states.inflexImbalanceData;
    //  this.calcFlexibles(update_states)
    //   .then(flexiblesData => {
    //     update_states.flexiblesData = flexiblesData;
        
    //     this.setState(update_states);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
      
  } */

  calcLighting(params) {
    params = params || {};
    const weather = params.weather || this.state.weather || "normal";
    const year = params.year || this.state.year || 2017;
    let lightingTotal = 0;
    let lightingData = [];
    if (this.demandprofiles[year] && this.demandprofiles[year][weather]) {
      const arLength = this.demandprofiles[year][weather].length;
      if (arLength > 0) {
        for (let i = 0; i < arLength; i++) {
          let yval =
            this.demandprofiles[year][weather][i].elecdemand *
            this.convdemand[0].elec;
          if (isNaN(yval)) {
            yval = 0;
          }
          lightingData.push({
            x: new Date(this.demandprofiles[year][weather][i].x),
            y: yval,
          });
          lightingTotal += yval;
        }
        console.log("calculated lighting data", lightingTotal.toFixed());
      }
    }
    this.totals.elec.lighting = 1 * lightingTotal.toFixed();
    return lightingData;
  }

  /*setLighting(update_states) {
    update_states = update_states || {};
    update_states.lightingData = this.calcLighting(update_states);
    this.setWholesaleElecDemand(update_states);
  }*/

  //railwkdayprofile = [1,1,1,1,1,2,4,7,9,7,4,3,3,3,3,4,7,9,9,7,5,4,3,2];
  //railwkendprofile = [2,2,2,2,2,3,5,6,7,6,4,3,3,3,3,5,6,6,6,6,6,5,4,3];
  //roadrailweekprofile = [8,16.8,16.8,16.8,16.8,16.8,8]; // day 0 = Sunday
  //roadqtrprofile = [23.5,25.5,26,25];
  //roaddayprofile = [8,8,8,9,9,6,5,3,1,2,3,3,3,3,2,2,1,1,1,2,2,4,6,8];

  calcRail(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    let datetime = moment([year, 0, 1, 0, 0, 0, 0]);
    let railData = [];
    let railTotal = 0;
    while (datetime.year() === year) {
      const dayofyear = datetime.dayOfYear();
      let quarter = Math.floor(dayofyear / 91);
      let weeksqtr = 13;
      if (quarter > 3) {
        quarter = 3;
        weeksqtr = 13.2;
      }
      const quarterly = 250000 * this.trnsptdemand[1].elec;
      const weekly = quarterly / weeksqtr;
      const dayofweek = datetime.day();
      const daily = (weekly * this.roadrailweekprofile[dayofweek]) / 100;
      const hourproportion =
        dayofweek === 0 || dayofweek === 6
          ? this.railwkendprofile[datetime.hour()]
          : this.railwkdayprofile[datetime.hour()];
      const hourly = Math.round((hourproportion * daily) / 10) / 10;
      railData.push({ x: datetime.toDate(), y: hourly });
      railTotal += hourly;

      datetime.add(1, "h");
    }
    this.totals.elec.rail = 1 * railTotal.toFixed();
    console.log("calculated rail data", this.totals.elec.rail);
    return railData;
  }

  /*setRail(update_states) {
    update_states = update_states || {};
    update_states.railData = this.calcRail(update_states);
    this.setWholesaleElecDemand(update_states);
  }*/

  /* calcResistance(params) {
    params = params || {};
    const weather = params.weather || this.state.weather || "normal";
    const year = params.year || this.state.year || 2017;
    const heatingFuel = this.getItem(this.heatsupply, "resistance");
    const initHeatDemand =
      params.initHeatDemand || this.calcHeatDemand(this.init.heatdemand);
    const heatDemand =
      params.heatDemand || this.calcHeatDemand(this.heatdemand);
    const nonSeasonalPart = this.heatSplit({
      heatDemand: heatDemand,
      initHeatDemand: initHeatDemand,
    });
    const seasonalPart = 1 - nonSeasonalPart;
    const hourPerYear = 1000000 / 8760;
    let resistanceTotal = 0;
    let resistanceData = [];
    if (this.demandprofiles[year] && this.demandprofiles[year][weather]) {
      const arLength = this.demandprofiles[year][weather].length;
      if (arLength > 0) {
        for (let i = 0; i < arLength; i++) {
          const hourlyShare =
            this.demandprofiles[year][weather][i].heatdemand * seasonalPart +
            hourPerYear * nonSeasonalPart;
          //            / 2;
          let yval = hourlyShare * heatingFuel.elec;
          resistanceData.push({
            x: new Date(this.demandprofiles[year][weather][i].x),
            y: yval,
          });
          resistanceTotal += yval;
        }
      }
    }

    //    if (!weather) {
    //   weather = this.state.weather;
    // }
    // //let resistanceTotal = 0;
    // let resistanceData = [];
    // const weatherData = this.calcWeather(weather);
    // if (weatherData && weatherData.heatdemand) {
    //   const heatdemand = weatherData.heatdemand;
    //   //const heatTotal = weatherData.heatTotal;
    //   const arLength = heatdemand.length;
    //   for (let i = 0; i < arLength; i++) {
    //     const y = this.heatdemand[0].elec * heatdemand[i].y;
    //     // (1000000 * this.heatdemand[0].elec * heatdemand[i].y) / heatTotal;
    //     resistanceData.push({
    //       x: new Date(heatdemand[i].x),
    //       y: y,
    //     });
    //     //resistanceTotal += y;
    //   }
    // }
    this.totals.elec.resistance = 1 * resistanceTotal.toFixed();
    console.log("calculated resistance heat elec", this.totals.elec.resistance);
    return resistanceData;
  } */

  /* setResistance(update_states) {
    update_states = update_states | {};
    update_states.resistanceData = this.calcResistance(update_states);
    this.setWholesaleElecDemand(update_states);
  } */

  calcRoad(params) {
    params = params || {};
    const year = params.year || this.state.year || 2017;
    let datetime = moment([year, 0, 1, 0, 0, 0, 0]);
    let roadTotal = 0;
    let roadData = [];
    while (datetime.year() === year) {
      const dayofyear = datetime.dayOfYear();
      let quarter = Math.floor(dayofyear / 91);
      let weeksqtr = 13;
      if (quarter > 3) {
        quarter = 3;
        weeksqtr = 13.2;
      }
      // Multiply by 1,000,000 for TWh -> MWh, and divide by 100 for %, gives 10000 *
      const quarterly =
        10000 * this.trnsptdemand[0].elec * this.roadqtrprofile[quarter];
      const weekly = quarterly / weeksqtr;
      const dayofweek = datetime.day();
      const daily = (weekly * this.roadrailweekprofile[dayofweek]) / 100;
      // Divide by 10, round then divide by 10, to get %age rounded to 1 dec place
      const hourly =
        Math.round((this.roaddayprofile[datetime.hour()] * daily) / 10) / 10;
      roadData.push({ x: datetime.toDate(), y: hourly });
      roadTotal += hourly;

      datetime.add(1, "h");
    }
    this.totals.elec.road = 1 * roadTotal.toFixed();
    console.log("calculated road data", this.totals.elec.road);
    return roadData;
  }

  /*setRoad(update_states) {
    update_states = update_states || {};
    update_states.roadData = this.calcRoad(update_states);
    this.setWholesaleElecDemand(update_states);
  }*/

  heatHomesExistingUpdate(vals = {}) {
    for (let key in vals) {
      let parts = key.split("_");
      while (parts.length > 2) {
        parts.shift();
      }
      const a = parts[0];
      const b = parts[1];
      if (typeof b === "undefined") {
        if (typeof this.heatdemand.homes.existing[a] === "number") {
          this.heatdemand.homes.existing[a] = vals[key];
        }
      } else {
        if (typeof this.heatdemand.homes.existing[a][b] === "number") {
          this.heatdemand.homes.existing[a][b] = vals[key];
        }
      }
    }
    const heatdemand = this.calcHeatDemand(this.heatdemand);
    this.heatSupplyRecalc(heatdemand);
    //	 console.log("this.heatsupply",this.heatsupply);
  }

  heatHomesNewUpdate(vals = {}) {
    for (let key in vals) {
      //      console.log(key, vals[key]);
      let parts = key.split("_");
      while (parts.length > 2) {
        parts.shift();
      }
      const a = parts[0];
      const b = parts[1];
      if (typeof b === "undefined") {
        if (typeof this.heatdemand.homes.new[a] === "number") {
          this.heatdemand.homes.new[a] = vals[key];
        }
      } else {
        if (typeof this.heatdemand.homes.new[a][b] === "number") {
          this.heatdemand.homes.new[a][b] = vals[key];
        }
      }
    }
    const heatdemand = this.calcHeatDemand(this.heatdemand);
    this.heatSupplyRecalc(heatdemand);
    //	 console.log("this.heatsupply",this.heatsupply);
  }

  heatNondomUpdate(key, val) {
    let [sector, characteristic] = key.split("_");
    this.heatdemand.nondom[sector][characteristic] = val;
    const heatdemand = this.calcHeatDemand(this.heatdemand);
    this.heatSupplyRecalc(heatdemand);
    //	 console.log("this.heatsupply",this.heatsupply);
  }

  heatSupplyRecalc(heatdemand) {
    console.log("heatdemand", heatdemand);
    for (let tech of this.heatsupply) {
      tech.heat = 1 * ((tech.heatshare * heatdemand.heat) / 100).toFixed(1);
      if ("elec" in tech) {
        tech.elec = 1 * ((100 * tech.heat) / tech.efficiency).toFixed(1);
      } else if ("fuel" in tech) {
        tech.fuel = 1 * ((100 * tech.heat) / tech.efficiency).toFixed(1);
      }
      console.log(
        tech.tech + " heat/input",
        tech.heat + " / " + 1 * ((100 * tech.heat) / tech.efficiency).toFixed(1)
      );
    }
  }

  heatSplit(params = {}) {
    const initHeatDemand =
      params.initHeatDemand || this.calcHeatDemand(this.init.heatdemand);
    //console.log("initHeatDemand", initHeatDemand);
    const heatDemand =
      params.heatDemand || this.calcHeatDemand(this.heatdemand);
    //console.log("heatDemand", heatDemand);
    const nonBuildingHeat =
      (heatDemand.heat - heatDemand.buildingHeat) / heatDemand.heat;
    //console.log("nonBuildingHeat", nonBuildingHeat);
    const initNonSpaceHeatInBuildings =
      (initHeatDemand.hotWater + initHeatDemand.cooking) /
      initHeatDemand.buildingHeat;
    //console.log("initNonSpaceHeatInBuildings", initNonSpaceHeatInBuildings);
    const curNonSpaceHeatInBuildings =
      (heatDemand.hotWater + heatDemand.cooking) / heatDemand.buildingHeat;
    //console.log("curNonSpaceHeatInBuildings", curNonSpaceHeatInBuildings);
    const nonSeasonalAdjustment =
      nonBuildingHeat +
      (1 - nonBuildingHeat) *
        (curNonSpaceHeatInBuildings - initNonSpaceHeatInBuildings);
    return nonSeasonalAdjustment;
  }

  setDistLosses(val) {
    this.transmission.elec.distlosses = val;
  }

  /*setDistLosses(event) {
    const distlosses = event.target.value;
    this.setWholesaleElecDemand({ distlosses: distlosses });
    //const wholesaleElecDemandData = this.calcWholesaleElecDemand({distlosses: distlosses});
    //this.setState({ distlosses: distlosses, wholesaleElecDemandData: wholesaleElecDemandData });
  }*/

  // Defunct calcWeather & setWeather methods removed - static on server now
  // push before tempdiff calc to assign previous period's
  // val to current period, to offset cooling demand by 1 hour
  // Math.pow to increase demand modestly exponentially by
  // the temperature difference above the threshold of 20.5 for cooling.
  // Adjust inwards or outwards by 35% from avg temp of 11.5
  // if weather is mild or severe. Equates to around 0-4
  // degrees adjustment depending on temperature, which is
  // reasonably consistent with a diff of around 2.6 between
  // normal and high/low in BMR typical temperature data
  // Multiply by 1000000 in final conversion and divide by
  // total of all tempdiffs, to convert TWh annual demand to
  // MWh hourly demand

  setNewGenProfile(params) {
    params = params || {};
    const tech = params.tech;
    if (tech) {
      const year = params.year || this.state.year || 2017;
      const techObj = this.getTechObj(tech);
      let currentVals = this.generationprofiles[year].current.map(
        (period) => period[tech]
      );
      if (!this.generationprofiles[year].new) {
        this.generationprofiles[year].new = [];
      }
      let arLength = currentVals.length;
      let newlf = techObj.current.availFactor;
      let j = 1;
      let lfstotal = 0;
      let max = 0;
      for (let i = 0; i < arLength; i++) {
        if (currentVals[i] > max) {
          max = currentVals[i];
        }
        lfstotal += currentVals[i];
        if (!this.generationprofiles[year].new[i]) {
          this.generationprofiles[year].new[i] = {
            x: this.generationprofiles[year].current[i].x,
          };
        }
      }
      const hundredpc = max * arLength;
      const maxBelowCap =
        (techObj.current.availFactor * hundredpc) / (100 * lfstotal);
      if (techObj.new.availFactor > techObj.current.availFactor) {
        // Keep nudging points below max towards max, to reduce the size of the troughs
        // 0.2 is fuzziness - close enough to match, as annual load factor is in percent.
        while (techObj.new.availFactor - newlf > 0.2 && j < 500) {
          lfstotal = 0;
          for (let i = 0; i < arLength; i++) {
            // 0.995 is fuzziness to raise values that are almost equal to or greater than max
            if (currentVals[i] / max < 0.995) {
              currentVals[i] *= 1.01;
            }
            lfstotal += currentVals[i];
          }
          newlf = decRound((100 * lfstotal * maxBelowCap) / hundredpc, 1);
          // round percentage to 1 decimal place.
          j++;
        }
        // Rebase profile so total of periods adds up to 1 (like current profile), for
        // multiplying by annual demand to give correct periodic values.
      } else if (techObj.new.availFactor > techObj.current.availFactor) {
        const decrement = max / 200;
        let newmax = max;
        while (newlf - techObj.new.availFactor > 0.5 && j < 500) {
          lfstotal = 0;
          for (let i = 0; i < arLength; i++) {
            if (currentVals[i]) {
              currentVals[i] -= decrement;
              if (currentVals[i] < 0) {
                currentVals[i] = 0;
              }
              lfstotal += currentVals[i];
            }
          }
          newmax -= decrement;
          newlf = decRound((100 * lfstotal) / (newmax * arLength), 1);
          j++;
        }
      }
      for (let i = 0; i < arLength; i++) {
        this.generationprofiles[year].new[i][tech] =
          (1000000 * currentVals[i]) / lfstotal;
      }
      //return newProfile;
    }
    //else {
    //  return [];
    //}
  }

  randomGenProfile(params) {
    const availFactor = params.availFactor || 50;
    const year = params.year || this.state.year || 2017;
    const asLoadFactors = params.asLoadFactors || false;
    let datetime = moment([year, 0, 1, 0, 0, 0, 0]);
    let profileData = [];
    let momentLF = availFactor;
    let totalLF = 0;
    while (datetime.year() === year) {
      const randomFactor = Math.random() * Math.random();
      let adjustFactor;
      if (momentLF > availFactor) {
        adjustFactor = 10 * (randomFactor - 0.5);
      } else {
        adjustFactor = 10 * (0.5 - randomFactor);
      }
      momentLF += adjustFactor;
      if (momentLF > 100) {
        momentLF = 100;
      } else if (momentLF < 0) {
        momentLF = 0;
      }
      profileData.push({
        x: datetime.valueOf(),
        y: momentLF,
      });
      totalLF += momentLF;
      datetime.add(1, "h");
    }
    if (!asLoadFactors) {
      for (let i = 0; i < profileData.length; i++) {
        profileData[i].y = (1000000 * profileData[i].y) / totalLF;
      }
    }
    return profileData;
  }

  setLoading(bool) {
    this.setState({ isLoading: bool });
  }

  toggleTab = (tab) => (e) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleTab2 = (tab) => (e) => {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  };

  toggleTab3 = (tab) => (e) => {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      });
    }
  };

  readScenario = (event) => {
    //console.log("directVars",this.directVars);
    const contents = event.target.result;
    const json = JSON.parse(contents);
    for (let key of this.directVars) {
      this[key] = json[key];
    }
    this.getProfiles();
    //this.setState({isLoading: false,});
  };

  loadScenario = (file) => {
    this.setState({ isLoading: true });
    var reader = new FileReader();
    reader.onloadend = this.readScenario;
    reader.onerror = function (event) {
      console.error("File could not be read! Code " + event.target.error.code);
      this.setState({ isLoading: false });
    };
    reader.readAsText(file);
  };

  saveScenario() {
    const json = {};
    for (let key of this.directVars) {
      json[key] = this[key];
    }
    const blob = new Blob([JSON.stringify(json)], { type: "application/json" });
    const filename = "EDFScenario" + new Date().getTime();
    saveAs(blob, filename);
  }

  /*buildFileSelector(){
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.json');
    this.fileSelector.click={alert("hello")};
    return fileSelector;
    return React.createElement(
      "input",
      {
        type: "file",
        accept: ".json",
        onClick: e => this.loadScenario(e.target.files[0]),
      },
    );
	 }*/

  toggleSideNav = () => {
    this.setState({
      showSideNav: !this.state.showSideNav,
    });
  };

  toggleCard = (cardName, newState) => {
    if (isNaN(newState)) {
      newState = !this.state[cardName];
    } else {
      newState = !!newState;
    }
    this.setState({
      [cardName]: newState,
      scrollTo: cardName,
    });
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      [collapseID]: !prevState[collapseID],
    }));
  };

  render() {
    console.log("rendering...");
    //console.log("this.sectionRefs", this.sectionRefs);
    //console.log("roaddayprofile",this.roaddayprofile.reduce((a,b) => a + b));
    const inflexiblesStackData =
      this.generationprofiles[this.state.year].current.length &&
      //      (this.state.activeTab2 === "f" || this.state.printLayout)
      (this.state.energySupplyBySource || this.state.printLayout)
        ? this.generation
            .filter((item) => {
              return this.inflexibles.includes(item.id);
            })
            .map((item) => {
              return {
                id: item.id,
                name: item.tech,
                data: this.state[item.id + "Data"],
              };
            })
        : [];
    //console.log("inflexiblesStackData", inflexiblesStackData);
    //console.log("show elec demand",(this.state.printLayout || this.state.inputDemandElec));
    return (
      <>
        {this.state.isLoading && (
          <div className="overlay">
            <div
              className="spinner-border spinner-center-overlay text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <section className="sidenavhead d-print-none">
          <MDBBtn
            onClick={this.toggleSideNav}
            className={
              "sidenavbtn green lighten-3 " +
              (this.state.showSideNav ? "close" : "open")
            }
          >
            <MDBIcon
              icon={this.state.showSideNav ? "arrow-left" : "arrow-right"}
            />
          </MDBBtn>
        </section>
        <MDBSideNav
          hidden
          triggerOpening={this.state.showSideNav}
          breakWidth={1300}
          className="default-color-dark d-print-none"
          showOverlay={false}
          logo={C4CSLogo}
        >
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              this.getProfiles();
            }}
          >
            Recalculate
          </button>

          <MDBSideNavNav>
            <MDBSideNavCat name="Inputs" id="inputs" icon="chevron-right">
              <MDBSideNavItem
                className={this.state.inputDemandElec ? "default-color" : ""}
                onClick={() => {
                  this.toggleCard("inputDemandElec");
                }}
              >
                Electricity Demand
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("inputTransDist");
                }}
                className={this.state.inputTransDist ? "default-color" : ""}
              >
                Electricity Transmission
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("inputGeneration");
                }}
                className={this.state.inputGeneration ? "default-color" : ""}
              >
                Electricity Generation
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("inputStorage");
                }}
                className={this.state.inputStorage ? "default-color" : ""}
              >
                Electricity Storage
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("inputInterconnectors");
                }}
                className={
                  this.state.inputInterconnectors ? "default-color" : ""
                }
              >
                Electricity Interconnectors
              </MDBSideNavItem>
              <MDBSideNavItem
                className={this.state.inputDemandHeat ? "default-color" : ""}
                onClick={() => {
                  this.toggleCard("inputDemandHeat");
                }}
              >
                Heat Demand
              </MDBSideNavItem>
              <MDBSideNavItem
                className={this.state.inputSupplyHeat ? "default-color" : ""}
                onClick={() => {
                  this.toggleCard("inputSupplyHeat");
                }}
              >
                Heat Supply
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("inputDemandTrans");
                }}
                className={this.state.inputDemandTrans ? "default-color" : ""}
              >
                Transport Demand
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("inputCost");
                }}
                className={this.state.inputCost ? "default-color" : ""}
              >
                Cost
              </MDBSideNavItem>
            </MDBSideNavCat>
            <MDBSideNavCat
              name="Energy Outputs"
              id="energy-outputs"
              icon="chevron-right"
            >
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyDemandElec");
                }}
                className={this.state.energyDemandElec ? "default-color" : ""}
              >
                Retail Elec. Demand
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyITSDO");
                }}
                className={this.state.energyITSDO ? "default-color" : ""}
              >
                Wholesale Elec. Demand
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyInflexibles");
                }}
                className={this.state.energyInflexibles ? "default-color" : ""}
              >
                Inflexible Electricity
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyNetDemand");
                }}
                className={this.state.energyNetDemand ? "default-color" : ""}
              >
                Net Elec. Demand
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energySupplyByType");
                }}
                className={this.state.energySupplyByType ? "default-color" : ""}
              >
                Supply by Type
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energySupplyBySource");
                }}
                className={
                  this.state.energySupplyBySource ? "default-color" : ""
                }
              >
                Supply by Source
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyStorage");
                }}
                className={this.state.energyStorage ? "default-color" : ""}
              >
                Storage
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyAdequacy");
                }}
                className={this.state.energyAdequacy ? "default-color" : ""}
              >
                Adequacy
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("energyUtilisation");
                }}
                className={this.state.energyUtilisation ? "default-color" : ""}
              >
                Utilisation
              </MDBSideNavItem>
            </MDBSideNavCat>
            <MDBSideNavCat
              name="Carbon outputs"
              id="carbon-outputs"
              icon="chevron-right"
            >
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("carbonTotal");
                }}
                className={this.state.carbonTotal ? "default-color" : ""}
              >
                Total
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("carbonElectricity");
                }}
                className={this.state.carbonElectricity ? "default-color" : ""}
              >
                Electricity
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("carbonInfrastructure");
                }}
                className={
                  this.state.carbonInfrastructure ? "default-color" : ""
                }
              >
                Electric. Infrastructure
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("carbonHeat");
                }}
                className={this.state.carbonHeat ? "default-color" : ""}
              >
                Heat
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("carbonTransport");
                }}
                className={this.state.carbonTransport ? "default-color" : ""}
              >
                Transport
              </MDBSideNavItem>
            </MDBSideNavCat>
            <MDBSideNavCat
              name="Cost outputs"
              id="cost-outputs"
              icon="chevron-right"
            >
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("costMWh");
                }}
                className={this.state.costMWh ? "default-color" : ""}
              >
                Cost/MWh
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("costCombined");
                }}
                className={this.state.costCombined ? "default-color" : ""}
              >
                Combined Cost
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("costInvestment");
                }}
                className={this.state.costInvestment ? "default-color" : ""}
              >
                Investment
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("costHourly");
                }}
                className={this.state.costHourly ? "default-color" : ""}
              >
                Hourly
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("costMarginal");
                }}
                className={this.state.costMarginal ? "default-color" : ""}
              >
                Marginal Cost
              </MDBSideNavItem>
              <MDBSideNavItem
                onClick={() => {
                  this.toggleCard("costStorage");
                }}
                className={this.state.costStorage ? "default-color" : ""}
              >
                Storage
              </MDBSideNavItem>
            </MDBSideNavCat>
          </MDBSideNavNav>
        </MDBSideNav>
        <MDBEdgeHeader color="green darken-3" />
        <MDBFreeBird>
          <MDBRow>
            <MDBCol
              md="10"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <MDBCardBody>
                <h2>Future Energy Scenarios</h2>
                <p>
                  A tool to play with potential future energy mixes, and test
                  how assumptions about some technologies or uses affect other
                  technologies and the overall system balance.
                </p>
                <MDBRow className="d-print-none">
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      this.setState({
                        showInstructions: !this.state.showInstructions,
                      });
                    }}
                  >
                    Instructions
                  </button>
                  <button
                    className={
                      "btn btn-primary ml-auto" +
                      (this.state.printLayout ? " active" : "")
                    }
                    aria-pressed={this.state.printLayout}
                    data-toggle="button"
                    onClick={() =>
                      this.setState({ printLayout: !this.state.printLayout })
                    }
                  >
                    {!this.state.printLayout && "Print "}
                    {this.state.printLayout && "Online "}Layout
                  </button>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
        <MDBContainer fluid className="mt-3">
          <MDBRow className="my-4 d-print-none">
            <MDBCol size="12" sm="6">
              <div className="text-center py-1">
                Base year:&nbsp;
                <div className="btn-group btn-group-lg" data-toggle="buttons">
                  <label
                    className={
                      "btn btn-default py-2 px-3 form-check-label" +
                      (this.state.year === 2016 ? " active" : "")
                    }
                    onClick={() => {
                      this.getProfiles({ year: 2016 });
                    }}
                    //onClick={() => this.setState({ year: 2016 })}
                  >
                    2016
                  </label>
                  <label
                    className={
                      "btn btn-default py-2 px-3 form-check-label" +
                      (this.state.year === 2017 ? " active" : "")
                    }
                    onClick={() => {
                      this.getProfiles({ year: 2017 });
                    }}
                    //onClick={() => this.setState({ year: 2017 })}
                  >
                    2017
                  </label>
                  <label
                    className={
                      "btn btn-default py-2 px-3 form-check-label" +
                      (this.state.year === 2018 ? " active" : "")
                    }
                    onClick={() => {
                      this.getProfiles({ year: 2018 });
                    }}
                    //onClick={() => this.setState({ year: 2018 })}
                  >
                    2018
                  </label>
                </div>
              </div>
            </MDBCol>
            <MDBCol size="12" sm="6">
              <div className="text-center py-1">
                Weather:&nbsp;
                <div className="btn-group btn-group-lg" data-toggle="buttons">
                  <label
                    className={
                      "btn btn-default py-2 px-3 form-check-label" +
                      (this.state.weather === "mild" ? " active" : "")
                    }
                    onClick={() => this.getProfiles({ weather: "mild" })}
                  >
                    Mild
                  </label>
                  <label
                    className={
                      "btn btn-default p-2 form-check-label" +
                      (this.state.weather === "normal" ? " active" : "")
                    }
                    onClick={() => this.getProfiles({ weather: "normal" })}
                  >
                    Normal
                  </label>
                  <label
                    className={
                      "btn btn-default p-2 form-check-label" +
                      (this.state.weather === "severe" ? " active" : "")
                    }
                    onClick={() => this.getProfiles({ weather: "severe" })}
                  >
                    Severe
                  </label>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          <h4 className="d-none d-print-block mt-5">
            Base year: {this.state.year}
          </h4>
          <h4 className="d-none d-print-block">
            Weather: {this.state.weather}
          </h4>

          <MDBRow className="my-4 d-print-none">
            <MDBCol size="12"></MDBCol>
          </MDBRow>

          <MDBRow className="mt-4">
            <MDBCol>
              <h2>Inputs</h2>
            </MDBCol>
            <MDBCol className="text-right">
              <button
                className="btn btn-primary d-print-none"
                onClick={() => {
                  this.getProfiles();
                }}
              >
                Recalculate
              </button>
            </MDBCol>
          </MDBRow>
          {(this.state.printLayout || this.state.inputDemandElec) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputDemandElec"]}
                >
                  Demand - Electricity
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputDemandElec");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSConvDemand
                  convdemand={this.convdemand}
                  fieldUpdate={this.fieldUpdate}
                  key={"convdemand" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputTransDist) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputTransDist"]}
                >
                  Electricity Transmission/Distribution
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputTransDist");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSDistribution
                  distlosses={this.transmission.elec.distlosses}
                  setDistLosses={this.setDistLosses}
                  key={"distribution" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputGeneration) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputGeneration"]}
                >
                  Electricity Generation
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputGeneration");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSGeneration
                  generation={this.generation}
                  initGeneration={this.init.generation}
                  fieldUpdate={this.fieldUpdate}
                  key={"generation" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputStorage) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputStorage"]}
                >
                  Electricity Storage
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputStorage");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSStorage
                  storage={this.storage}
                  initStorage={this.init.storage}
                  fieldUpdate={this.fieldUpdate}
                  key={"storage" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputInterconnectors) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputInterconnectors"]}
                >
                  Electricity Interconnectors
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputInterconnectors");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSInterconnection
                  interconnection={this.interconnection}
                  fieldUpdate={this.fieldUpdate}
                  key={"interconnection" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputDemandHeat) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputDemandHeat"]}
                >
                  Heat Demand
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputDemandHeat");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSHeatDemand
                  heatdemand={this.heatdemand}
                  initheatdemand={this.init.heatdemand}
                  feePoints={this.feePoints}
                  fieldUpdate={this.fieldUpdate}
                  //totalheat={this.totalheat}
                  buildingheat={this.buildingheat}
                  heatHomesExistingUpdate={this.heatHomesExistingUpdate}
                  heatHomesNewUpdate={this.heatHomesNewUpdate}
                  heatNondomUpdate={this.heatNondomUpdate}
                  //convdemandTech={this.convdemand[0].tech}
                  key={"heatdemand" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputSupplyHeat) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputSupplyHeat"]}
                >
                  Heat Supply
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputSupplyHeat");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSHeatSupply
                  heatdemand={this.calcHeatDemand(this.heatdemand)}
                  heatsupply={this.heatsupply}
                  fieldUpdate={this.fieldUpdate}
                  getItem={this.getItem}
                  totalheat={this.totalheat}
                  buildingheat={this.buildingheat}
                  //convdemandTech={this.convdemand[0].tech}
                  key={"heatsupply" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputDemandTrans) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputDemandTrans"]}
                >
                  Transport Demand
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputDemandTrans");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSTrnsptDemand
                  trnsptdemand={this.trnsptdemand}
                  fieldUpdate={this.fieldUpdate}
                  key={"trnsptdemand" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.inputCost) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["inputCost"]}
                >
                  Cost
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("inputCost");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSCostInputs
                  generation={this.generation}
                  wacc={this.wacc}
                  carbonCost={this.carbonCost}
                  fieldUpdate={this.fieldUpdate}
                  soFieldUpdate={this.soFieldUpdate}
                  key={"cost" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}

          <h2 className="mt-4">Energy Outputs</h2>
          {(this.state.printLayout || this.state.energyDemandElec) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyDemandElec"]}
                >
                  Retail Electricity Demand
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyDemandElec");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSDemandStack
                  lightingData={this.state.lightingData}
                  airconData={this.state.airconData}
                  resistanceData={this.state.resistanceData}
                  ashpData={this.state.ashpData}
                  gshpData={this.state.gshpData}
                  roadData={this.state.roadData}
                  railData={this.state.railData}
                  //convdemand={this.convdemand}
                  //heatsupply={this.heatsupply}
                  //trnsptdemand={this.trnsptdemand}
                  totals={this.totals}
                  init={this.init}
                  key={"demandStack" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energyITSDO) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyITSDO"]}
                >
                  Wholesale Electricity Demand
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyITSDO");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSITSDOChart
                  wholesaleElecDemandData={this.state.wholesaleElecDemandData}
                  key={"itsdo" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energyInflexibles) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyInflexibles"]}
                >
                  Inflexible Electricity
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyInflexibles");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSInflexibles
                  solarData={this.state.solarData}
                  biogasData={this.state.biogasData}
                  nuclearData={this.state.nuclearData}
                  onshorewindData={this.state.onshorewindData}
                  offshorewindData={this.state.offshorewindData}
                  wholesaleElecDemandData={this.state.wholesaleElecDemandData}
                  key={"inflexibles" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energyNetDemand) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyNetDemand"]}
                >
                  Demand Net of Inflexibles
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyNetDemand");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSInflexImbalance
                  inflexImbalanceData={this.state.inflexImbalanceData}
                  key={"inflexImbalance" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energySupplyByType) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energySupplyByType"]}
                >
                  Electricity Supply By Type
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energySupplyByType");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSFlowTypeStack
                  inflexiblesData={this.state.inflexiblesData}
                  flexiblesData={this.state.flexiblesData.gentotal}
                  storageData={this.state.flexiblesData.stortotal}
                  interconnectData={this.state.flexiblesData.ictotal}
                  wholesaleElecDemandData={this.state.wholesaleElecDemandData}
                  key={"flowTypeStack" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energySupplyBySource) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energySupplyBySource"]}
                >
                  Electricity Supply By Source
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energySupplyBySource");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSMeritOrderStack
                  storageData={this.state.flexiblesData.stortotal}
                  interconnectData={this.state.flexiblesData.ictotal}
                  flexiblesData={this.state.flexiblesData.generation}
                  inflexiblesData={inflexiblesStackData}
                  wholesaleElecDemandData={this.state.wholesaleElecDemandData}
                  generation={this.generation}
                  storage={this.storage}
                  interconnection={this.interconnection}
                  init={this.init}
                  key={"meritOrderStack" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energyStorage) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyStorage"]}
                >
                  Electricity Storage
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyStorage");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSStorageChart
                  storageData={this.state.flexiblesData.storage}
                  storbalance={this.state.flexiblesData.storbalance}
                  storageParams={this.storage}
                  techNames={this.techNames}
                  key={"storageChart" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energyAdequacy) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyAdequacy"]}
                >
                  Adequacy
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyAdequacy");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSMarginChart
                  marginData={this.state.flexiblesData.margin}
                  wholesaleElecDemandData={this.state.wholesaleElecDemandData}
                  key={"marginChart" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.energyUtilisation) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["energyUtilisation"]}
                >
                  Utilisation
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("energyUtilisation");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSUtilisation
                  generation={this.generation}
                  initGeneration={this.init.generation}
                  //storage={this.storage}
                  //interconnection={this.interconnection}
                  key={"utilisation" + this.state.recalcDate}
                />
                <hr className="my-4" />
                <EDFSUtilisationMarginal
                  marginalGenTechData={this.state.marginalGenTechData}
                  initMarginalGenTechData={this.state.initMarginalGenTechData}
                  marginalCapData={this.state.marginalCapData}
                  initMarginalCapData={this.state.initMarginalCapData}
                  generation={this.generation}
                  key={"utilMarginal" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}

          <h2 className="mt-4">Carbon Outputs</h2>
          {/*<EDFSCarbon
            aggData={this.state.aggData}
            initAggData={this.state.initAggData}
            generation={this.generation}
            storage={this.storage}
            interconnection={this.interconnection}
            printLayout={this.state.printLayout}
				    carbonTotal={this.state.carbonTotal}
				    carbonElectricity={this.state.carbonElectricity}
				    carbonInfrastructure={this.state.carbonInfrastructure}
				    carbonHeat={this.state.carbonHeat}
				    carbonTransport={this.state.carbonTransport}
            toggleCard={this.toggleCard}
            ref={{
              carbonTotal: this.sectionRefs["carbonTotal"], 
              carbonElectricity: this.sectionRefs["carbonElectricity"],
              carbonInfrastructure: this.sectionRefs["carbonInfrastructure"],
              carbonHeat: this.sectionRefs["carbonHeat"],
              carbonTransport: this.sectionRefs["carbonTransport"],
            }}
            networkReinforcement={
              this.maxWholesaleElecDemand > 60000
                ? (this.maxWholesaleElecDemand - this.init.maxWholesaleElecDemand) *
                  this.CO2e.construction.transmission
                : 0
            }
            key={"carbon" + this.state.recalcDate}
          />*/}

          {(this.state.printLayout || this.state.carbonTotal) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs.carbonTotal}
                >
                  Total carbon emissions from fuel combustion
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("carbonTotal");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol>
                    <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                      <EDFSCarbonTotal
                        aggData={this.state.aggData}
                        initAggData={this.state.initAggData}
                        generation={this.generation}
                        key={"carbonTotal" + this.state.recalcDate}
                      />
                    </div>
                    <p>
                      This chart shows the carbon emissions relating to the
                      combustion of fuel for energy. This is only a partial
                      picture of total Greenhouse Gas Emissions attributable to
                      UK activities. Some of the emissions not considered in
                      these calculations are listed below, if you click on the
                      "Not Included" heading. Nevertheless, the direct emissions
                      from combustion constitute the majority of emissions by
                      most estimates.
                    </p>
                    <p>
                      <em>Base</em> is the emissions from our existing systems
                      as of mid-2019, on the basis of the selected base data
                      (e.g. weather).
                    </p>
                    <p>
                      <em>Proposed</em> is the emissions under the scenario you
                      have specified, on the basis of the same base data.
                    </p>
                    <p>
                      When you first view this page, the two columns are
                      identical. You need to specify in the Inputs sections
                      (click arrow button at top left for the menu) how your
                      Proposed scenario will vary from the Base scenario.
                    </p>
                    <p>
                      The values from which the figures for{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("carbonElectricity", 1)}
                      >
                        electricity
                      </button>
                      ,{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("carbonHeat", 1)}
                      >
                        heat
                      </button>{" "}
                      and{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("carbonTransport", 1)}
                      >
                        transport
                      </button>{" "}
                      are derived, are shown in more details in their respective
                      cards.
                    </p>
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={this.toggleCollapse("carbonNotIncluded")}
                      >
                        <h6>
                          Not Included{" "}
                          <MDBIcon
                            icon={
                              this.state.carbonNotIncluded
                                ? "caret-up"
                                : "caret-down"
                            }
                          />
                        </h6>
                      </button>
                    </div>
                    <MDBCollapse
                      id="carbonNotIncluded"
                      isOpen={this.state.carbonNotIncluded}
                    >
                      <dl>
                        <dt>Non-energy</dt>
                        <dd>
                          The roughly 20% of the UK's greenhouse gas emissions
                          that do not derive from fuel-combustion, e.g.
                          agriculture and waste disposal.
                        </dd>
                        <dt>Construction</dt>
                        <dd>
                          Emissions attributable to the construction of energy{" "}
                          <button
                            type="button"
                            className="btn btn-link inline-link"
                            onClick={() =>
                              this.toggleCard("carbonInfrastructure", 1)
                            }
                          >
                            infrastructure
                          </button>
                          , which are partially listed (for the construction of
                          future components of the electricity systems) in their
                          own card. For many of the leading low-carbon
                          technologies, embodied emissions are the primary, and
                          often overlooked, sources of GHG emissions for their
                          technology.
                        </dd>
                        <dt>Offshored</dt>
                        <dd>
                          The emissions from production and distribution of
                          goods manufactured outside the UK and consumed in the
                          UK. A significant proportion of the reduction in
                          industrial emissions is attributable not a reduction
                          in consumption, but to driving the manufacture of
                          these goods outside of the UK through higher costs
                          than developing nations. In many cases, the carbon
                          efficiency of the manufacturing process in those
                          countries will be worse than if the goods had been
                          produced in the UK.
                        </dd>
                        <dt>Wholesale</dt>
                        <dd>
                          The emissions from the process of producing the fuels
                          consumed in the UK. This is one of the two major
                          sources of carbon emissions for nuclear (the other is
                          in the construction of the facility). However, (a)
                          there are also significant emissions for the
                          production of other fuels, which are often ignored,
                          and (b) it is a relatively small source compared to
                          the direct effect of burning fossil fuels.
                        </dd>
                        <dt>Fugitive</dt>
                        <dd>
                          A material proportion of the gas consumed in the UK
                          escapes in production and en route to the consumers.
                          Methane (natural gas) has a carbon intensity 25 times
                          higher than carbon dioxide (depending on how one
                          accounts for longevity in the atmosphere), so these
                          emissions materially offset the lower carbon
                          contribution from burning gas rather than oil or coal.
                          To a much smaller extent (because it is mostly
                          small-scale and therefore not distributed long
                          distances), this is also a factor for biogas.
                        </dd>
                        <dt>Waste</dt>
                        <dd>
                          Energy is expended (and emissions are therefore
                          emitted) in disposing of the waste from energy
                          production, both by-products of operation and at
                          end-of-life of the equipment. How much depends on the
                          disposal method. Recovering scarce elements (e.g. used
                          in the manufacture of wind and solar generators) may
                          be relatively intensive. Simple disposal (e.g. of ash)
                          is generally <em>de minimis</em>.
                        </dd>
                        <dt>Decommissioning</dt>
                        <dd>
                          Some technologies incorporate large elements of civil
                          engineering. The emissions associated with their
                          removal at end of life depend to what extent the
                          owners are required to remove them or whether they can
                          be left <em>in situ</em>. Dismantling a nuclear power
                          station or hydroelectric facility is a large and
                          energy-intensive task.
                        </dd>
                        <dt>Animals</dt>
                        <dd>
                          A lot of CO<sub>2</sub> and CH<sub>4</sub> is emitted
                          by animals (including humans) in the process of
                          living. These tend not to be included in any
                          carbon-accounting exercise.
                        </dd>
                      </dl>
                    </MDBCollapse>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}

          {(this.state.printLayout || this.state.carbonElectricity) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs.carbonElectricity}
                >
                  Carbon emissions from electricity
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("carbonElectricity");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol size="12">
                    <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                      <EDFSCarbonElectricity
                        propData={this.state.aggData.elec}
                        baseData={this.state.initAggData.elec}
                        generation={this.generation}
                        key={"carbonElectricity" + this.state.recalcDate}
                      />
                    </div>
                    <p>
                      These are the emissions from the production of
                      electricity. The emissions from the construction of
                      electricity infrastructure (generation and distribution)
                      are shown separately in the{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() =>
                          this.toggleCard("carbonInfrastructure", 1)
                        }
                      >
                        Electricity Infrastructure
                      </button>{" "}
                      card.
                    </p>
                    <p>
                      These emissions are calculated on the basis of the UK
                      Government's{" "}
                      <a
                        href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Greenhouse gas reporting conversion factors
                      </a>{" "}
                      for each technology. Some compromises are necessary. Coal
                      is a compromise between the different forms (brown/black,
                      hard/soft). Gas is dominated by natural gas, but
                      incorporates LPG, butane, propane, etc. Oil occurs in many
                      grades - not just petrol and diesel, but kerosene, etc.
                      Biomass is a compromise between the emission factors for
                      wood chips and pellets, and virgin and waste fibre. The
                      differences in emissions between the compromised values
                      are not significant at the resolution encompassed in this
                      model. We use the following values:
                    </p>
                    <table class="table table-borderless table-sm table-xsm w-auto">
                      <thead>
                        <tr>
                          <th>Fuel</th>
                          <th className="text-right">
                            tCO<sub>2</sub>e/MWh
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Gas</td>
                          <td className="text-right">
                            {this.CO2e.fuel.gas.toFixed(4)}
                          </td>
                        </tr>
                        <tr>
                          <td>Oil</td>
                          <td className="text-right">
                            {this.CO2e.fuel.oil.toFixed(4)}
                          </td>
                        </tr>
                        <tr>
                          <td>Coal</td>
                          <td className="text-right">
                            {this.CO2e.fuel.coal.toFixed(4)}
                          </td>
                        </tr>
                        <tr>
                          <td>Biomass</td>
                          <td className="text-right">
                            {this.CO2e.fuel.biomass.toFixed(4)}
                          </td>
                        </tr>
                        <tr>
                          <td>Biogas</td>
                          <td className="text-right">
                            {this.CO2e.fuel.biogas.toFixed(4)}
                          </td>
                        </tr>
                        <tr>
                          <td>Others</td>
                          <td className="text-right"> - </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="alert alert-info">
                      These figures are per MWh of fuel in, not per MWh of
                      electricity out. They are therefore affected by the
                      assumed conversion efficiency, as we assume that demand
                      determines supply, not vice versa.
                    </div>
                    <p>
                      Some people have argued for much higher emissions factors
                      for biomass than those used historically by the UK
                      government (e.g.{" "}
                      <a
                        href="https://www.foeeurope.org/biomass-dirtier-than-coal-121112"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Dirtier Than Coal"
                      </a>
                      ).
                    </p>
                    <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          title="Biomass: Seeing the Wood for the Trees"
                          className="embed-responsive-item"
                          src="https://www.youtube.com/embed/Rq0g9I8xxXI"
                          frameBorder="0"
                          allow="display; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                    <p>
                      These arguments are based on a mistaken conception of the
                      carbon cycle as it relates to forestry. The UK government
                      is under continuous pressure to adopt this erroneous
                      approach to calculating the carbon cost of biomass, but we
                      will continue to use values that reflect the reality of
                      forestry, the carbon cycle, the availability and use of
                      waste wood and by-products, etc.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.carbonInfrastructure) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs.carbonInfrastructure}
                >
                  Emissions from electricity infrastructure construction
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("carbonInfrastructure");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol size="12">
                    <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                      <EDFSCarbonInfrastructure
                        aggData={this.state.aggData}
                        initAggData={this.state.initAggData}
                        generation={this.generation}
                        storage={this.storage}
                        interconnection={this.interconnection}
                        networkReinforcement={
                          this.maxWholesaleElecDemand > 60000
                            ? (this.maxWholesaleElecDemand -
                                this.init.maxWholesaleElecDemand) *
                              this.CO2e.construction.transmission
                            : 0
                        }
                        key={"carbonInfrastructure" + this.state.recalcDate}
                      />
                    </div>
                    <p>
                      The emissions from the construction of generating stations
                      vary widely for each technology according to circumstance,
                      and are therefore subject to a range of estimates for a
                      typical value for each technology.
                    </p>
                    <p>
                      Nevertheless, the figures are significant (the main factor
                      for some low-carbon technologies) and need to be taken
                      into account in any carbon calculation. And there are
                      clear differences of scale, between, for example,
                      technologies like hydroelectricity and nuclear that have
                      massive civil-engineering requirements (concrete embodies
                      a substantial amount of carbon), and plant that is so
                      insubtantial that it may be containerised, such as small
                      oil- and gas-fired generators that may be widely deployed
                      for standby generation.
                    </p>
                    <p>
                      Opinions will vary widely over the correct figure to take
                      as a representative figure for each technology. We welcome
                      additional evidence, but will only revise these figures if
                      the case seems widely recognised from a range of
                      perspectives, not because we are pressured by any interest
                      groups. The following figures are a genuine attempt to
                      pick reasonably representative figures from the literature
                      that we have seen.
                    </p>
                    <table class="table table-borderless table-sm table-xsm w-auto">
                      <tr>
                        <th>Technology</th>
                        <th className="text-right">
                          tCO<sub>2</sub>e/MW *
                        </th>
                      </tr>
                      {Object.keys(this.CO2e.construction).map((tech) => (
                        <tr>
                          <td>{this.techNames[tech]}</td>
                          <td className="text-right">
                            {this.CO2e.construction[tech]}
                          </td>
                        </tr>
                      ))}
                    </table>
                    <div class="alert alert-info">
                      * These figures are mostly tCO<sub>2</sub>e/MW as
                      indicated in the table heading. But in the case of storage
                      technologies (e.g. Pumped Storage and Batteries), they are
                      tCO<sub>2</sub>e/MWh, as the storage capacity is better
                      defined in terms of MWh than MW.
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={this.toggleCollapse("infrSources")}
                      >
                        <h6>
                          Sources{" "}
                          <MDBIcon
                            icon={
                              this.state.infrSources ? "caret-up" : "caret-down"
                            }
                          />
                        </h6>
                      </button>
                    </div>
                    <MDBCollapse
                      id="infrSources"
                      isOpen={this.state.infrSources}
                    >
                      <ul>
                        <li>
                          Weißbach et al, "Energy intensities, EROIs, and energy
                          payback times of electricity generating power plants"{" "}
                          <em>Energy 52</em> (2013) 210{" "}
                          <a
                            href="https://festkoerper-kernphysik.de/Weissbach_EROI_preprint.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Hammond &amp; Spargo, "The prospects for coal-fired
                          power plants with carbon capture and storage: A UK
                          perspective"{" "}
                          <em>Energy Conversion and Management, Vol. 86</em>{" "}
                          (Oct 2014), 476-489{" "}
                          <a
                            href="https://reader.elsevier.com/reader/sd/pii/S0196890414004415"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Flury &amp; Frischknecht, "Life Cycle Inventories of
                          Hydroelectric Power Generation"{" "}
                          <em>Öko-Institute e.V.</em> (2012){" "}
                          <a
                            href="http://esu-services.ch/fileadmin/download/publicLCI/flury-2012-hydroelectric-power-generation.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          National Renewable Energy Laboratory,{" "}
                          <em>
                            Renewable Electricity Futures Study, Vol.1:
                            Exploration of High-Penetration Renewable
                            Electricity Futures
                          </em>{" "}
                          (2012){" "}
                          <a
                            href="https://www.nrel.gov/docs/fy12osti/52409-1.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Rhodes et al, "Nuclear and wind power estimated to
                          have lowest levelized CO2 emissions"{" "}
                          <em>
                            University of Texas at Austin Energy Institute
                          </em>{" "}
                          (2017){" "}
                          <a
                            href="https://energy.utexas.edu/news/nuclear-and-wind-power-estimated-have-lowest-levelized-co2-emissions"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Smoucha et al, "Life Cycle Analysis of the Embodied
                          Carbon Emissions from 14 Wind Turbines with Rated
                          Powers between 50 kW and 3.4 MW"{" "}
                          <em>
                            Journal of Fundamentals of Renewable Energy and
                            Applications 6
                          </em>{" "}
                          (Jun 2016) 211{" "}
                          <a
                            href="https://www.omicsonline.org/open-access/life-cycle-analysis-of-the-embodied-carbon-emissions-from-14-wind-turbines-with-rated-powers-between-50-kw-and-34-mw-2090-4541-1000211.php?aid=74577"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Thomson &amp; Harrison, "Life Cycle Costs and Carbon
                          Emissions of Offshore Wind Power"{" "}
                          <em>
                            University of Edinburgh on behalf of ClimateXChange
                          </em>{" "}
                          (2015){" "}
                          <a
                            href="https://www.climatexchange.org.uk/media/1461/main_report_-_life_cycle_costs_and_carbon_emissions_of_offshore_wind_power.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          World Nuclear Association, "Energy Return on
                          Investment" (2017){" "}
                          <a
                            href="https://www.world-nuclear.org/information-library/energy-and-the-environment/energy-return-on-investment.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Shwartz M, Precourt Institute for Energy, Stanford
                          University, "Stanford scientists calculate the carbon
                          footprint of grid-scale battery technologies"{" "}
                          <em>AAAS EurekAlert!</em> (Mar 2013){" "}
                          <a
                            href="https://www.eurekalert.org/pub_releases/2013-03/su-ssc030813.php"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Notter et al, "Contribution of Li-ion batteries to the
                          environmental impact of electric vehicles"{" "}
                          <em>
                            Environmental Science &amp; Technology Vol.44 Iss.17{" "}
                          </em>{" "}
                          (Sep 2010) 6550-6556{" "}
                          <a
                            href="https://pubs.acs.org/doi/suppl/10.1021/es903729a/suppl_file/es903729a_si_001.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                        <li>
                          Electricity North West Limited, "Capacity to Customers
                          (C<sub>2</sub>C)" <em>Low Carbon Networks Fund</em>{" "}
                          (2011){" "}
                          <a
                            href="https://www.ofgem.gov.uk/sites/default/files/docs/2013/10/130517_-_capacity_to_customers_project_-_full_submission_vs_03_0.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <MDBIcon icon="external-link-alt" />
                          </a>
                        </li>
                      </ul>
                    </MDBCollapse>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.carbonHeat) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs.carbonHeat}
                >
                  Carbon emissions from heat
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("carbonHeat");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol>
                    <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                      <EDFSCarbonHeat
                        propData={this.state.aggData.heat}
                        baseData={this.state.initAggData.heat}
                        heatsupply={this.heatsupply}
                        key={"carbonHeat" + this.state.recalcDate}
                      />
                    </div>
                    <p>
                      Only a small proportion of heat is produced with
                      electricity, and the emissions from that are captured
                      within the{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("carbonElectricity", 1)}
                      >
                        Electricity
                      </button>{" "}
                      chart. This chart illustrates the emissions from the
                      production of heat by other means, under our current Base
                      arrangements (mainly gas), and under your Proposed
                      scenario.
                    </p>
                    <p>
                      Roughly twice as much energy is consumed for heat as
                      electricity. And because electricity has already been
                      significantly decarbonised, heat's share of carbon
                      emissions is even more significant, although that is
                      offset by the fact that the dominant fuel (gas) is the
                      lowest-carbon fossil fuel. To see how these figures sit
                      within the overall figures for carbon emissions, see the
                      card for{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("carbonTotal", 1)}
                      >
                        Total
                      </button>{" "}
                      emissions from fuel combustion. Typically, people forget
                      about heat (and transport) when thinking about
                      decarbonisation, and therefore greatly over-estimate the
                      impact of their plans to decarbonise electricity on the
                      overall level of emissions.
                    </p>
                    <p>
                      You can specify to electrify a much larger proportion of
                      our heat in your scenario via the{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("inputHeat", 1)}
                      >
                        Heat Supply
                      </button>{" "}
                      input card. In that case, the Proposed column in this
                      chart should be much lower, as the emissions from the
                      electrified heat are shown elsewhere. However, you should
                      see a corresponding increase in the emissions from{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("carbonElectricity", 1)}
                      >
                        Electricity
                      </button>{" "}
                      generation, or if you try to prevent this by significantly
                      increasing the amount of low-carbon generating capacity in
                      the{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("inputGeneration", 1)}
                      >
                        Generation
                      </button>{" "}
                      card, check the{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("energyNetDemand", 1)}
                      >
                        Net Electricity Demand
                      </button>
                      ,{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("energyAdequacy", 1)}
                      >
                        Adequacy
                      </button>{" "}
                      and{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("energyUtilisation", 1)}
                      >
                        Utilisation
                      </button>{" "}
                      cards to see whether you are able to balance low-carbon
                      generation with heat demand on an hourly basis. And check
                      the Cost cards (e.g.{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("costMWh", 1)}
                      >
                        Cost/MWh
                      </button>{" "}
                      and{" "}
                      <button
                        type="button"
                        className="btn btn-link inline-link"
                        onClick={() => this.toggleCard("costCombined", 1)}
                      >
                        Combined Cost
                      </button>
                      ) to see the impact this has.
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.carbonTransport) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs.carbonTransport}
                >
                  Carbon emissions from transport
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("carbonTransport");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol>
                    D
                    <div className="col-12 col-md-8 col-lg-7 col-xl-6 float-right pl-4 pb-4 pr-0">
                      <EDFSCarbonTransport
                        propData={this.state.aggData.transport}
                        baseData={this.state.initAggData.transport}
                        key={"carbonTransport" + this.state.recalcDate}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}

          <h2 className="mt-4">Cost Outputs</h2>
          {(this.state.printLayout || this.state.costMWh) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["costMWh"]}
                >
                  Average Cost per MWh per Technology
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("costMWh");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSGenTechPrices
                  generation={this.generation}
                  transmission={this.transmission}
                  storage={this.storage}
                  init={this.init}
                  wacc={this.wacc}
                  key={"genTechPrices" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.costCombined) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["costCombined"]}
                >
                  Total Cost of Energy Systems
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("costCombined");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSTotalCost
                  generation={this.generation}
                  transmission={this.transmission}
                  supply={this.supply}
                  heatsupply={this.heatsupply}
                  trnsptdemand={this.trnsptdemand}
                  storage={this.storage}
                  interconnection={this.interconnection}
                  init={this.init}
                  wacc={this.wacc}
                  carbonCost={this.carbonCost}
                  demandShedCost={this.demandShedCost}
                  totalRetailElecDemand={this.totalRetailElecDemand}
                  totalWholesaleElecDemand={this.totalWholesaleElecDemand}
                  aggData={this.state.aggData}
                  initAggData={this.state.initAggData}
                  //fuelEmissions={this.state.aggData.elec.fuelemissions}
                  //initFuelEmissions={this.state.initAggData.elec.fuelemissions}
                  key={"totalCost" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.costInvestment) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["costInvestment"]}
                >
                  Investment
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("costInvestment");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSInvestmentChart
                  generation={this.generation}
                  transmission={this.transmission}
                  storage={this.storage}
                  constremissions={this.state.aggData.elec.constremissions}
                  carbonCost={this.carbonCost}
                  init={this.init}
                  key={"investment" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.costHourly) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["costHourly"]}
                >
                  Hourly
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("costHourly");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSGenCostHourly
                  costData={this.state.costData}
                  key={"genCostHourly" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.costMarginal) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["costMarginal"]}
                >
                  Marginal Cost
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("costMarginal");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSGenCostMarginal
                  marginalGenTechData={this.state.marginalGenTechData}
                  initMarginalGenTechData={this.state.initMarginalGenTechData}
                  marginalCapData={this.state.marginalCapData}
                  initMarginalCapData={this.state.initMarginalCapData}
                  generation={this.generation}
                  wacc={this.wacc}
                  //initGeneration={this.init.generation}
                  key={"genCostMarginal" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          {(this.state.printLayout || this.state.costStorage) && (
            <MDBCard className="my-3">
              <MDBCardHeader className="d-flex">
                <h4
                  className="flex-grow-1 my-0"
                  ref={this.sectionRefs["costStorage"]}
                >
                  Storage Cost
                </h4>
                <MDBCloseIcon
                  className="flex-shrink-1 mt-0"
                  onClick={() => {
                    this.toggleCard("costStorage");
                  }}
                />
              </MDBCardHeader>
              <MDBCardBody>
                <EDFSStorageElecCostChart
                  storageData={this.state.flexiblesData.storage}
                  storElecCostData={this.state.storElecCostData}
                  storageParams={this.storage}
                  key={"storageChart" + this.state.recalcDate}
                />
              </MDBCardBody>
            </MDBCard>
          )}
          <MDBRow>
            <MDBCol size="6" md="4">
              <div className="custom-file">
                <input
                  type="file"
                  accept=".json"
                  className="custom-file-input d-none"
                  id="loadScenario"
                  aria-describedby="Load scenario file selector"
                  onChange={(e) => this.loadScenario(e.target.files[0])}
                />
                <label
                  htmlFor="loadScenario"
                  className="btn btn-default btn-block d-print-none mt-3 mb-1"
                >
                  Load Scenario
                </label>
              </div>
            </MDBCol>
            <MDBCol size="6" md="4">
              <button
                className="btn btn-primary btn-block d-print-none mt-3 mb-1"
                onClick={() => {
                  this.saveScenario();
                }}
              >
                Save Scenario
              </button>
            </MDBCol>
            <MDBCol size="12" md="4">
              <EDFSDownload
                flexiblesData={this.state.flexiblesData}
                solarData={this.state.solarData}
                biogasData={this.state.biogasData}
                nuclearData={this.state.nuclearData}
                onshorewindData={this.state.onshorewindData}
                offshorewindData={this.state.offshorewindData}
                lightingData={this.state.lightingData}
                airconData={this.state.airconData}
                resistanceData={this.state.resistanceData}
                ashpData={this.state.ashpData}
                gshpData={this.state.gshpData}
                roadData={this.state.roadData}
                railData={this.state.railData}
                wholesaleElecDemandData={this.state.wholesaleElecDemandData}
                inflexiblesData={this.state.inflexiblesData}
                inflexImbalanceData={this.state.inflexImbalanceData}
                heatUseData={this.heatUseHourly}
                costData={this.state.costData}
                storElecCostData={this.state.storElecCostData}
                storageData={this.state.flexiblesData.storage}
                storTotalData={this.state.flexiblesData.stortotal}
                storBalanceData={this.state.flexiblesData.storbalance}
                year={this.state.year}
                weather={this.state.weather}
                generation={this.generation}
                distlosses={this.transmission.elec.distlosses}
                totalheat={this.totalheat}
                buildingheat={this.buildingheat}
                spaceheat={this.spaceheat}
                storage={this.storage}
                interconnection={this.interconnection}
                convdemand={this.convdemand}
                heatsupply={this.heatsupply}
                heatdemand={this.heatdemand}
                trnsptdemand={this.trnsptdemand}
                setLoading={this.setLoading}
                key={"EDFSDL" + this.state.recalcDate}
                filename={"EDFSDL" + this.state.recalcDate}
              />
            </MDBCol>
          </MDBRow>
          <MDBModal
            isOpen={this.state.showInstructions}
            size="lg"
            id="instructions"
            toggle={() => {
              this.setState({ showInstructions: !this.state.showInstructions });
            }}
          >
            <MDBModalHeader
              toggle={() => {
                this.setState({
                  showInstructions: !this.state.showInstructions,
                });
              }}
            >
              How to use this page
            </MDBModalHeader>
            <EDFSInstructions />
          </MDBModal>
          <MDBRow className="p-2 d-print-none">
            <MDBCol>&nbsp;</MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }

  railwkdayprofile = [
    1,
    1,
    1,
    1,
    1,
    2,
    4,
    7,
    9,
    7,
    4,
    3,
    3,
    3,
    3,
    4,
    7,
    9,
    9,
    7,
    5,
    4,
    3,
    2,
  ];
  railwkendprofile = [
    2,
    2,
    2,
    2,
    2,
    3,
    5,
    6,
    7,
    6,
    4,
    3,
    3,
    3,
    3,
    5,
    6,
    6,
    6,
    6,
    6,
    5,
    4,
    3,
  ];
  roadrailweekprofile = [8, 16.8, 16.8, 16.8, 16.8, 16.8, 8]; // day 0 = Sunday
  roadqtrprofile = [23.5, 25.5, 26, 25];
  roaddayprofile = [
    7,
    8,
    8,
    8,
    8,
    8,
    5,
    4,
    2,
    2,
    3,
    3,
    3,
    3,
    3,
    3,
    2,
    1,
    1,
    1,
    2,
    3,
    5,
    7,
  ];
}

/*function getEmptyGen(ids) {
  const emptyGen = {};
  for (let i = 0; i < ids.length; i++) {
    emptyGen[ids[i]] = 0;
  }
  return emptyGen;
}*/

function decRound(val, places) {
  const factor = Math.pow(10, places);
  return Math.round(factor * val) / factor;
}

/*function setEmptyGen(objlist) {
  const emptyGen = {};
  for (let i = 0; i < objlist.length; i++) {
    emptyGen[objlist[i].id] = 0;
  }
  return emptyGen;
}*/

export default EDFutureScenarios;
