import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSGenTechPrices extends Component {
  render() {
    console.log("Rendering EDFSGenTechPrices");

    let propCost = 0;
    let propOutput = 0;
    let totalCurOutput = 0;
    let totalNewOutput = 0;
    let baseCost = 0;
    let baseOutput = 0;
    let initCurOutput = 0;
    let initNewOutput = 0;
    //let maxPrice = 0;
    let dataPoints1 = [];
    let dataPoints2 = [];
    const fontFamily =
      "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";
    let x = 1;

    if (this.props.generation) {
      for (let i = 0; i < this.props.generation.length; i++) {
        const item = this.props.generation[i];
        const combCapacity = item.current.capacity + item.new.capacity;
        if (combCapacity > 0) {
          let currentOutput =
            item.current.capacity * item.current.loadFactor * 87.6;
          let newOutput = item.new.capacity * item.new.loadFactor * 87.6;
          let output = currentOutput + newOutput;
          totalCurOutput += currentOutput;
          totalNewOutput += newOutput;

          let currentVariableCost = item.current.variableCost * currentOutput;
          let currentCapitalCost =
            10 *
            this.props.wacc *
            item.current.capacity *
            item.current.capitalCost;
          let currentFixedCost =
            1000 * item.current.fixedCost * item.current.capacity;
          let currentCost =
            currentVariableCost + currentCapitalCost + currentFixedCost;

          let newVariableCost = item.new.variableCost * newOutput;
          let newCapitalCost =
            10 * this.props.wacc * item.new.capacity * item.new.capitalCost;
          let newFixedCost = 1000 * item.new.fixedCost * item.new.capacity;
          let newCost = newVariableCost + newCapitalCost + newFixedCost;

          propCost += currentCost + newCost;
          propOutput += output;

          let price = output
            ? Math.round((100 * (currentCost + newCost)) / output) / 100
            : 0;
          //if (price > maxPrice) {
          //  maxPrice = price;
          //}

          dataPoints1.push({
            x: x,
            y: price,
            label: item.tech,
          });

          const init = this.props.init.generation[i];
          currentOutput =
            init.current.capacity * init.current.loadFactor * 87.6;
          newOutput = init.new.capacity * init.new.loadFactor * 87.6;
          output = currentOutput + newOutput;
          initCurOutput += currentOutput;
          initNewOutput += newOutput;

          currentVariableCost = init.current.variableCost * currentOutput;
          currentCapitalCost =
            10 *
            this.props.init.wacc *
            init.current.capacity *
            init.current.capitalCost;
          currentFixedCost =
            1000 * init.current.fixedCost * init.current.capacity;
          currentCost =
            currentVariableCost + currentCapitalCost + currentFixedCost;

          newVariableCost = init.new.variableCost * newOutput;
          newCapitalCost =
            10 *
            this.props.init.wacc *
            init.new.capacity *
            init.new.capitalCost;
          newFixedCost = 1000 * init.new.fixedCost * init.new.capacity;
          newCost = newVariableCost + newCapitalCost + newFixedCost;
          baseCost += currentCost + newCost;
          baseOutput += output;

          price = output
            ? Math.round((100 * (currentCost + newCost)) / output) / 100
            : 0;
          //if (price > maxPrice) {
          //  maxPrice = price;
          //}

          dataPoints2.push({
            x: x,
            y: price,
            label: item.tech,
          });

          if (
            dataPoints1[dataPoints1.length - 1].y +
              dataPoints2[dataPoints2.length - 1].y >
            0
          ) {
            x++;
          } else {
            dataPoints1.pop();
            dataPoints2.pop();
          }
        }
      }
    }

    if (this.props.transmission) {
      const item = this.props.transmission.elec;
      //console.log("Network item", item);

      let currentVariableCost = item.current.variableCost * totalCurOutput;
      let currentCapitalCost =
        10 * this.props.wacc * item.current.capacity * item.current.capitalCost;
      let currentFixedCost =
        1000 * item.current.fixedCost * item.current.capacity;
      let currentCost =
        currentVariableCost + currentCapitalCost + currentFixedCost;

      let newVariableCost = item.new.variableCost * totalNewOutput;
      let newCapitalCost =
        10 * this.props.wacc * item.new.capacity * item.new.capitalCost;
      let newFixedCost = 1000 * item.new.fixedCost * item.new.capacity;
      let newCost = newVariableCost + newCapitalCost + newFixedCost;

      //propCost += currentCost + newCost;

      let price = propOutput
        ? Math.round((100 * (currentCost + newCost)) / propOutput) / 100
        : 0;
      //if (price > maxPrice) {
      //  maxPrice = price;
      //}

      dataPoints1.push({
        x: x,
        y: price,
        label: "Network",
      });

      const init = this.props.init.transmission.elec;
      //console.log("Network init", init);
      /*currentOutput = init.current.capacity * init.current.loadFactor * 87.6;
        newOutput = init.new.capacity * init.new.loadFactor * 87.6;
        output = currentOutput + newOutput;*/

      currentVariableCost = init.current.variableCost * initCurOutput;
      currentCapitalCost =
        10 *
        this.props.init.wacc *
        init.current.capacity *
        init.current.capitalCost;
      currentFixedCost = 1000 * init.current.fixedCost * init.current.capacity;
      currentCost = currentVariableCost + currentCapitalCost + currentFixedCost;

      newVariableCost = init.new.variableCost * initNewOutput;
      newCapitalCost =
        10 * this.props.init.wacc * init.new.capacity * init.new.capitalCost;
      newFixedCost = 1000 * init.new.fixedCost * init.new.capacity;
      newCost = newVariableCost + newCapitalCost + newFixedCost;

      //baseCost += currentCost + newCost;

      price = baseOutput
        ? Math.round((100 * (currentCost + newCost)) / baseOutput) / 100
        : 0;
      //if (price > maxPrice) {
      //  maxPrice = price;
      //}

      dataPoints2.push({
        x: x,
        y: price,
        label: "Network",
      });

      x++;
    }

    if (this.props.storage) {
      for (let i = 0; i < this.props.storage.length; i++) {
        const item = this.props.storage[i];
        //console.log(item.tech + " item", item);
        let combCapacity = item.current.capacity + item.new.capacity || 0;
        let combFlow = item.dischargekWh + item.chargekWh || 0;
        if (combCapacity > 0) {
          let avgVariableCost =
            (item.current.variableCost * item.current.capacity +
              item.new.variableCost * item.new.capacity) /
              combCapacity || 0;
          let variableCost = avgVariableCost * combFlow;
          let currentCapitalCost =
            10 *
              this.props.wacc *
              (item.current.capacity * item.current.capitalCostkWh +
                item.current.power * item.current.capitalCostkW) || 0;
          let currentFixedCost =
            1000 * item.current.fixedCost * item.current.capacity || 0;
          let currentCost = currentCapitalCost + currentFixedCost;

          let newCapitalCost =
            10 *
              this.props.wacc *
              (item.new.capacity * item.new.capitalCostkWh +
                item.new.power * item.new.capitalCostkW) || 0;
          let newFixedCost = 1000 * item.new.fixedCost * item.new.capacity || 0;
          let newCost = newCapitalCost + newFixedCost;
          //propCost += currentCost + newCost + variableCost + item.elecCost;

          let price = item.dischargekWh
            ? Math.round(
                (100 * (currentCost + newCost + variableCost + item.elecCost)) /
                  item.dischargekWh
              ) / 100
            : 0;
          //if (price > maxPrice) {
          //  maxPrice = price;
          //}

          /*console.log("currentCost", currentCost);
          console.log("newCost", newCost);
          console.log("variableCost", variableCost);
          console.log("item", item);*/
          dataPoints1.push({
            x: x,
            y: price,
            label: item.tech,
          });

          const init = this.props.init.storage[i];
          //console.log(init.tech + " init", init);
          combCapacity = init.current.capacity + init.new.capacity || 0;
          combFlow = init.dischargekWh + init.chargekWh || 0;

          avgVariableCost =
            (init.current.variableCost * init.current.capacity +
              init.new.variableCost * init.new.capacity) /
              combCapacity || 0;
          variableCost = avgVariableCost * combFlow;
          currentCapitalCost =
            10 *
              this.props.init.wacc *
              (init.current.capacity * init.current.capitalCostkWh +
                init.current.power * init.current.capitalCostkW) || 0;
          currentFixedCost =
            1000 * init.current.fixedCost * init.current.capacity || 0;
          currentCost = currentCapitalCost + currentFixedCost;

          newCapitalCost =
            10 *
              this.props.init.wacc *
              (init.new.capacity * init.new.capitalCostkWh +
                init.new.power * init.new.capitalCostkW) || 0;
          newFixedCost = 1000 * init.new.fixedCost * init.new.capacity || 0;
          newCost = newCapitalCost + newFixedCost;
          //baseCost += currentCost + newCost + variableCost + init.elecCost;

          price = init.dischargekWh
            ? Math.round(
                (100 * (currentCost + newCost + variableCost + init.elecCost)) /
                  init.dischargekWh
              ) / 100
            : 0;
          //if (price > maxPrice) {
          //  maxPrice = price;
          //}

          dataPoints2.push({
            x: x,
            y: price,
            label: init.tech,
          });

          if (
            dataPoints1[dataPoints1.length - 1].y === 0 &&
            dataPoints2[dataPoints2.length - 1].y === 0
          ) {
            dataPoints1.pop();
            dataPoints2.pop();
          } else {
            x++;
          }
        }
      }
    }

    /*console.log("dataPoints1", dataPoints1);
    console.log("dataPoints2", dataPoints2);*/

    if (dataPoints1.length) {
      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Average cost per MWh per technology",
          fontSize: 24,
          fontFamily: fontFamily,
          fontWeight: "bold",
        },
        axisX: {
          labelAngle: 45,
          interval: 1,
        },
        axisY: {
          includeZero: true,
          title: "£/MWh",
          valueFormatString: "#,##0",
          scaleBreaks: {
            autoCalculate: true,
          },
        },
        toolTip: {
          shared: true,
        },
        legend: {
          cursor: "pointer",
          itemclick: function(e) {
            //console.log("legend click: " + e.dataPointIndex);
            //console.log(e);
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }

            e.chart.render();
          },
        },
      };
      //console.log("dataPoints1", dataPoints1);

      const propPrice = Math.round((100 * propCost) / propOutput) / 100;
      const basePrice = Math.round((100 * baseCost) / baseOutput) / 100;
      const dataPoints3 = dataPoints1.map(obj => {
        return { x: obj.x, y: propPrice };
      });
      const dataPoints4 = dataPoints1.map(obj => {
        return { x: obj.x, y: basePrice };
      });

      /*if (maxPrice > 200) {
      const highPrices1 = dataPoints1
        .map(obj => obj.y)
        .filter(y => {
          if (y > 200) {
            return true;
          } else {
            return false;
          }
        });
      const highPrices2 = dataPoints2
        .map(obj => obj.y)
        .filter(y => {
          if (y > 200) {
            return true;
          } else {
            return false;
          }
        });
      const highPrices = [...highPrices1, ...highPrices2].sort();
      if (highPrices[0] > 350) {
        options.axisY.scaleBreaks = {
          customBreaks: [
            {
              startValue: 200,
              endValue: 10 * Math.floor(highPrices[0] / 10),
              color: "gray",
            },
          ],
        };
      } else {
        options.axisY.maximum = 300;
      }
      // options.axisY.maximum = 200;
    }*/

      options.data = [
        {
          type: "column",
          name: "Base",
          showInLegend: true,
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints2,
        },
        {
          type: "column",
          name: "Proposed",
          showInLegend: true,
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints1,
        },
        {
          type: "line",
          name: "Gen. Avg. (Base)",
          color: "darkgray",
          lineDashType: "dash",
          showInLegend: true,
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints4,
        },
        {
          type: "line",
          name: "Gen. Avg. (Proposed)",
          color: "gray",
          showInLegend: true,
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints3,
        },
      ];

      return (
        <CanvasJSChart options={options} onRef={ref => (this.chart = ref)} />
      );
    } else {
      return "Pending...";
    }
  }
}

export default EDFSGenTechPrices;
