import React, { PureComponent } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSHeatDemand extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cavityWall_insulated_changed: false,
      cavityWall_notInsulatedEasy_changed: false,
      cavityWall_notInsulatedHard_changed: false,
      solidWall_insulated_changed: false,
      solidWall_notInsulated_changed: false,
      unknownWall_changed: false,
      loft_insulatedPlus_changed: false,
      loft_insulatedMinusEasy_changed: false,
      loft_insulatedMinusHard_changed: false,
      loft_notInsulated_changed: false,
      doubleGlazing_fullPC_changed: false,
      doubleGlazing_partialPC_changed: false,
      doubleGlazing_nonePC_changed: false,
      cavityWall_insulated:
        props.heatdemand.homes.existing.cavityWall.insulated,
      cavityWall_notInsulatedEasy:
        props.heatdemand.homes.existing.cavityWall.notInsulatedEasy,
      cavityWall_notInsulatedHard:
        props.heatdemand.homes.existing.cavityWall.notInsulatedHard,
      cavityWall_total: props.heatdemand.homes.existing.cavityWall.total,
      solidWall_insulated: props.heatdemand.homes.existing.solidWall.insulated,
      solidWall_notInsulated:
        props.heatdemand.homes.existing.solidWall.notInsulated,
      solidWall_total: props.heatdemand.homes.existing.solidWall.total,
      unknownWall: props.heatdemand.homes.existing.unknownWall,
      loft_insulatedPlus: props.heatdemand.homes.existing.loft.insulatedPlus,
      loft_insulatedMinusEasy:
        props.heatdemand.homes.existing.loft.insulatedMinusEasy,
      loft_insulatedMinusHard:
        props.heatdemand.homes.existing.loft.insulatedMinusHard,
      loft_notInsulated: props.heatdemand.homes.existing.loft.notInsulated,
      loft_total: props.heatdemand.homes.existing.loft.total,
      doubleGlazing_fullPC:
        props.heatdemand.homes.existing.doubleGlazing.fullPC,
      doubleGlazing_partialPC:
        props.heatdemand.homes.existing.doubleGlazing.partialPC,
      doubleGlazing_nonePC:
        props.heatdemand.homes.existing.doubleGlazing.nonePC,
      exist_totals_spaceHeating: 0,
      exist_totals_hotWater: 0,
      exist_totals_cooking: 0,
      exist_totals_heat: 0,
      houses_mnBuilt_changed: false,
      houses_feeLevel_changed: false,
      houses_size_changed: false,
      houses_mnBuilt: props.heatdemand.homes.new.houses.mnBuilt,
      houses_feeLevel: props.heatdemand.homes.new.houses.feeLevel,
      houses_size: props.heatdemand.homes.new.houses.size,
      houses_heatingSqm: 0,
      houses_heatingPA: 0,
      houses_spaceHeating: props.heatdemand.homes.new.houses.spaceHeating,
      houses_hotWater: props.heatdemand.homes.new.houses.hotWater,
      houses_cooking: props.heatdemand.homes.new.houses.cooking,
      houses_heat: props.heatdemand.homes.new.houses.heat,
      //houses_heatPA: 0,
      //houses_totalHeatPA: 0,
      flats_mnBuilt_changed: false,
      flats_feeLevel_changed: false,
      flats_size_changed: false,
      flats_mnBuilt: props.heatdemand.homes.new.flats.mnBuilt,
      flats_feeLevel: props.heatdemand.homes.new.flats.feeLevel,
      flats_size: props.heatdemand.homes.new.flats.size,
      flats_heatingSqm: 0,
      flats_heatingPA: 0,
      flats_spaceHeating: props.heatdemand.homes.new.flats.spaceHeating,
      flats_hotWater: props.heatdemand.homes.new.flats.hotWater,
      flats_cooking: props.heatdemand.homes.new.flats.cooking,
      flats_heat: props.heatdemand.homes.new.flats.heat,
      //flats_heatPA: 0,
      //flats_totalHeatPA: 0,
      services_spaceHeating_changed: false,
      services_hotWater_changed: false,
      services_cooking_changed: false,
      industry_spaceHeating_changed: false,
      industry_processHigh_changed: false,
      industry_processLow_changed: false,
      industry_drying_changed: false,
      industry_other_changed: false,
      services_spaceHeating: props.heatdemand.nondom.services.spaceHeating,
      services_hotWater: props.heatdemand.nondom.services.hotWater,
      services_cooking: props.heatdemand.nondom.services.cooking,
      industry_spaceHeating: props.heatdemand.nondom.industry.spaceHeating,
      industry_processHigh: props.heatdemand.nondom.industry.processHigh,
      industry_processLow: props.heatdemand.nondom.industry.processLow,
      industry_drying: props.heatdemand.nondom.industry.drying,
      industry_other: props.heatdemand.nondom.industry.other,
    };
    /*if (props.heatdemand) {
      for (let i = 0; i < props.heatdemand.length; i++) {
        const id = props.heatdemand[i].id;
        for (let char in props.heatdemand[i]) {
          if (char !== "id" && char !== "tech") {
            let dataKey = id + "_" + char;
            this.state[dataKey] = props.heatdemand[i][char];
            dataKey += "_changed";
            this.state[dataKey] = false;
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this); */
    this.newHomesUpdate = this.newHomesUpdate.bind(this);
    this.newHomesCalc = this.newHomesCalc.bind(this);
    this.cavityWallInsulatedUpdate = this.cavityWallInsulatedUpdate.bind(this);
    this.cavityWallNotInsulatedEasyUpdate = this.cavityWallNotInsulatedEasyUpdate.bind(
      this
    );
    this.cavityWallNotInsulatedHardUpdate = this.cavityWallNotInsulatedHardUpdate.bind(
      this
    );
    this.solidWallInsulatedUpdate = this.solidWallInsulatedUpdate.bind(this);
    this.solidWallNotInsulatedUpdate = this.solidWallNotInsulatedUpdate.bind(
      this
    );
    this.loftInsulatedPlusUpdate = this.loftInsulatedPlusUpdate.bind(this);
    this.loftInsulatedMinusHardUpdate = this.loftInsulatedMinusHardUpdate.bind(
      this
    );
    this.loftInsulatedMinusEasyUpdate = this.loftInsulatedMinusEasyUpdate.bind(
      this
    );
    this.loftNotInsulatedUpdate = this.loftNotInsulatedUpdate.bind(this);
    this.loftMax = this.loftMax.bind(this);
    this.doubleGlazingFullUpdate = this.doubleGlazingFullUpdate.bind(this);
    this.doubleGlazingPartialUpdate = this.doubleGlazingPartialUpdate.bind(
      this
    );
    this.doubleGlazingNoneUpdate = this.doubleGlazingNoneUpdate.bind(this);
    this.existingEnergyUpdate = this.existingEnergyUpdate.bind(this);
    this.currentState = this.currentState.bind(this);
    this.unknownWallUpdate = this.unknownWallUpdate.bind(this);
    this.nondomUpdate = this.nondomUpdate.bind(this);
    //this.nondomIndustryUpdate = this.nondomIndustryUpdate.bind(this);
  }

  componentDidMount() {
    this.existingEnergyUpdate();
    let update_states = Object.assign(
      this.newHomesCalc("houses", "mnBuilt", this.state.houses_mnBuilt),
      this.newHomesCalc("flats", "mnBuilt", this.state.flats_mnBuilt)
    );
    this.setState(update_states);
  }

  cavityWallInsulatedUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    const oldVal = this.state.cavityWall_insulated;
    let update_states = {
      cavityWall_insulated: newVal,
    };
    if (newVal >= this.props.initheatdemand.homes.existing.cavityWall.total) {
      /*      console.log(
        "total",
        this.props.initheatdemand.homes.existing.cavityWall.total
      ); */
      update_states = {
        cavityWall_insulated: this.props.heatdemand.homes.existing.cavityWall
          .total,
        cavityWall_notInsulatedEasy: 0,
        cavityWall_notInsulatedHard: 0,
      };
    } else if (newVal > oldVal) {
      let balance =
        this.props.initheatdemand.homes.existing.cavityWall.total - newVal;
      if (this.state.cavityWall_notInsulatedHard > 0) {
        if (balance > this.state.cavityWall_notInsulatedHard) {
          balance -= this.state.cavityWall_notInsulatedHard;
        } else {
          update_states.cavityWall_notInsulatedHard = 1 * balance.toFixed(1);
          balance = 0;
        }
      }
      update_states.cavityWall_notInsulatedEasy =
        1 *
        (balance > this.state.cavityWall_notInsulatedEasy
          ? this.state.cavityWall_notInsulatedEasy
          : balance
        ).toFixed(1);
    }
    update_states.cavityWall_total =
      1 *
      (
        update_states.cavityWall_insulated +
        (typeof update_states.cavityWall_notInsulatedEasy === "undefined"
          ? this.state.cavityWall_notInsulatedEasy
          : update_states.cavityWall_notInsulatedEasy) +
        (typeof update_states.cavityWall_notInsulatedHard === "undefined"
          ? this.state.cavityWall_notInsulatedHard
          : update_states.cavityWall_notInsulatedHard)
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  cavityWallNotInsulatedEasyUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    //console.log("newVal", newVal);
    let update_states = {
      cavityWall_notInsulatedEasy: newVal,
    };
    const headroom =
      1 *
      (
        this.props.initheatdemand.homes.existing.cavityWall.total -
        this.state.cavityWall_insulated -
        this.state.cavityWall_notInsulatedHard
      ).toFixed(1);
    //console.log("headroom", headroom);
    const maxVal =
      1 *
      (this.props.initheatdemand.homes.existing.cavityWall.notInsulatedEasy <
      headroom
        ? this.props.initheatdemand.homes.existing.cavityWall.notInsulatedEasy
        : headroom
      ).toFixed(1);
    //console.log("maxVal", maxVal);
    if (newVal >= maxVal) {
      update_states.cavityWall_notInsulatedEasy = maxVal;
    }
    update_states.cavityWall_total =
      1 *
      (
        update_states.cavityWall_notInsulatedEasy +
        this.state.cavityWall_insulated +
        this.state.cavityWall_notInsulatedHard
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  cavityWallNotInsulatedHardUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    let update_states = {
      cavityWall_notInsulatedHard: newVal,
    };
    const headroom =
      1 *
      (
        this.props.initheatdemand.homes.existing.cavityWall.total -
        this.state.cavityWall_insulated -
        this.state.cavityWall_notInsulatedEasy
      ).toFixed(1);
    const maxVal =
      1 *
      (this.props.initheatdemand.homes.existing.cavityWall.notInsulatedHard <
      headroom
        ? this.props.initheatdemand.homes.existing.cavityWall.notInsulatedHard
        : headroom
      ).toFixed(1);
    if (newVal >= maxVal) {
      update_states.cavityWall_notInsulatedHard = maxVal;
    }
    update_states.cavityWall_total =
      1 *
      (
        update_states.cavityWall_notInsulatedHard +
        this.state.cavityWall_insulated +
        this.state.cavityWall_notInsulatedEasy
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  solidWallInsulatedUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    const oldVal = this.state.solidWall_insulated;
    let update_states = {
      solidWall_insulated: newVal,
    };
    if (newVal >= this.props.initheatdemand.homes.existing.solidWall.total) {
      update_states = {
        solidWall_insulated: this.props.initheatdemand.homes.existing.solidWall
          .total,
        solidWall_notInsulated: 0,
      };
    } else if (
      newVal + this.state.solidWall_notInsulated >
      this.props.initheatdemand.homes.existing.solidWall.total
    ) {
      update_states.solidWall_notInsulated =
        1 * (this.state.solidWall_notInsulated - newVal + oldVal).toFixed(1);
      if (update_states.solidWall_notInsulated < 0) {
        update_states.solidWall_insulated +=
          update_states.solidWall_notInsulated;
        update_states.solidWall_notInsulated = 0;
      }
    }
    update_states.solidWall_total =
      1 *
      (
        update_states.solidWall_insulated +
        (typeof update_states.solidWall_notInsulated === "undefined"
          ? this.state.solidWall_notInsulated
          : update_states.solidWall_notInsulated)
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  solidWallNotInsulatedUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    let update_states = {
      solidWall_notInsulated: newVal,
    };
    const headroom =
      1 *
      (
        this.props.initheatdemand.homes.existing.solidWall.total -
        this.state.solidWall_insulated
      ).toFixed(1);
    const maxVal =
      1 *
      (this.props.initheatdemand.homes.existing.solidWall.notInsulated <
      headroom
        ? this.props.initheatdemand.homes.existing.solidWall.notInsulated
        : headroom
      ).toFixed(1);
    if (newVal >= maxVal) {
      update_states.solidWall_notInsulated = maxVal;
    }
    update_states.solidWall_total =
      1 *
      (
        update_states.solidWall_notInsulated + this.state.solidWall_insulated
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  loftInsulatedPlusUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    let update_states = {
      loft_insulatedPlus: newVal,
    };
    const maxLoft = this.loftMax();
    if (newVal >= maxLoft) {
      update_states = {
        loft_insulatedPlus: maxLoft,
        loft_insulatedMinusEasy: 0,
        loft_insulatedMinusHard: 0,
        loft_notInsulated: 0,
      };
    } else if (
      newVal > this.props.heatdemand.homes.existing.loft.insulatedPlus
    ) {
      let balance = 1 * (maxLoft - newVal).toFixed(1);

      if (balance >= this.state.loft_insulatedMinusHard) {
        balance = 1 * (balance - this.state.loft_insulatedMinusHard).toFixed(1);
      } else {
        update_states.loft_insulatedMinusHard = balance;
        balance = 0;
      }
      if (balance >= this.state.loft_insulatedMinusEasy) {
        balance = 1 * (balance - this.state.loft_insulatedMinusEasy).toFixed(1);
      } else {
        update_states.loft_insulatedMinusEasy = balance;
        balance = 0;
      }
      update_states.loft_notInsulated =
        1 *
        (balance > this.state.loft_notInsulated
          ? this.state.loft_notInsulated
          : balance
        ).toFixed(1);
    }
    update_states.loft_total =
      1 *
      (
        update_states.loft_insulatedPlus +
        (typeof update_states.loft_insulatedMinusEasy === "undefined"
          ? this.state.loft_insulatedMinusEasy
          : update_states.loft_insulatedMinusEasy) +
        (typeof update_states.loft_insulatedMinusHard === "undefined"
          ? this.state.loft_insulatedMinusHard
          : update_states.loft_insulatedMinusHard) +
        (typeof update_states.loft_notInsulated === "undefined"
          ? this.state.loft_notInsulated
          : update_states.loft_notInsulated)
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  loftInsulatedMinusHardUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    //console.log("newVal", newVal);
    let update_states = {
      loft_insulatedMinusHard: newVal,
    };
    const headroom =
      this.loftMax() -
      this.state.loft_insulatedPlus -
      this.state.loft_insulatedMinusEasy -
      this.state.loft_notInsulated;
    //console.log("headroom", headroom);
    const maxVal =
      1 *
      (this.props.initheatdemand.homes.existing.loft.insulatedMinusHard <
      headroom
        ? this.props.initheatdemand.homes.existing.loft.insulatedMinusHard
        : headroom
      ).toFixed(1);
    //console.log("maxVal", maxVal);
    if (newVal >= maxVal) {
      update_states.loft_insulatedMinusHard = maxVal;
    }
    update_states.loft_total =
      1 *
      (
        update_states.loft_insulatedMinusHard +
        this.state.loft_insulatedPlus +
        this.state.loft_insulatedMinusEasy +
        this.state.loft_notInsulated
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  loftInsulatedMinusEasyUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    let update_states = {
      loft_insulatedMinusEasy: newVal,
    };
    const headroom =
      this.loftMax() -
      this.state.loft_insulatedPlus -
      this.state.loft_insulatedMinusHard -
      this.state.loft_notInsulated;
    const maxVal =
      1 *
      (this.props.initheatdemand.homes.existing.loft.insulatedMinusEasy <
      headroom
        ? this.props.initheatdemand.homes.existing.loft.insulatedMinusEasy
        : headroom
      ).toFixed(1);
    if (newVal >= maxVal) {
      update_states.loft_insulatedMinusEasy = maxVal;
    }
    update_states.loft_total =
      1 *
      (
        update_states.loft_insulatedMinusEasy +
        this.state.loft_insulatedPlus +
        this.state.loft_insulatedMinusHard +
        this.state.loft_notInsulated
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  loftNotInsulatedUpdate(event) {
    const newVal = 1 * (+event.target.value).toFixed(1);
    let update_states = {
      loft_notInsulated: newVal,
    };
    const headroom =
      this.loftMax() -
      this.state.loft_insulatedPlus -
      this.state.loft_insulatedMinusHard -
      this.state.loft_insulatedMinusEasy;
    const maxVal =
      1 *
      (this.props.initheatdemand.homes.existing.loft.notInsulated < headroom
        ? this.props.initheatdemand.homes.existing.loft.notInsulated
        : headroom
      ).toFixed(1);
    if (newVal >= maxVal) {
      update_states.loft_notInsulated = maxVal;
    }
    update_states.loft_total =
      1 *
      (
        update_states.loft_notInsulated +
        this.state.loft_insulatedPlus +
        this.state.loft_insulatedMinusHard +
        this.state.loft_insulatedMinusEasy
      ).toFixed(1);
    this.existingEnergyUpdate(update_states);
  }

  loftMax() {
    const homes =
      this.state.cavityWall_total +
      this.state.solidWall_total +
      this.state.unknownWall;
    return this.props.initheatdemand.homes.existing.loft.total > homes
      ? homes
      : this.props.initheatdemand.homes.existing.loft.total;
  }

  unknownWallUpdate(event) {
    let val = 1 * (+event.target.value).toFixed(1);
    if (val > this.props.initheatdemand.homes.existing.unknownWall) {
      val = this.props.initheatdemand.homes.existing.unknownWall;
    }
    this.existingEnergyUpdate({ unknownWall: val });
  }

  doubleGlazingFullUpdate(event) {
    const val = 1 * (+event.target.value).toFixed(1);
    const partialRatio =
      this.state.doubleGlazing_partialPC /
      (this.state.doubleGlazing_partialPC + this.state.doubleGlazing_nonePC);
    const balance = 100 - val;
    const partialPC = 1 * (partialRatio * balance).toFixed(1);
    const update_states = {
      doubleGlazing_fullPC: val,
      doubleGlazing_partialPC: partialPC,
      doubleGlazing_nonePC: 1 * (balance - partialPC).toFixed(1),
      //doubleGlazing_nonePC_changed: true,
      //doubleGlazing_partialPC_changed: true,
      //doubleGlazing_fullPC_changed: true,
    };
    this.existingEnergyUpdate(update_states);
  }

  doubleGlazingPartialUpdate(event) {
    const val = 1 * (+event.target.value).toFixed(1);
    const noneRatio =
      this.state.doubleGlazing_nonePC /
      (this.state.doubleGlazing_fullPC + this.state.doubleGlazing_nonePC);
    const balance = 100 - val;
    const nonePC = 1 * (noneRatio * balance).toFixed(1);
    const update_states = {
      doubleGlazing_partialPC: val,
      doubleGlazing_fullPC: 1 * (balance - nonePC).toFixed(1),
      doubleGlazing_nonePC: nonePC,
      //doubleGlazing_nonePC_changed: true,
      //doubleGlazing_partialPC_changed: true,
      //doubleGlazing_fullPC_changed: true,
    };
    this.existingEnergyUpdate(update_states);
  }

  doubleGlazingNoneUpdate(event) {
    const val = 1 * (+event.target.value).toFixed(1);
    const partialRatio =
      this.state.doubleGlazing_partialPC /
      (this.state.doubleGlazing_partialPC + this.state.doubleGlazing_fullPC);
    const balance = 100 - val;
    const partialPC = 1 * (partialRatio * balance).toFixed(1);
    const update_states = {
      doubleGlazing_nonePC: val,
      doubleGlazing_partialPC: partialPC,
      doubleGlazing_fullPC: 1 * (balance - partialPC).toFixed(1),
      //doubleGlazing_nonePC_changed: true,
      //doubleGlazing_partialPC_changed: true,
      //doubleGlazing_fullPC_changed: true,
    };
    this.existingEnergyUpdate(update_states);
  }

  existingEnergyUpdate(update_states = {}) {
    const homes =
      this.currentState("cavityWall_total", update_states) +
      this.currentState("solidWall_total", update_states) +
      this.currentState("unknownWall", update_states);
    let TWh = homes * this.props.heatdemand.homes.existing.avgUninsulatedMWh;

    TWh -=
      this.currentState("cavityWall_insulated", update_states) *
      this.props.heatdemand.homes.existing.avgUninsulatedMWh *
      this.props.heatdemand.homes.existing.cavityWall.saving;

    TWh -=
      this.currentState("solidWall_insulated", update_states) *
      this.props.heatdemand.homes.existing.avgUninsulatedMWh *
      this.props.heatdemand.homes.existing.solidWall.saving;

    TWh -=
      (this.currentState("loft_insulatedPlus", update_states) +
        (this.currentState("loft_insulatedMinusEasy", update_states) +
          this.currentState("loft_insulatedMinusHard", update_states)) /
          2) *
      this.props.heatdemand.homes.existing.avgUninsulatedMWh *
      this.props.heatdemand.homes.existing.loft.saving;

    TWh -=
      (this.currentState("doubleGlazing_fullPC", update_states) *
        this.props.heatdemand.homes.existing.doubleGlazing.fullSaving *
        TWh) /
      100;

    TWh -=
      (this.currentState("doubleGlazing_partialPC", update_states) *
        this.props.heatdemand.homes.existing.doubleGlazing.partialSaving *
        TWh) /
      100;

    update_states.exist_totals_spaceHeating = 1 * TWh.toFixed(1);
    update_states.exist_totals_hotWater =
      1 *
      (
        homes *
        ((this.props.heatdemand.homes.houses.hotWater *
          this.props.heatdemand.homes.existing.housesPC +
          this.props.heatdemand.homes.flats.hotWater *
            this.props.heatdemand.homes.existing.flatsPC) /
          100)
      ).toFixed(1);

    update_states.exist_totals_cooking =
      1 *
      (
        homes *
        ((this.props.heatdemand.homes.houses.cooking *
          this.props.heatdemand.homes.existing.housesPC +
          this.props.heatdemand.homes.flats.cooking *
            this.props.heatdemand.homes.existing.flatsPC) /
          100)
      ).toFixed(1);

    update_states.exist_totals_heat =
      1 *
      (
        update_states.exist_totals_spaceHeating +
        update_states.exist_totals_hotWater +
        update_states.exist_totals_cooking
      ).toFixed(1);

    this.props.heatHomesExistingUpdate(update_states);
    for (let key in update_states) {
      const changeKey = key + "_changed";
      if (!(typeof this.state[changeKey] === "undefined")) {
        update_states[changeKey] = true;
      }
    }
    this.setState(update_states);
  }

  currentState(key, update_states) {
    return typeof update_states[key] === "undefined"
      ? this.state[key]
      : update_states[key];
  }

  newHomesUpdate(event) {
    if (event.target.id) {
      let [building, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      let update_states = this.newHomesCalc(building, characteristic, val);
      this.props.heatHomesNewUpdate(update_states);
      update_states[building + "_" + characteristic + "_changed"] = true;
      this.setState(update_states);
    }
  }

  newHomesCalc(building, characteristic, value) {
    const mnBuilt =
      1 *
      (characteristic === "mnBuilt"
        ? value
        : this.state[building + "_mnBuilt"]);
    const feeLevel =
      characteristic === "feeLevel"
        ? value
        : this.state[building + "_feeLevel"];
    let update_states = { [building + "_" + characteristic]: value };
    update_states[building + "_heatingSqm"] = this.props.feePoints[building][
      feeLevel
    ];
    const size =
      characteristic === "size" ? value : this.state[building + "_size"];
    const heatingPA = this.props.feePoints[building][feeLevel] * size;
    update_states[building + "_heatingPA"] = heatingPA;
    const spaceHeating = 1 * ((mnBuilt * heatingPA) / 1000).toFixed(1);
    update_states[building + "_spaceHeating"] = spaceHeating;
    const hotWater =
      1 * (mnBuilt * this.props.heatdemand.homes[building].hotWater).toFixed(1);
    update_states[building + "_hotWater"] = hotWater;
    const cooking =
      1 * (mnBuilt * this.props.heatdemand.homes[building].cooking).toFixed(1);
    update_states[building + "_cooking"] = cooking;
    update_states[building + "_heat"] =
      1 * (spaceHeating + hotWater + cooking).toFixed(1);
    // console.log("new " + building + " heat", update_states[building + "_heat"]);
    return update_states;
    //this.setState(update_states);
  }

  nondomUpdate(event) {
    if (event.target.id) {
      const val = 1 * (+event.target.value).toFixed(1);
      //let [sector, characteristic] = event.target.id.split("_");
      const key = event.target.id;
      let update_states = { [key]: val, [key + "_changed"]: true };
      this.setState(update_states);
      this.props.heatNondomUpdate(key, val);
    }
  }

  render() {
    console.log("Rendering EDFSHeatDemand");
    //console.log("heatdemand", this.props.heatdemand);
    //console.log("state", this.state);
    return (
      <>
        <h4>Existing homes</h4>
        <MDBRow className="px-2">
          <MDBCol
            key={"heatdemand_homes_exist_cavityWall_col"}
            id={"heatdemand_homes_exist_cavityWall_col"}
            className="p-2"
            size="12"
            md="6"
            xl="4"
          >
            <MDBCard id={"heatdemand_homes_exist_cavityWall_card"}>
              <MDBCardHeader id={"heatdemand_homes_exist_cavityWall_header"}>
                Cavity Wall Insulation
              </MDBCardHeader>
              <MDBCardBody
                id={"heatdemand_homes_exist_cavityWall_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Insulated <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_cavityWall_insulated"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["cavityWall_insulated_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.cavityWall
                          .total
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.cavityWall_insulated}
                      onChange={this.cavityWallInsulatedUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Uninsulated (easy){" "}
                    <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_cavityWall_notInsulatedEasy"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["cavityWall_notInsulatedEasy_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.cavityWall
                          .notInsulatedEasy
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.cavityWall_notInsulatedEasy}
                      onChange={this.cavityWallNotInsulatedEasyUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Uninsulated (difficult){" "}
                    <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_cavityWall_notInsulatedHard"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["cavityWall_notInsulatedHard_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.cavityWall
                          .notInsulatedHard
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.cavityWall_notInsulatedHard}
                      onChange={this.cavityWallNotInsulatedHardUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Total <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_cavityWall_total"}
                      type="number"
                      className={"form-control form-control-sm"}
                      value={this.state.cavityWall_total}
                      disabled
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            key={"heatdemand_homes_exist_solidWall_col"}
            id={"heatdemand_homes_exist_solidWall_col"}
            className="p-2"
            size="12"
            md="6"
            xl="4"
          >
            <MDBCard id={"heatdemand_homes_exist_solidWall_card"}>
              <MDBCardHeader id={"heatdemand_homes_exist_solidWall_header"}>
                Solid Wall Insulation
              </MDBCardHeader>
              <MDBCardBody
                id={"heatdemand_homes_exist_solidWall_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Insulated <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_solidWall_insulated"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["solidWall_insulated_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.solidWall.total
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.solidWall_insulated}
                      onChange={this.solidWallInsulatedUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Uninsulated <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_solidWall_notInsulated"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["solidWall_notInsulated_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.solidWall
                          .notInsulatedEasy
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.solidWall_notInsulated}
                      onChange={this.solidWallNotInsulatedUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Total <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_solidWall_total"}
                      type="number"
                      className={"form-control form-control-sm"}
                      value={this.state.solidWall_total}
                      disabled
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>

            <MDBCard
              id={"heatdemand_homes_exist_unknownWall_card"}
              className="mt-3"
            >
              <MDBCardBody
                id={"heatdemand_homes_exist_unknownWall_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-1">
                  <label className="col-form-label col-form-label-sm col-8">
                    Unknown wall insulation{" "}
                    <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_unknownWall"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["unknownWall_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={this.props.initheatdemand.homes.existing.unknownWall}
                      min={0}
                      step={"0.1"}
                      value={this.state.unknownWall}
                      onChange={this.unknownWallUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            key={"heatdemand_homes_exist_loft_col"}
            id={"heatdemand_homes_exist_loft_col"}
            className="p-2"
            size="12"
            md="6"
            xl="4"
          >
            <MDBCard id={"heatdemand_homes_exist_loft_card"}>
              <MDBCardHeader id={"heatdemand_homes_exist_loft_header"}>
                Loft Insulation
              </MDBCardHeader>
              <MDBCardBody
                id={"heatdemand_homes_exist_loft_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Insulated &ge;125mm{" "}
                    <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_loft_insulatedPlus"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["loft_insulatedPlus_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={this.props.initheatdemand.homes.existing.loft.total}
                      min={0}
                      step={"0.1"}
                      value={this.state.loft_insulatedPlus}
                      onChange={this.loftInsulatedPlusUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Insulated &lt;125mm (easy){" "}
                    <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_loft_insulatedMinusEasy"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["loft_insulatedMinusEasy_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.loft
                          .insulatedMinusEasy
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.loft_insulatedMinusEasy}
                      onChange={this.loftInsulatedMinusEasyUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Insulated &lt;125mm (difficult){" "}
                    <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_loft_insulatedMinusHard"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["loft_insulatedMinusHard_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.loft
                          .insulatedMinusHard
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.loft_insulatedMinusHard}
                      onChange={this.loftInsulatedMinusHardUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Not Insulated <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_loft_notInsulated"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["loft_notInsulated_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={
                        this.props.initheatdemand.homes.existing.loft
                          .notInsulated
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.loft_notInsulated}
                      onChange={this.loftNotInsulatedUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Total <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_loft_total"}
                      type="number"
                      className={"form-control form-control-sm"}
                      value={this.state.loft_total}
                      disabled
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol
            key={"heatdemand_homes_exist_doubleGlazing_col"}
            id={"heatdemand_homes_exist_doubleGlazing_col"}
            className="p-2"
            size="12"
            md="6"
            xl="4"
          >
            <MDBCard id={"heatdemand_homes_exist_doubleGlazing_card"}>
              <MDBCardHeader id={"heatdemand_homes_exist_doubleGlazing_header"}>
                Double Glazing
              </MDBCardHeader>
              <MDBCardBody
                id={"heatdemand_homes_exist_doubleGlazing_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Full <small className="text-muted">%</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_doubleGlazing_fullPC"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["doubleGlazing_fullPC_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={100}
                      min={0}
                      step={"0.5"}
                      value={this.state.doubleGlazing_fullPC}
                      onChange={this.doubleGlazingFullUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Partial <small className="text-muted">%</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_doubleGlazing_partialPC"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["doubleGlazing_partialPC_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={100}
                      min={0}
                      step={"0.5"}
                      value={this.state.doubleGlazing_partialPC}
                      onChange={this.doubleGlazingPartialUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    None <small className="text-muted">%</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_doubleGlazing_nonePC"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["doubleGlazing_nonePC_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={100}
                      min={0}
                      step={"0.5"}
                      value={this.state.doubleGlazing_nonePC}
                      onChange={this.doubleGlazingNoneUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            key={"heatdemand_homes_exist_totals_Heat_col"}
            id={"heatdemand_homes_exist_totals_Heat_col"}
            className="p-2"
            size="12"
            md="6"
            xl="4"
          >
            <MDBCard id={"heatdemand_homes_exist_totals_Heat_card"}>
              <MDBCardHeader id={"heatdemand_homes_exist_totals_Heat_header"}>
                Total Energy for Heat
              </MDBCardHeader>
              <MDBCardBody
                id={"heatdemand_homes_exist_totals_Heat_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-1">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_totals_spaceHeating"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.exist_totals_spaceHeating}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-1">
                  <label className="col-form-label col-form-label-sm col-8">
                    Hot water <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_totals_hotWater"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.exist_totals_hotWater}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-1">
                  <label className="col-form-label col-form-label-sm col-8">
                    Cooking <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_totals_cooking"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.exist_totals_cooking}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-1">
                  <label className="col-form-label col-form-label-sm col-8">
                    Total Heat <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"heatdemand_homes_exist_totals_heat"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.exist_totals_heat}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <h4 className="mt-3">New homes</h4>
        <MDBRow className="px-2">
          <MDBCol
            key={"houses_col"}
            id={"houses_col"}
            className="p-2"
            size="12"
            md="6"
          >
            <MDBCard id={"houses_card"}>
              <MDBCardHeader id={"houses_header"}>Houses</MDBCardHeader>
              <MDBCardBody id={"houses_body"} className="p-1">
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    No. built <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_mnBuilt"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["houses_mnBuilt_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.houses_mnBuilt}
                      onChange={this.newHomesUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label
                    className="col-form-label col-form-label-sm col-8"
                    title="Fabric Energy Efficiency"
                  >
                    FEE level
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_feeLevel"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["houses_feeLevel_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={9}
                      min={3}
                      value={this.state.houses_feeLevel}
                      onChange={this.newHomesUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating / floor area{" "}
                    <small className="text-muted">
                      kWh/m<sup>2</sup>/yr
                    </small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_heatingSqm"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.houses_heatingSqm}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Avg. floor area{" "}
                    <small className="text-muted">
                      m<sup>2</sup>
                    </small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_size"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["houses_size_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={1000}
                      min={50}
                      value={this.state.houses_size}
                      onChange={this.newHomesUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating / house{" "}
                    <small className="text-muted">kWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_heatingPA"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.houses_heatingPA}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_spaceHeating"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.houses_spaceHeating}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Hot water <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_hotWater"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.houses_hotWater}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Cooking <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_cooking"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.houses_cooking}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Total Heat <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"houses_heat"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.houses_heat}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            key={"flats_col"}
            id={"flats_col"}
            className="p-2"
            size="12"
            md="6"
          >
            <MDBCard id={"flats_card"}>
              <MDBCardHeader id={"flats_header"}>Flats</MDBCardHeader>
              <MDBCardBody id={"flats_body"} className="p-1">
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    No. built <small className="text-muted">million</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_mnBuilt"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["flats_mnBuilt_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.flats_mnBuilt}
                      onChange={this.newHomesUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label
                    className="col-form-label col-form-label-sm col-8"
                    title="Fabric Energy Efficiency"
                  >
                    FEE level
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_feeLevel"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["flats_feeLevel_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={9}
                      min={3}
                      value={this.state.flats_feeLevel}
                      onChange={this.newHomesUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating / floor area{" "}
                    <small className="text-muted">
                      kWh/m<sup>2</sup>/yr
                    </small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_heatingSqm"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.flats_heatingSqm}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Avg. floor area{" "}
                    <small className="text-muted">
                      m<sup>2</sup>
                    </small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_size"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["flats_size_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={2000}
                      min={20}
                      value={this.state.flats_size}
                      onChange={this.newHomesUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating / flat{" "}
                    <small className="text-muted">kWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_heatingPA"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.flats_heatingPA}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_spaceHeating"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.flats_spaceHeating}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Hot water <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_hotWater"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.flats_hotWater}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Cooking <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_cooking"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.flats_cooking}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Total heat <small className="text-muted">TWh/yr</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"flats_heat"}
                      type="number"
                      className={"form-control form-control-sm"}
                      disabled
                      value={this.state.flats_heat}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <h4 className="mt-3">Non-domestic</h4>
        <MDBRow className="px-2">
          <MDBCol
            key={"services_col"}
            id={"services_col"}
            className="p-2"
            size="12"
            md="6"
          >
            <MDBCard id={"services_card"}>
              <MDBCardHeader id={"services_header"}>Services</MDBCardHeader>
              <MDBCardBody id={"services_body"} className="p-1">
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"services_spaceHeating"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["services_spaceHeating_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.services_spaceHeating}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Hot water <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"services_hotWater"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["services_hotWater_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.services_hotWater}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Cooking <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"services_cooking"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["services_cooking_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.services_cooking}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol
            key={"industry_col"}
            id={"industry_col"}
            className="p-2"
            size="12"
            md="6"
          >
            <MDBCard id={"industry_card"}>
              <MDBCardHeader id={"industry_header"}>Industry</MDBCardHeader>
              <MDBCardBody id={"industry_body"} className="p-1">
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Space heating <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"industry_spaceHeating"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["industry_spaceHeating_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.industry_spaceHeating}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Process heat (high temp.){" "}
                    <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"industry_processHigh"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["industry_processHigh_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.industry_processHigh}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Process heat (low temp.){" "}
                    <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"industry_processLow"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["industry_processLow_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.industry_processLow}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Drying/separation <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"industry_drying"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["industry_drying_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.industry_drying}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-8">
                    Other <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-4">
                    <input
                      id={"industry_other"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state["industry_other_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      step={"0.1"}
                      value={this.state.industry_other}
                      onChange={this.nondomUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default EDFSHeatDemand;
