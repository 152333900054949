import React, { Component } from "react";
import { MDBModalBody, MDBCollapse } from "mdbreact";

class EDFSInstructions extends Component {
  state = {
    instrIntro: false,
    instrBasis: false,
    instrLayout: false,
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      [collapseID]: !prevState[collapseID],
    }));
  };

  render() {
    return (
      <MDBModalBody>
        <p className="lead">
          The following instructions explain how to use the model to test a
          scenario. In practice, you will want to test multiple scenarios, both
          by varying the assumptions within your control, and by varying the
          base data with which the assumptions are combined to estimate the
          outcomes. Simply repeat the process below to test multiple scenarios.
        </p>
        <h5>Choose the basis (exogenous factors)</h5>
        <p className="lead">
          Immediately below the button for these instructions, select the base
          year and the weather conditions by clicking on the relevant buttons,
          which act as toggles within their group. Three years and three weather
          conditions make nine base scenarios in total. A spinner will indicate
          when the downloading and calculating is under way or complete.
        </p>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={this.toggleCollapse("instrBasis")}
          >
            {this.state.instrBasis ? "Hide..." : "More..."}
          </button>
        </div>
        <MDBCollapse id="instrBasis" isOpen={this.state.instrBasis}>
          <p>
            The starting point for this model is historic data. A key challenge
            in balancing supply and demand for energy, and in modelling that
            balance, is that irregularly-variable factors, such as wind and
            temperatures, play a strong and increasing role.
          </p>
          <p>
            It is difficult to say if randomly-generated data is sufficiently
            realistic. Historic trend data is worse than useless, because the
            law of large numbers means that trends neutralise much of the
            variation that is critical to model. So this model takes historic
            data for one of three years (2016, 2017 or 2018) as the basis for
            key variable factors, before adjusting and inferring outcomes
            combining the base data with the selected assumptions (e.g. levels
            of demand and capacity to supply).
          </p>
          <p>
            Unfortunately, temperatures were slightly above average in all three
            years for which the full range of required base data is available.
            It is important to test how systems perform in atypical years to
            ensure there is sufficient margin to ensure security of supply under
            testing conditions. But the last really cold year in the UK was
            2010, and not all the required data are available for that year.
          </p>
          <p>
            We know the extent to which temperatures vary in 1-in-20 extreme
            years compared to typical years, so we generate versions of the
            annual data with (a) warmer winters and cooler summers ("Mild"
            weather), and (b) cooler winters and hotter summers ("Severe"
            weather), through the application of slightly randomised factors to
            stretch the temperatures towards or away from the mean. The factors
            are only slightly randomised to preserve the patterns that are the
            purpose of using historic rather than artificial data.
          </p>
          <p>
            Like much of the model, or indeed any model, the method is not
            certain to provide an exactly-realistic basis. For example, 1-in-20
            cold winters will rarely coincide with 1-in-20 hot summers (and
            likewise for unusually mild conditions). But these assumptions allow
            us to test the stresspoints concisely, and the potential flaws are{" "}
            <i>de minimis</i> compared to the effects of varying the supply and
            demand variables within the user's control.
          </p>
        </MDBCollapse>
        <h5>Choose the parameters you want to specify</h5>
        <p className="lead">
          Below the buttons to select the base data, is the Inputs section. By
          default, the card for specifying the electricity demand parameters is
          displayed.
        </p>
        <p className="lead">
          You can (and should) choose other parameters to specify by selecting
          them from the side menu. The side menu can be opened and closed by
          clicking on the arrow button that sits on the left near the top of the
          window. Click the Inputs option to see the sets of parameters
          available for you to specify. When you click one of the options, a
          card containing the options will be added to the main page (or removed
          from it, if already open).
        </p>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={this.toggleCollapse("instrLayout")}
          >
            {this.state.instrLayout ? "Hide..." : "More..."}
          </button>
        </div>
        <MDBCollapse id="instrLayout" isOpen={this.state.instrLayout}>
          <p>The side menu is divided into four sections:</p>
          <ol>
            <li>Inputs</li>
            <li>Energy Outputs</li>
            <li>Carbon Outputs</li>
            <li>Cost Outputs</li>
          </ol>
          <p>
            As the names indicate, the first section contains the various sets
            of parameters that you can specify/modify for your scenario. The
            other three sections contain the different visualisations available
            for the scenario you have modelled, categorised according to whether
            they are primarily about energy, carbon or cost.
          </p>
        </MDBCollapse>
        <h5>Adjust the parameters</h5>
        <p className="lead">
          Close the side menu to make the selected cards fully visible. Each
          card in the Inputs section typically contains a set of sub-cards,
          which each contain input boxes for you to enter your desired values.
          You can either type them directly, or most of them allow you to use
          up/down arrows to move the values up or down.
        </p>
        <div className="alert alert-warning">
          <p className="lead my-0">
            The values are not updated until you exit the input field, e.g.
            click outside the box or hit tab.
          </p>
        </div>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={this.toggleCollapse("instrAdjust")}
          >
            {this.state.instrAdjust ? "Hide..." : "More..."}
          </button>
        </div>
        <MDBCollapse id="instrAdjust" isOpen={this.state.instrAdjust}>
          <p>
            Some of the parameters are linked, so as you change the values in
            one box, they are automatically adjusted in others. For example, if
            you adjust the assumed efficiency of a technology, the system will
            automatically adjust the amount of energy required to achieve the
            equivalent amount of work (higher efficiency = less energy).
          </p>
          <p>
            Other parameters are not editable. If so, they are greyed-out. They
            are (a) for your information, and (b) tied to other values that
            either adjust them or use them for calculations. For example, heat
            demand for each technology is calculated as Total Heat Demand * the
            technology's share of that demand. Adjust the share to adjust the
            demand.
          </p>
        </MDBCollapse>
        <h5>Recalculate</h5>
        <p className="lead">
          This system is consciously designed not to recalculate until you tell
          it to, because the calculations are intensive, and it would be
          impossibly sclerotic if it recalculated every time you changed a
          parameter.
        </p>
        <p className="lead">
          To recalculate, click one of the "Recalculate" buttons, either to the
          right of the <em>Inputs</em> heading, or below the logo in the side
          menu.
        </p>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={this.toggleCollapse("instrRecalc")}
          >
            {this.state.instrRecalc ? "Hide..." : "More..."}
          </button>
        </div>
        <MDBCollapse id="instrRecalc" isOpen={this.state.instrRecalc}>
          <p>
            This means that the Inputs can easily be out-of-sync with the
            Outputs, if you have modified some of the Inputs but have not yet
            clicked the button to Recalculate the Outputs. That's simply
            something for you to manage manually. Recalculating normally only
            takes a second or two on typical computers, so if in doubt, just
            click the Recalculate button again to ensure the Inputs and Outputs
            are in sync.
          </p>
          <p>
            On phones and other low-powered systems, recalculation may take
            longer and require a high proportion of the processing and memory
            capacity available, so you may want to be more judicious in your use
            of the Recalculate button on these systems.
          </p>
          <p>
            The amount of processing required on recalculation is also affected
            by the number of Output options visible (see below). Besides
            calculating the underlying data, which is the same regardless of
            which Outputs are visible, every chart that is visible requires
            processing. Some of them (e.g. the line charts) display thousands of
            data points and are very processor/memory intensive. Others are
            simpler (e.g. the bar charts) and consume minimal resources.
          </p>
          <p>Everything is visible in Print Layout.</p>
          <div className="alert alert-warning">
            It is strongly recommended not to Recalculate in Print Layout.
          </div>
          <p>
            Edit your parameters and recalculate in Online Layout with limited
            visualisations selected (switch between them as necessary), and when
            you are happy, select Print Layout if you want to be able to view or
            print all of the inputs and visualisations.
          </p>
        </MDBCollapse>
        <h5>View the data visualisations for your scenario</h5>
        <p className="lead">
          From the side menu, select whichever visualisations of your scenario
          you are interested in. You can close visualisations either by
          unselecting them in the side menu, or by clicking the cross at the
          top-right of the card.
        </p>
        <div className="alert alert-warning">
          <p className="lead">
            It is strongly recommended to check all the visualisations before
            finalising a scenario. This system is designed to reveal unintended
            consequences of system-design choices. You may well not realise that
            there is a flaw in your scenario that makes it impractical unless
            you check all the visualisations.
          </p>
        </div>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={this.toggleCollapse("instrView")}
          >
            {this.state.instrView ? "Hide..." : "More..."}
          </button>
        </div>
        <MDBCollapse id="instrView" isOpen={this.state.instrView}>
          <p>
            We use{" "}
            <a
              href="https://canvasjs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              canvasjs
            </a>{" "}
            to generate the charts, because it is blisteringly fast (compared to
            the alternatives) on large data-sets for a client-side system. Many
            of the charts share common features that leverage canvasjs's
            functionality
          </p>
          <dl>
            <dt>Print/Save Chart</dt>
            <dd>
              There is a "hamburger menu" (three horizontal bars) at the top
              right of each chart, with options to Print or to Save (i.e.
              download) as JPEG or PNG. The nature of image formats is that the
              data is not accessible from these versions, and you cannot zoom in
              as you can on the live versions.
            </dd>
            <dt>Data Labels</dt>
            <dd>
              If you hover over a line or bar, a popup will reveal the data
              values relating to that data point (or all the data points at that
              point in time for time series data).
            </dd>
            <dt>Pan and Zoom</dt>
            <dd>
              You can zoom in to many of the charts simply by clicking and
              holding your mouse button to drag it across part of the chart.
              (This is not applicable to the simple bar charts.) If you are
              zoomed in, you will see two options next to the hamburger menu at
              the top-right. The circular arrow means zoom back out. The other
              option may be a cross or a magnifying glass. The cross is to
              select "Pan" mode when you are in "Zoom" mode. Click it and then
              use your mouse to drag the chart to left or right. The magnifying
              glass is to select "Zoom" mode when you are in "Pan" mode. There
              is unfortunately no option to zoom out a bit. If you zoom in too
              far, you have to hit the circular arrow to zoom out completely and
              then zoom in again.
            </dd>
            <dt>Enable/disualise data sets</dt>
            <dd>
              In the line charts with multiple datasets, you can choose which of
              the datasets to display, by clicking on its key in the legend.
            </dd>
            <dt>Multiple Y Axes</dt>
            <dd>
              Watch out for charts with a vertical scale on the right as well as
              the left. These are used wherever the maximum value of one or more
              of the datasets is so much higher than the others that it would
              make the others unreadable. Which scale applies to which dataset
              is indicated by a (R) for Right or (L) for Left against each key
              in the legend. If a key says neither (R) nor (L), then it is using
              the Left axis.
            </dd>
            <dt>Scale Breaks</dt>
            <dd>
              Where there are a small number of anomalous values (e.g. a few
              periods of exceptionally high cost), a scale break may be employed
              on the vertical axis. It is indicated by a horizontal zig-zag
              line. A scale break is a discontinuity in the scale, so it might
              jump (for example) from steps of 20,40,60,80,100 to
              500,520,540,etc if most of the data is &lt;100 but a few data
              points are &gt;500.
            </dd>
            <dt>Base/Proposed</dt>
            <dd>
              Several of the charts compare Base with Proposed values.
              <br />
              <strong>
                <em>Base</em>
              </strong>{" "}
              is roughly the status quo mid-2019 (e.g. installed capacities of
              technologies). It is "roughly" because:
              <ol type="a">
                <li>
                  there is no snapsot dataset that gives a precise record of all
                  technologies at a moment in time (particularly as substantial
                  proportions of some technologies are embedded and vary widely
                  in their inclusion in official statistics), and
                </li>
                <li>
                  these reasonable estimates of the installed base are combined
                  with weather hypotheticals on the basis of reasonable
                  assumptions (based on historical performance) to give
                  estimated (not measured) outputs, costs etc for that
                  combination of estimated potential and hypothetical
                  conditions.
                </li>
              </ol>
              <strong>
                <em>Proposed</em>
              </strong>{" "}
              is the outcome of the same methodology for the values you specify
              for your scenario. At the outset, these are obviously identical,
              because the starting point is the status quo. The point of these
              charts is to show how your scenario moves us on from where we are
              now.
            </dd>
          </dl>
        </MDBCollapse>
        <h5>Print/Save/Download</h5>
        <p className="lead">
          Once your scenario is in a state that you'd like to record, you have
          various options:
          <dl>
            <dt>Print</dt>
            <dd>
              In the box at the top of the page is a <em>"Print Layout"</em>{" "}
              button. When you click this, all Inputs and all Visualisations
              become visible. Use your browser's print facilities to print hard
              copy, or print to PDF to save the information in this layout
              electronically. Printing the page will capture none of the hourly
              generated data, other than in the charts, whose resolution is not
              sufficient to make out individual hourly data points.
            </dd>
            <dt>Save</dt>
            <dd>
              At the bottom of the page is a <em>"Save Scenario"</em> button.
              This will download to your computer a JSON file containing the
              parameters of your scenario. It does not contain any of the
              outputs. It is for loading the parameters again in future without
              re-entering them, using the neighbouring <em>"Load Scenario"</em>{" "}
              button to upload this JSON file. You can save as many scenarios as
              you like.
            </dd>
            <dt>Download</dt>
            <dd>
              At the bottom of the page is a <em>"Download Data"</em> button.
              This will download to your computer an Excel worksheet. This file
              contains only a subset of the generated data, and none of the base
              data nor any of the charts.
            </dd>
          </dl>
          <p className="lead">
            The live data on the web page is much the most useful for
            interrogation, as the system allows you to drill down visually as
            close as you like.
          </p>
        </p>
      </MDBModalBody>
    );
  }
}

export default EDFSInstructions;
