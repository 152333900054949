import React, { PureComponent } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

class EDFSTrnsptDemand extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      roadConstant: true,
      railConstant: true,
      airConstant: true,
      waterConstant: true,
    };
    if (props.trnsptdemand) {
      for (let i = 0; i < props.trnsptdemand.length; i++) {
        const id = props.trnsptdemand[i].id;
        for (let char in props.trnsptdemand[i]) {
          if (char !== "id" && char !== "tech") {
            let dataKey = id + "_" + char;
            this.state[dataKey] = props.trnsptdemand[i][char];
            dataKey += "_changed";
            this.state[dataKey] = false;
          }
        }
      }
    }
    this.itemUpdate = this.itemUpdate.bind(this);
  }

  itemUpdate(event) {
    if (event.target.id) {
      // eslint-disable-next-line no-unused-vars
      let [type, id, characteristic] = event.target.id.split("_");
      const val = +event.target.value;
      const valKey = id + "_" + characteristic;
      const changedKey = valKey + "_changed";
      let params = { type: type, id: id, vals: { [characteristic]: val } };
      let update_states = { [valKey]: val, [changedKey]: true };
      if (this.state[id + "Constant"]) {
        const oldElecWork =
          Math.round(
            this.state[id + "_elec"] * this.state[id + "_elecefficiency"]
          ) / 100;
        let nonElecEnergy =
          this.state[id + "_total"] - this.state[id + "_elec"];
        let nonElecWork =
          Math.round(nonElecEnergy * this.state[id + "_fuelefficiency"]) / 100;
        const totalWork = oldElecWork + nonElecWork;
        let totalEnergy = this.state[id + "_total"];
        if (characteristic === "elec") {
          const newElecWork =
            Math.round(val * this.state[id + "_elecefficiency"]) / 100;
          nonElecWork = totalWork - newElecWork;
          totalEnergy =
            val +
            Math.round(
              (10000 * nonElecWork) / this.state[id + "_fuelefficiency"]
            ) /
              100;
        } else if (characteristic === "elecefficiency") {
          const newElecWork = Math.round(this.state[id + "_elec"] * val) / 100;
          nonElecWork = totalWork - newElecWork;
          totalEnergy =
            this.state[id + "_elec"] +
            Math.round(
              (10000 * nonElecWork) / this.state[id + "_fuelefficiency"]
            ) /
              100;
        } else if (characteristic === "fuelefficiency") {
          nonElecEnergy = Math.round((100 * nonElecWork) / val);
          totalEnergy = nonElecEnergy + this.state[id + "_elec"];
        }
        update_states[id + "_total"] = totalEnergy;
        update_states[id + "_total_changed"] = true;
        params.vals["total"] = totalEnergy;
      }
      this.setState(update_states);
      this.props.fieldUpdate(params);
    }
  }

  render() {
    //console.log("trnsptdemand", this.props.trnsptdemand);
    //console.log("state", this.state);
    return (
      <MDBRow className="px-2">
        {this.props.trnsptdemand.map(item => (
          <MDBCol
            key={"trnsptdemand_" + item.id + "_col"}
            id={"trnsptdemand_" + item.id + "_col"}
            className="p-2"
            size="12"
            md="6"
            xl="3"
          >
            <MDBCard id={"trnsptdemand_" + item.id + "_card"}>
              <MDBCardHeader id={"trnsptdemand_" + item.id + "_header"}>
                {item.tech}
              </MDBCardHeader>
              <MDBCardBody
                id={"trnsptdemand_" + item.id + "_body"}
                className="p-1"
              >
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-7">
                    Total energy p.a. <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-5">
                    <input
                      id={"trnsptdemand_" + item.id + "_total"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_total_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      value={this.state[item.id + "_total"]}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-7">
                    of which, electric <small className="text-muted">TWh</small>
                  </label>
                  <div className="col-5">
                    <input
                      id={"trnsptdemand_" + item.id + "_elec"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_elec_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      max={this.state[item.id + "_total"]}
                      value={this.state[item.id + "_elec"]}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-7">
                    Electric efficiency <small className="text-muted">%</small>
                  </label>
                  <div className="col-5">
                    <input
                      id={"trnsptdemand_" + item.id + "_elecefficiency"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_elecefficiency_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={100}
                      min={0}
                      value={this.state[item.id + "_elecefficiency"]}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-2 mb-2">
                  <label className="col-form-label col-form-label-sm col-7">
                    Fuel efficiency <small className="text-muted">%</small>
                  </label>
                  <div className="col-5">
                    <input
                      id={"trnsptdemand_" + item.id + "_fuelefficiency"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_fuelefficiency_changed"]
                          ? " border-danger"
                          : "")
                      }
                      max={100}
                      min={0}
                      value={this.state[item.id + "_fuelefficiency"]}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
                <div className="form-group row px-4">
                  <div className="form-check">
                    <input
                      id={"trnsptdemand_" + item.id + "_workconstant"}
                      type="checkbox"
                      className="form-check-input"
                      checked={this.state[item.id + "Constant"]}
                      onChange={() =>
                        this.setState({
                          [item.id + "Constant"]: !this.state[
                            item.id + "Constant"
                          ],
                        })
                      }
                    />
                    <label className="form-check-label small">
                      Keep total work constant
                    </label>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    );
  }
  /*
  <MDBRow className="px-2">
        {this.props.convdemand.map(item => (
          <MDBCol
            key={"convdemand_" + item.id + "_col"}
            id={"convdemand_" + item.id + "_col"}
            className="p-2"
            size="6"
          >
            <MDBCard id={"convdemand_" + item.id + "_card"}>
              <MDBCardHeader id={"convdemand_" + item.id + "_header"}>
                {item.tech}
              </MDBCardHeader>
              <MDBCardBody
                id={"convdemand_" + item.id + "_body"}
                className="p-1"
              >
                <div className="form-group row px-2">
                  <label className="col-form-label col">
                    Demand <small className="text-muted">TWh</small>
                  </label>
                  <div className="col">
                    <input
                      id={"convdemand_" + item.id + "_demand"}
                      type="number"
                      className={
                        "form-control form-control-sm" +
                        (this.state[item.id + "_demand_changed"]
                          ? " border-danger"
                          : "")
                      }
                      min={0}
                      value={this.state[item.id + "_demand"]}
                      onChange={this.itemUpdate}
                    />
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
      */
}

export default EDFSTrnsptDemand;
