import React from "react";
//import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import {
  VictoryChart,
  VictoryScatter,
  VictoryLine,
  //VictoryAxis,
  VictoryLabel,
  //VictoryTooltip,
} from "victory";

const EDScatterWithTrend = props => {
  //let lr = {};
  let n = 0;
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let sum_yy = 0;
  let min_x = 99999999;
  let max_x = -99999999;
  let min_y = 99999999;
  let max_y = -99999999;
  const q = props.rows.length;

  for (let i = 0; i < q; i++) {
    const x_val = props.rows[i][props.x];
    const y_val = props.rows[i][props.y];
    if (isNaN(x_val) || isNaN(y_val)) {
      continue;
    }
    sum_x += x_val;
    sum_y += y_val;
    sum_xy += x_val * y_val;
    sum_xx += x_val * x_val;
    sum_yy += y_val * y_val;
    if (x_val < min_x) {
      min_x = x_val;
    }
    if (y_val < min_y) {
      min_y = y_val;
    }
    if (x_val > max_x) {
      max_x = x_val;
    }
    if (y_val > max_y) {
      max_y = y_val;
    }
    n++;
  }

  const slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  const intercept = (sum_y - slope * sum_x) / n;
  const r2 = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2
  );
  const sign = intercept < 0 ? "x - " : "x + ";
  const absintercept = Math.abs(intercept);
  //lr["N"] = n;
  //lr["Q"] = q;

  const titles = {
    1: "Half-hourly total MW (",
    2: "Half-hourly average MW (",
    3: "Half-hourly % load factor (",
  };

  return (
    <VictoryChart
      scale={{ x: "linear", y: "linear" }}
      height={240}
      width={530}
      padding={{ bottom: 50, left: 76, right: 30, top: 40 }}
    >
      <VictoryScatter data={props.rows} x={props.x} y={props.y} size={2} />
      <VictoryLine
        data={props.rows}
        x={props.x}
        y={d => intercept + d[props.x] * slope}
      />
      <VictoryLabel
        angle={270}
        text={props.ytitle}
        x={7}
        y={120}
        textAnchor="middle"
        style={{ labels: { fontSize: 10 } }}
      />
      <VictoryLabel
        text={props.xtitle}
        x={280}
        y={230}
        textAnchor="middle"
        style={{ fontSize: 14 }}
      />
      <VictoryLabel
        text={
          "R\xB2 = " +
          r2.toFixed(2) +
          "\nN = " +
          n +
          (n !== q ? "(/" + q + ")" : "") +
          "\ny = " +
          slope.toFixed(2) +
          sign +
          absintercept.toFixed(2)
        }
        x={2}
        y={220}
        textAnchor="start"
        style={{ fontSize: 10 }}
      />
      <VictoryLabel
        text={titles[props.dataType] + props.from + " - " + props.to + ")"}
        x={270}
        y={15}
        textAnchor="middle"
        style={{ fontSize: 18 }}
      />
    </VictoryChart>
  );
};

export default EDScatterWithTrend;
