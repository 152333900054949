import React, { Component } from "react";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSCarbonTransport extends Component {
  render() {
    console.log("Rendering EDFSCarbonTransport");
    if (this.props.propData && this.props.propData) {
      const fontFamily =
        "Roboto, Open Sans, Calibri, Optima, Candara, Verdana, Geneva, sans-serif";
      const data = [];
      let transportTotal = 0;
      let initTransportTotal = 0;
      for (let mode in this.props.propData) {
        const modeName = mode.charAt(0).toUpperCase() + mode.slice(1);
        data.push({
          type: "stackedColumn",
          legendText: modeName,
          showInLegend: "true",
          yValueFormatString: "0.000",
          toolTipContent: modeName + ": {y}",
          dataPoints: [
            {
              label: "Base",
              y: this.props.baseData[mode],
            },
            {
              label: "Proposed",
              y: this.props.propData[mode],
            },
          ],
        });
        transportTotal += this.props.propData[mode];
        initTransportTotal += this.props.baseData[mode];
      }
      if (data.length) {
        data[data.length - 1].indexLabel = "#total";
        data[data.length - 1].indexLabelPlacement = "outside";
      }
      const options = {
        theme: "light2",
        colorSet: "lightColorSet",
        exportEnabled: true,
        title: {
          text: "Non-electric transport fuel",
          fontSize: 20,
          fontFamily: fontFamily,
          //fontWeight: "bold",
        },
        legend: {
          fontFamily: fontFamily,
        },
        axisX: {
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
        },
        axisY: {
          title: "M tCO2e p.a.",
          titleFontFamily: fontFamily,
          labelFontFamily: fontFamily,
          valueFormatString:
            Math.max(transportTotal, initTransportTotal) >= 2000000
              ? "#,##0"
              : "0.0",
        },
        data: data,
      };

      return <CanvasJSChart options={options} />;
    } else {
      return "Loading...";
    }
  }
}

export default EDFSCarbonTransport;
