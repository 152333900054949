import React, { Component } from "react";
import { MDBBtnGroup, MDBBtn, MDBRangeInput, MDBInput } from "mdbreact";
import moment from "moment";
import CanvasJSReact from "../assets/js/canvasjs.react";
import { lightColorset } from "./colorsets";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("lightColorSet", lightColorset);

class EDFSStorageChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: "manual",
      month: 0,
      week: 0,
      viewportRange: { viewportMinimum: null, viewportMaximum: null },
    };

    this.months = [
      { abbrev: "", range: { viewportMinimum: null, viewportMaximum: null } },
    ];

    this.weeks = [{ viewportMinimum: null, viewportMaximum: null }];

    if (typeof props.storageData.pumped[0] === "object") {
      const startDate = props.storageData.pumped[0].x;
      let startMoment = moment(startDate);
      let i = 1;
      for (let monthAbbrev of [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "month");
        this.months[i] = {
          abbrev: monthAbbrev,
          range: {
            viewportMinimum: startMoment.toDate(),
            viewportMaximum: endMoment.toDate(),
          },
        };
        startMoment = endMoment;
        i++;
      }

      startMoment = moment(startDate);
      for (let i = 1; i < 54; i++) {
        const endMoment = startMoment.clone();
        endMoment.add(1, "week");
        this.weeks[i] = {
          viewportMinimum: startMoment.toDate(),
          viewportMaximum: endMoment.toDate(),
        };
        startMoment = endMoment;
      }
    }

    this.changeWeek = this.changeWeek.bind(this);
  }

  changeWeek(weekNo) {
    console.log(weekNo, this.weeks[weekNo]);
    this.setState({
      week: weekNo,
      viewportRange: this.weeks[weekNo],
    });
  }

  render() {
    console.log("Rendering EDFSStorageChart");
    //console.log("props", this.props);
    //const inflexibles = this.props.inflexiblesData || [];
    //const names = { pumped: "Pumped Storage", batteries: "Batteries" };
    const dataFields = [];
    let minFlow = 0;
    let maxFlow = 0;
    let maxBalance = 0;
    const marginalCharge = [];
    const marginalDischarge = [];
    let chargeStepSize = 0;
    let stepCount = 0;
    if (
      this.props.storageData &&
      this.props.storageData.pumped &&
      this.props.storageData.pumped.length
    ) {
      for (let item in this.props.storbalance) {
        dataFields.push({
          type: "stackedArea",
          name: this.props.techNames[item] + " charge (L)",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          dataPoints: this.props.storbalance[item],
        });
      }

      for (let item in this.props.storageData) {
        dataFields.push({
          type: "line",
          name: this.props.techNames[item] + " flow (R)",
          showInLegend: true,
          xValueFormatString: "D MMM HH:mm",
          yValueFormatString: "#,##0",
          axisYType: "secondary",
          dataPoints: this.props.storageData[item],
        });
        for (let i = 0; i < this.props.storageData[item].length; i++) {
          const thisFlow = this.props.storageData[item][i].y;
          if (thisFlow < minFlow) {
            minFlow = thisFlow;
          }
          if (thisFlow > maxFlow) {
            maxFlow = thisFlow;
          }
        }
      }
      for (let i = 0; i < this.props.storbalance.pumped.length; i++) {
        let thisBalance = 0;
        for (let item in this.props.storageData) {
          thisBalance += this.props.storbalance[item][i].y;
        }
        if (thisBalance > maxBalance) {
          maxBalance = thisBalance;
        }
      }

      // Calculate values for marginal-value chart
      let combCapacity = 0;
      let combPower = 0;
      let techs = [];
      let efficCap = 0;
      for (let tech of this.props.storageParams) {
        combCapacity += tech.current.capacity + tech.new.capacity;
        combPower += tech.current.power + tech.new.power;
        techs.push(tech.id);
        efficCap += tech.current.capacity * tech.current.efficiency;
        efficCap += tech.new.capacity * tech.new.efficiency;
      }
      const combEfficiency = efficCap / combCapacity;
      //const powerChargeRatio = Math.ceil(combCapacity / combPower);
      chargeStepSize =
        combCapacity >= 800000 ? 100000 : combCapacity >= 80000 ? 10000 : 1000;
      stepCount = Math.ceil(combCapacity / chargeStepSize);
      let powerStepSize = Math.ceil(combPower / stepCount);
      //chargeStepSize = powerStepSize * powerChargeRatio;

      const marginalBalance = [];
      for (let i = 0; i < stepCount; i++) {
        marginalCharge.push(0);
        marginalDischarge.push(0);
        marginalBalance.push(0);
      }
      let prevBalance = 0;
      for (let i = 0; i < this.props.storbalance.pumped.length; i++) {
        let currentBalance = 0;
        for (let tech of techs) {
          currentBalance += this.props.storbalance[tech][i].y;
        }
        let charging = currentBalance - prevBalance;
        prevBalance = currentBalance;
        for (let j = 0; j < stepCount; j++) {
          if (charging > 0) {
            const space = chargeStepSize - marginalBalance[j];
            const thisCharge = Math.min(space, charging, powerStepSize);
            marginalCharge[j] +=
              Math.ceil((10000 * thisCharge) / combEfficiency) / 100;
            marginalBalance[j] += thisCharge;
            charging -= thisCharge;
            if (charging <= 0) {
              break;
            }
          } else {
            const thisDischarge = Math.min(
              marginalBalance[j],
              Math.abs(charging),
              powerStepSize
            );
            marginalDischarge[j] += thisDischarge;
            marginalBalance[j] -= thisDischarge;
            charging += thisDischarge;
            if (charging >= 0) {
              break;
            }
          }
        }
      }
    }
    let factor = Math.pow(10, parseInt(maxFlow).toString().length - 2);
    let minAxis2 = factor * Math.floor(minFlow / factor);
    let maxAxis2 = -5 * minAxis2;
    if (maxAxis2 < maxFlow) {
      maxAxis2 = factor * Math.ceil(maxFlow / factor);
      minAxis2 = maxAxis2 / 5;
    }
    let intAxis2 = -1 * minAxis2;
    factor = Math.pow(10, parseInt(maxBalance).toString().length - 2);
    let maxAxis1 = factor * Math.ceil(maxBalance / factor);
    let intAxis1 = maxAxis1 / 5;
    let minAxis1 = -1 * intAxis1;
    if (maxAxis1 > maxAxis2) {
      if (maxAxis1 / maxAxis2 < 2) {
        minAxis2 = minAxis1;
        maxAxis2 = maxAxis1;
        intAxis2 = intAxis1;
      }
    } else {
      if (maxAxis2 / maxAxis1 < 2) {
        minAxis1 = minAxis2;
        maxAxis1 = maxAxis2;
        intAxis1 = intAxis2;
      }
    }

    //console.log("dataFields", dataFields);
    const options = {
      theme: "light2",
      colorSet: "lightColorSet",
      exportEnabled: true,
      zoomEnabled: true,
      rangeChanged: function (e) {
        const threshold = 300000000;
        const timediff =
          e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
        if (timediff > threshold || e.trigger === "reset") {
          this.options.axisX.valueFormatString = "D MMM";
        } else {
          this.options.axisX.valueFormatString = "D MMM HH:mm";
        }
        this.render();
      },
      zoomType: "x",
      title: {
        text: "Electricity storage",
      },
      axisX: {
        ...this.state.viewportRange,
        valueFormatString: "D MMM",
        labelAngle: 45,
      },
      axisY: {
        includeZero: true,
        title: "MWh",
        minimum: minAxis1,
        maximum: maxAxis1,
        interval: intAxis1,
      },
      axisY2: {
        includeZero: true,
        title: "MW",
        minimum: minAxis2,
        maximum: maxAxis2,
        interval: intAxis2,
      },
      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        itemclick: function (e) {
          //console.log("legend click: " + e.dataPointIndex);
          //console.log(e);
          if (
            typeof e.dataSeries.visible === "undefined" ||
            e.dataSeries.visible
          ) {
            e.dataSeries.visible = false;
          } else {
            e.dataSeries.visible = true;
          }

          e.chart.render();
        },
      },
      data: dataFields,
    };

    const chargeData = [];
    const dischargeData = [];
    for (let i = 0; i < stepCount; i++) {
      const tranche = i + 1;
      chargeData.push({ x: tranche, y: marginalCharge[i] });
      dischargeData.push({ x: tranche, y: marginalDischarge[i] });
    }
    const xinterval =
      stepCount > 100 ? 10 : stepCount > 40 ? 5 : stepCount > 20 ? 2 : 1;
    const options2 = {
      theme: "light2",
      exportEnabled: true,
      zoomEnabled: true,
      zoomType: "x",
      title: {
        text: "Marginal storage utilisation",
      },
      axisX: {
        valueFormatString: "#,##0",
        title:
          "Tranche of " +
          chargeStepSize.toLocaleString() +
          " MWh storage capacity",
        interval: xinterval,
      },
      axisY: {
        includeZero: true,
        title: "Utilisation (MWh p.a.)",
      },
      toolTip: {
        shared: true,
      },
      data: [
        {
          type: "line",
          name: "Charge",
          showInLegend: true,
          xValueFormatString: "Tranche #,##0",
          yValueFormatString: "#,##0",
          dataPoints: chargeData,
        },
        {
          type: "line",
          name: "Discharge",
          showInLegend: true,
          xValueFormatString: "Tranche #,##0",
          yValueFormatString: "#,##0",
          dataPoints: dischargeData,
        },
      ],
    };

    return (
      <>
        <div>
          <CanvasJSChart
            options={options}
            onRef={(ref) => (this.chart = ref)}
          />
          <div className="d-flex flex-row justify-content-center align-items-center mt-3">
            Zoom:
            <MDBBtnGroup size="sm">
              <MDBBtn
                active={this.state.zoom === "manual"}
                onClick={() =>
                  this.setState({
                    zoom: "manual",
                    viewportRange: this.months[0].range,
                  })
                }
              >
                Manual
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "month"}
                onClick={() =>
                  this.setState({
                    zoom: "month",
                    viewportRange: this.months[this.state.month].range,
                  })
                }
              >
                Month
              </MDBBtn>
              <MDBBtn
                active={this.state.zoom === "week"}
                onClick={() =>
                  this.setState({
                    zoom: "week",
                    viewportRange: this.weeks[this.state.week],
                  })
                }
              >
                Week
              </MDBBtn>
            </MDBBtnGroup>
          </div>
          {this.state.zoom === "month" && (
            <div className="d-flex flex-row justify-content-center align-items-center mt-2">
              <MDBBtnGroup size="sm">
                {this.months.map((object, index) => {
                  if (index > 0) {
                    return (
                      <MDBBtn
                        key={object.abbrev}
                        active={this.state.month === index}
                        className="px-2"
                        onClick={() =>
                          this.setState({
                            month: index,
                            viewportRange: this.months[index].range,
                          })
                        }
                      >
                        {object.abbrev}
                      </MDBBtn>
                    );
                  } else {
                    return "";
                  }
                })}
              </MDBBtnGroup>
            </div>
          )}
          {this.state.zoom === "week" && (
            <div className="d-flex flex-row align-items-center">
              <div className="justify-content-start mx-1">1</div>
              <div className="flex-fill mt-2">
                <MDBRangeInput
                  min={1}
                  max={53}
                  value={this.state.week}
                  getValue={this.changeWeek}
                />
              </div>
              <div className="justify-content-end mx-1">53</div>
              <div className="justify-content-end mx-1">
                <MDBInput
                  value={this.state.week}
                  size="sm"
                  min={1}
                  max={53}
                  type="number"
                  getValue={this.changeWeek}
                  outline
                />
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="mt-4">
          <CanvasJSChart options={options2} />
        </div>
      </>
    );
  }
}

export default EDFSStorageChart;
